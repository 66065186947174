<template>
  <div class="file-menu-container elevation-1 rounded rounded-l-0">
    <span
      class="file-menu-title d-block text-center font-weight-bold pt-3 pb-1"
    >
      {{ $t("components.fileMenu.title") }}
    </span>
    <v-row dense no-gutters class="file-menu-icon-container">
      <v-col cols="2">
        <icon-size-dropdown v-model="filesPerLine" />
      </v-col>
      <v-col cols="2">
        <v-tooltip top>
          <template #activator="{ on, attrs }">
            <v-btn
              x-small
              text
              @click="toggleListView"
              v-bind="attrs"
              v-on="on"
            >
              <v-icon :color="showListView ? 'primary' : undefined"
                >mdi-list-box-outline</v-icon
              ></v-btn
            >
          </template>
          <span>{{
            showListView
              ? $t("components.fileMenu.disableListView")
              : $t("components.fileMenu.enableListView")
          }}</span>
        </v-tooltip>
      </v-col>
      <v-col cols="2">
        <v-tooltip top>
          <template #activator="{ on, attrs }">
            <v-btn
              x-small
              text
              @click="toggleMultiSelect"
              v-bind="attrs"
              v-on="on"
            >
              <v-icon :color="multiSelectActive ? 'primary' : undefined"
                >mdi-file-check-outline</v-icon
              >
            </v-btn>
          </template>
          <span>
            {{
              multiSelectActive
                ? $t("components.fileMenu.disableMultiSelect")
                : $t("components.fileMenu.enableMultiSelect")
            }}
          </span>
        </v-tooltip>
      </v-col>
      <v-col cols="2">
        <sort-dropdown v-model="sorting" :disabled="isMenuLoading" />
      </v-col>
      <v-col cols="2">
        <file-type-dropdown
          v-model="visibleFileTypes"
          :options="fileTypeOptions"
        />
      </v-col>
      <v-col cols="2">
        <v-tooltip top>
          <template #activator="{ on, attrs }">
            <v-btn
              x-small
              text
              @click="toggleAssignedFiles"
              v-bind="attrs"
              v-on="on"
            >
              <v-icon :color="hideAssignedFiles ? 'primary' : undefined"
                >mdi-checkbox-marked-outline</v-icon
              >
            </v-btn>
          </template>
          <span>{{
            hideAssignedFiles
              ? $t("components.fileMenu.showAssignedFiles")
              : $t("components.fileMenu.hideAssignedFiles")
          }}</span>
        </v-tooltip>
      </v-col>
    </v-row>
    <v-row dense class="mx-0 mt-1">
      <v-col cols="9">
        <div class="d-flex align-center">
          <v-btn
            x-small
            text
            class="text-decoration-underline"
            @click="handleFolderSegmentClicked('/')"
          >
            {{ $t("components.fileMenu.rootFolder") }}
          </v-btn>
          <template v-for="segment in folderSegments">
            <span class="line-height-5 mx-1" :key="`${segment.path}-seperator`"
              >></span
            >
            <v-btn
              x-small
              text
              class="text-decoration-underline path-segment"
              :key="segment.path"
              @click="handleFolderSegmentClicked(segment.path)"
            >
              <span class="text-truncate">
                {{ segment.name }}
              </span>
            </v-btn>
          </template>
        </div>
      </v-col>
      <v-col cols="3">
        <div class="d-flex justify-end">
          <v-btn
            x-small
            text
            :disabled="!folderHistories.back.length || isMenuLoading"
            class="navigation-button mr-1"
            @click="folderNavigateBack"
          >
            <v-icon>mdi-chevron-left</v-icon>
          </v-btn>
          <v-btn
            x-small
            text
            :disabled="!folderHistories.forward.length || isMenuLoading"
            class="navigation-button mr-1"
            @click="folderNavigateForward"
          >
            <v-icon>mdi-chevron-right</v-icon>
          </v-btn>
        </div>
      </v-col>
    </v-row>
    <div
      class="fill-height file-list-container"
      :class="`${osFilesDragging ? 'active' : ''} ${
        osFilesHovered ? 'hovered' : ''
      }`"
      @dragenter="handleFileListDragEnter"
      @dragover="handleFileListDragOver"
      @dragleave="handleFileListDragLeave"
      @drop="handleFileListDrop"
    >
      <div class="d-flex pa-2 flex-column">
        <template v-if="!showListView">
          <v-row v-for="(chunk, index) in entryRows" :key="index" class="mx-0">
            <v-col
              v-for="(fileEntry, index) in chunk"
              :key="index"
              :cols="12 / filesPerLine"
              class="flex-grow-1"
            >
              <file-menu-entry
                :fileEntry="fileEntry"
                :draggingFileEntries="draggingFileEntries"
                :currentFolder="currentFolder"
                :filePerLine="filesPerLine"
                :multiSelectActive="multiSelectActive"
                :multiSelectedFileEntries="multiSelectFiles"
                @clicked="handleFileEntryClicked"
                @dragStarted="handleFileEntryDragStart"
                @dragEnded="handleFileEntryDragEnd"
              />
            </v-col>
          </v-row>
        </template>
        <template v-else>
          <v-list dense class="py-0" draggable="false">
            <file-menu-entry
              v-for="fileEntry in filteredFileEntries"
              :key="fileEntry.id"
              :fileEntry="fileEntry"
              :draggingFileEntries="draggingFileEntries"
              :currentFolder="currentFolder"
              :filePerLine="filesPerLine"
              :multiSelectActive="multiSelectActive"
              :multiSelectedFileEntries="multiSelectFiles"
              listView
              @clicked="handleFileEntryClicked"
              @dragStarted="handleFileEntryDragStart"
              @dragEnded="handleFileEntryDragEnd"
            />
          </v-list>
        </template>
      </div>
    </div>
    <div v-if="loading" class="loading-overlay">
      <v-progress-circular indeterminate color="primary"></v-progress-circular>
      <p class="mb-0 mt-2">{{ $t("components.fileMenu.loadingFiles") }}</p>
    </div>
  </div>
</template>
<script>
import throttle from "lodash/throttle";

import IconSizeDropdown from "./FileMenu/IconSizeDropdown.vue";
import SortDropdown from "./FileMenu/SortDropdown.vue";
import FileTypeDropdown from "./FileMenu/FileTypeDropdown.vue";
import FileMenuEntry from "./FileMenu/FileMenuEntry.vue";

import SvgPdf from "../../assets/svg/file-pdf-regular.svg";
import SvgExcel from "../../assets/svg/file-excel-regular.svg";
import SvgImage from "../../assets/svg/image-regular.svg";
import SvgFile from "../../assets/svg/file-regular.svg";
import { serialize } from "object-to-formdata";

export default {
  components: {
    IconSizeDropdown,
    SortDropdown,
    FileTypeDropdown,
    FileMenuEntry,
  },
  props: {
    refreshFiles: Boolean,
    osFilesDragging: Boolean,
    organizationId: String,
    entryType: String,
  },
  data() {
    return {
      fileEntries: [],
      filesPerLine: 3,
      showListView: false,
      sorting: {
        sortBy: "name",
        displayName: this.$t("components.fileMenu.sortOptions.name"),
        descending: false,
      },
      currentFolder: "/",
      folderHistories: {
        back: [],
        forward: [],
      },
      loading: false,
      loadingTimeout: null,
      draggingFileEntries: [],
      multiSelectActive: false,
      multiSelectFiles: [],
      hideAssignedFiles: false,
      osFilesHovered: false,
    };
  },
  emits: ["filesDraggingChanged", "filesRefreshed"],
  computed: {
    entryRows() {
      const chunks = [];

      for (
        let i = 0;
        i < this.filteredFileEntries.length;
        i += this.filesPerLine
      ) {
        const chunk = this.filteredFileEntries.slice(i, i + this.filesPerLine);
        chunks.push(chunk);
      }

      return chunks;
    },
    folderSegments() {
      const segments = [];

      const maxSegments = 2;

      let remainingPath = this.currentFolder;

      while (
        !!remainingPath &&
        remainingPath.includes("/") &&
        segments.length < maxSegments
      ) {
        const index = remainingPath.lastIndexOf("/");

        if (index === -1) break;

        const name = remainingPath.substring(index + 1);

        if (name.trim() === "") break;

        segments.push({
          name: name,
          path: remainingPath,
        });

        remainingPath = remainingPath.substring(0, index);
      }

      return segments.reverse();
    },
    isMenuLoading() {
      return !!this.loadingTimeout || this.loading;
    },
    filteredFileEntries() {
      if (this.visibleFileTypes.includes("other")) {
        const includeImages = this.visibleFileTypes.includes("image");
        const includePdfs = this.visibleFileTypes.includes("pdf");
        const includeSpreadsheets =
          this.visibleFileTypes.includes("spreadsheet");

        return this.fileEntries.filter(
          (x) =>
            (x.fileType === "folder" ||
              (includeImages && x.fileType === "image") ||
              (includePdfs && x.fileType === "pdf") ||
              (includeSpreadsheets && x.fileType === "spreadsheet") ||
              x.fileType === "other") &&
            (!this.hideAssignedFiles || !x[this.hasConnectionProp])
        );
      }

      return this.fileEntries.filter(
        (x) =>
          x.fileType === "folder" || this.visibleFileTypes.includes(x.fileType)
      );
    },
    hasConnectionProp() {
      switch (this.entryType) {
        case "asset":
          return "hasAssetConnection";
        case "solution":
          return "hasSolutionConnection";
        default:
          return null;
      }
    },
    currentSection() {
      switch (this.entryType) {
        case "asset":
          return "assets";
        case "solution":
          return "solutions";
        default:
          return null;
      }
    },
    fileTypeOptions() {
      return [
        {
          text: this.$t("components.fileMenu.fileTypes.images"),
          value: "image",
          iconSrc: SvgImage,
        },
        {
          text: this.$t("components.fileMenu.fileTypes.pdfs"),
          value: "pdf",
          iconSrc: SvgPdf,
        },
        {
          text: this.$t("components.fileMenu.fileTypes.spreadsheets"),
          value: "spreadsheet",
          iconSrc: SvgExcel,
        },
        {
          text: this.$t("components.fileMenu.fileTypes.allOthers"),
          value: "other",
          iconSrc: SvgFile,
        },
      ];
    },
    visibleFileTypes() {
      return this.fileTypeOptions.map((x) => x.value);
    },
  },
  methods: {
    getFiles(
      folder = "/",
      forwardNavigation = true,
      preserveForwardHistory = false
    ) {
      if (this.isMenuLoading) return Promise.resolve();

      this.loadingTimeout = setTimeout(() => {
        this.loading = true;
        this.loadingTimeout = null;
      }, 300);

      const folderChanged = folder !== this.currentFolder;

      return fetch(
        this.route("api.files.get", {
          section: this.currentSection,
          folder: folder,
          sortBy: this.sorting.sortBy,
          sortDescending: this.sorting.descending,
        })
      )
        .then((res) => res.json())
        .then((data) => {
          if (folderChanged) {
            if (forwardNavigation) {
              this.folderHistories.back.push(this.currentFolder);

              if (!preserveForwardHistory) this.folderHistories.forward = [];
            } else {
              this.folderHistories.forward.push(this.currentFolder);
            }
          }

          if (this.loadingTimeout) {
            clearTimeout(this.loadingTimeout);
            this.loadingTimeout = null;
          }

          this.fileEntries = data;
          this.currentFolder = folder ?? "/";
          this.loading = false;
        });
    },
    handleFileEntryClicked(fileEntry) {
      if (fileEntry.fileType === "folder") {
        const searchFolder = this.currentFolder.endsWith("/")
          ? this.currentFolder
          : this.currentFolder + "/";

        this.getFiles(searchFolder + fileEntry.name);
        return;
      } else if (this.multiSelectActive) {
        if (this.multiSelectFiles.includes(fileEntry)) {
          this.multiSelectFiles = this.multiSelectFiles.filter(
            (x) => x !== fileEntry
          );
          return;
        }

        this.multiSelectFiles.push(fileEntry);
        return;
      }
    },
    handleFileEntryDragStart(event, fileEntry) {
      let fileEntryIds = [fileEntry.id];

      if (this.multiSelectActive) {
        const multiSelectIds = this.multiSelectFiles.map((x) => x.id);

        fileEntryIds = fileEntryIds.concat(multiSelectIds);
      }

      fileEntryIds = [...new Set(fileEntryIds)];

      event.dataTransfer.setData(
        "filemenu:selectedFileIds",
        JSON.stringify(fileEntryIds)
      );
      event.dataTransfer.effectAllowed = "link";

      this.draggingFileEntries = [fileEntry];
      this.$emit("filesDraggingChanged", true);
    },
    handleFileEntryDragEnd() {
      this.draggingFileEntries = [];
      this.$emit("filesDraggingChanged", false);
    },
    handleFolderSegmentClicked(folder) {
      if (folder === this.currentFolder) return;

      this.getFiles(folder);
    },
    handleFileListDragEnter(event) {
      if (!event.dataTransfer.types.some((x) => x === "Files")) return;

      event.preventDefault();

      this.osFilesHovered = true;
    },
    handleFileListDragOver(event) {
      if (!event.dataTransfer.types.some((x) => x === "Files")) return;

      event.preventDefault();

      event.dataTransfer.dropEffect = "copy";

      this.osFilesHovered = true;
    },
    handleFileListDragLeave(event) {
      if (!event.dataTransfer.types.some((x) => x === "Files")) return;

      this.osFilesHovered = false;
    },
    handleFileListDrop(event) {
      event.preventDefault();

      this.osFilesHovered = false;

      if (
        !event.dataTransfer.types.some((x) => x === "Files") ||
        !event.dataTransfer.files.length ||
        !this.organizationId
      )
        return;

      const fileUploadForm = {
        files: [...event.dataTransfer.files],
        path: this.currentFolder,
        section: this.currentSection,
        organizationId: this.organizationId,
      };

      const formData = serialize(fileUploadForm, {
        noFilesWithArrayNotation: true,
      });

      fetch(this.route("api.files.store"), {
        method: "POST",
        body: formData,
      }).then(() => {
        this.getFiles(this.currentFolder);
      });
      // TODO: Upload to new files API endpoint
    },
    folderNavigateBack() {
      if (!this.folderHistories.back.length) return;

      const folder = this.folderHistories.back.pop();
      this.getFiles(folder, false);
    },
    folderNavigateForward() {
      if (!this.folderHistories.forward.length) return;

      const folder = this.folderHistories.forward.pop();
      this.getFiles(folder, true, true);
    },
    toggleListView() {
      this.showListView = !this.showListView;
    },
    toggleMultiSelect() {
      this.multiSelectActive = !this.multiSelectActive;

      if (!this.multiSelectActive) {
        this.multiSelectFiles = [];
      }
    },
    toggleAssignedFiles() {
      this.hideAssignedFiles = !this.hideAssignedFiles;
    },
  },
  watch: {
    sorting: {
      handler: throttle(function () {
        this.getFiles(this.currentFolder);
      }, 150),
      deep: true,
    },
    refreshFiles: function (value) {
      if (!value) return;

      this.getFiles(this.currentFolder);
      this.$emit("filesRefreshed");
    },
  },
  created() {
    this.getFiles();
  },
};
</script>
<style scoped>
.file-menu-container {
  position: relative;
  background: #e2dee3;
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
}

.file-menu-container .loading-overlay {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: #ffffff;
  opacity: 0.8;
}

.file-menu-title {
  color: rgba(0, 0, 0, 0.6);
  font-size: 0.9rem;
}

.file-menu-icon-container {
  border: thin solid rgba(0, 0, 0, 0.12);
  border-width: 1px 0;
  margin-top: 10px;
  padding-top: 12px;
  padding-bottom: 11px;
  width: 100%;
  text-align: center;
}

.navigation-button {
  padding: 0 !important;
  min-width: initial !important;
}

.line-height-5 {
  line-height: 1.25rem;
}

.path-segment {
  text-transform: none;
  max-width: 70px;
  padding: 0 4px !important;
}

.file-list-container.active {
  outline: 2px dashed gray;
  outline-offset: -2px;
}

.file-list-container.hovered {
  outline: 2px solid #00695c;
  outline-offset: -2px;
}
</style>
<style>
.path-segment > .v-btn__content {
  width: 100%;
}
</style>
