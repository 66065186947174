<template>
  <div>
    <!--- Front page --->
    <div class="print-page d-flex flex-column">
      <div style="position: absolute; top: 80px; left: 80px">
        <PoweredByVerarca></PoweredByVerarca>
      </div>
      <div
        class="cover-content d-flex flex-column align-center justify-center flex-grow-1"
      >
        <p class="report-title">Carbon Emission Report</p>
        <p class="company-name">{{ organization.name }}</p>
        <div
          class="report-date-range d-flex flex-row align-center justify-center border-right"
        >
          {{ formattedDateRange.from }}
          <ArrowRightIcon class="mx-4"></ArrowRightIcon>
          {{ formattedDateRange.to }}
        </div>
      </div>
      <div
        style="position: absolute; bottom: 0; left: 0; right: 0"
        class="d-flex align-center justify-center"
      >
        <ReportTitleGraphics width="1313px" height="371px" />
      </div>
    </div>
    <!--- page 1 --->
    <div class="print-page d-flex">
      <CarbonFootprintOverview
        :organization="organization"
      ></CarbonFootprintOverview>
      <div style="position: absolute; bottom: 50px; left: 50px">
        <PoweredByVerarca></PoweredByVerarca>
      </div>
      <div
        style="position: absolute; bottom: 50px; right: 80px; text-align: right"
      >
        1 / 6
      </div>
    </div>
    <!--- page 2 --->
    <div class="print-page d-flex">
      <TotalGhgAndScopes></TotalGhgAndScopes>
      <div style="position: absolute; bottom: 50px; left: 50px">
        <PoweredByVerarca></PoweredByVerarca>
      </div>
      <div
        style="position: absolute; bottom: 50px; right: 80px; text-align: right"
      >
        2 / 6
      </div>
    </div>
    <!--- page 3 --->
    <div class="print-page d-flex">
      <EmissionsByGhgs></EmissionsByGhgs>
      <div style="position: absolute; bottom: 50px; left: 50px">
        <PoweredByVerarca></PoweredByVerarca>
      </div>
      <div
        style="position: absolute; bottom: 50px; right: 80px; text-align: right"
      >
        3 / 6
      </div>
    </div>

    <!-- Appendix -->
    <div class="print-page d-flex">
      <ReportAppendix1></ReportAppendix1>
      <div style="position: absolute; bottom: 50px; left: 50px">
        <PoweredByVerarca></PoweredByVerarca>
      </div>
      <div
        style="position: absolute; bottom: 50px; right: 80px; text-align: right"
      >
        4 / 6
      </div>
    </div>
    <div class="print-page d-flex">
      <ReportAppendix2></ReportAppendix2>
      <div style="position: absolute; bottom: 50px; left: 50px">
        <PoweredByVerarca></PoweredByVerarca>
      </div>
      <div
        style="position: absolute; bottom: 50px; right: 80px; text-align: right"
      >
        5 / 6
      </div>
    </div>
    <div class="print-page d-flex">
      <ReportAppendix3></ReportAppendix3>
      <div style="position: absolute; bottom: 50px; left: 50px">
        <PoweredByVerarca></PoweredByVerarca>
      </div>
      <div
        style="position: absolute; bottom: 50px; right: 80px; text-align: right"
      >
        6 / 6
      </div>
    </div>
    <div class="print-page d-flex">
      <div class="d-flex align-center justify-center flex-grow-1">
        <VerarcaLargeLogo></VerarcaLargeLogo>
      </div>
    </div>
  </div>
</template>

<script>
import ArrowRightIcon from "../../assets/svg/arrow-right-dark-20px.svg";
import ReportTitleGraphics from "../../assets/svg/report-title-graphics-en.svg";
import PoweredByVerarca from "../../assets/svg/powered-by-verarca.svg";
import VerarcaLargeLogo from "../../assets/svg/verarca-large-logo.svg";

import CarbonFootprintOverview from "./CarbonFootprintOverview.vue";
import TotalGhgAndScopes from "./TotalGhgAndScopes.vue";
import EmissionsByGhgs from "./EmissionsByGhgs.vue";
import ReportAppendix1 from "./ReportAppendix1.vue";
import ReportAppendix2 from "./ReportAppendix2.vue";
import ReportAppendix3 from "./ReportAppendix3.vue";

import GlobalDateRange from "../../mixins/GlobalDateRange.vue";

export default {
  components: {
    ArrowRightIcon,
    ReportTitleGraphics,
    PoweredByVerarca,
    CarbonFootprintOverview,
    TotalGhgAndScopes,
    EmissionsByGhgs,
    ReportAppendix1,
    ReportAppendix2,
    ReportAppendix3,
    VerarcaLargeLogo,
  },
  mixins: [GlobalDateRange],
  props: {
    organization: Object,
  },
  data() {
    return {
      dateRange: { from: "", to: "" },
      companyName: "",
    };
  },
  mounted() {
    this.syncDateRange((dateRange) => {
      this.dateRange = dateRange;
    });
  },
  computed: {
    formattedDateRange() {
      const formattedFrom = this.formatDate(this.dateRange?.from);
      const formattedTo = this.formatDate(this.dateRange?.to);
      return { from: formattedFrom, to: formattedTo };
    },
  },
  methods: {
    formatDate(dateString) {
      const date = new Date(dateString);
      const options = {
        weekday: "short",
        day: "numeric",
        month: "short",
        year: "numeric",
      };
      const localDateString = date.toLocaleDateString("en-us", options);
      return localDateString;
    },
  },
};
</script>

<style lang="scss" scoped>
.print-page {
  position: relative;
  width: 1684px;
  height: calc(1684px / 1.4142135624); //1190.7678195181px;
  background-color: #f9f9f9;
  border-bottom: 1px solid #bfbfbf;

  .cover-content {
    margin-bottom: 169px !important;
  }

  .report-title {
    color: #33333399;
    font-size: 60px;
  }

  .company-name {
    color: #333333;
    font-size: 90px;
  }

  .report-date-range {
    color: #131313;
    font-size: 28px;
    path {
      fill: #131313;
    }
  }
}
</style>
