<template>
  <dialog-form :show-dialog="show" max-width="800px" persistent>
    <dialog-form-header
      :title="$t('components.onboarding.noMappingSuccess.title')"
    />
    <dialog-form-section-one-col>
      <div class="d-flex align-center flex-column">
        <v-avatar color="#D0DFEB" class="pa-4 my-3" height="64px" width="64px">
          <v-icon color="#6493BE" size="30px">mdi-connection</v-icon>
        </v-avatar>
        <p class="text-h6 black--text text-center font-weight-regular">
          {{ $t("components.onboarding.noMappingSuccess.heading") }}
        </p>
      </div>
      <p class="orange--text mb-1">
        {{ $t("components.onboarding.noMappingSuccess.howEver") }}
      </p>
      <p>{{ $t("components.onboarding.noMappingSuccess.description") }}</p>
      <p class="mb-3">
        {{ $t("components.onboarding.noMappingSuccess.howTo") }}
      </p>
      <code class="pa-2 d-flex align-center mb-3"
        >{{ $t("components.onboarding.noMappingSuccess.settings") }}
        <v-icon class="px-2">mdi-chevron-right</v-icon>
        {{ $t("components.onboarding.noMappingSuccess.integrations") }}
        <v-icon class="px-2">mdi-chevron-right</v-icon>
        <v-icon>mdi-dots-vertical</v-icon>
        <v-icon class="px-2">mdi-chevron-right</v-icon>
        {{ $t("components.onboarding.noMappingSuccess.accounts") }}
      </code>
    </dialog-form-section-one-col>
    <v-card-actions class="d-flex flex-row footer-section pa-8">
      <v-spacer></v-spacer>
      <v-btn
        tile
        outlined
        large
        elevation="0"
        bg-color="white"
        class="border-sm text-none black--text black--border white ml-4"
        @click="show = false"
        style="flex: 1"
      >
        {{ $t("components.onboarding.noMappingSuccess.goBack") }}
      </v-btn>
      <v-btn
        tile
        large
        elevation="0"
        color="orange"
        class="font-weight-regular text-none white--text"
        type="submit"
        @click="confirm()"
        style="flex: 1"
      >
        {{ $t("components.onboarding.noMappingSuccess.continueLater") }}
      </v-btn>
      <!-- Hiding the api fields popup currently because they are not used -->
    </v-card-actions>
  </dialog-form>
</template>

<script>
import DialogForm from "../Dialog/DialogForm.vue";
import DialogFormHeader from "../Dialog/DialogFormHeader.vue";
import DialogFormSectionOneCol from "../Dialog/DialogFormSectionOneCol.vue"; 
export default {
  components: {
    DialogForm,
    DialogFormHeader,
    DialogFormSectionOneCol, 
  },
  props: {
    value: Boolean,
  },
  methods: {
    confirm() {
      this.show = false;
      this.$emit("confirm-skip");
    },
  },
  computed: {
    show: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit("input", value);
      },
    },
  },
};
</script>

<style lang="scss" scoped></style>
