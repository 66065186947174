<template>
  <div class="flex-grow-1 d-flex justify-center align-center">
    <div class="d-flex justify-center align-center">
      <div class="px-0" fluid style="width: 1313px">
        <v-card
          light
          color="#fff"
          elevation="0"
          rounded="0"
          max-width="100%"
          class="flex-grow-1"
        >
          <div class="px-10 py-10">
            <v-row>
              <v-col cols="4"
                ><p class="page-title">The Three Scopes</p>
                <p class="page-description">
                  The GHG Protocol divides an organization's emissions into
                  three main categories, referred to as scopes: Scope 1, Scope
                  2, and Scope 3. These three main categories provide a
                  comprehensive overview of CO2 emissions throughout the supply
                  chain and enable the identification of reduction
                  opportunities.
                </p>
              </v-col>
              <v-col cols="8" class="d-flex">
                <div
                  class="flex-grow-1 d-flex flex-column justify-space-between"
                >
                  <div class="mt-8">
                    <v-row>
                      <v-col col="2"><CloudNo2Icon></CloudNo2Icon></v-col>
                      <v-col col="2"><CloudCh2Icon></CloudCh2Icon></v-col>
                      <v-col col="2"><CloudPfcsIcon></CloudPfcsIcon></v-col>
                      <v-col col="2"><CloudCo2Icon></CloudCo2Icon></v-col>
                      <v-col col="2"><CloudHcfsIcon></CloudHcfsIcon></v-col>
                      <v-col col="2"><CloudSf6Icon></CloudSf6Icon></v-col>
                    </v-row>
                  </div>
                  <div>
                    <v-row>
                      <v-col col="3">
                        <div class="d-flex align-center flex-column">
                          <span class="scope-label scope-3">Scope</span>
                          <span>Indirect</span>
                        </div>
                      </v-col>
                      <v-col col="3">
                        <div class="d-flex align-center flex-column">
                          <span class="scope-label scope-1">Scope</span>
                          <span>Direct</span>
                        </div>
                      </v-col>
                      <v-col col="3">
                        <div class="d-flex align-center flex-column">
                          <span class="scope-label scope-2">Scope</span>
                          <span>Indirect</span>
                        </div>
                      </v-col>
                      <v-col col="3">
                        <div class="d-flex align-center flex-column">
                          <span class="scope-label scope-3">Scope</span>
                          <span>Indirect</span>
                        </div>
                      </v-col>
                    </v-row>
                  </div>
                </div>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="4">
                <div class="info-box">
                  <p>
                    <strong>Scope 1</strong> covers emissions from sources that
                    an organisation owns or controls directly - for example from
                    burning fuel in company vehicles (if they're not
                    electrically-powered).
                  </p>
                  <p>
                    <strong>Scope 2</strong> represents indirect emissions from
                    the production of purchased energy from a utility company.
                    In other words, it includes all greenhouse gas emissions
                    released into the atmosphere as a result of consuming
                    purchased electricity, steam, heating, and cooling.
                  </p>
                  <p>
                    <strong>Scope 3</strong> represents indirect emissions that
                    occur within a company's value chain, including both
                    upstream and downstream emissions. In other words, these
                    emissions are associated with a company's operations.
                    According to the GHG Protocol, scope 3 emissions are divided
                    into 15 categories.
                  </p>
                </div>
              </v-col>
              <v-col cols="8">
                <v-row>
                  <v-col col="3">
                    <div class="house">
                      <div>
                        <PurchasedGoodsServicesIcon
                          width="18px"
                          height="17px"
                        ></PurchasedGoodsServicesIcon>
                        <p>Purchased goods and services</p>
                      </div>
                      <div>
                        <CapitalGoodsIcon
                          width="22px"
                          height="22px"
                        ></CapitalGoodsIcon>
                        <p>Capital goods</p>
                      </div>
                      <div>
                        <FueldAndEnergyRelatedActivitiesIcon
                          width="16px"
                          height="18px"
                        ></FueldAndEnergyRelatedActivitiesIcon>
                        <p>Fuel- & energy-related activities</p>
                      </div>
                      <div>
                        <DownstreamTransportationAndDistributionIcon
                          width="17px"
                          height="18px"
                        ></DownstreamTransportationAndDistributionIcon>
                        <p>Transportation & distribution</p>
                      </div>
                      <div>
                        <WasteGeneratedInOperationsIcon
                          width="18px"
                          height="19px"
                        ></WasteGeneratedInOperationsIcon>
                        <p>Waste generated in operations</p>
                      </div>
                      <div>
                        <BusinessTravelIcon
                          width="18px"
                          height="18px"
                        ></BusinessTravelIcon>
                        <p>Business travel</p>
                      </div>
                      <div>
                        <EmploteeCommutingIcon
                          width="20px"
                          height="15px"
                        ></EmploteeCommutingIcon>
                        <p>Employee commuting</p>
                      </div>
                      <div>
                        <UpstreamLeasedAssetsIcon
                          width="21px"
                          height="20px"
                        ></UpstreamLeasedAssetsIcon>
                        <p>Upstream leased assets</p>
                      </div>
                    </div>
                  </v-col>
                  <v-col col="3">
                    <div class="house">
                      <div>
                        <CompanyFacilitiesIcon
                          width="21px"
                          height="21px"
                        ></CompanyFacilitiesIcon>
                        <p>Company facilities</p>
                      </div>
                      <div>
                        <CompanyVeiclesIcon
                          width="32px"
                          height="24px"
                        ></CompanyVeiclesIcon>
                        <p>Company vehicles</p>
                      </div>
                    </div>
                  </v-col>
                  <v-col col="3">
                    <div class="house">
                      <div>
                        <ElectricityHeatingIcon
                          width="21px"
                          height="21px"
                        ></ElectricityHeatingIcon>
                        <p>
                          Purchased electricity, steam, heating & cooling for
                          own use
                        </p>
                      </div>
                    </div>
                  </v-col>
                  <v-col col="3">
                    <div class="house">
                      <div>
                        <DownstreamTransportationAndDistributionIcon
                          width="23px"
                          height="24px"
                        ></DownstreamTransportationAndDistributionIcon>
                        <p>Transportation & distribution</p>
                      </div>
                      <div>
                        <ProcessingOfSoldProductsIcon
                          width="23px"
                          height="24px"
                        ></ProcessingOfSoldProductsIcon>
                        <p>Processing of sold products</p>
                      </div>
                      <div>
                        <UseOfSoldProductsIcon
                          width="23px"
                          height="24px"
                        ></UseOfSoldProductsIcon>
                        <p>Use of sold products</p>
                      </div>
                      <div>
                        <EndOfLifeTreatmentOfSoldProductsIcon
                          width="23px"
                          height="24px"
                        ></EndOfLifeTreatmentOfSoldProductsIcon>
                        <p>End-of-life processing of sold products</p>
                      </div>
                      <div>
                        <DownStreamLeasedAssetsIcon
                          width="23px"
                          height="24px"
                        ></DownStreamLeasedAssetsIcon>
                        <p>Downstream leased assets</p>
                      </div>
                    </div>
                  </v-col>
                </v-row>
                <div class="d-flex flex-row mt-10">
                   <div class="arrow flex-grow-1">UPSTREAM ACTIVITIES</div>
                   <div class="arrow light flex-grow-1">REPORTING COMPANY</div>
                   <div class="arrow flex-grow-1">DOWNSTREAM ACTIVITIES</div>
                </div>
              </v-col>
            </v-row>
          </div>
        </v-card>
      </div>
    </div>
  </div>
</template>

<script>
import CloudNo2Icon from "../../assets/svg/cloud-no2.svg";
import CloudCh2Icon from "../../assets/svg/cloud-ch4.svg";
import CloudPfcsIcon from "../../assets/svg/cloud-pfcs.svg";
import CloudCo2Icon from "../../assets/svg/cloud-co2.svg";
import CloudHcfsIcon from "../../assets/svg/cloud-hcfs.svg";
import CloudSf6Icon from "../../assets/svg/cloud-sf6.svg";

import CompanyFacilitiesIcon from "../../../src/assets/svg/report-company-facilities.svg";
import CapitalGoodsIcon from "../../../src/assets/svg/report-capital-goods.svg";
import ElectricityHeatingIcon from "../../../src/assets/svg/report-electricity-and-heating.svg";
import BusinessTravelIcon from "../../../src/assets/svg/report-business-travel.svg";
import CompanyVeiclesIcon from "../../../src/assets/svg/report-company-vehicles.svg";
import DownStreamLeasedAssetsIcon from "../../../src/assets/svg/report-downstream-leased-assets.svg";
import DownstreamTransportationAndDistributionIcon from "../../../src/assets/svg/report-downstream-transportation-and-distribution.svg";
import EmploteeCommutingIcon from "../../../src/assets/svg/report-employee-commuting.svg";
import EndOfLifeTreatmentOfSoldProductsIcon from "../../../src/assets/svg/report-end-of-life-treatment-of-sold-products.svg";
import FueldAndEnergyRelatedActivitiesIcon from "../../../src/assets/svg/report-fuel-and-energy-related-activities.svg";
import ProcessingOfSoldProductsIcon from "../../../src/assets/svg/report-processing-of-sold-products.svg";
import PurchasedGoodsServicesIcon from "../../../src/assets/svg/report-purchased-goods-services.svg";
import UpstreamLeasedAssetsIcon from "../../../src/assets/svg/report-upstream-leased-assets.svg";
import WasteGeneratedInOperationsIcon from "../../../src/assets/svg/report-waste-generated-in-operations.svg";
import UseOfSoldProductsIcon from "../../../src/assets/svg/report-use-of-sold-products.svg";

export default {
  components: {
    CloudNo2Icon,
    CloudCh2Icon,
    CloudPfcsIcon,
    CloudCo2Icon,
    CloudHcfsIcon,
    CloudSf6Icon,
    PurchasedGoodsServicesIcon,
    CapitalGoodsIcon,
    FueldAndEnergyRelatedActivitiesIcon,
    DownstreamTransportationAndDistributionIcon,
    WasteGeneratedInOperationsIcon,
    BusinessTravelIcon,
    EmploteeCommutingIcon,
    UpstreamLeasedAssetsIcon,
    CompanyVeiclesIcon,
    CompanyFacilitiesIcon,
    ElectricityHeatingIcon,
    ProcessingOfSoldProductsIcon,
    UseOfSoldProductsIcon,
    EndOfLifeTreatmentOfSoldProductsIcon,
    DownStreamLeasedAssetsIcon,
  },
};
</script>

<style lang="scss" scoped>
.page-title {
  font-size: 35px;
}

.page-description {
  font-size: 14px;
  color: #666666;
}

.info-box {
  background-color: #266663;
  padding: 35px;
  p {
    color: #d1d1d1;
    font-size: 13px;
    strong {
      font-weight: normal;
      color: #fff;
    }
    &:last-child {
      margin-bottom: 0;
    }
  }
}

.scope-label {
  font-size: 20px;
  color: #131313;
  position: relative;
  display: inline-block;
  margin-right: 25px;
  &:after {
    content: "";
    font-size: 17px;
    border-radius: 7px;
    width: 23px;
    height: 23px;
    display: block;
    text-align: center;
    align-items: center;
    display: flex;
    justify-content: center;
    color: #fff;
    position: absolute;
    right: -28px;
    top: 3px;
    line-height: 0;
  }

  &.scope-1:after {
    content: "1";
    background-color: #266663;
  }
  &.scope-2:after {
    content: "2";
    background-color: #6493be;
  }
  &.scope-3:after {
    content: "3";
    background-color: #f25d3b;
  }
}

.house {
  background-color: #f4f4f4;
  position: relative;
  height: 100%;
  padding-top: 10px;
  padding-bottom: 10px;

  &:before {
    content: "";
    border-bottom: 25px solid #f4f4f4;
    border-left: 95px solid transparent;
    border-right: 90px solid transparent;
    width: 100%;
    height: 0px;
    display: block;
    position: absolute;
    top: -24px;
  }
  div {
    padding: 10px 16px;
    margin-bottom: 0;
    display: flex;
    align-items: center;
    svg {
      position: absolute;
      left: 16px;
    }
    p {
      flex: 1;
      padding-left: 40px;
      margin-bottom: 0;
      font-size: 11px;
      color: #686868;
    }
  }
}

.arrow {
  height: 48px;
  width: 250px;
  background: #b7b7b7;
  position: relative;
  align-items: center;
  display:flex;
  justify-content: center;
  color: #fff;
  font-weight: 500;
  margin-right: 8px;
}

.arrow:before {
  content: "";
  border-bottom: 24px solid transparent;
  border-left: 18px solid #fff;
  border-top: 24px solid transparent;
  width: 10px;
  height: 0px;
  display: block;
  position: absolute;
  left: 0;
  top: 0;
}

.arrow:after {
  content: "";
  border-bottom: 24px solid transparent;
  border-left: 18px solid #b7b7b7;
  border-top: 24px solid transparent;
  width: 10px;
  height: 0px;
  display: block;
  position: absolute;
  right: -18px;
  top: 0;
  z-index: 99;
}

.arrow.light {
  background-color: #f4f4f4;
  color: #666666;
  &:after {
    border-left: 18px solid #f4f4f4;
  }
}
</style>
