<template>
  <div>
    <DialogFormHeader
      :title="$t('pages.assets.import.title')"
    ></DialogFormHeader>
    <DialogFormSectionOneCol>
      <i18n
        path="pages.assets.import.stepFour.importReportCreate"
        tag="p"
        class="text-body-1 mb-8"
      >
        <span class="font-weight-bold">{{ importCount }}</span>
        <span class="font-weight-bold">{{ portfolioName }}</span>
      </i18n>
    </DialogFormSectionOneCol>
  </div>
</template>
<script>
import DialogFormHeader from "../../Dialog/DialogFormHeader.vue";
import DialogFormSectionOneCol from "../../Dialog/DialogFormSectionOneCol.vue";

export default {
  props: {
    importCount: Number,
    portfolioName: String,
  },
  components: { DialogFormSectionOneCol, DialogFormHeader },
};
</script>
