<template>
  <v-tab-item>
    <div class="fill-height fill-width">
      <v-row class="black--text">
        <v-col cols="12" sm="5">
          <v-row dense>
            <v-col>
              <span>{{
                $t("pages.portfolios.form.fields.economicAccounts")
              }}</span>
            </v-col>
          </v-row>
          <v-row dense>
            <v-col>
              <form autocomplete="off" @submit.prevent="() => {}">
                <v-autocomplete
                  :value="form.economicAccountIds"
                  :items="economicAccounts"
                  :filter="filterEconomicAccounts"
                  item-text="name"
                  item-value="id"
                  name="economicAccounts"
                  solo
                  dense
                  multiple
                  :error-messages="form.errors.economicAccountIds"
                  @input="updateForm('economicAccountIds', $event)"
                >
                  <template #item="{ item, on, attrs }">
                    <v-list-item v-on="on" v-bind="attrs">
                      <v-list-item-content>
                        <v-list-item-subtitle>
                          {{ item.accountNumber }}
                        </v-list-item-subtitle>
                        <v-list-item-title>
                          {{ item.name }}
                        </v-list-item-title>
                        <v-list-item-subtitle v-if="item.accountType">
                          {{
                            $t(
                              `common.economic.accountTypes.${item.accountType}`
                            )
                          }}
                        </v-list-item-subtitle>
                      </v-list-item-content>
                    </v-list-item>
                  </template>
                </v-autocomplete>
              </form>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </div>
  </v-tab-item>
</template>
<script>
export default {
  props: {
    form: Object,
    economicAccounts: Array,
  },
  methods: {
    updateForm(key, value) {
      this.$emit("updateForm", key, value);
    },
    filterEconomicAccounts(item, queryText) {
      const name = item?.name ?? "";
      const accountNumber = (item?.accountNumber ?? "") + "";

      return name.includes(queryText) || accountNumber.includes(queryText);
    },
  },
};
</script>
