import { render, staticRenderFns } from "./Copilot.vue?vue&type=template&id=6d160aa6&scoped=true"
import script from "./Copilot.vue?vue&type=script&lang=js"
export * from "./Copilot.vue?vue&type=script&lang=js"
import style0 from "./Copilot.vue?vue&type=style&index=0&id=6d160aa6&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6d160aa6",
  null
  
)

export default component.exports