<template>
  <v-tab-item>
    <dialog-form-section-one-col no-bottom-border>
      <v-col cols="12" class="d-flex flex-column fill-height">
        <v-row dense class="flex-grow-0">
          <v-col>
            <span>{{ $t("pages.portfolios.form.fields.attributes") }}</span>
          </v-col>
        </v-row>
        <v-row dense class="flex-grow-0">
          <v-col>
            <dialog-form-text-input
              v-model="attributeFilter"
              solo
              dense
              type="text"
              :placeholder="$t('pages.portfolios.form.searchAttributes')"
              hide-details
            >
            </dialog-form-text-input>
          </v-col>
        </v-row>
        <v-row dense style="max-height: calc(100% - 76px)">
          <v-col class="fill-height">
            <div class="solo-item-list-area mt-4 pa-1">
              <div
                v-for="attribute in filteredAttributes"
                :key="attribute.id"
                :class="{
                  selected: selectedAttribute?.id === attribute.id,
                }"
                class="px-1"
                @click="selectAttribute(attribute)"
              >
                <span>{{ attribute.formattedName }}</span>
                <v-icon
                  v-if="attribute.hasDefaultValue"
                  class="font-size-4 ml-1 transition-none"
                  :color="
                    selectedAttribute?.id === attribute.id ? 'white' : 'black'
                  "
                >
                  mdi-check-circle-outline
                </v-icon>
              </div>
            </div>
          </v-col>
        </v-row>
      </v-col>

      <!-- BOOLEAN -->
      <v-col
        v-if="selectedAttribute && selectedAttribute.format == 'Boolean'"
        cols="12"
        sm="7"
        offset-sm="2"
      >
        <v-row dense>
          <v-col>
            <span class="mb-1">
              {{ $t("pages.portfolios.form.fields.defaultValue") }}
            </span>
          </v-col>
        </v-row>
        <v-row dense>
          <v-col sm="6">
            <v-select
              v-model="defaultValueForm.boolValue"
              :items="boolValueOptions"
              name="boolValue"
              clearable
              item-text="text"
              item-value="value"
              dense
              solo
            ></v-select>
          </v-col>
        </v-row>
      </v-col>
      <!-- /BOOLEAN -->

      <!-- CURRENCY -->
      <v-col
        v-if="selectedAttribute && selectedAttribute.format == 'Currency'"
        cols="12"
        sm="7"
        offset-sm="2"
      >
        <v-row dense>
          <v-col cols="6">
            <span class="mb-1">
              {{ $t("pages.portfolios.form.fields.currencyCode") }}
            </span>
          </v-col>
          <v-col cols="6">
            <span class="mb-1">
              {{ $t("pages.portfolios.form.fields.defaultValue") }}
            </span>
          </v-col>
        </v-row>
        <v-row dense align="center">
          <v-col cols="6">
            <v-autocomplete
              dense
              v-model="defaultValueForm.stringValue"
              :items="currencies"
              clearable
              hide-selected
              item-text="currencyCode"
              item-value="currencyCode"
              solo
            >
            </v-autocomplete>
          </v-col>
          <v-col cols="6">
            <v-text-field
              type="number"
              :value="defaultValueForm.decimalValue"
              @input="handleDecimalValueChanged"
              name="decimalValue"
              solo
              dense
            />
          </v-col>
        </v-row>
      </v-col>
      <!-- /CURRENCY -->

      <!-- DATE -->
      <v-col
        v-if="selectedAttribute && selectedAttribute.format == 'Date'"
        cols="12"
        sm="7"
        offset-sm="2"
      >
        <v-row dense>
          <v-col>
            <span class="mb-1">
              {{ $t("pages.portfolios.form.fields.defaultValue") }}
            </span>
          </v-col>
        </v-row>
        <v-row dense align="center">
          <v-col>
            <date-picker
              :lang="lang"
              first-day-of-week="1"
              :appendToBody="false"
              v-model="defaultValueForm.dateTimeValue"
              type="date"
              format="DD-MM-YYYY"
            ></date-picker>
          </v-col>
          <v-col>
            <span>{{ $t("pages.portfolios.form.fields.dateFormat") }}</span>
          </v-col>
        </v-row>
      </v-col>
      <!-- /DATE -->

      <!-- DATETIME -->
      <v-col
        v-if="selectedAttribute && selectedAttribute.format == 'DateTime'"
        cols="12"
        sm="7"
        offset-sm="2"
      >
        <v-row dense>
          <v-col>
            <span class="mb-1">
              {{ $t("pages.portfolios.form.fields.defaultValue") }}
            </span>
          </v-col>
        </v-row>
        <v-row dense align="center">
          <v-col>
            <date-picker
              :lang="lang"
              first-day-of-week="1"
              style="width: full"
              :appendToBody="false"
              v-model="defaultValueForm.dateTimeValue"
              type="datetime"
              format="DD-MM-YYYY HH:mm:ss"
            ></date-picker>
          </v-col>
          <v-col>
            <span>{{ $t("pages.portfolios.form.fields.dateTimeFormat") }}</span>
          </v-col>
        </v-row>
      </v-col>
      <!-- /DATETIME -->

      <!-- NUMBER -->
      <v-col
        v-if="selectedAttribute && selectedAttribute.format == 'Number'"
        cols="12"
        sm="7"
        offset-sm="2"
      >
        <v-row dense>
          <v-col>
            <span class="mb-1">
              {{ $t("pages.portfolios.form.fields.defaultNumber") }}
            </span>
          </v-col>
        </v-row>
        <v-row dense align="center">
          <v-col cols="12" sm="6">
            <v-text-field
              type="number"
              :value="defaultValueForm.decimalValue"
              @input="handleDecimalValueChanged"
              name="decimalValue"
              solo
              dense
            />
          </v-col>
        </v-row>
      </v-col>
      <!-- /NUMBER -->

      <!-- PERCENTAGE -->
      <v-col
        v-if="selectedAttribute && selectedAttribute.format == 'Percentage'"
        cols="12"
        sm="7"
        offset-sm="2"
      >
        <v-row dense>
          <v-col>
            <span class="mb-1">
              {{ $t("pages.portfolios.form.fields.defaultPercentage") }}
            </span>
          </v-col>
        </v-row>
        <v-row dense align="center">
          <v-col cols="12" sm="6">
            <v-text-field
              type="number"
              :value="defaultValueForm.decimalValue"
              @input="handleDecimalValueChanged"
              name="decimalValue"
              solo
              dense
              append-icon="mdi-percent-outline"
            />
          </v-col>
        </v-row>
      </v-col>
      <!-- /PERCENTAGE -->

      <!-- SELECT -->
      <v-col
        v-if="selectedAttribute && selectedAttribute.format == 'Select'"
        cols="12"
        sm="7"
        offset-sm="2"
      >
        <v-row dense>
          <v-col>
            <span>
              {{ $t("pages.portfolios.form.fields.defaultValue") }}
            </span>
          </v-col>
        </v-row>
        <v-row dense>
          <v-col cols="12" sm="6">
            <v-autocomplete
              dense
              v-model="defaultValueForm.attributeSelectOptionId"
              :items="selectedAttribute.attributeSelectOptions"
              clearable
              hide-selected
              item-text="value"
              item-value="id"
              solo
            >
            </v-autocomplete>
          </v-col>
        </v-row>
      </v-col>
      <!-- /SELECT -->

      <!-- TEXT -->
      <v-col
        v-if="selectedAttribute && selectedAttribute.format == 'Text'"
        offset-sm="2"
      >
        <v-row dense>
          <v-col>
            <span>{{ $t("pages.portfolios.form.fields.defaultText") }}</span>
          </v-col>
        </v-row>
        <v-row dense>
          <v-col>
            <v-textarea
              solo
              name="input-7-4"
              v-model="defaultValueForm.stringValue"
            ></v-textarea>
          </v-col>
        </v-row>
      </v-col>
      <!-- /TEXT -->
    </dialog-form-section-one-col>
  </v-tab-item>
</template>
<script>
import DatePicker from "vue2-datepicker";
import "vue2-datepicker/index.css";
import DialogFormSectionOneCol from "../../../Components/Dialog/DialogFormSectionOneCol.vue";
import DialogFormTextInput from '../../../Components/Dialog/inputs/DialogFormTextInput.vue';

export default {
  components: { DatePicker, DialogFormSectionOneCol, DialogFormTextInput },
  props: {
    form: Object,
    attributes: Array,
    currencies: Array,
    visible: Boolean,
  },
  data() {
    return {
      lang: {
        formatLocale: {
          firstDayOfWeek: 1,
        },
        monthBeforeYear: false,
      },
      attributeFilter: "",
      selectedAttribute: null,
      defaultValueForm: {
        id: null,
        attributeId: null,
        stringValue: null,
        dateTimeValue: null,
        decimalValue: null,
        boolValue: null,
        attributeSelectOptionId: null,
      },
    };
  },
  emits: ["updateForm", "attributeDefaultValueUpdated"],
  methods: {
    updateForm(formKey, value) {
      this.$emit("updateForm", formKey, value);
    },
    selectAttribute(attribute) {
      let selectedDefaultValueForm = this.form.portfolioAttributes.find(
        (x) => x.attributeId === attribute.id
      );

      if (!selectedDefaultValueForm) {
        selectedDefaultValueForm = {
          id: null,
          attributeId: attribute.id,
          stringValue: null,
          dateTimeValue: null,
          decimalValue: null,
          boolValue: null,
          attributeSelectOptionId: null,
        };
      }

      this.selectedAttribute = attribute;
      this.defaultValueForm = {
        ...this.defaultValueForm,
        ...selectedDefaultValueForm,
      };
    },
    formatAttributeName(attribute) {
      let name = "";

      if (attribute.attributeClass) {
        name += `[${attribute.attributeClass.name}] `;
      }

      name += attribute.name;

      return name;
    },
    handleDecimalValueChanged(value) {
      if (isNaN(value)) {
        this.defaultValueForm.decimalValue = null;
        return;
      }

      this.defaultValueForm.decimalValue = parseFloat(value);
    },
    updateAttributeDefaultValue(formValues) {
      this.$emit("attributeDefaultValueUpdated", formValues);
    },
    isDefaultValueSet(attributeId) {
      return this.form.portfolioAttributes.some(
        (x) => x.attributeId === attributeId
      );
    },
  },
  computed: {
    formattedAttributes() {
      if (!this.attributes) return [];

      return this.attributes.map((x) => ({
        ...x,
        formattedName: this.formatAttributeName(x),
        hasDefaultValue: this.isDefaultValueSet(x.id),
      }));
    },
    filteredAttributes() {
      if (!this.formattedAttributes) return [];

      return this.formattedAttributes.filter((x) =>
        x.formattedName
          .toUpperCase()
          .includes(this.attributeFilter.toUpperCase())
      );
    },
    boolValueOptions() {
      return [
        { value: true, text: this.$t("common.boolean.true") },
        { value: false, text: this.$t("common.boolean.false") },
      ];
    },
  },
  watch: {
    defaultValueForm: {
      handler: function (value) {
        this.updateAttributeDefaultValue(value);
      },
      deep: true,
    },
    visible(value) {
      if (!value) {
        this.attributeFilter = "";
        this.selectedAttribute = null;
        this.defaultValueForm = {
          id: null,
          attributeId: null,
          stringValue: null,
          dateTimeValue: null,
          decimalValue: null,
          boolValue: null,
          attributeSelectOptionId: null,
        };
      }
    },
  },
};
</script>
<style scoped>
.solo-item-list-area {
  overflow: auto;
  height: calc(100% - 1rem);
  border-radius: 4px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  color: rgba(0, 0, 0, 0.87);
  padding: 0 12px;
  background: #F7F7F7;
  background-origin: padding-box;
  background-clip: border-box;
  background-position-x: 0%;
  background-position-y: 0%;
  background-repeat: repeat;
  background-attachment: scroll;
  background-image: none;
  background-size: auto;
}

.selected {
  background: #004d40;
  color: white;
}
</style>
