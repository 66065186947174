<template>
  <v-dialog v-model="visible" max-width="500px">
    <v-card>
      <v-card-title>{{ $t("components.assets.dialog.title") }}</v-card-title>
      <v-card-text>
        <v-autocomplete
          ref="contentTypeList"
          v-model="selectedAssets"
          :items="assets"
          :label="$t('components.assets.dialog.assets')"
          multiple
          return-object
          :item-text="formattedAssetName"
          item-value="id"
          :item-disabled="isAssetDisabled"
        >
          <v-list-item
            slot="append-item"
            ripple
            class="close-menu-button"
            @click="closeAssetListMenu"
          >
            <v-list-item-action>
              <v-icon>mdi-close</v-icon>
            </v-list-item-action>
            <v-list-item-title>{{
              $t("components.assets.dialog.closeMenu")
            }}</v-list-item-title>
          </v-list-item>
        </v-autocomplete>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="primary" text @click="visible = false">
          {{ $t("components.assets.dialog.close") }}
        </v-btn>
        <v-btn color="primary" text @click="addAssets">
          {{ $t("components.assets.dialog.add") }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
import { fixedAttributes } from "@/util/fixedAssetData";

export default {
  props: {
    value: Boolean,
    assets: {
      type: Array,
      default: () => [],
    },
    existingAssetIds: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      selectedAssets: [],
    };
  },
  computed: {
    visible: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit("input", value);
      },
    },
  },
  methods: {
    addAssets() {
      const assets = this.selectedAssets.map((x) => x.id);
      this.selectedAssets = [];
      this.$emit("submit", assets);
      this.visible = false;
    },
    closeAssetListMenu() {
      this.$refs.contentTypeList.isMenuActive = false;
      this.$refs.contentTypeList.blur();
    },
    isAssetDisabled(item) {
      return this.existingAssetIds.includes(item.id);
    },
    formattedAssetName(item) {
      const nameAttribute = item?.assetAttributeLinks?.find(
        (x) => x.attributeId === fixedAttributes.generalNameId
      );

      if (!nameAttribute)
        return this.$t("components.assets.dialog.noAssetName");

      return nameAttribute.stringValue;
    },
  },
};
</script>
