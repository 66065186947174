<template>
  <v-card elevation="2" class="d-flex flex-column fill-height">
    <v-card-title>
      {{ assetName }}
    </v-card-title>
    <v-card-text class="flex-grow-1">
      <v-list dense>
        <v-list-item
          v-for="attribute in listableAttributeValues"
          :key="attribute.name"
          class="px-0"
        >
          <v-list-item-content class="mr-1">
            <v-tooltip top>
              <template #activator="{ on, attrs }">
                <span v-bind="attrs" v-on="on" class="attribute-text">
                  {{ attribute.name }}:
                </span>
              </template>
              <span>{{ attribute.name }}</span>
            </v-tooltip>
          </v-list-item-content>
          <v-list-item-content>
            <v-tooltip top>
              <template #activator="{ on, attrs }">
                <span v-bind="attrs" v-on="on" class="attribute-text">
                  {{ attribute.value }}
                </span>
              </template>
              <span>{{ attribute.value }}</span>
            </v-tooltip>
          </v-list-item-content>
        </v-list-item>
      </v-list>
    </v-card-text>
    <v-card-actions>
      <v-spacer />
      <v-btn color="teal darken-3" text @click="editAsset">
        {{ $t("components.assets.preview.actions.edit") }}
      </v-btn>
      <v-btn v-if="!hideRemove" color="error" text @click="removeAsset">
        {{ $t("components.assets.preview.actions.remove") }}
      </v-btn>
    </v-card-actions>
  </v-card>
</template>
<script>
import { fixedAttributes } from "@/util/fixedAssetData";
import { formatAssetAttributeLink } from "@/util/formatters";

export default {
  props: {
    asset: Object,
    hideRemove: Boolean,
  },
  emits: ["edit", "remove"],
  methods: {
    removeAsset() {
      this.$emit("remove", this.asset);
    },
    editAsset() {
      this.$emit("edit", this.asset);
    },
  },
  computed: {
    assetAttributeLinks() {
      return this.asset.assetAttributeLinks ?? [];
    },
    listableAttributeValues() {
      return this.assetAttributeLinks
        .filter(
          (x) => x.attribute && x.attributeId !== fixedAttributes.generalNameId
        )
        .map((x) => ({
          name: x.attribute.name,
          value: formatAssetAttributeLink(x),
        }));
    },
    assetName() {
      const attribute = this.assetAttributeLinks.find(
        (x) => x.attributeId === fixedAttributes.generalNameId
      );

      return (
        attribute?.stringValue ??
        this.$t("components.assets.preview.noAssetName")
      );
    },
  },
};
</script>
<style scoped>
.attribute-text {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
</style>
