const fixedClasses = {
  generalId: "b83bee53-130b-41df-b3a5-bd245c0b6b01",
  co2Id: "f0275401-57d4-472b-a893-b4c39d8609a9",
  fixedAssetsId: "352bd32c-7c70-41dc-8a3e-aeb516bd0967",
  assessmentId: "72d646dd-b46d-4cff-a9dc-ee364b5b7482",
  infoId: "72c5a7eb-884f-4a53-acdc-77c5bf30c48c",
};

const fixedAttributes = {
  generalNameId: "649f345f-bb47-47b5-af15-6ef294e72afd",
  generalPurchasePriceId: "8192dfef-ef4b-4ed1-9c9e-26ed84ef8de2",
  generalPurchaseDateId: "e2af7a7d-9f2e-4797-8f0a-3630fd4e8ab0",
  generalPortfolioId: "88f727c4-f63a-4d3c-b94a-c774f8d2d28c",
  generalPortfolioSubcategoryId: "bce46452-7ca2-4b17-b4f0-df8a1fbe71c4",
  generalWarrantyEndDateId: "b05907c9-6989-4fcf-9487-fbffbdc55ad9",
  generalModelId: "fdb4c45b-6f92-4124-a66b-f010cf050884",
  generalSerialNumberId: "d588bda2-ae9c-4062-8d4b-5c23f1c2e47b",
  generalManufacturerId: "a3191a90-d7ae-4aeb-8968-c4bf7544602f",
  generalUnitTypeId: "ad7af66b-d5e1-4a74-9b36-ebb38b85a268",
  generalProductionDateId: "92afcab9-233a-455c-9c0e-414053348b51",
  generalQuantityId: "231745de-4cfd-4e7e-b554-3abd781d3864",
  co2MethodId: "da7288f9-50d4-4928-91cc-98790e76b79b",
  co2SectorId: "a2728615-2215-4d06-9326-3db17787268d",
  co2CategoryId: "5ac7671d-fb9d-4441-8203-a09763fbcff8",
  co2ElectricitySourceId: "e854a510-4240-4c13-a086-fd2b50a05b59",
  co2RegionId: "a6db55d4-f887-48ed-815c-785b419d8dba",
  co2NameId: "9b748631-0d19-4b09-a570-9f43d842b6fa",
  co2EmissionSourceId: "fc24e517-72d1-4df1-9c73-9b0454d04c9a",
  co2EmissionsId: "041a251a-2138-4e14-bdfd-876194f4e04f",
  co2DocumentationId: "374e39f6-6f7a-4a38-b9e0-35fdcd6f348a",
  co2GhgCategoryId: "450e5e6f-48dd-4ef7-84fc-1a3586845df8",
  co2VoucherNumberId: "0e4e8c2d-3800-45bd-9afe-3c12a6e642a5",
  fixedAssetsMethodId: "92fdf736-fc52-45fc-b3a8-37fb63f592bc",
  fixedAssetsDepreciationPeriodId: "add187cf-077e-4167-8f09-9b5a48ad5cff",
  fixedAssetsScrapValueId: "0c078750-ce75-48f1-9c30-f5a814054f70",
  fixedAssetsRegionId: "29f197ae-8a10-4de2-95da-5a23cd794671",
  fixedAssetsTimeOfCommissioningId: "2b99c978-2c8c-430a-aaa9-f9f9ebb553d9",
  fixedAssetsDateOfSaleId: "fd945093-1e00-4925-9eba-d7543de74a25",
  fixedAssetsSalePriceId: "bb95b050-eb10-47b1-9ace-bacfaaadea23",
  fixedAssetsStartOfDepreciationId: "5246b78b-3480-4092-842d-f0075d034cdd",
  assessmentPrice1Id: "a3cd53fc-fe41-48ed-b89a-48a5e51de6e2",
  assessmentPrice2Id: "fbd23286-ac20-43f1-b97e-fad371d99da1",
  assessmentPrice3Id: "27b4c4ec-c0ce-4f66-abf5-ca4287afa629",
  assessmentPrice4Id: "4363e00a-98cb-487f-aecd-df02abdb0c21",
  assessmentProductionDateId: "92afcab9-233a-455c-9c0e-414053348b51",
  infoAccountNumberId: "c044d9a9-c3c7-44ea-94c8-c30552ac2fd8",
  infoSupplierId: "ab5f134b-e80b-4d97-b122-13eb5cc03278",
  infoInvoiceId: "7b047078-b38d-43df-a759-5a7c78caecca",
  infoEntryTextId: "d59f9a70-13ee-4904-b1bf-f9a144870b26",
  infoEntryNumberId: "2b331dfd-a7c7-4a06-9221-8c29fdb82ff5",
  infoEntryAmountId: "21c3fc02-af4e-4da8-93ce-741662137213",
};

const fixedFieldAttributes = {
  generalVerarcaIdId: "f06648e9-f838-4ca6-b4a2-5af349ccd34c",
  co2EmissionFactorId: "9717a873-cf73-425d-8b23-e6df952863aa",
  co2EmissionFactorCurrencyId: "14560bea-4d44-4b22-8a2a-67035e19a392",
  co2EmissionFactorStatusId: "bfffe340-b4af-4e32-a27e-2eeff7a0979d",
  co2EmissionFactorFromAiId: "c23e47be-1b23-4a59-8b31-d01e4759fe0d",
  co2SourceId: "7a858001-a633-4acf-9986-41b30b726411",
  co2UuidId: "97df513e-0671-48e6-998c-5c795b15e459",
  co2FactorTypeId: "0362fcb6-9ec5-4d20-b5e0-25f8265359df",
  co2ChosenById: "c15e9f9e-2c05-47a8-94a6-17cee0069310",
  fixedAssetsBookValueId: "de985ba6-4909-4d89-bcc9-6ba715a91ebb",
  fixedAssetsGainLossId: "2a41d3d4-2f8a-49e0-b452-43286c2ed2bb",
  fixedAssetsYearlyDepreciationId: "16c0d406-9723-48d6-bbbf-541d8fdf6623",
  fixedAssetsDepreciationStartMonthId: "35b2b7a4-6e4e-4cdb-9f91-3f38247658c0",
  fixedAssetsCurrentYearDepreciationId: "106c9590-9048-489d-b11a-d5b9e193aaa8",
  fixedAssetsAccumulatedDepreciationId: "fb82c4c5-45f5-48c4-b37d-c5375d171fc8",
  fixedAssetsMonthlyDepreciationId: "d9569925-2540-4fac-abd8-495d98770c22",
  fixedAssetsDepreciationMonthId: "478ac308-9e0e-4eea-84c9-cbb3959f8e7b",
};

const co2MethodOptions = {
  spendBasedMethodId: "cb32f080-d3ce-41cc-8f89-467b88f2e297",
  averageDataMethodId: "ca4543d4-a19f-46fd-af5f-17a772ab4309",
  supplierSpecificMethodId: "fbcafdd9-9b0a-4ace-af3d-d061d946f2fc",
};

const computedFields = [
  {
    id: "emissionsFactor",
    name: "Emission factor (kgCO2e/currency)",
    format: "Number",
    attributeClassId: fixedClasses.co2Id,
  },
  {
    id: "emissionsFactorCurrency",
    name: "Emission factor currency",
    format: "Number",
    attributeClassId: fixedClasses.co2Id,
  },
  {
    id: "emissionsSource",
    name: "Source",
    format: "String",
    attributeClassId: fixedClasses.co2Id,
  },
  {
    id: "emissionsUuid",
    name: "UUID",
    format: "String",
    attributeClassId: fixedClasses.co2Id,
  },
  {
    id: "emissionsScope3",
    name: "SCOPE 3 emissions (Kg. CO2e)",
    format: "Number",
    attributeClassId: fixedClasses.co2Id,
  },
  {
    id: "emissionsType",
    name: "Factor calculation type",
    format: "String",
    attributeClassId: fixedClasses.co2Id,
  },
  {
    id: "depreciation",
    name: "Depreciation",
    format: "Currency",
    attributeClassId: fixedClasses.fixedAssetsId,
  },
  {
    id: "bookValue",
    name: "Book value",
    format: "Currency",
    attributeClassId: fixedClasses.fixedAssetsId,
  },
];

const emissionCurrencies = ["EUR", "DKK", "USD", "GBP"];

const emissionConvertibleCurrencies = ["EUR", "DKK", "USD"];

const emissionFactorStatus = {
  defining: "Defining",
  defined: "Defined",
  undefined: "Undefined",
  approved: "Approved",
};

const depreciationStartModes = {
  currentMonth: "78d5a3ff-aa41-44aa-8c05-b6eb6ae0c99b",
  nextMonth: "a98949b1-82c0-4c6b-8aa8-59f55ac9e6bb",
  currentDate: "650c2581-3bfc-469e-93de-a549be0d4fcc",
};

const notBulkEditableAttributes = [
  ...Object.values(fixedFieldAttributes),
  fixedAttributes.co2SectorId,
  fixedAttributes.co2CategoryId,
  fixedAttributes.co2RegionId,
  fixedAttributes.co2NameId,
  fixedAttributes.generalNameId,
  fixedAttributes.generalPortfolioId,
  fixedAttributes.generalPortfolioSubcategoryId,
];

const assetRuleActionProperty = {
  portfolio: "Portfolio",
  portfolioSubcategory: "Portfolio subcategory",
  sector: "Sector",
  category: "Category",
  region: "Region",
  emissionFactorName: "Activity name",
};

export {
  fixedClasses,
  fixedAttributes,
  fixedFieldAttributes,
  computedFields,
  emissionCurrencies,
  emissionConvertibleCurrencies,
  emissionFactorStatus,
  depreciationStartModes,
  notBulkEditableAttributes,
  co2MethodOptions,
  assetRuleActionProperty,
};
