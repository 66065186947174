<!-- eslint-disable prettier/prettier -->
<template>
  <dialog-form :persistent="true" :show-dialog="show" max-width="800px">
    <div class="d-flex flex-column px-6 py-8">
      <div class="pb-8 d-flex justify-space-between">
        <div style="width: 30px"></div>
        <LogoBlack />
        <remove
          @click="close"
          class="mt-n4"
          style="cursor: pointer; width: 30px;"
        />
      </div>
      <div>
        <h1 class="text-h4 black--text mb-2 text-center">
          {{ this.$t("components.payment.title") }}
        </h1>
      </div>
      <div
        v-if="!hasValidBillingSubscription"
        style="background-color:#deffe3; border-radius: 5px"
        :style="syncingIntegrationProgress.hasNonProcessedCount ? 'background-color:  #fcdfd8' : null"
      >
        <!-- Når synktonisering er færdig -->
        <p v-if="!syncingIntegrationProgress.hasNonProcessedCount && suggestionFiles > 0 && suggestionLines > 0" class="black--text my-2 text-center">
          {{
            this.$t("components.payment.subtitle", {
              fileCount: suggestionFiles,
              lineCount: suggestionLines,
              subscriptionTier: suggestionTier,
            })
          }}
        </p>
        <!-- Imens der synkroniseres -->
        <p v-else-if="syncingIntegrationProgress.hasNonProcessedCount" class="black--text my-2 text-center">
          {{
            this.$t("components.payment.whileSyncingSubtitle")
          }}
        </p> 

        <!-- Før der er tilføjet integration 
        <p v-else class="black--text my-2 text-center">
          {{
            this.$t("components.payment.onboardingSubtitle")
          }}
        </p>-->

      </div>
      <div class="custom-slider">
        <input
          type="range"
          v-model="sliderValue"
          step="100"
          min="0"
          :max="sliderMaxFiles"
          ref="sliderInput"
          :style="{ '--percentage': thumbLeft }"
          @input="handleSliderInput"
        />
        <div class="slider-thumb" :style="{ left: thumbLeftThumb }">
          {{ sliderValue }} {{ this.$t("components.payment.slider.invoices") }}
        </div>
      </div>
      <v-container class="pa-0">
        <v-row>
          <v-col
            class="pt-6"
            cols="12"
            sm="6"
            md="6"
            style="border: 1px solid #e8e8e8"
          >
            <div style="border-bottom: 1px solid #e8e8e8; height: 50%">
              <div v-if="selectedPlan === FreeId">
                <div>
                  <div class="pt-4 pl-2" style="color: #666">
                    {{
                      this.$t("components.payment.subscriptionType.general.dkk")
                    }}
                  </div>
                  <div class="d-flex">
                    <div class="px-2 font-weight-bold" style="font-size: 50px">
                      {{
                        this.$t("components.payment.subscriptionType.free.price")
                      }}
                    </div>
                    <div class="align-self-end pb-4" style="color: #666">
                      {{
                        this.$t(
                          "components.payment.subscriptionType.general.month"
                        )
                      }}
                    </div>
                  </div>
                  <div class="pl-2 d-flex" style="color: #666; font-size: 12px;">
                    <span class="px-2 py-1" style="background-color: #f1f1f1; display: inline-block;">
                    {{
                      this.$t("components.payment.subscriptionType.general.dkk")
                    }}
                    {{
                      this.$t("components.payment.subscriptionType.free.yearlyPrice")
                    }}
                    {{
                      this.$t("components.payment.subscriptionType.general.yearlyPayment")
                    }}
                    </span>
                  </div>
                </div>
              </div>

              <div v-if="selectedPlan === Tier500Id">
                <div>
                  <div class="pt-4 pl-2" style="color: #666">
                    {{
                      this.$t("components.payment.subscriptionType.general.dkk")
                    }}
                  </div>
                  <div class="d-flex">
                    <div class="px-2 font-weight-bold" style="font-size: 50px">
                      {{
                        this.$t("components.payment.subscriptionType.tier500.price")
                      }}
                    </div>
                    <div class="align-self-end pb-4" style="color: #666">
                      {{
                        this.$t(
                          "components.payment.subscriptionType.general.month"
                        )
                      }}
                    </div>
                  </div>
                  <div class="pl-2 d-flex" style="color: #666; font-size: 12px;">
                    <span class="px-2 py-1" style="background-color: #f1f1f1; display: inline-block;">
                    {{
                      this.$t("components.payment.subscriptionType.general.dkk")
                    }}
                    {{
                      this.$t("components.payment.subscriptionType.tier500.yearlyPrice")
                    }}
                    {{
                      this.$t("components.payment.subscriptionType.general.yearlyPayment")
                    }}
                    </span>
                  </div>
                </div>
              </div>

              <div v-if="selectedPlan === Tier1000Id">
                <div>
                  <div class="pt-4 pl-2" style="color: #666">
                    {{
                      this.$t("components.payment.subscriptionType.general.dkk")
                    }}
                  </div>
                  <div class="d-flex">
                    <div class="px-2 font-weight-bold" style="font-size: 50px">
                      {{
                        this.$t("components.payment.subscriptionType.tier1000.price")
                      }}
                    </div>
                    <div class="align-self-end pb-4" style="color: #666">
                      {{
                        this.$t(
                          "components.payment.subscriptionType.general.month"
                        )
                      }}
                    </div>
                  </div>
                  <div class="pl-2 d-flex" style="color: #666; font-size: 12px;">
                    <span class="px-2 py-1" style="background-color: #f1f1f1; display: inline-block;">
                    {{
                      this.$t("components.payment.subscriptionType.general.dkk")
                    }}
                    {{
                      this.$t("components.payment.subscriptionType.tier1000.yearlyPrice")
                    }}
                    {{
                      this.$t("components.payment.subscriptionType.general.yearlyPayment")
                    }}
                    </span>
                  </div>
                </div>
              </div>

              <div v-if="selectedPlan === Tier3000Id">
                <div>
                  <div class="pt-4 pl-2" style="color: #666">
                    {{
                      this.$t("components.payment.subscriptionType.general.dkk")
                    }}
                  </div>
                  <div  class="d-flex">
                    <div class="px-2 font-weight-bold" style="font-size: 50px">
                      {{
                        this.$t("components.payment.subscriptionType.tier3000.price")
                      }}
                    </div>
                    <div class="align-self-end pb-4" style="color: #666">
                      {{
                        this.$t(
                          "components.payment.subscriptionType.general.month"
                        )
                      }}
                    </div>
                  </div>
                  <div class="pl-2 d-flex" style="color: #666; font-size: 12px;">
                    <span class="px-2 py-1" style="background-color: #f1f1f1; display: inline-block;">
                    {{
                      this.$t("components.payment.subscriptionType.general.dkk")
                    }}
                    {{
                      this.$t("components.payment.subscriptionType.tier3000.yearlyPrice")
                    }}
                    {{
                      this.$t("components.payment.subscriptionType.general.yearlyPayment")
                    }}
                    </span>
                  </div>
                </div>
              </div>

              <div v-if="selectedPlan === Tier6000Id">
                <div>
                  <div class="pt-4 pl-2" style="color: #666">
                    {{
                      this.$t("components.payment.subscriptionType.general.dkk")
                    }}
                  </div>
                  <div class="d-flex">
                    <div class="px-2 font-weight-bold" style="font-size: 50px">
                      {{
                        this.$t("components.payment.subscriptionType.tier6000.price")
                      }}
                    </div>
                    <div class="align-self-end pb-4" style="color: #666">
                      {{
                        this.$t(
                          "components.payment.subscriptionType.general.month"
                        )
                      }}
                    </div>
                  </div>
                  <div class="pl-2 d-flex" style="color: #666; font-size: 12px;">
                    <span class="px-2 py-1" style="background-color: #f1f1f1; display: inline-block;">
                    {{
                      this.$t("components.payment.subscriptionType.general.dkk")
                    }}
                    {{
                      this.$t("components.payment.subscriptionType.tier6000.yearlyPrice")
                    }}
                    {{
                      this.$t("components.payment.subscriptionType.general.yearlyPayment")
                    }}
                    </span>
                  </div>
                </div>
              </div>

              <div v-if="selectedPlan === Tier12000Id">
                <div>
                  <div class="pt-4 pl-2" style="color: #666">
                    {{
                      this.$t("components.payment.subscriptionType.general.dkk")
                    }}
                  </div>
                  <div class="d-flex">
                    <div class="px-2 font-weight-bold" style="font-size: 50px">
                      {{
                        this.$t(
                          "components.payment.subscriptionType.tier12000.price"
                        )
                      }}
                    </div>
                    <div class="align-self-end pb-4" style="color: #666">
                      {{
                        this.$t(
                          "components.payment.subscriptionType.general.month"
                        )
                      }}
                    </div>
                  </div>
                  <div class="pl-2 d-flex" style="color: #666; font-size: 12px;">
                    <span class="px-2 py-1" style="background-color: #f1f1f1; display: inline-block;">
                    {{
                      this.$t("components.payment.subscriptionType.general.dkk")
                    }}
                    {{
                      this.$t("components.payment.subscriptionType.tier12000.yearlyPrice")
                    }}
                    {{
                      this.$t("components.payment.subscriptionType.general.yearlyPayment")
                    }}
                    </span>
                  </div>
                </div>
              </div>
              <div v-if="selectedPlan === Tier20000Id">
                <div>
                  <div class="pt-4 pl-2" style="color: #666">
                    {{
                      this.$t("components.payment.subscriptionType.general.dkk")
                    }}
                  </div>
                  <div class="d-flex">
                    <div class="px-2 font-weight-bold" style="font-size: 50px">
                      {{
                        this.$t(
                          "components.payment.subscriptionType.tier20000.price"
                        )
                      }}
                    </div>
                    <div class="align-self-end pb-4" style="color: #666">
                      {{
                        this.$t(
                          "components.payment.subscriptionType.general.month"
                        )
                      }}
                    </div>
                  </div>
                  <div class="pl-2 d-flex" style="color: #666; font-size: 12px;">
                    <span class="px-2 py-1" style="background-color: #f1f1f1; display: inline-block;">
                    {{
                      this.$t("components.payment.subscriptionType.general.dkk")
                    }}
                    {{
                      this.$t("components.payment.subscriptionType.tier20000.yearlyPrice")
                    }}
                    {{
                      this.$t("components.payment.subscriptionType.general.yearlyPayment")
                    }}
                    </span>
                  </div>
                </div>
              </div>
              <div v-if="selectedPlan === Tier30000Id">
                <div>
                  <div class="pt-4 pl-2" style="color: #666">
                    {{
                      this.$t("components.payment.subscriptionType.general.dkk")
                    }}
                  </div>
                  <div class="d-flex">
                    <div class="px-2 font-weight-bold" style="font-size: 50px">
                      {{
                        this.$t(
                          "components.payment.subscriptionType.tier30000.price"
                        )
                      }}
                    </div>
                    <div class="align-self-end pb-4" style="color: #666">
                      {{
                        this.$t(
                          "components.payment.subscriptionType.general.month"
                        )
                      }}
                    </div>
                  </div>
                  <div class="pl-2 d-flex" style="color: #666; font-size: 12px;">
                    <span class="px-2 py-1" style="background-color: #f1f1f1; display: inline-block;">
                    {{
                      this.$t("components.payment.subscriptionType.general.dkk")
                    }}
                    {{
                      this.$t("components.payment.subscriptionType.tier30000.yearlyPrice")
                    }}
                    {{
                      this.$t("components.payment.subscriptionType.general.yearlyPayment")
                    }}
                    </span>
                  </div>
                </div>
              </div>
            </div>
            <div style="color: #666">
              <div v-if="selectedPlan === FreeId">
                <div>
                  <div class="d-flex pt-4">
                    <div class="font-weight-bold pr-1" style="color: #000">
                      {{
                        this.$t(
                          "components.payment.subscriptionType.free.numberOfFiles"
                        )
                      }}
                    </div>
                    <div>
                      {{
                        this.$t(
                          "components.payment.subscriptionType.general.files"
                        )
                      }}
                    </div>
                  </div>
                  <div class="d-flex">
                    <div class="font-weight-bold pr-1" style="color: #000">
                      {{
                        this.$t(
                          "components.payment.subscriptionType.free.numberOfLines"
                        )
                      }}
                    </div>
                    <div>
                      {{
                        this.$t(
                          "components.payment.subscriptionType.general.lines"
                        )
                      }}
                    </div>
                  </div>
                  <div class="d-flex">
                    <div class="font-weight-bold pr-1" style="color: #000">
                      {{
                        this.$t(
                          "components.payment.subscriptionType.free.numberOfUsers"
                        )
                      }}
                    </div>
                    <div>
                      {{
                        this.$t(
                          "components.payment.subscriptionType.general.user"
                        )
                      }}
                    </div>
                  </div>
                </div>
              </div>
              <div v-if="selectedPlan === Tier500Id">
                <div>
                  <div class="d-flex pt-4">
                    <div class="font-weight-bold pr-1" style="color: #000">
                      {{
                        this.$t(
                          "components.payment.subscriptionType.tier500.numberOfFiles"
                        )
                      }}
                    </div>
                    <div>
                      {{
                        this.$t(
                          "components.payment.subscriptionType.general.files"
                        )
                      }}
                    </div>
                  </div>
                  <div class="d-flex">
                    <div class="font-weight-bold pr-1" style="color: #000">
                      {{
                        this.$t(
                          "components.payment.subscriptionType.tier500.numberOfLines"
                        )
                      }}
                    </div>
                    <div>
                      {{
                        this.$t(
                          "components.payment.subscriptionType.general.lines"
                        )
                      }}
                    </div>
                  </div>
                  <div class="d-flex">
                    <div class="font-weight-bold pr-1" style="color: #000">
                      {{
                        this.$t(
                          "components.payment.subscriptionType.tier500.numberOfUsers"
                        )
                      }}
                    </div>
                    <div>
                      {{
                        this.$t(
                          "components.payment.subscriptionType.general.users"
                        )
                      }}
                    </div>
                  </div>
                </div>
              </div>
              <div v-if="selectedPlan === Tier1000Id">
                <div>
                  <div class="d-flex pt-4">
                    <div class="font-weight-bold pr-1" style="color: #000">
                      {{
                        this.$t(
                          "components.payment.subscriptionType.tier1000.numberOfFiles"
                        )
                      }}
                    </div>
                    <div>
                      {{
                        this.$t(
                          "components.payment.subscriptionType.general.files"
                        )
                      }}
                    </div>
                  </div>
                  <div class="d-flex">
                    <div class="font-weight-bold pr-1" style="color: #000">
                      {{
                        this.$t(
                          "components.payment.subscriptionType.tier1000.numberOfLines"
                        )
                      }}
                    </div>
                    <div>
                      {{
                        this.$t(
                          "components.payment.subscriptionType.general.lines"
                        )
                      }}
                    </div>
                  </div>
                  <div class="d-flex">
                    <div class="font-weight-bold pr-1" style="color: #000">
                      {{
                        this.$t(
                          "components.payment.subscriptionType.tier1000.numberOfUsers"
                        )
                      }}
                    </div>
                    <div>
                      {{
                        this.$t(
                          "components.payment.subscriptionType.general.users"
                        )
                      }}
                    </div>
                  </div>
                </div>
              </div>
              <div v-if="selectedPlan === Tier3000Id">
                <div>
                  <div class="d-flex pt-4">
                    <div class="font-weight-bold pr-1" style="color: #000">
                      {{
                        this.$t(
                          "components.payment.subscriptionType.tier3000.numberOfFiles"
                        )
                      }}
                    </div>
                    <div>
                      {{
                        this.$t(
                          "components.payment.subscriptionType.general.files"
                        )
                      }}
                    </div>
                  </div>
                  <div class="d-flex">
                    <div class="font-weight-bold pr-1" style="color: #000">
                      {{
                        this.$t(
                          "components.payment.subscriptionType.tier3000.numberOfLines"
                        )
                      }}
                    </div>
                    <div>
                      {{
                        this.$t(
                          "components.payment.subscriptionType.general.lines"
                        )
                      }}
                    </div>
                  </div>
                  <div class="d-flex">
                    <div class="font-weight-bold pr-1" style="color: #000">
                      {{
                        this.$t(
                          "components.payment.subscriptionType.tier3000.numberOfUsers"
                        )
                      }}
                    </div>
                    <div>
                      {{
                        this.$t(
                          "components.payment.subscriptionType.general.users"
                        )
                      }}
                    </div>
                  </div>
                </div>
              </div>
              <div v-if="selectedPlan === Tier6000Id">
                <div>
                  <div class="d-flex pt-4">
                    <div class="font-weight-bold pr-1" style="color: #000">
                      {{
                        this.$t(
                          "components.payment.subscriptionType.tier6000.numberOfFiles"
                        )
                      }}
                    </div>
                    <div>
                      {{
                        this.$t(
                          "components.payment.subscriptionType.general.files"
                        )
                      }}
                    </div>
                  </div>
                  <div class="d-flex">
                    <div class="font-weight-bold pr-1" style="color: #000">
                      {{
                        this.$t(
                          "components.payment.subscriptionType.tier6000.numberOfLines"
                        )
                      }}
                    </div>
                    <div>
                      {{
                        this.$t(
                          "components.payment.subscriptionType.general.lines"
                        )
                      }}
                    </div>
                  </div>
                  <div class="d-flex">
                    <div class="font-weight-bold pr-1" style="color: #000">
                      {{
                        this.$t(
                          "components.payment.subscriptionType.tier6000.numberOfUsers"
                        )
                      }}
                    </div>
                    <div>
                      {{
                        this.$t(
                          "components.payment.subscriptionType.general.users"
                        )
                      }}
                    </div>
                  </div>
                </div>
              </div>
              <div v-if="selectedPlan === Tier12000Id">
                <div>
                  <div class="d-flex pt-4">
                    <div class="font-weight-bold pr-1" style="color: #000">
                      {{
                        this.$t(
                          "components.payment.subscriptionType.tier12000.numberOfFiles"
                        )
                      }}
                    </div>
                    <div>
                      {{
                        this.$t(
                          "components.payment.subscriptionType.general.files"
                        )
                      }}
                    </div>
                  </div>
                  <div class="d-flex">
                    <div class="font-weight-bold pr-1" style="color: #000">
                      {{
                        this.$t(
                          "components.payment.subscriptionType.tier12000.numberOfLines"
                        )
                      }}
                    </div>
                    <div>
                      {{
                        this.$t(
                          "components.payment.subscriptionType.general.lines"
                        )
                      }}
                    </div>
                  </div>
                  <div class="d-flex">
                    <div class="font-weight-bold pr-1" style="color: #000">
                      {{
                        this.$t(
                          "components.payment.subscriptionType.tier12000.numberOfUsers"
                        )
                      }}
                    </div>
                    <div>
                      {{
                        this.$t(
                          "components.payment.subscriptionType.general.users"
                        )
                      }}
                    </div>
                  </div>
                </div>
              </div>
              <div v-if="selectedPlan === Tier20000Id">
                <div>
                  <div class="d-flex pt-4">
                    <div class="font-weight-bold pr-1" style="color: #000">
                      {{
                        this.$t(
                          "components.payment.subscriptionType.tier20000.numberOfFiles"
                        )
                      }}
                    </div>
                    <div>
                      {{
                        this.$t(
                          "components.payment.subscriptionType.general.files"
                        )
                      }}
                    </div>
                  </div>
                  <div class="d-flex">
                    <div class="font-weight-bold pr-1" style="color: #000">
                      {{
                        this.$t(
                          "components.payment.subscriptionType.tier20000.numberOfLines"
                        )
                      }}
                    </div>
                    <div>
                      {{
                        this.$t(
                          "components.payment.subscriptionType.general.lines"
                        )
                      }}
                    </div>
                  </div>
                  <div class="d-flex">
                    <div class="font-weight-bold pr-1" style="color: #000">
                      {{
                        this.$t(
                          "components.payment.subscriptionType.tier20000.numberOfUsers"
                        )
                      }}
                    </div>
                    <div>
                      {{
                        this.$t(
                          "components.payment.subscriptionType.general.users"
                        )
                      }}
                    </div>
                  </div>
                </div>
              </div>
              <div v-if="selectedPlan === Tier30000Id">
                <div>
                  <div class="d-flex pt-4">
                    <div class="font-weight-bold pr-1" style="color: #000">
                      {{
                        this.$t(
                          "components.payment.subscriptionType.tier30000.numberOfFiles"
                        )
                      }}
                    </div>
                    <div>
                      {{
                        this.$t(
                          "components.payment.subscriptionType.general.files"
                        )
                      }}
                    </div>
                  </div>
                  <div class="d-flex">
                    <div class="font-weight-bold pr-1" style="color: #000">
                      {{
                        this.$t(
                          "components.payment.subscriptionType.tier30000.numberOfLines"
                        )
                      }}
                    </div>
                    <div>
                      {{
                        this.$t(
                          "components.payment.subscriptionType.general.lines"
                        )
                      }}
                    </div>
                  </div>
                  <div class="d-flex">
                    <div class="font-weight-bold pr-1" style="color: #000">
                      {{
                        this.$t(
                          "components.payment.subscriptionType.tier30000.numberOfUsers"
                        )
                      }}
                    </div>
                    <div>
                      {{
                        this.$t(
                          "components.payment.subscriptionType.general.users"
                        )
                      }}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </v-col>
          <v-col
            class="pt-6"
            cols="12"
            sm="6"
            md="6"
            style="border: 1px solid #e8e8e8; background-color: #fef7f5"
          >
            <v-list style="background-color: #fef7f5">
              <v-list-item
                v-for="item in infoListings"
                :key="item"
                style="
                  margin: 0 !important;
                  padding: 0 !important;
                  min-height: auto;
                "
              >
                <v-list-item-icon
                  style="margin: 0 !important; align-self: center"
                >
                  <v-icon>mdi-circle-small</v-icon>
                </v-list-item-icon>
                <v-list-item-title style="color: #666">{{
                  item
                }}</v-list-item-title>
              </v-list-item>
            </v-list>
          </v-col>
        </v-row>
      </v-container>
      <v-card-actions class="d-flex justify-space-between pr-2">
        <div class="d-flex justify-space-between" style="width: 48%">
          <v-btn 
            v-if="!hasValidBillingSubscription"
            tile
            block
            outlined
            elevation="0"
            bg-color="white"
            class="border-sm black--text text-none black--border white mb-2 mr-8"
            @click="hideModal()"
          >
            {{this.$t("components.payment.buttons.skipAndContinue")}}
          </v-btn>
          <div v-else style="padding-right: 110%;"></div>
          <v-btn
            tile
            block
            elevation="0"
            color="orange"
            class="font-weight-regular text-none mb-2 white--text"
            type="submit"
            @click="handleSubscriptionAction()"
          >
            {{this.$t("components.payment.buttons.upgradeAndBenefit")}}
          </v-btn>
        </div>
      </v-card-actions>
    </div>
    <payment-info-popup
      v-model="showPaymentInfoPopup"
      v-show="showPaymentInfoPopup"
      :subscription-client-secret="clientSecret"
      :publishable-key="publishableKey"
      :payment-return-url="paymentReturnUrl"
    ></payment-info-popup>
  </dialog-form>
</template>

<script>
import DialogForm from "../Dialog/DialogForm.vue";
import LogoBlack from "../../assets/svg/verarca-logo-black.svg";
import Remove from "../../assets/svg/remove.svg";
import PaymentInfoPopup from "../../Pages/Payment/PaymentInfoPopup.vue";
import {
  FreeId,
  // FreeMaxFiles,
  // FreeMaxLines,
  Tier500MaxFiles,
  Tier500MaxLines,
  Tier1000MaxFiles,
  Tier1000MaxLines,
  Tier3000MaxFiles,
  Tier3000MaxLines,
  Tier6000MaxFiles,
  Tier6000MaxLines,
  Tier12000MaxFiles,
  Tier12000MaxLines,
  Tier20000MaxFiles,
  Tier20000MaxLines,
  Tier30000MaxFiles,
} from "../../util/paymentProductPrice.js";

export default {
  components: {
    DialogForm,
    LogoBlack,
    PaymentInfoPopup,
    Remove,
  },
  props: {
    value: Boolean,
    publishableKey: String,
    billingSubscription: Object,
    openedFromSettings: Boolean,
    paymentReturnUrl: String,
    paymentTierIds: Object,
    dateRange: Object,
    redirectToAssets: Boolean,
  },
  data() {
    return {
      sliderValue: 0,

      FreeId: null,
      Tier500Id: null,
      Tier1000Id: null,
      Tier3000Id: null,
      Tier6000Id: null,
      Tier12000Id: null,
      Tier20000Id: null,
      Tier30000Id: null,

      subscriptionCreateForm: this.$inertia.form({
        subscriptionId: "",
      }),
      subscriptionUpdateForm: this.$inertia.form({
        subscriptionId: "",
        newPriceId: "",
      }),

      showPaymentInfoPopup: false,
      clientSecret: null,
      subscriptionId: null,
      newPriceId: "",

      suggestionFiles: null,
      suggestionLines: null,
      suggestionTier: null,
      sliderMaxFiles: Tier30000MaxFiles,
      syncingIntegrationProgress: {},
      syncInterval: null,
    };
  },
  mounted() {
    if (
      this.hasValidBillingSubscription &&
      this.billingSubscription.productPriceId
    ) {
      this.sliderValue = this.getProductPriceValue(
        this.billingSubscription.productPriceId
      );
    }

    // Moved to show inside watch() to minimize intital requests
    //if (!this.hasValidBillingSubscription) {
    //  this.getTierSuggestion();
    //}

    // Run once and ones every minute
    this.syncInterval = setInterval(() => {
      if (this.show) {
        this.checkHasSyncingIntegrations();
        console.info("checkHasSyncingIntegrations", "payment window");
      }
    }, 5000);
  },
  created() {
    this.initializePaymentTierIds();
  },
  computed: {
    hasValidBillingSubscription() {
      return this.billingSubscription?.status === "active";
    },
    show: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit("input", value);
      },
    },
    thumbLeftThumb() {
      const percentage = this.sliderValue / Tier30000MaxFiles;
      const sliderW = (this.$refs["sliderInput"]?.clientWidth ?? 0) - 30;
      return `calc(${percentage * sliderW}px - 25px)`;
    },
    thumbLeft() {
      const percentage = this.sliderValue / Tier30000MaxFiles;
      const sliderW = (this.$refs["sliderInput"]?.clientWidth ?? 0) - 30;
      return `calc(${percentage * sliderW}px)`;
    },
    infoListings() {
      var result = [
        this.$t("components.payment.info.import"),
        this.$t("components.payment.info.integration"),
        this.$t("components.payment.info.aiCalculation"),
        this.$t("components.payment.info.aiScope"),
        this.$t("components.payment.info.factors"),
        this.$t("components.payment.info.dashboard"),
        this.$t("components.payment.info.emissionTargets"),
        this.$t("components.payment.info.documentation"),
        this.$t("components.payment.info.co2Reports"),
        this.$t("components.payment.info.fileModule"),
        this.$t("components.payment.info.export"),
        this.$t("components.payment.info.support"),
        this.$t("components.payment.info.revisorAccess"),
        this.$t("components.payment.info.greenCalcultaions"),
      ];

      return result;
    },
    selectedPlan() {
      if (this.sliderValue > Tier20000MaxFiles) {
        return this.Tier30000Id;
      } else if (this.sliderValue > Tier12000MaxFiles) {
        return this.Tier20000Id;
      } else if (this.sliderValue > Tier6000MaxFiles) {
        return this.Tier12000Id;
      } else if (this.sliderValue > Tier3000MaxFiles) {
        return this.Tier6000Id;
      } else if (this.sliderValue > Tier1000MaxFiles) {
        return this.Tier3000Id;
      } else if (this.sliderValue > Tier500MaxFiles) {
        return this.Tier1000Id;
      } else if (this.sliderValue > 0) {
        return this.Tier500Id;
      } else {
        return this.FreeId;
      }
    },
  },
  methods: {
    initializePaymentTierIds() {
      this.FreeId = FreeId;
      this.Tier500Id = this.paymentTierIds[500];
      this.Tier1000Id = this.paymentTierIds[1000];
      this.Tier3000Id = this.paymentTierIds[3000];
      this.Tier6000Id = this.paymentTierIds[6000];
      this.Tier12000Id = this.paymentTierIds[12000];
      this.Tier20000Id = this.paymentTierIds[20000];
      this.Tier30000Id = this.paymentTierIds[30000];
    },
    cleanUrl() {
      console.log("cleaning");
      const searchParams = new URLSearchParams(window.location.search);
      searchParams.delete("openPayment");
      const newUrl = `${window.location.pathname}?${searchParams.toString()}`;
      window.history.replaceState({}, "", newUrl);
    },
    hideModal() {
      this.cleanUrl();
      if (!this.hasValidBillingSubscription && !this.openedFromSettings) {
        this.createFreeSubscription();
        // redirect to data
        if (this.redirectToAssets) {
          const url = this.route("assets.index", {
            page: 1,
            dateRangeFrom: this.dateRange.from,
            dateRangeTo: this.dateRange.to,
          });
          window.location.href = url;
        }
      }

      this.show = false;
    },
    handleSubscriptionAction() {
      if (
        this.hasValidBillingSubscription &&
        this.billingSubscription.productPriceId === this.FreeId &&
        this.selectedPlan !== this.billingSubscription.productPriceId
      ) {
        this.createSubscription();
      }
      if (
        this.hasValidBillingSubscription &&
        this.selectedPlan !== this.billingSubscription.productPriceId
      ) {
        this.updateSubscription();
      } else if (
        this.hasValidBillingSubscription &&
        this.selectedPlan === this.billingSubscription.productPriceId
      ) {
        this.hideModal();
      } else {
        this.createSubscription();
      }
    },
    createSubscription() {
      this.subscriptionCreateForm.priceId = this.selectedPlan;

      const body = {
        priceId: this.subscriptionCreateForm.priceId,
      };

      fetch(this.route("payments.create-subscription"), {
        method: "POST",
        body: JSON.stringify(body),
        headers: {
          "Content-Type": "application/json",
        },
      })
        .then((response) => response.json())
        .then((data) => {
          this.clientSecret = data.clientSecret;
          this.subscriptionId = data.subscriptionId;

          this.showPaymentInfo();
        });
    },
    updateSubscription() {
      this.subscriptionUpdateForm.subscriptionId = this.billingSubscription.id;
      this.subscriptionUpdateForm.newPriceId = this.selectedPlan;

      this.subscriptionUpdateForm.post(
        this.route("payments.update-subscription")
      );

      this.hideModal();
    },
    createFreeSubscription() {
      this.subscriptionCreateForm.priceId = this.FreeId;

      const body = {
        priceId: this.subscriptionCreateForm.priceId,
      };

      fetch(this.route("payments.create-subscription"), {
        method: "POST",
        body: JSON.stringify(body),
        headers: {
          "Content-Type": "application/json",
        },
      })
        .then((response) => response.json())
        .then((data) => {
          this.clientSecret = data.clientSecret;
          this.subscriptionId = data.subscriptionId;

          this.showPaymentInfo();
        });
    },

    showPaymentInfo() {
      this.showPaymentInfoPopup = true;
    },
    getProductPriceValue(productPriceId) {
      switch (productPriceId) {
        case FreeId:
          return 0;
        case this.paymentTierIds[500]:
          return Tier500MaxFiles;
        case this.paymentTierIds[1000]:
          return Tier1000MaxFiles;
        case this.paymentTierIds[3000]:
          return Tier3000MaxFiles;
        case this.paymentTierIds[6000]:
          return Tier6000MaxFiles;
        case this.paymentTierIds[12000]:
          return Tier12000MaxFiles;
        case this.paymentTierIds[20000]:
          return Tier20000MaxFiles;
        case this.paymentTierIds[30000]:
          return Tier30000MaxFiles;
        default:
          return 0;
      }
    },
    handleSliderInput() {
      if (
        this.hasValidBillingSubscription &&
        this.billingSubscription.productPriceId
      ) {
        if (
          this.sliderValue <
          this.getProductPriceValue(this.billingSubscription.productPriceId)
        ) {
          this.sliderValue = this.getProductPriceValue(
            this.billingSubscription.productPriceId
          );
        }
      }
    },
    getTierSuggestion() {
      fetch(this.route("payments.getSuggestedSubscriptionTier"))
        .then((res) => res.json())
        .then((data) => {
          this.suggestionFiles = data.fileCount;
          this.suggestionLines = data.lineCount;

          console.log(this.suggestionFiles, this.suggestionLines);

          // if (
          //   data.fileCount <= FreeMaxFiles &&
          //   data.lineCount <= FreeMaxLines
          // ) {
          //   this.suggestionTier = this.$t(
          //     "components.payment.subscriptionType.free.name"
          //   );
          //   this.sliderValue = this.getProductPriceValue(FreeId);
          // } else
          if (
            data.fileCount <= Tier500MaxFiles &&
            data.lineCount <= Tier500MaxLines
          ) {
            this.suggestionTier = this.$t(
              "components.payment.subscriptionType.tier500.name"
            );
            this.sliderValue = this.getProductPriceValue(
              this.paymentTierIds[500]
            );
          } else if (
            data.fileCount <= Tier1000MaxFiles &&
            data.lineCount <= Tier1000MaxLines
          ) {
            this.suggestionTier = this.$t(
              "components.payment.subscriptionType.tier1000.name"
            );
            this.sliderValue = this.getProductPriceValue(
              this.paymentTierIds[1000]
            );
          } else if (
            data.fileCount <= Tier3000MaxFiles &&
            data.lineCount <= Tier3000MaxLines
          ) {
            this.suggestionTier = this.$t(
              "components.payment.subscriptionType.tier3000.name"
            );
            this.sliderValue = this.getProductPriceValue(
              this.paymentTierIds[3000]
            );
          } else if (
            data.fileCount <= Tier6000MaxFiles &&
            data.lineCount <= Tier6000MaxLines
          ) {
            this.suggestionTier = this.$t(
              "components.payment.subscriptionType.tier6000.name"
            );
            this.sliderValue = this.getProductPriceValue(
              this.paymentTierIds[6000]
            );
          } else if (
            data.fileCount <= Tier12000MaxFiles &&
            data.lineCount <= Tier12000MaxLines
          ) {
            this.suggestionTier = this.$t(
              "components.payment.subscriptionType.tier12000.name"
            );
            this.sliderValue = this.getProductPriceValue(
              this.paymentTierIds[12000]
            );
          } else if (
            data.fileCount <= Tier20000MaxFiles &&
            data.lineCount <= Tier20000MaxLines
          ) {
            this.suggestionTier = this.$t(
              "components.payment.subscriptionType.tier20000.name"
            );
            this.sliderValue = this.getProductPriceValue(
              this.paymentTierIds[20000]
            );
          } else {
            this.suggestionTier = this.$t(
              "components.payment.subscriptionType.tier30000.name"
            );
            this.sliderValue = this.getProductPriceValue(
              this.paymentTierIds[30000]
            );
          }
        });
    },
    checkHasSyncingIntegrations() {
      fetch(this.route("api.integrations.isSyncing"))
        .then((response) => {
          if (!response.ok) {
            return Promise.reject();
          }
          return response.json();
        })
        .then((data) => {
          this.syncingIntegrationProgress = data;
        })
        .catch((error) => {
          console.warn("Could not load data", error);
        });
    },
    close() {
      this.show = false;
    },
  },
  beforeDestroy() {
    clearInterval(this.syncInterval);
  },
  watch: {
    show(to) {
      if (to) {
        if (!this.hasValidBillingSubscription) {
          this.getTierSuggestion();
        }

        this.checkHasSyncingIntegrations();
      }
    },
  },
};
</script>

<style scoped>
.custom-slider {
  position: relative;
  width: 100%;
  margin: 20px 0;
}

input[type="range"] {
  width: 100%;
  height: 10px;
  background: linear-gradient(
    to right,
    #f25d3b 0%,
    #f25d3b calc(var(--percentage, 0%)),
    #ccc calc(var(--percentage, 0%) + 0.1%),
    #ccc 100%
  );
  border: none;
  border-radius: 5px;
  appearance: none;
  -webkit-appearance: none;
}

input[type="range"]::-webkit-slider-thumb {
  -webkit-appearance: none;
  width: 30px;
  height: 30px;
  border: 5px solid white;
  border-radius: 50%;
  background-color: #f25d3b;
  cursor: pointer;
  position: relative;
  z-index: 2;
  box-shadow: rgba(0, 0, 0, 0.3) 0px 0px 5px;
}

.slider-thumb {
  position: sticky;
  background-color: black;
  color: white;
  padding: 10px 15px;
  border-radius: 25px;
  white-space: nowrap;
  z-index: 1;
  text-align: center;
  font-size: small;
  font-weight: bold;
  width: 130px;
  margin-top: 14px;
}
</style>
