<template>
  <v-row class="px-2">
    <v-col lg="3" class="pt-2 pb-0 px-2 pr-0 d-flex">
      <div class="flex-grow-1 white pa-4 pr-2 d-flex align-center">
        <v-text-field
          :label="$t('components.assets.listFilter.search')"
          color="#686868"
          class="search-field rounded-0 pt-0"
          height="44"
          light
          hide-details="auto"
          background-color="#F7F7F7"
          filled
          solo
          dense
          prepend-inner-icon="mdi-magnify"
          flat
          @change="(val) => updateSearch(val)"
          :value="postFilters.search"
        ></v-text-field>
      </div>
    </v-col>
    <v-col cols="auto" lg="2" class="pt-2 px-0 pr-2 pr-lg-0 pb-0 d-flex">
      <div class="flex-grow-1 white pa-4 pl-2 d-flex align-center">
        <filters-modal
          v-model="filtersDialog"
          :attributes="attributes"
          :attributeFilters="currentAttributeFilters"
          @changeAttributeFilters="(value) => updateAttributeFilters(value)"
        >
          <template #trigger-button>
            <v-icon color="#686868">mdi-filter-outline</v-icon>
            <span class="d-none d-sm-block">
              {{ $t("components.assets.listFilter.moreFilters") }}
            </span>
          </template>
        </filters-modal>
      </div>
    </v-col>
    <v-col cols="12" lg="7" class="pt-0 pt-lg-2 pb-0 px-2 pl-lg-0 d-flex">
      <div
        class="flex-grow-1 white pa-4 d-flex d-flex-row align-center justify-end flex-column flex-sm-row"
      >
        <div class="mr-4 mr-lg-0 align-self-start align-self-sm-center">
          <v-select
            :value="selectedPortfolios"
            :items="portfolios"
            :label="$t('components.assets.listFilter.selectPortfolios')"
            single-line
            filled
            multiple
            :hide-details="true"
            persistent-hint
            item-text="name"
            item-value="id"
            style="width: 320px; display: inline-block; z-index: 103"
            class="rounded-0 mb-1 custom-select mr-4"
            background-color="#F7F7F7"
            solo
            flat
            light
            :menu-props="{ top: false, offsetY: true }"
            color="#F25D3C"
            item-color="red"
            @change="(val) => updatePortfolioIds(val)"
            @blur="selectBlurred()"
            @keydown.esc="selectBlurred()"
            @click:append="selectBlurred()"
          >
            <template #append-item>
              <v-btn
                plain
                block
                tile
                :href="route('portfolios.index')"
                target="_blank"
                class="add-portfolio-btn"
                color="#F25D3C"
                ><v-icon>mdi-open-in-new</v-icon>
                <span class="ml-3">{{
                  $t("components.assets.listFilter.editPortfolios")
                }}</span></v-btn
              >
            </template>
            <template v-slot:selection="{ item, index }">
              <v-chip v-if="index === 0">
                <span class="text-caption">{{ item.name }}</span>
              </v-chip>
              <span v-if="index === 1" class="grey--text text-caption">
                (+{{ selectedPortfolios.length - 1 }}
                {{ $t("components.assets.listFilter.others") }})
              </span>
            </template>
          </v-select>
          <v-menu
            v-if="layoutOptions.length > 0"
            class="layout-menu"
            offset-y
            light
          >
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                v-show="false"
                class="text-none align-self-stretch align-self-sm-center px-2 px-xl-4 mt-5 mt-sm-0"
                elevation="0"
                tile
                text
                color="#B4B4B4"
                v-bind="attrs"
                v-on="on"
                ><presentation-icon class="mr-3"></presentation-icon>
                {{ $t("components.assets.listFilter.layout") }}:
                <span style="color: #686868">{{
                  layoutOptions[selectedLayoutOption]
                }}</span></v-btn
              >
            </template>
            <v-list>
              <v-list-item
                link
                v-for="(value, key) in layoutOptions"
                :key="key"
                @click="setLayoutMode(key)"
                ><v-list-item-title>{{ value }}</v-list-item-title></v-list-item
              >
            </v-list>
          </v-menu>
          <choose-attributes-modal
            class="d-inline-block"
            v-model="chooseAttributesDialog"
            :layout="currentAssetLayout"
            :attributes="attributes"
            :attributeClasses="attributeClasses"
            :organizationId="currentOrganizationId"
            @changeLayout="updateAssetLayout"
            @resetLayout="resetAssetLayout"
          >
            <template #trigger-button>
              <layout-icon class="mr-3"></layout-icon>
              {{ $t("components.assets.listFilter.layoutOptions") }}
            </template>
          </choose-attributes-modal>
          <!--<layout-options-modal
            v-model="layoutOptionsDialog"
            :layout="currentAssetLayout"
            :attributeDisplaySizes="attributeDisplaySizes"
            @changeLayout="changeLayout"
            @saveLayout="saveLayout"
          >
            <template #trigger-button>
              <layout-icon class="mr-3"></layout-icon>Layout options
            </template>
          </layout-options-modal>-->
          <export-assets
            ref="export-assets"
            class="d-inline-block"
            :assetLayout="currentAssetLayout"
            v-model="exportAssetsDialog"
            :attributes="attributes"
            :filteredAssets="assets"
            :selectedAssets="selectedAssets"
            :organizationId="currentOrganizationId"
            :postFilters="postFilters"
            :portfolios="portfolios"
          >
            <template #trigger-button>
              <download-icon class="mr-3"></download-icon>
              {{ $t("components.assets.listFilter.export") }}
            </template>
          </export-assets>
        </div>
        <span
          class="d-none d-lg-block mx-4 mx-md-8"
          style="border-right: 1px solid #d9d9d9; height: 56px"
        ></span>

        <v-menu class="create-menu" offset-y light>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              large
              class="align-self-stretch align-self-sm-center mt-5 mt-sm-0"
              elevation="0"
              tile
              color="#F25D3B"
              v-bind="attrs"
              v-on="on"
              dark
            >
              <v-icon class="pr-3 block" color="#fff" small>mdi-plus</v-icon>
              {{ $t("components.assets.listFilter.create.label") }}
            </v-btn>
          </template>
          <v-list>
            <v-list-item link @click="$emit('clickCreateSingle', $event)">
              <v-list-item-title>
                {{ $t("components.assets.listFilter.create.manual") }}
              </v-list-item-title>
            </v-list-item>
            <v-list-item link @click="$emit('clickImportFromCSV', $event)">
              <v-list-item-title>
                {{ $t("components.assets.listFilter.create.import") }}
              </v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>
      </div>
    </v-col>
  </v-row>
</template>

<script>
import DownloadIcon from "../../assets/svg/archive-download.svg";
import LayoutIcon from "../../assets/svg/layout.svg";
import PresentationIcon from "../../assets/svg/presentation.svg";
//import LayoutOptionsModal from "../Layout/LayoutOptionsModal.vue";
import ExportAssets from "./../../Pages/Asset/ExportAssets.vue";
import ChooseAttributesModal from "../../Components/Attribute/ChooseAttributesModal.vue";
import FiltersModal from "../../Components/Attribute/FiltersModal.vue";

export default {
  components: {
    DownloadIcon,
    LayoutIcon,
    PresentationIcon,
    /*LayoutOptionsModal,*/
    ExportAssets,
    ChooseAttributesModal,
    FiltersModal,
  },
  props: {
    attributeDisplaySizes: Array,
    currentAssetLayout: Object,
    changeLayout: Function,
    saveLayout: Function,

    attributes: Array,
    portfolios: Array,
    assets: Object,
    selectedAssets: Array,
    currentOrganizationId: String,
    postFilters: Object,

    attributeClasses: Array,
    updateAssetLayout: Function,
    resetAssetLayout: Function,
    currentAttributeFilters: Array,
    updateAttributeFilters: Function,

    layoutOptions: Array,
    selectedLayoutOption: Number,
    selectedPortfolios: Array,
  },
  data() {
    return {
      layoutOptionsDialog: false,
      exportAssetsDialog: false,
      chooseAttributesDialog: false,
      filtersDialog: false,
    };
  },
  mounted() {
    // [2023-09-05] AHA: Enforce layout mode to be 1 (table) for now
    //const layoutMode = localStorage.getItem("layout-mode");
    const layoutMode = 1;
    this.$emit("selectLayoutOption", Number(layoutMode) ?? 0);
  },
  methods: {
    setLayoutMode(key) {
      key = 1;
      this.$emit("selectLayoutOption", key);
      localStorage.setItem("layout-mode", key);
    },
    updateSearch(val) {
      this.$emit("searchAttributes", val);
    },
    updatePortfolioIds(val) {
      this.$emit("updatePortfolioIds", val);
    },
    selectBlurred() {
      this.$emit("commitPortfolioIds");
    },
  },
};
</script>

<style lang="scss" scoped>
.search-field:deep(.v-text-field__slot .v-label) {
  color: #aeaeae !important;
  font-size: 15px;
  font-weight: 400;
}

.create-menu {
  z-index: 99999;
}

.add-portfolio-btn {
  font-size: 16px;
  letter-spacing: normal;
  line-height: 19.2px;
  text-transform: unset;
  font-weight: unset;
  min-height: 48px;
  position: sticky;
  bottom: 0;
  background: white;
  z-index: 9;
  box-shadow: 0px 1px 5px 0px rgba(0, 0, 0, 0.4);
}
</style>
