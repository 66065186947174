import i18n from "../i18n";
import { fixedAttributes, fixedFieldAttributes } from "./fixedAssetData";

export const roundedNumberFormatter = new Intl.NumberFormat("da-DK", {
  maximumFractionDigits: 2,
});

export const fullNumberFormatter = new Intl.NumberFormat("da-DK", {
  maximumFractionDigits: 20,
});

export const currencyFormatter = new Intl.NumberFormat("da-DK", {
  useGrouping: "always",
  minimumFractionDigits: 2,
  maximumFractionDigits: 2,
});
export const decimalFormatter = new Intl.NumberFormat("da-DK", {
  useGrouping: "always",
  minimumFractionDigits: 2,
  maximumFractionDigits: 4,
});

export const integerNumberFormatter = new Intl.NumberFormat("da-DK", {
  useGrouping: "always",
  maximumFractionDigits: 0,
});

export function formatAttributeLink(attributeLink, defaultCurrencyCode) {
  if (!defaultCurrencyCode) defaultCurrencyCode = "";

  const guidValueAttributeIds = [
    fixedAttributes.co2SectorId,
    fixedAttributes.co2CategoryId,
    fixedAttributes.co2RegionId,
    fixedAttributes.co2NameId,
  ];

  if (guidValueAttributeIds.includes(attributeLink?.attributeId)) {
    return attributeLink.searchString;
  }

  if (
    attributeLink?.attributeId === fixedFieldAttributes.generalVerarcaIdId ||
    attributeLink?.attributeId === fixedAttributes.infoAccountNumberId ||
    attributeLink?.attributeId === fixedAttributes.infoEntryNumberId
  ) {
    return attributeLink.searchString;
  }

  switch (attributeLink?.attribute?.format) {
    case "Text":
      return attributeLink.stringValue;
    case "TextArea":
      return attributeLink.stringValue;
    case "Select":
      return attributeLink.attributeSelectOption?.value;
    case "Number":
      if (!attributeLink.decimalValue) return "";

      return fullNumberFormatter.format(attributeLink.decimalValue);
    case "Percentage":
      if (!attributeLink.decimalValue) return "";

      return fullNumberFormatter.format(attributeLink.decimalValue) + " %";
    case "DateTime":
      return attributeLink.searchString;
    case "Date":
      return attributeLink.searchString;
    case "Currency":
      return `${
        attributeLink.stringValue
          ? attributeLink.stringValue + " "
          : defaultCurrencyCode + " "
      }${currencyFormatter.format(attributeLink.decimalValue)}`;
    case "Boolean":
      if (
        attributeLink.boolValue === undefined ||
        attributeLink.boolValue === null
      )
        return "";

      return attributeLink.boolValue
        ? i18n.t("common.boolean.true")
        : i18n.t("common.boolean.false");
    default:
      return;
  }
}

export function formatAndConvertStringToFloat(stringToConvert) {
  stringToConvert = stringToConvert.replace(/\./g, "");
  stringToConvert = stringToConvert.replace(/,/g, ".");
  let floatValue = parseFloat(stringToConvert);

  return floatValue;
}
