var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('DialogFormHeader',{attrs:{"title":_vm.$t('pages.assets.import.title')}}),_c('div',{staticClass:"border-bottom"},[(_vm.hasErrors)?_c('v-row',{staticClass:"d-flex justify-center align-center mb-2 mx-2 my-2"},[_c('p',{staticClass:"mb-0 mr-4"},[_vm._v(" "+_vm._s(_vm.$t("pages.assets.import.stepThree.showAllData"))+" ")]),_c('v-switch',{staticClass:"mt-0",attrs:{"hide-details":""},scopedSlots:_vm._u([{key:"label",fn:function(){return [_c('i18n',{staticClass:"mb-0 ml-3",attrs:{"path":"pages.assets.import.stepThree.onlyShow","tag":"p"}},[_c('span',{staticClass:"red--text"},[_vm._v(_vm._s(_vm.$t("pages.assets.import.stepThree.invalid")))])])]},proxy:true}],null,false,3209767859),model:{value:(_vm.onlyErroredRows),callback:function ($$v) {_vm.onlyErroredRows=$$v},expression:"onlyErroredRows"}})],1):_vm._e(),(_vm.loading)?_c('v-progress-linear',{attrs:{"indeterminate":"","color":"#f25d3b"}}):_vm._e(),_c('div',{staticClass:"table-container"},[(_vm.importStatusCode === 402)?_c('v-row',{staticClass:"px-3 py-3 mx-0 align-center border-bottom",attrs:{"dense":""}},[_c('v-col',{attrs:{"cols":"1"}},[_c('v-icon',{attrs:{"color":"red","large":""}},[_vm._v("mdi-alert-circle-outline")])],1),_c('v-col',{staticClass:"text-center"},[_vm._v(" "+_vm._s(_vm.$t("pages.assets.import.stepThree.exceedsLimit"))+" ")]),_c('v-col',{staticClass:"d-flex",attrs:{"cols":"1"}},[_c('v-spacer'),_c('v-btn',{staticStyle:{"text-transform":"none","letter-spacing":"normal"},attrs:{"elevation":"0","tile":"","large":""},on:{"click":function($event){return _vm.redirectToBilling()}}},[_vm._v(_vm._s(_vm.$t("components.cards.upgradeToPro.upgrade"))+" ")])],1)],1):_vm._e(),_c('v-data-table',{class:{
          compressed: _vm.hasErrors,
          'with-upgrade-notice': _vm.importStatusCode === 402,
        },attrs:{"headers":_vm.tableHeaders,"height":"calc(100% - 61px)","items":_vm.filteredAssets,"item-class":_vm.getAssetRowClass,"loading":_vm.loading,"item-key":"index","show-select":"","dense":"","disable-sort":"","fixed-header":"","options":_vm.tableOptions,"footer-props":_vm.tableFooterProps},on:{"update:options":function($event){_vm.tableOptions=$event}},scopedSlots:_vm._u([_vm._l((_vm.headers),function(header){return {key:`header.row.${header.mapping}`,fn:function(){return [(_vm.getMapping(header.mapping).type === 'attribute')?_c('v-autocomplete',{key:header.mapping,staticClass:"my-2",class:_vm.getMappingAttribute(header.mapping) === null ? 'unknown' : '',attrs:{"flat":"","items":_vm.attributeOptionsWithUnknown(),"item-disabled":_vm.getAttributeOptionDisabled,"value":_vm.getMappingAttribute(header.mapping) !== null
                ? _vm.getMappingAttribute(header.mapping).id
                : null,"item-text":"name","item-value":"id","item-color":"#000","dense":"","solo":"","hide-details":""},on:{"change":(value) => _vm.handleHeaderMappingChanged(header.mapping, value)},scopedSlots:_vm._u([{key:"item",fn:function({ item }){return [_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(" "+_vm._s(item.name.replace( " " + _vm.$t("pages.assets.import.stepThree.unknownSuffixPart"), "" ))+" "),(item.unknown)?_c('span',{staticClass:"yellow--text accent-3 ml-1"},[_vm._v(" "+_vm._s(_vm.$t("pages.assets.import.stepThree.unknownSuffixPart"))+" ")]):_vm._e()]),(item.attributeClass)?_c('v-list-item-subtitle',[_vm._v(" "+_vm._s(item.attributeClass.name)+" ")]):_vm._e()],1)]}}],null,true)}):(
              _vm.getMapping(header.mapping).type === 'field' &&
              !!_vm.getMappingField(header.mapping)
            )?_c('v-autocomplete',{key:header.mapping,staticClass:"my-2",attrs:{"items":[_vm.getMappingField(header.mapping)],"value":_vm.getMappingField(header.mapping),"disabled":"","dense":"","solo":"","hide-details":""}}):_vm._e()]},proxy:true}}),_vm._l((_vm.headers),function(header){return {key:`item.row.${header.mapping}`,fn:function({ item }){return [_c('div',{key:header.mapping,staticClass:"cell-container",class:item.columnErrorMessages &&
              item.columnErrorMessages[header.mapping]
                ? 'red'
                : ''},[(
                _vm.editingRows.includes(item) &&
                _vm.isSubcategoryColumn(header.mapping)
              )?_c('v-autocomplete',{attrs:{"items":_vm.portfolioSubcategories,"value":item.row[header.mapping],"item-text":"name","item-value":"name","solo":"","dense":"","hide-details":"","clearable":""},on:{"change":function($event){return _vm.updateAssetAttributeLink(item, header.mapping, $event)}}}):(
                _vm.editingRows.includes(item) &&
                _vm.getAttributeFormat(header.mapping) === 'Select'
              )?_c('v-autocomplete',{attrs:{"items":_vm.getAttributeSelectOptions(header.mapping),"value":item.row[header.mapping],"item-text":"value","item-value":"value","solo":"","dense":"","hide-details":"","clearable":""},on:{"change":function($event){return _vm.updateAssetAttributeLink(item, header.mapping, $event)}}}):(_vm.editingRows.includes(item))?_c('v-text-field',{staticClass:"my-2",attrs:{"value":item.row[header.mapping],"solo":"","dense":"","hide-details":"","clearable":""},on:{"input":function($event){return _vm.updateAssetAttributeLink(item, header.mapping, $event)},"keydown":(event) => _vm.saveEditingRow(event, item)}}):_c('span',[_vm._v(_vm._s(item.row[header.mapping]))]),(
                item.columnErrorMessages &&
                item.columnErrorMessages[header.mapping] &&
                !_vm.editingRows.includes(item)
              )?_c('div',{staticClass:"flex-grow-1"}):_vm._e(),(
                item.columnErrorMessages &&
                item.columnErrorMessages[header.mapping] &&
                !_vm.editingRows.includes(item)
              )?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('v-icon',_vm._g(_vm._b({},'v-icon',attrs,false),on),[_vm._v(" mdi-information-outline ")])]}}],null,true)},[_c('div',[_c('h6',{staticClass:"text-h6"},[_vm._v(" "+_vm._s(_vm.$t("pages.assets.import.stepThree.formatInvalid"))+" ")]),_c('p',{staticClass:"text-body-1"},[_vm._v(" "+_vm._s(_vm.getCellFormatHelpText( item.columnErrorMessages[header.mapping] ))+" ")]),(
                    _vm.getCellValueExamples(
                      item.columnErrorMessages[header.mapping],
                      _vm.getMappingAttribute(header.mapping)
                    )
                  )?_c('ul',[_vm._l((_vm.getCellFormatExamples(
                      item.columnErrorMessages[header.mapping]
                    )),function(format){return _c('li',{key:format},[_vm._v(" "+_vm._s(format)+" ")])}),_c('li',[_vm._v(_vm._s(_vm.$t("pages.assets.import.stepThree.eg")))]),_c('ul',{staticClass:"help-text-sublist"},_vm._l((_vm.getCellValueExamples(
                        item.columnErrorMessages[header.mapping],
                        _vm.getMappingAttribute(header.mapping)
                      )),function(example){return _c('li',{key:example},[_vm._v(" "+_vm._s(example)+" ")])}),0)],2):_vm._e()])]):_vm._e()],1)]}}}),{key:"item.action",fn:function({ item }){return [_c('v-row',{staticClass:"actionrow",attrs:{"justify":"space-between"}},[_c('v-icon',{staticClass:"ml-2",attrs:{"small":""},on:{"click":function($event){return _vm.toggleAssetEditMode(item)}}},[_vm._v(_vm._s(_vm.editingRows.includes(item) ? "mdi-content-save" : "mdi-pencil"))]),_c('v-icon',{staticClass:"mr-2",attrs:{"small":""},on:{"click":function($event){return _vm.removeAsset(item)}}},[_vm._v("mdi-trash-can-outline")])],1)]}},{key:"footer.prepend",fn:function(){return [_c('v-row',{staticClass:"ml-0 mr-4 align-center",attrs:{"justify":"space-between"}},[_c('v-btn',{attrs:{"text":"","x-small":""},on:{"click":_vm.removeAssetRange}},[_vm._v(" "+_vm._s(_vm.$t("pages.assets.import.stepThree.deleteSelected"))+" ")]),_c('p',{staticClass:"mb-0",class:_vm.hasErrors && _vm.onlyErroredRows ? 'red--text' : ''},[_vm._v(" "+_vm._s(_vm.$t("pages.assets.import.stepThree.assetsAttributes", { assetAmount: _vm.filteredAssets.length, attributeAmount: _vm.selectedAttributeCount, }))+" ")])],1)]},proxy:true},{key:"footer.page-text",fn:function(pageTextProps){return [_vm._v(" "+_vm._s(_vm.$t("common.dataTable.visibleRows", pageTextProps))+" ")]}}],null,true),model:{value:(_vm.selectedRows),callback:function ($$v) {_vm.selectedRows=$$v},expression:"selectedRows"}})],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }