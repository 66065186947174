<template>
  <div class="">
    <v-row align="center">
      <div class="col-12 border mb-3" style="height: 90px">
        <category-account-mapper
          v-show="!loadingAccountMapper"
          :categories="categoryMap"
          @updateCategories="(val) => (categoryMap = val)"
          :accounts="accounts"
          :syncAccounts="accountsToSync"
          @toggleSync="({ id, value }) => setAccountSync(id, value)"
          :autoMappedAccounts="autoMapptedAccounts"
          :readOnly="mappingIsReadOnly"
        ></category-account-mapper>
        <div
          v-if="loadingAccountMapper"
          class="flex-grow-1 d-flex align-center justify-center"
          style="height: 50px"
        >
          <activity-indicator></activity-indicator>
        </div>
      </div>
    </v-row>
  </div>
</template>
<script>
import { serialize } from "object-to-formdata";
import CategoryAccountMapper from "../../Inputs/CategoryAccountMapperNew.vue";
import Vue from "vue";
import ActivityIndicator from "../../Outputs/ActivityIndicator.vue";

export default {
  components: { CategoryAccountMapper, ActivityIndicator },
  props: {
    integrationId: String,
    mappingIsReadOnly: Boolean,
  },
  data() {
    return {
      categoryMap: [],
      accounts: [],
      accountsToSync: {},
      autoMapptedAccounts: {},
      loadingAccountMapper: false,
      assetAttributeSelectOptionIdSuggestion: {},
    };
  },
  watch: {
    mappingIsReadOnly(to) {
      if (to) {
        this.categoryMap = [];
        this.accounts = [];
        this.accountsToSync = {};
        this.autoMapptedAccounts = {};
        this.syncAccounts();
      }
    },
  },
  mounted() {
    this.syncAccounts();

    // When setting up integration get integration id from financial system
    // When editing existing integration get integration id from integration
  },
  methods: {
    setAccountSync(id, value) {
      Vue.set(this.accountsToSync, id, value);
    },
    // Syncs accounts from integration
    syncAccounts() {
      this.loadingAccountMapper = true;
      fetch(
        this.route("api.onboarding.integrations.economic-accounts.sync", {
          integrationid: this.integrationId,
        }),
        {
          method: "POST",
        }
      )
        .then(() => {
          this.getAccountsAndCategories();
        })
        .catch(() => {
          this.loadingAccountMapper = false;
        });
    },
    // Get categories and accounts from server
    async getAccountsAndCategories() {
      fetch(this.route("onboarding.accountNumberMapper"), {})
        .then((res) => res.json())
        .then((data) => {
          this.categoryMap = [...this.formatCategories(data)];
          this.accounts = [...this.formatAccounts(data.economicAccounts)];

          this.getSelectedOptions();
        })
        .catch(() => {
          this.loadingAccountMapper = false;
        });
    },
    // Formatter for categories and accounts
    formatCategories(data) {
      let _categoryMap = [];

      data.scopes.forEach((scope) => {
        const catsInScope = data.categories.filter(
          (category) => category.dependentOptionId === scope.id
        );
        catsInScope.forEach((category) => {
          _categoryMap.push({
            ...category,
            accountNumbers: [],
          });
        });
      });

      return _categoryMap.sort((a, b) => a.sortOrder - b.sortOrder);
    },
    formatAccounts(data) {
      const _accounts = [];
      const currentParent = {};

      data.forEach((account) => {
        if (account.accountType === "heading") {
          // Add last parentobject when encountering a new heading (Ignore first time since no parent object data has been added yet)
          if (currentParent.name && currentParent.accountNumbers.length > 0) {
            _accounts.push({ ...currentParent });
          }
          currentParent.name = account.name;
          currentParent.accountNumber = account.accountNumber;
          currentParent.accountNumbers = [];
        } else {
          const accountNumber = {
            name: account.name,
            number: account.accountNumber,
            id: account.id,
          };
          currentParent.accountNumbers.push(accountNumber);
        }
      });

      return _accounts;
    },

    // Adds selected options to categorymap
    getSelectedOptions() {
      fetch(
        this.route("asset-attribute-select-option-financial.list", {
          integrationid: this.integrationId,
        }),
        {
          method: "GET",
        }
      )
        .then((res) => res.json())
        .then((data) => {
          // Loop though all relations
          data.forEach((attributeAccountLink) => {
            const account = attributeAccountLink.financialAccount;

            const autoMappingSuggestion =
              attributeAccountLink.autoMappingSuggestion;
            const assetAttributeSelectOptionIdSuggestion =
              attributeAccountLink.assetAttributeSelectOptionIdSuggestion;

            // Set account sync to true if present
            Vue.set(this.accountsToSync, account.id, true);

            // add to list of automapped
            Vue.set(
              this.autoMapptedAccounts,
              account.id,
              autoMappingSuggestion
            );

            // add to list of automapped
            Vue.set(
              this.assetAttributeSelectOptionIdSuggestion,
              account.id,
              assetAttributeSelectOptionIdSuggestion
            );

            // loop though all category
            this.categoryMap.forEach((category, index) => {
              // If category id matches
              if (
                category.id ===
                attributeAccountLink.assetAttributeSelectOption?.id
              ) {
                // add account number to category
                this.categoryMap[index].accountNumbers.push({
                  name: account.name,
                  number: account.accountNumber,
                  id: account.id,
                });

                Vue.set(this.accountsToSync, account.id, true);
              }
            });
          });
        })
        .finally(() => {
          this.loadingAccountMapper = false;
        });
    },
    // Sets up links for account and categories
    setLinkedAccounts(callback) {
      // Add updated
      const links = [];

      this.categoryMap.forEach((category) => {
        category.accountNumbers.forEach((x) => {
          links.push({
            integrationId: this.integrationId,
            assetAttributeSelectOptionId: category.id,
            financialAccountId: x.id,
            autoMappingSuggestion: this.autoMapptedAccounts[x.id],
            assetAttributeSelectOptionIdSuggestion:
              this.assetAttributeSelectOptionIdSuggestion[x.id],
          });
        });
      });

      // Find accounts that have sync enabled but are not included in a category
      const accountsToSyncWithoutCategory = Object.keys(
        this.accountsToSync
      ).filter(
        (key) =>
          this.accountsToSync[key] === true &&
          links.filter((link) => link.financialAccountId === key).length === 0
      );

      // Add to links
      accountsToSyncWithoutCategory.forEach((accountId) =>
        links.push({
          integrationId: this.integrationId,
          assetAttributeSelectOptionId: null,
          financialAccountId: accountId,
          autoMappingSuggestion: this.autoMapptedAccounts[accountId],
          assetAttributeSelectOptionIdSuggestion:
            this.assetAttributeSelectOptionIdSuggestion[accountId],
        })
      );

      const formData = serialize(
        {
          links: links,
          integrationId: this.integrationId,
        },
        {
          indices: true,
        }
      );

      fetch(this.route("asset-attribute-select-option-financial.update"), {
        method: "POST",
        body: formData,
      })
        .then((res) => res.json())
        .then(() => {
          callback();
        });
    },
  },
};
</script>
