import { render, staticRenderFns } from "./FileMenuEntry.vue?vue&type=template&id=2fd2a156&scoped=true"
import script from "./FileMenuEntry.vue?vue&type=script&lang=js"
export * from "./FileMenuEntry.vue?vue&type=script&lang=js"
import style0 from "./FileMenuEntry.vue?vue&type=style&index=0&id=2fd2a156&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2fd2a156",
  null
  
)

export default component.exports