<template>
  <div class="flex-grow-1 d-flex justify-center align-center">
    <div class="d-flex justify-center align-center">
      <div class="px-0" fluid style="width: 1313px">
        <v-card
          light
          color="#fff"
          elevation="0"
          rounded="0"
          max-width="100%"
          class="flex-grow-1"
        >
          <div class="px-10 py-10">
            <p class="page-title">The GHG Protocol</p>
            <div class="appendix-content">
              <p>
                GHG Protocol establishes comprehensive global standardized
                frameworks to measure and manage greenhouse gas (GHG) emissions
                from private and public sector operations, value chains and
                mitigation actions. Building on a 20-year partnership between
                World Resources Institute (WRI) and the World Business Council
                for Sustainable Development (WBCSD), GHG Protocol works with
                governments, industry associations, NGOs, businesses and other
                organizations.
              </p>
              <p class="heading">Why Verarca uses the GHG Protocol?</p>
              <p>
                GHG Protocol supplies the world’s most widely used greenhouse
                gas accounting standards. The Corporate Accounting and Reporting
                Standard provides the accounting platform for virtually every
                corporate GHG reporting program in the world.
              </p>
              <p class="heading">Companies and Organizations</p>
              <p>
                In 2016, 92% of Fortune 500 companies responding to the CDP used
                GHG Protocol directly or indirectly through a program based on
                GHG Protocol.
              </p>
              <p class="heading">Countries and Cities</p>
              <p>
                Through their commitment to the Compact of Mayors, hundreds of
                cities across the globe have committed to using the GHG Protocol
                for Cities. The GHG Protocol also work with partners in key
                countries to develop national GHG emissions programs based on
                the GHG Protocol.
              </p>
              <p class="heading">The database</p>
              <p>Our data in numbers:</p>
              <div class="d-flex flex-row usps">
                <div class="flex-grow-1">
                  <p>48k+</p>
                  <p>emission calculations</p>
                </div>
                <div class="flex-grow-1">
                  <p>300+</p>
                  <p>global regions</p>
                </div>
                <div class="flex-grow-1">
                  <p>31</p>
                  <p>sources of data</p>
                </div>
              </div>
            </div>
          </div>
        </v-card>
      </div>
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style lang="scss" scoped>
.page-title {
  font-size: 42px;
}

.appendix-content {
  p {
    font-size: 20px;
    color: #666666;
    &.heading {
      color: #000;
      margin-bottom: 0;
    }
  }

  .usps {
    margin-top: 80px;
    div {
      p {
        font-size: 26px;
      }
      > p:first-child {
        margin-bottom: 0;
        font-size: 38px;
        line-height: 1;
        color: #000000;
      }
    }
  }
}
</style>
