<template>
  <div class="px-8">
    <div class="">
      <dialog-form-text-input
        title="Company reg. (CVR) no *"
        @input="(val) => (companyRegNoProxy = val)"
        :value="companyRegNoProxy"
      ></dialog-form-text-input>
      <dialog-form-text-input
        title="Company *"
        @input="(val) => (companyNameProxy = val)"
        :value="companyNameProxy"
      ></dialog-form-text-input>
      <dialog-form-text-input
        title="Email *"
        @input="(val) => (companyEmailProxy = validateEmail(val))"
        :value="companyEmailProxy"
      ></dialog-form-text-input>
    </div>
  </div>
</template>
<script>
import DialogFormTextInput from "../../Dialog/inputs/DialogFormTextInput.vue";
export default {
  components: {
    DialogFormTextInput,
  },
  props: {
    companyRegNo: String,
    companyName: String,
    companyEmail: String,
  },
  computed: {
    companyRegNoProxy: {
      get() {
        return this.companyRegNo;
      },
      set(value) {
        this.$emit("updateForm", "companyRegNo", value);
        this.$emit("update:companyRegNo", value);
      },
    },
    companyNameProxy: {
      get() {
        return this.companyName;
      },
      set(value) {
        this.$emit("updateForm", "companyName", value);
        this.$emit("update:companyName", value);
      },
    },
    companyEmailProxy: {
      get() {
        return this.companyEmail;
      },
      set(value) {
        this.$emit("updateForm", "companyEmail", value);
        this.$emit("update:companyEmail", value);
      },
    },
  },
  methods: {
    validateEmail(email) {
      const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      return emailRegex.test(email) ? email : this.companyEmailProxy;
    },
  },
};
</script>
