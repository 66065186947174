<template>
  <v-card light color="#fff" elevation="0" rounded="0" max-width="100%">
    <div class="px-5 py-5 fill-height d-flex flex-column overflow-hidden">
      <h3 class="pb-3 font-weight-regular text-h5">{{ title }}</h3>
      <div class="d-flex flex-column flex-sm-row align-center flex-grow-1">
        <apexchart
          width="220"
          type="pie"
          :options="chartOptions"
          :series="dataSeries"
          class="ml-0 ml-xl-8"
        ></apexchart>
        <v-container>
          <v-row>
            <v-col class="d-flex">
              <table>
                <tr
                  v-for="(value, index) in chartOptions.labels"
                  :key="index"
                  :class="{
                    invisible: value == 'Unassigned' && dataSeries[index] == 0,
                  }"
                >
                  <td class="py-1 px-3 font-weight-medium">
                    <div class="d-flex flex-row align-center">
                      <div
                        class="mr-4 rounded-4"
                        :style="
                          'background-color:' +
                          chartOptions.colors[index] +
                          ';display: inline-block; min-width: 16px; height: 16px; border-radius: 4px;'
                        "
                      ></div>
                      <div style="color: black">
                        {{ value }}
                      </div>
                    </div>
                  </td>
                  <td class="py-1 px-3">
                    <div>
                      {{ formatEmissions(dataSeries[index]) }}
                    </div>
                  </td>
                  <td class="py-1 px-3" style="color: #686868">
                    <div>{{ getPercentageFormatted(dataSeries[index]) }}</div>
                  </td>
                </tr>
              </table>
            </v-col>
          </v-row>
        </v-container>
      </div>
    </div>
  </v-card>
</template>

<script>
import { currencyFormatter } from "../../util/formatters";

export default {
  props: {
    title: String,
    dataSeries: Array,
    labels: Array,
    colors: Array,
    unitLabel: String,
  },
  computed: {
    summedValues() {
      return this.dataSeries.reduce((a, b) => a + b, 0);
    },
  },
  methods: {
    getPercentageFormatted(value) {
      if (this.summedValues !== 0) {
        const percent = (value / this.summedValues) * 100;
        const roundedPercent = Math.round(percent * 100) / 100; // two decimals
        return roundedPercent + "%";
      }
      return 0;
    },
    formatEmissions(value) {
      if (value > 999) {
        return (
          currencyFormatter.format(value / 1000) +
          " " +
          this.$t("common.units.tCo2e")
        );
      }

      if (isNaN(value)) {
        return "";
      }

      return (
        currencyFormatter.format(value) + " " + this.$t("common.units.kgCo2e")
      );
    },
  },
  data() {
    return {
      series: this.dataSeries,
      chartOptions: {
        chart: {
          animations: {
            dynamicAnimation: {
              enabled: true,
              speed: 1350,
            },
          },
        },
        legend: {
          show: false,
        },
        stroke: {
          show: false,
        },
        dataLabels: {
          enabled: false,
        },
        tooltip: {
          enabled: true,
        },
        labels: this.labels,
        colors: this.colors,
        responsive: [
          {
            breakpoint: 480,
            options: {
              chart: {
                width: 200,
              },
              legend: {
                show: false,
              },
            },
          },
        ],
      },
    };
  },
};
</script>

<style scoped>
.invisible {
  visibility: hidden;
}
</style>
