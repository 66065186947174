<template>
  <dialog-form
    :show-dialog="show"
    :loading="loading"
    @showStateChanged="show = $event"
    max-width="500px"
    persistent
  >
    <dialog-form-header
      :title="
        $t('pages.settings.customEmissionFactorsForm.customEmissionFactor')
      "
    ></dialog-form-header>
    <dialog-form-section-one-col
      class="pb-3"
      style="height: calc(81vh - 175px); overflow-y: scroll"
    >
      <dialog-form-text-input
        :title="
          $t(
            'pages.settings.customEmissionFactorsForm.fields.emissionFactorName'
          )
        "
        id="emission-factor-name"
        :value="form.name"
        :error-messages="formErrors['name']"
        :hide-details="!!!formErrors['name']"
        @input="
          (val) => {
            form.name = val;
            form.activityId = slugify(val); // Generate activityId
          }
        "
      ></dialog-form-text-input>
      <!--<dialog-form-text-input
        :title="$t('pages.settings.customEmissionFactorsForm.fields.uuid')"
        id="uuid"
        :value="form.activityId"
        @change="(val) => (form.activityId = slugify(val))"
        :error-messages="formErrors['activityId']"
        :hide-details="!!!formErrors['activityId']"
      ></dialog-form-text-input>-->
      <dialog-form-select-input
        :title="
          $t(
            'pages.settings.customEmissionFactorsForm.fields.calculationMethod'
          )
        "
        id="calculationMethod"
        :items="calculationMethods"
        :value="selectedCalculationMethod"
        @input="
          (val) => {
            selectedCalculationMethod = val;
            // Clear depending fields
            form.activityUnit = '';
            form.sectorId = null;
            emissionSectors = null;
            form.categoryId = null;
            emissionCategories = null;
            form.regionId = null;
          }
        "
        :error-messages="formErrors['calculationMethod']"
        :hide-details="!!!formErrors['calculationMethod']"
      ></dialog-form-select-input>
      <dialog-form-select-input
        :title="
          $t('pages.settings.customEmissionFactorsForm.fields.activityUnit')
        "
        id="activity-unit"
        :items="activityUnits"
        @change="
          (val) => {
            form.sectorId = null;
            emissionSectors = null;
            form.categoryId = null;
            emissionCategories = null;
            form.regionId = null;

            form.activityUnit = val;
            getEmissionSectors();
          }
        "
        :value="form.activityUnit"
        :error-messages="formErrors['activityUnit']"
        :hide-details="!!!formErrors['activityUnit']"
      ></dialog-form-select-input>
      <dialog-form-select-input
        :title="$t('pages.settings.customEmissionFactorsForm.fields.sector')"
        id="sector"
        :items="emissionSectors"
        itemText="name"
        itemValue="id"
        :value="form.sectorId"
        @input="
          (val) => {
            form.sectorId = val;
            form.categoryId = null;
          }
        "
        :error-messages="formErrors['sector']"
        :hide-details="!!!formErrors['sector']"
      ></dialog-form-select-input>
      <dialog-form-select-input
        :title="$t('pages.settings.customEmissionFactorsForm.fields.category')"
        id="category"
        :items="emissionCategories"
        itemText="name"
        itemValue="id"
        :value="form.categoryId"
        @input="
          (val) => {
            form.categoryId = val;
          }
        "
        :error-messages="formErrors['category']"
        :hide-details="!!!formErrors['category']"
      ></dialog-form-select-input>
      <dialog-form-select-input
        :title="$t('pages.settings.customEmissionFactorsForm.fields.region')"
        id="region"
        :items="emissionRegions"
        itemText="countryName"
        itemValue="id"
        :value="form.regionId"
        @input="(val) => (form.regionId = val)"
        :error-messages="formErrors['region']"
        :hide-details="!!!formErrors['region']"
      ></dialog-form-select-input>

      <dialog-form-text-input
        :title="
          $t('pages.settings.customEmissionFactorsForm.fields.emissionFactor')
        "
        id="emission-amount"
        :value="emissionFormattedForInput(emissionAmount)"
        @input="(val) => (emissionAmount = val)"
        @change="(val) => (form.kgCo2eAr5 = emissionFormattedForSystem(val))"
        :error-messages="formErrors['emissionAmount']"
        :hide-details="!!!formErrors['emissionAmount']"
        ref="emission-amount"
      >
        <template #append>
          <span
            >{{
              $t(
                "pages.settings.customEmissionFactorsForm.fields.kgCo2PerFormActivityUnit"
              )
            }}
            / {{ form.activityUnit }}</span
          >
        </template>
      </dialog-form-text-input>
      <dialog-form-text-area-input
        :title="$t('pages.settings.customEmissionFactorsForm.fields.info')"
        :value="form.description"
        @input="(val) => (form.description = val)"
      ></dialog-form-text-area-input>
      <dialog-form-input-wrapper
        :title="
          $t('pages.settings.customEmissionFactorsForm.fields.documentation')
        "
      >
        <div
          v-if="form.documentationFileId !== null"
          class="documentation-item d-flex flex-row align-center pl-4 mb-2"
        >
          <PdfIcon></PdfIcon>
          <v-btn
            class="documentation-btn flex-grow-1 mb-0 ml-0 px-2 justify-start text-none font-weight-regular"
            :href="
              route('api.files.content', {
                id: uploadedFile.id,
                filename: uploadedFile.originalName,
              })
            "
            target="_blank"
            left
            plain
            >{{ renderFileUrlLabel }}</v-btn
          >
          <v-btn @click="handleDocumentationRemoved()" class="mr-1" icon plain
            ><v-icon size="20" color="#686868">mdi-close</v-icon></v-btn
          >
        </div>
        <div
          v-else-if="form.documentationFile !== null"
          class="documentation-item d-flex flex-row align-center pl-4 mb-2"
          style="background: #fef2ef; border: 1px solid #fef2ef"
        >
          <PdfIcon></PdfIcon>
          <p class="flex-grow-1 mb-0 ml-2">{{ renderFileUrlLabel }}</p>

          <v-btn
            @click="handleNotUploadedDocumentationRemoved()"
            class="mr-1"
            icon
            plain
            ><v-icon size="20" color="#686868">mdi-close</v-icon></v-btn
          >
        </div>
        <dialog-form-file-upload-input
          v-else
          small
          :allowedImportMimeTypes="[
            'application/pdf',
            'image/png',
            'image/jpeg',
          ]"
          allowedFileExpensions=".pdf,.png,.jpg,.jpeg"
          @onFileUploadChanged="
            ($event) => {
              handleDocumentationChanged($event.target.files[0]);
            }
          "
          @fileChanged="
            ($event) => {
              handleDocumentationChanged($event);
            }
          "
          :multiple="false"
        ></dialog-form-file-upload-input>
      </dialog-form-input-wrapper>
    </dialog-form-section-one-col>
    <v-card-actions
      class="py-6 px-6 d-flex flex-column-reverse flex-md-row form-bottom-section"
    >
      <v-btn
        v-if="form.id"
        @click="destroyEmissionFactor(form.id)"
        class="text-none px-4 mb-4 mb-md-0 align-self-stretch"
        color="#686868"
        elevation="0"
        text
        tile
        x-large
      >
        <v-icon class="mr-3" color="#686868">mdi-delete-outline</v-icon>
        {{ $t("common.actions.delete") }}
      </v-btn>
      <v-spacer></v-spacer>
      <v-btn
        @click="show = false"
        class="text-none px-4 mb-4 mb-md-0 align-self-stretch"
        color="#686868"
        elevation="0"
        text
        tile
        x-large
      >
        <v-icon class="mr-3" color="#686868">mdi-close</v-icon>
        {{ $t("common.actions.cancel") }}
      </v-btn>
      <v-btn
        class="text-none ml-0 mb-4 mb-md-0 ml-md-4 align-self-stretch white--text"
        color="#F25D3B"
        elevation="0"
        tile
        x-large
        :disabled="loading"
        @click="submit()"
      >
        {{ $t("common.actions.save") }}
      </v-btn>
    </v-card-actions>
    <confirm-dialog
      v-model="showConfirmDialog"
      v-show="showConfirmDialog"
      :confirm-message="confirmMessage"
      @confirmed="handleConfirmation"
    ></confirm-dialog>
  </dialog-form>
</template>
<script>
import { serialize } from "object-to-formdata";

import DialogForm from "../../Components/Dialog/DialogForm.vue";
import DialogFormSectionOneCol from "../../Components/Dialog/DialogFormSectionOneCol.vue";

import { fixedAttributes, fixedClasses } from "../../util/fixedAssetData";
import DialogFormHeader from "../../Components/Dialog/DialogFormHeader.vue";
import DialogFormTextInput from "../../Components/Dialog/inputs/DialogFormTextInput.vue";
import DialogFormFileUploadInput from "../../Components/Dialog/inputs/DialogFormFileUploadInput.vue";
import DialogFormInputWrapper from "../../Components/Dialog/inputs/DialogFormInputWrapper.vue";
import DialogFormSelectInput from "../../Components/Dialog/inputs/DialogFormSelectInput.vue";
import { fullNumberFormatter } from "../../util/formatters";
import DialogFormTextAreaInput from "../../Components/Dialog/inputs/DialogFormTextAreaInput.vue";
import PdfIcon from "../../assets/svg/pdf.svg";
import ConfirmDialog from "../../Components/ConfirmDialog.vue";

export default {
  components: {
    DialogForm,
    DialogFormSectionOneCol,
    DialogFormHeader,
    DialogFormTextInput,
    DialogFormFileUploadInput,
    DialogFormInputWrapper,
    DialogFormSelectInput,
    DialogFormTextAreaInput,
    PdfIcon,
    ConfirmDialog,
  },
  props: {
    value: Boolean,
    currencies: Array,
    customEmissionFactor: Object,
    attributes: Array,
    defaultRegionId: String,
  },
  emits: ["setTab"],
  data() {
    return {
      form: this.$inertia.form({
        id: null,
        name: null,
        activityId: null,
        activityUnit: null,
        sectorId: null,
        categoryId: null,
        regionId: null,
        kgCo2eAr5: null,
        kgCo2eAr4: null,
        description: null,
        documentationFile: null,
        documentationFileId: null,
      }),
      loading: false,
      rerenderCounter: 0,
      fixedClasses,

      emissionSectors: null,

      emissionCategories: null,

      emissionRegions: [],

      formErrors: {},

      renderedKgCo2eAr5: "",

      calculationMethods: ["Spend-based", "Average-data"],
      selectedCalculationMethod: null,

      flattenedAverageDataUnits: [],

      itemId: String,
      showConfirmDialog: false,
      confirmMessage: this.$t("pages.assets.index.confirmDelete"),
    };
  },
  computed: {
    renderFileUrlLabel() {
      if (this.form.documentationFile !== null) {
        const splitStr = this.form.documentationFile.name.split("/");
        return splitStr[splitStr.length - 1];
      }

      if (this.uploadedFile !== null) {
        return this.uploadedFile.originalName;
      }

      return null;
    },
    show: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit("input", value);
      },
    },
    emissionAmount: {
      get() {
        const emissionAmount = String(this.renderedKgCo2eAr5);
        return this.emissionFormattedForInput(emissionAmount);
      },
      set(value) {
        this.renderedKgCo2eAr5 = value;
      },
    },
    activityUnits() {
      if (this.selectedCalculationMethod === "Spend-based") {
        return this.flattenedCurrencies;
      }

      return this.flattenedAverageDataUnits;
    },
    flattenedCurrencies() {
      return this.currencies.map((currency) => currency.currencyCode);
    },
    uploadedFile() {
      return this.customEmissionFactor?.documentationFile ?? null;
    },
  },
  mounted() {
    this.getEmissionSectors();
    this.getAverageDataUnits();
  },
  methods: {
    handleDocumentationRemoved() {
      this.form.documentationFileId = null;
    },
    handleNotUploadedDocumentationRemoved() {
      this.form.documentationFile = null;
      this.form.documentationFileId = null;
    },
    handleDocumentationChanged(file) {
      this.form.documentationFile = file;
    },
    setUserRegion() {
      const defaultRegionId = this.defaultRegionId;
      if (
        this.emissionRegions
          .map((region) => region.id)
          .includes(defaultRegionId)
      ) {
        this.form.regionId = defaultRegionId;
      }
    },
    getAverageDataUnits() {
      const averageDataUnits = this.attributes.find(
        (x) => x.id === fixedAttributes.generalUnitTypeId
      );

      this.flattenedAverageDataUnits =
        averageDataUnits.attributeSelectOptions.map((item) => item.value);
    },
    slugify(str) {
      return String(str)
        .replace(/[øØ]/g, "oe")
        .replace(/[æÆ]/g, "ae")
        .replace(/[åÅ]/g, "aa")
        .replace(/,/g, "") // remove ,
        .normalize("NFKD") // split accented characters into their base characters and diacritical marks
        .replace(/[\u0300-\u036f]/g, "") // remove all the accents, which happen to be all in the \u03xx UNICODE block.
        .trim() // trim leading or trailing whitespace
        .toLowerCase() // convert to lowercase
        .replace(/[^a-z0-9 -]/g, "") // remove non-alphanumeric characters + allow underscore
        .replace(/\s+/g, "_") // replace spaces with underscore
        .replace(/_+/g, "_"); // remove consecutive underscore
    },
    emissionFormattedForInput(value) {
      var strippedVal = String(value)
        .replace(/\./g, "") // remove all .
        .replace(",", ".") // convert first , to .
        .replace(/,/g, ""); // remove remaining ,

      // Prepend 0 if first char is ,
      if (strippedVal.charAt(0) === ",") {
        strippedVal = "0" + strippedVal;
      }

      strippedVal = strippedVal.replace(/[^0-9.]/g, "");

      return fullNumberFormatter.format(Number(strippedVal));
    },
    emissionFormattedForSystem(value) {
      var formattedVal = String(value)
        .replace(/\./g, "") // remove all .
        .replace(",", "."); // replace , with .
      return formattedVal;
    },
    getEmissionSectors() {
      fetch(
        this.route("api.emission-sectors.index", {
          spendBased: this.selectedCalculationMethod === "Spend-based",
          unitType: this.form.activityUnit,
          withFactors: "false",
        })
      )
        .then((res) => res.json())
        .then((data) => {
          this.emissionSectors = data.map((x) => ({
            ...x,
            value: x.name,
          }));
        });
    },
    getEmissionCategories(sectorId) {
      fetch(
        this.route("api.emission-categories.index", {
          emissionSectorId: sectorId,
          spendBased: this.selectedCalculationMethod === "Spend-based",
          unitType: this.form.activityUnit,
          withFactors: "false",
        })
      )
        .then((res) => res.json())
        .then((data) => {
          this.emissionCategories = data.map((x) => ({
            ...x,
            value: x.name,
          }));
        });
    },
    getEmissionRegions(/* categoryId */) {
      fetch(
        this.route("api.emission-regions.index", {
          withFactors: "false",
          /*emissionCategoryId: categoryId,
          spendBased: this.selectedCalculationMethod === "Spend-based",
          unitType: this.form.activityUnit,*/
          // all regions should always be available (VER-415)
        })
      )
        .then((res) => res.json())
        .then((data) => {
          this.emissionRegions = data.map((x) => ({
            ...x,
            value: x.countryName ? x.countryName : x.name,
          }));

          this.setUserRegion();
        });
    },
    setForm(customEmissionFactor) {
      if (customEmissionFactor != null) {
        this.form.id = customEmissionFactor.id;
        this.form.name = customEmissionFactor.name;
        this.form.activityId = customEmissionFactor.activityId;
        this.form.activityUnit = customEmissionFactor.activityUnit;
        this.form.sectorId = customEmissionFactor.sector?.id;
        this.form.categoryId = customEmissionFactor.category?.id;
        this.form.regionId = customEmissionFactor.region?.id;
        this.form.kgCo2eAr4 = customEmissionFactor.kgCo2eAr4
          ? "" + customEmissionFactor.kgCo2eAr4
          : null;
        this.form.kgCo2eAr5 = customEmissionFactor.kgCo2eAr5
          ? "" + customEmissionFactor.kgCo2eAr5
          : null;
        this.form.description = customEmissionFactor.description;
        this.form.documentationFile = null;
        this.form.documentationFileId =
          customEmissionFactor.documentationFileId;
      } else {
        this.form.id = null;
        this.form.name = null;
        this.form.activityId = null;
        this.form.activityUnit = null;
        this.form.sectorId = null;
        this.form.categoryId = null;
        this.form.regionId = null;
        this.form.kgCo2eAr4 = null;
        this.form.kgCo2eAr5 = null;
        this.form.description = null;
        this.form.documentationFile = null;
        this.form.documentationFileId = null;
      }
    },
    validateForm() {
      this.formErrors = {};

      if (!this.form.name || this?.form?.name?.length === 0) {
        this.formErrors["name"] = this.$t(
          "pages.settings.customEmissionFactorsForm.validation.activityNameRequired"
        );
        document.getElementById("emission-factor-name").scrollIntoView();
      } else if (
        !this.form.activityId ||
        this?.form?.activityId?.length === 0
      ) {
        this.formErrors["activityId"] = this.$t(
          "pages.settings.customEmissionFactorsForm.validation.uuidRequired"
        );
        document.getElementById("uuid").scrollIntoView();
      } else if (
        !this.selectedCalculationMethod ||
        this.selectedCalculationMethod?.length === 0
      ) {
        this.formErrors["calculationMethod"] = this.$t(
          "calculationMethodRequired"
        );
        document.getElementById("calculationMethod").scrollIntoView();
      } else if (
        !this.form.activityUnit ||
        this?.form?.activityUnit?.length === 0
      ) {
        this.formErrors["activityUnit"] = this.$t(
          "pages.settings.customEmissionFactorsForm.validation.activityUnitRequired"
        );
        document.getElementById("activity-unit").scrollIntoView();
      } else if (!this.form.sectorId || this?.form?.sectorId?.length === 0) {
        this.formErrors["sector"] = this.$t(
          "pages.settings.customEmissionFactorsForm.validation.sectorRequired"
        );
        document.getElementById("sector").scrollIntoView();
      } else if (
        !this.form.categoryId ||
        this?.form?.categoryId?.length === 0
      ) {
        this.formErrors["category"] = this.$t(
          "pages.settings.customEmissionFactorsForm.validation.categoryRequired"
        );
        document.getElementById("category").scrollIntoView();
      } else if (!this.form.regionId || this?.form?.regionId?.length === 0) {
        this.formErrors["region"] = this.$t(
          "pages.settings.customEmissionFactorsForm.validation.regionRequired"
        );
        document.getElementById("region").scrollIntoView();
      } else if (!this.emissionAmount || this.emissionAmount.length === 0) {
        this.formErrors["emissionAmount"] = this.$t(
          "pages.settings.customEmissionFactorsForm.validation.emissionAmountRequired"
        );
        document.getElementById("emission-amount").scrollIntoView();
      }

      if (Object.keys(this.formErrors).length > 0) {
        return false;
      }

      return true;
    },
    closeModal(confirmClose = true) {
      const confirmText = this.$t(
        "pages.settings.customEmissionFactorsForm.confirmClose"
      );

      const hasUserInteraction = this.currentStep > 1 || this.form.isDirty;

      if (hasUserInteraction && confirmClose && !confirm(confirmText)) return;

      this.show = false;

      this.currentStep = 1;
      this.form.reset();

      this.portfolios = [];
      this.sectors = [];
      this.categories = [];
      this.regions = [];
    },
    submit() {
      if (this.validateForm() === false) {
        return;
      }

      const preparedForm = this.form.transform((data) =>
        serialize(data, {
          indices: true,
          dotsForObjectNotation: true,
        })
      );

      if (this.form.id) {
        preparedForm.patch(
          this.route(
            "settings.emissionFactor.update",
            this.customEmissionFactor.id
          ),
          {
            onSuccess: () => {
              this.closeModal(false);
            },
            onFinish: () => {
              this.$emit("setTab");
            },
          }
        );
      } else {
        preparedForm.post(this.route("settings.emissionFactor.create"), {
          onSuccess: () => {
            this.closeModal(false);
          },
          onFinish: () => {
            this.$emit("setTab");
          },
        });
      }
      this.$emit("customEmissionFactorChanged");
    },
    destroyEmissionFactor(id) {
      this.itemId = id;
      this.showConfirmDialog = true;
    },
    handleConfirmation(status) {
      this.isConfirmed = status;
      if (status) {
        this.confirmDestroyEmissionFactor();
      }
    },
    confirmDestroyEmissionFactor() {
      this.$inertia.delete(
        this.route("settings.emissionFactor.destroy", this.itemId),
        {
          onSuccess: () => {
            this.closeModal(false);
          },
          onFinish: () => {
            this.$emit("setTab");
          },
        }
      );
      this.itemId = "";
    },
  },
  watch: {
    customEmissionFactor: function (customEmissionFactor) {
      this.setForm(customEmissionFactor);
    },
    show(value) {
      if (value === false) {
        this.setForm(null);
        this.currentStep = 1;
        this.$emit("customEmissionFactorChanged");
      }

      // Set rendered emission val
      if (value === true) {
        this.renderedKgCo2eAr5 = fullNumberFormatter.format(
          Number(this.form.kgCo2eAr5)
        );

        this.selectedCalculationMethod = this.flattenedCurrencies.includes(
          this.form.activityUnit
        )
          ? "Spend-based"
          : "Average-data";
      }

      if (!value) return;
    },
    "form.sectorId"(to) {
      // not on initial
      //this.form.categoryId = null;
      //this.form.regionId = null;
      this.emissionCategories = null;
      if (to !== null) {
        this.getEmissionCategories(to);
      }
    },
    "form.categoryId"(to) {
      // not on initial load
      //this.form.regionId = null;
      if (to !== null) {
        this.getEmissionRegions(to);
      } else {
        this.emissionRegions = [];
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.form-bottom-section {
  position: sticky;
  bottom: 0;
  background: #fff;
}

.documentation-item {
  background: #fef2ef;
  border: 1px solid #fef2ef;
  .documentation-btn {
    color: #686868;
    flex: 1;
    overflow: hidden;
    letter-spacing: 0;
  }
  &:hover {
    border-color: #f25d3b;
    .documentation-btn * {
      color: #f25d3b;
    }
  }
}
</style>
