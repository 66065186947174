<template>
  <v-menu
    v-model="showFilter"
    :close-on-content-click="false"
    :close-on-click="false"
    offset-y
    nudge-left="50px"
  >
    <template #activator="{ on, attrs }">
      <td v-bind="attrs" v-on="on">
        <div class="d-flex flex-row filter-icon">
          <v-icon>mdi-magnify</v-icon>
          <p class="mb-0 ml-1 fill-width nowrap">
            {{ formattedTimestampFilter }}
          </p>
        </div>
      </td>
    </template>
    <v-card>
      <v-card-text>
        <v-row class="mx-0">
          <v-col class="pl-0">
            <label class="d-block">{{ $t("common.filters.from") }}</label>
            <date-picker
              :lang="lang"
              first-day-of-week="1"
              :value="from"
              type="date"
              @input="handleFromValueChanged"
            />
          </v-col>
          <v-col class="pr-0">
            <label class="d-block">{{ $t("common.filters.to") }}</label>
            <date-picker
              :lang="lang"
              first-day-of-week="1"
              v-model="to"
              type="date"
              :disabled-date="(date) => date < from"
            />
          </v-col>
        </v-row>
      </v-card-text>
      <v-card-actions>
        <v-row justify="end" class="mx-0">
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" text @click="close">
            <v-icon>mdi-close</v-icon>
            <p class="teal--text text--darken-4 mb-0">
              {{ $t("common.actions.close") }}
            </p>
          </v-btn>
          <v-btn color="blue darken-1" text @click="submit">
            <v-icon>mdi-chevron-right</v-icon>
            <p class="teal--text text--darken-4 mb-0">
              {{ $t("common.actions.submit") }}
            </p>
          </v-btn>
        </v-row>
      </v-card-actions>
    </v-card>
  </v-menu>
</template>
<script>
import DatePicker from "vue2-datepicker";
import "vue2-datepicker/index.css";

import { toLocaleUTCDateStringFiltered } from "@/util/dateTime";

export default {
  components: { DatePicker },
  props: {
    value: Boolean,
    identifier: String,
  },
  emits: ["input", "submit"],
  data() {
    return {
      lang: {
        formatLocale: {
          firstDayOfWeek: 1,
        },
        monthBeforeYear: false,
      },
      from: null,
      to: null,
      showFilter: false,
    };
  },
  computed: {
    show: {
      get() {
        return this.showFilter;
      },
      set(value) {
        this.showFilter = value;
      },
    },
    formattedTimestampFilter() {
      let result = "";

      if (!this.from && !this.to) return result;

      if (this.from) {
        result += toLocaleUTCDateStringFiltered(this.from, false) + " ";
      }

      result += "→";

      if (this.to)
        result += " " + toLocaleUTCDateStringFiltered(this.to, false) + " ";

      return result;
    },
  },
  methods: {
    handleFromValueChanged(value) {
      if (value > this.to) this.to = null;

      this.from = value;
    },
    submit() {
      const fromDate =
        this.from !== null ? this.from.toISOString().slice(0, 10) : "";
      const toDate = this.to ? this.to.toISOString().slice(0, 10) : "";

      this.$emit("submit", {
        value: fromDate + " | " + toDate,
        identifier: this.identifier,
      });

      this.showFilter = false;
    },
    close() {
      this.showFilter = false;
    },
  },
  watch: {
    show(value) {
      if (!value) return;

      this.from = this.from ?? null;
      this.to = this.to ?? null;
    },
  },
};
</script>
<style lang="scss">
.filter-icon .v-icon {
  font-size: 16px;
}
.nowrap {
  white-space: nowrap;
}
</style>
