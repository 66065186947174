export const FreeId = "free";

export const Extra = "Additinal stripe payment";

export const FreeMaxFiles = 10;
export const FreeMaxLines = 50;

export const Tier500MaxFiles = 500;
export const Tier500MaxLines = 2500;

export const Tier1000MaxFiles = 1000;
export const Tier1000MaxLines = 5000;

export const Tier3000MaxFiles = 3000;
export const Tier3000MaxLines = 15000;

export const Tier6000MaxFiles = 6000;
export const Tier6000MaxLines = 30000;

export const Tier12000MaxFiles = 12000;
export const Tier12000MaxLines = 60000;

export const Tier20000MaxFiles = 20000;
export const Tier20000MaxLines = 100000;

export const Tier30000MaxFiles = 30000;
export const Tier30000MaxLines = 150000;
