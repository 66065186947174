<template>
  <div class="px-5">
    <v-row align="center">
      <div
        class="col-12 border mb-3 justify-center d-flex align-center pointer"
        :class="{ 'red-border': selectedIntegration === 'e-conomic' }"
        @click="selectItem('e-conomic')"
        style="height: 90px"
      >
        <EConomic />
      </div>
    </v-row>
    <!-- <v-row align="center">
      <div
        class="col-12 border mb-3 justify-center d-flex align-center pointer"
        :class="{ 'red-border': selectedIntegration === 'uniconta' }"
        @click="selectItem('uniconta')"
        style="height: 90px"
      >
        <Uniconta />
      </div>
    </v-row> -->
    <v-row align="center">
      <div
        class="col-12 border mb-3 justify-center d-flex align-center pointer"
        :class="{ 'red-border': selectedIntegration === 'dynamic365' }"
        @click="selectItem('dynamic365')"
        style="height: 90px"
      >
        <Dynamic365 />
      </div>
    </v-row>
    <v-row align="center">
      <div
        class="col-12 border justify-center d-flex align-center flex-column pointer"
        :class="{ 'red-border': selectedIntegration === 'other' }"
        @click="selectItem('other')"
        :style="{ height: selectedIntegration === 'other' ? '90px' : '90px' }"
      >
        <div class="py-2">
          <span
            class="pointer"
            :style="{
              color: selectedIntegration === 'other' ? '#F25D3B' : 'inherit',
            }"
            disabled
          >
            {{ $t("components.onboarding.stepThree.systemNotHere") }}
          </span>
        </div>
        <!--<div
          v-if="selectedIntegration === 'other'"
          style="width: 100%"
          class="pt-2"
        >
          <input
            autofocus
            type="financialSystem"
            :placeholder="$t('components.onboarding.stepThree.letUsKnowWhich')"
            name="financialSystem"
            class="lightGrey black--text pl-4 pt-2 pb-2 mb-4 text-caption input"
            style="width: 100%"
            @click.stop
            v-model="otherIntegration"
            @input="emitOtherIntegration"
          />
        </div>-->
      </div>
    </v-row>
  </div>
</template>

<script>
import EConomic from "../../../assets/svg/visma-e-conomic.svg";
// import Uniconta from "../../../assets/svg/uniconta.svg";
import Dynamic365 from "../../../assets/svg/dynamic365.svg";
export default {
  components: {
    EConomic,
    // Uniconta,
    Dynamic365,
  },
  data() {
    return {
      selectedIntegration: null,
      otherIntegration: "",
    };
  },
  methods: {
    selectItem(item) {
      if (this.selectedIntegration === item) {
        this.selectedIntegration = null;
      } else {
        this.selectedIntegration = item;
      }
      this.$emit("update:selected-integration", this.selectedIntegration);
      this.$emit("updateForm", "financialSystem", this.selectedIntegration);
    },
    emitOtherIntegration() {
      this.$emit("otherIntegration", this.otherIntegration);
    },
  },
};
</script>

<style lang="scss" scoped>
.border {
  border: 2px solid #f7f7f7;
}

.red-border {
  border-color: #f25d3b;
}

input:focus {
  outline: none !important;
  box-shadow: 0 0 3px black;
}
.pointer {
 cursor: pointer;
}
</style>
