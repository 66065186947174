<template>
  <v-dialog
    style="z-index: 20001"
    v-model="show"
    scrollable
    max-width="80vw"
    hide-overlay
    persistent
  >
    <template #activator="{ on, attrs }">
      <v-btn
        v-bind="attrs"
        v-on="on"
        text
        light
        tile
        class="text-none font-weight-medium px-2 px-xl-4"
        color="#686868"
        ><slot name="trigger-button"></slot>
      </v-btn>
    </template>
    <v-card height="70vh" class="base-background-color">
      <v-card-title>{{ $t("components.layouts.options.title") }}</v-card-title>
      <v-card-text>
        <v-row>
          <v-col>
            <v-radio-group v-model="form.attributeDisplaySize">
              <template #label>
                <h4 class="font-weight-black">
                  {{ $t("components.layouts.options.attributeOptionsLabel") }}
                </h4>
              </template>
              <v-radio
                v-for="displaySize in attributeDisplaySizes"
                :key="displaySize"
                :value="displaySize"
              >
                <template #label>
                  <div>
                    {{
                      $t(
                        `components.layouts.options.attributeOptions.${displaySize}`
                      )
                    }}
                  </div>
                </template>
              </v-radio>
            </v-radio-group>
          </v-col>
        </v-row>
        <v-row dense>
          <v-col>
            <label class="text-h6">
              {{ $t("components.layouts.options.attributeSortingLabel") }}
            </label>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="2">
            <v-btn
              color="primary"
              class="fill-width"
              :loading="resetOrderLoading"
              @click="resetAttributeSortOrder"
            >
              {{ $t("components.layouts.options.resetAttributeOrder") }}
            </v-btn>
          </v-col>
          <v-col v-if="showResetOrderConfirmation" cols="1">
            <v-icon color="green" class="mt-1">mdi-check</v-icon>
          </v-col>
        </v-row>
      </v-card-text>
      <v-card-actions class="px-6">
        <v-row justify="end">
          <v-btn
            color="blue darken-1"
            text
            @click="changeLayout(true)"
            :disabled="loading"
          >
            <v-icon class="black--text">mdi-content-save-outline</v-icon>
            <p class="teal--text text--darken-4 mb-0">
              {{ $t("common.actions.submitAndSave") }}
            </p>
          </v-btn>
          <v-btn color="blue darken-1" text @click="show = false">
            <v-icon class="black--text">mdi-close</v-icon>
            <p class="teal--text text--darken-4 mb-0">
              {{ $t("common.actions.close") }}
            </p>
          </v-btn>
          <v-btn
            color="blue darken-1"
            text
            @click="changeLayout()"
            :disabled="loading"
          >
            <v-icon class="black--text">mdi-content-save-outline</v-icon>
            <p class="teal--text text--darken-4 mb-0">
              {{ $t("common.actions.submit") }}
            </p>
          </v-btn>
        </v-row>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
/*
  <v-btn
      v-bind="attrs"
      v-on="on"
      class="ml-4 mb-2"
      height="3rem"
      text
      color="primary"
    >
    <div class="d-flex flex-column justify-center align-center">
      <v-icon class="black--text">mdi-tune</v-icon>
      <p class="teal--text text--darken-4 mb-0">
        {{ $t("components.layouts.options.layoutOptions") }}
      </p>
    </div>
  </v-btn>
*/

export default {
  props: {
    value: Boolean,
    layout: Object,
    attributeDisplaySizes: Array,
  },
  emits: ["input", "changeLayout", "saveLayout"],
  data() {
    return {
      form: {
        attributeDisplaySize: this.layout.attributeDisplaySize,
      },
      loading: false,
      resetOrderLoading: false,
      showResetOrderConfirmation: false,
    };
  },
  computed: {
    show: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit("input", value);
      },
    },
  },
  methods: {
    changeLayout(saveLayout) {
      this.loading = true;

      this.$emit("changeLayout", {
        attributeDisplaySize: this.form.attributeDisplaySize,
      });

      if (saveLayout) this.$emit("saveLayout");

      this.show = false;
      this.loading = false;
    },
    resetAttributeSortOrder() {
      this.resetOrderLoading = true;

      this.$emit("changeLayout", {
        sortOrderMappings: {},
      });

      this.resetOrderLoading = false;

      this.showResetOrderConfirmation = true;
    },
  },
  watch: {
    showResetOrderConfirmation: function (value) {
      if (!value) return;

      setTimeout(() => {
        this.showResetOrderConfirmation = false;
      }, 5000);
    },
  },
};
</script>
