<template>
  <v-tab-item :transition="false" :value="tabHref">
    <div class="fill-height fill-width">
      <v-row>
        <v-col cols="12" md="4" class="pr-xl-16">
          <dialog-form-select-input
            :title="$t('pages.settings.index.fields.standardCurrency')"
            v-model="defaultCurrencyIdProxy"
            :items="currencies"
            item-text="currencyCode"
            item-value="id"
            @change="(value) => (defaultCurrencyIdProxy = value)"
          >
          </dialog-form-select-input>
          <dialog-form-select-input
            :title="$t('pages.settings.index.fields.dateFormat')"
            :items="dateFormats"
            v-model="selectedDateFormatProxy"
            itemText=""
            item-value=""
          ></dialog-form-select-input>
          <dialog-form-select-input
            :title="$t('pages.settings.index.fields.defaultRegion')"
            :items="emissionRegions"
            v-model="selectedRegionProxy"
            itemText="countryName"
            item-value="id"
          ></dialog-form-select-input>
        </v-col>
      </v-row>
    </div>
  </v-tab-item>
</template>
<script>
import DialogFormSelectInput from "../../../Components/Dialog/inputs/DialogFormSelectInput.vue";
export default {
  components: { DialogFormSelectInput },
  props: {
    tabHref: String,
    defaultCurrencyId: String,
    currencies: Array,
    selectedDateFormat: String,
    dateFormats: Array,
    emissionRegions: Array,
    defaultRegionId: String,
  },
  data() {
    return {};
  },
  emits: ["updateForm"],
  computed: {
    defaultCurrencyIdProxy: {
      get() {
        return this.defaultCurrencyId;
      },
      set(value) {
        this.$emit("updateForm", "defaultCurrencyId", value);
      },
    },
    selectedDateFormatProxy: {
      get() {
        return this.selectedDateFormat;
      },
      set(value) {
        this.$emit("updateForm", "selectedDateFormat", value);
      },
    },
    selectedRegionProxy: {
      get() {
        return this.defaultRegionId;
      },
      set(value) {
        this.$emit("updateForm", "defaultRegionId", value);
      },
    },
  },
};
</script>
