<template>
  <v-data-table
    style="font-weight: 400; font-size: 16px !important"
    light
    :headers="headers"
    :items="itemsToRender"
    :items-per-page="5"
    :key="tableRerenderCounter"
    v-model="proxySelectedAssets"
    v-sortable-table="{ onEnd: sortTableHeaders }"
    class="elevation-0 row-pointer rounded-r-0 fill-height attributes-table"
    :class="isDisplaySize('Minimal') ? 'attributes-fixed-table' : ''"
    :server-items-length="totalItems ?? null"
    :options.sync="proxyPagination"
    :footer-props="tableFooterProps"
    :no-data-text="$t('common.dataTable.noData')"
    multi-sort
    show-select
    @update:page="changePage()"
    @update:items-per-page="changePage()"
    @update:sort-desc="changeSorting()"
    checkbox-color="#F25D3B"
    :header-props="{ 'sort-icon': 'mdi-unfold-more-horizontal' }"
    ref="dataTable"
  >
    <!--height="calc(100% - 58px)"-->
    <template v-for="header in headers" v-slot:[`header.${header.value}`]>
      <v-tooltip top v-bind:key="header.key">
        <template v-slot:activator="{ on }">
          <em v-on="on">
            <span>{{ header.text }} </span>
            <v-icon
              v-if="getBulkEditAttribute(header)"
              class="bulk-edit-icon ml-1"
              small
              @click.stop="toggleBulkEditModal(header)"
            >
              mdi-pencil-box-multiple-outline
            </v-icon>
          </em>
        </template>
        <span>{{ header.text }} </span>
      </v-tooltip>
    </template>
    <!--<template v-slot:body.prepend>
      <header-search-row
        :columnIdentifiers="columnIdentifiers"
        :attributeFilters="currentAttributeFilters"
        :attributes="attributes"
        :columnFilterData="columnFilterData"
        :postFilters="postFilters"
        :showActionRow="!showFileMenu"
        @updateAttributeFilters="$emit('updateAttributeFilters', $event)"
        @updatePostFilters="$emit('updatePostFilters', $event)"
        @updateColumnFilterData="$emit('updateColumnFilterData', $event)"
      />
    </template>-->
    <template #item="{ isSelected, select, item }">
      <attribute-data-row
        :groupName="getGroupName(item)"
        :isSelected="isSelected"
        :select="select"
        :item="item"
        :attributes="attributes"
        :currentLayout="currentAssetLayout"
        :headers="headers"
        :onlyFileAttribute="onlyFileAttribute"
        :defaultCurrencyCode="defaultCurrencyCode"
        entryType="asset"
        @editRowClicked="editAsset"
        @copyRowClicked="copyAsset"
        @destroyRowClicked="destroyAsset"
        @linkFilesToRow="handleAssetFileLinking"
        :loadingSkeletonOn="
          loadingSkeletonOn || updatingAssetIds.includes(item.id)
        "
      />
    </template>
    <!--<template #footer.prepend>
      <v-row justify="space-between" class="ml-0 mr-8 align-center">
        <v-btn text small @click="() => removeAssetRange()">
          {{ $t("pages.assets.index.deleteSelectedAssets") }}
        </v-btn>
      </v-row>
    </template>-->
    <template #body.append="{ items }">
      <tr>
        <td></td>
        <td
          v-for="header in headers"
          :key="header.value"
          :class="header.value === 'action' ? 'fixed' : ''"
        >
          <span
            v-if="dynamicHeaderIds.includes(header.value)"
            class="font-weight-bold"
          >
            {{ summarizeAttribute(mappedAttributes[header.value], items) }}
          </span>
        </td>
      </tr>
    </template>
    <template #footer.page-text="pageTextProps">
      {{ $t("common.dataTable.visibleRows", pageTextProps) }}
    </template>
    <template #footer>
      <v-expand-transition>
        <v-card
          transition="scroll-y-transition"
          style="position: sticky; bottom: 0; z-index: 200"
          class=""
          tile
          elevation="0"
          dark
          v-show="selectedAssets.length > 0"
        >
          <div class="px-4 d-flex flex-row py-4">
            <div class="flex-grow-1 align-center d-flex">
              <v-simple-checkbox
                on-icon="mdi-minus-box"
                color="#F25D3B"
                :ripple="false"
                :value="true"
                @input="() => (proxySelectedAssets = [])"
              ></v-simple-checkbox>
              <v-avatar class="ml-5 mr-3" color="#545454" size="24">
                <span class="white--text text-body-2">{{
                  selectedAssets.length
                }}</span></v-avatar
              ><span>{{ $t("common.dataTable.itemsSelected") }}</span>
            </div>
            <div class="">
              <v-btn
                class="text-none"
                large
                elevation="0"
                outlined
                tile
                @click="() => removeAssetRange()"
              >
                <v-progress-circular
                  v-if="deleteRangeLoading"
                  :size="24"
                  :width="3"
                  indeterminate
                  class="mr-3"
                ></v-progress-circular>
                <v-icon v-else class="mr-3" dark>mdi-trash-can-outline</v-icon>
                {{ $t("common.dataTable.deleteAll") }}
              </v-btn>
              <v-btn
                class="text-none ml-4"
                large
                color="#266663"
                elevation="0"
                tile
                @click="verifyAssetRange"
                ><v-icon class="mr-3" dark>mdi-check-circle</v-icon>
                {{ $t("common.dataTable.verifyAll") }}
              </v-btn>
            </div>
          </div>
        </v-card>
      </v-expand-transition>
    </template>
  </v-data-table>
</template>

<script>
import AttributeDataRow from "../../Components/Attribute/AttributeDataRow.vue";
import { sortableTableDirective } from "@/util/dataTable";
import {
  fixedAttributes,
  fixedFieldAttributes,
  notBulkEditableAttributes,
} from "../../util/fixedAssetData";
import { currencyFormatter } from "../../util/formatters";

export default {
  components: { AttributeDataRow },
  props: {
    headers: Array,
    items: Array,
    totalItems: Number,
    currentAttributeFilters: Array,
    attributes: { type: Array, default: () => [] },
    columnFilterData: Object,
    postFilters: Object,
    showFileMenu: Boolean,
    currentAssetLayout: Object,
    onlyFileAttribute: Object,
    defaultCurrencyCode: String,
    editAsset: Function,
    copyAsset: Function,
    destroyAsset: Function,
    handleAssetFileLinking: Function,
    sortTableHeaders: Function,
    tableRerenderCounter: Number,
    pagination: Object,
    tableFooterProps: Object,
    selectedAssets: { type: Array, default: () => [] },
    columnFilterProperties: Array,
    deleteRangeLoading: Boolean,
    loadingSkeletonOn: Boolean,
    updatingAssetIds: Array,
  },
  data() {
    return {
      proxySelectedAssets: this.selectedAssets,
      proxyPagination: this.pagination,
      hasDefiningRows: false,
      lastGroupKey: "",
    };
  },
  watch: {
    proxySelectedAssets() {
      this.$emit("input", this.proxySelectedAssets);
    },
    selectedAssets(value) {
      this.proxySelectedAssets = value;
    },
    proxyPagination() {
      this.$emit("updatePagination", this.proxyPagination);
    },
    pagination(value) {
      this.proxyPagination = value;
    },
  },
  directives: {
    "sortable-table": sortableTableDirective,
  },
  methods: {
    getGroupName(item) {
      const key =
        item[fixedAttributes.infoInvoiceId]?.assetAttributeFileLinks[0]?.fileId;

      if (typeof key === "undefined") {
        return "no-group";
      }
      return this.groupsByKeys[item.id];
    },
    getAttributeFromHeader(header) {
      if (!header?.value) return;

      const attribute = this.attributes.find((x) => x.id === header.value);

      return attribute ?? null;
    },
    getBulkEditAttribute(header) {
      if (notBulkEditableAttributes.includes(header.value)) return null;

      const attribute = this.getAttributeFromHeader(header);

      if (attribute?.format === "File") return null;

      return attribute;
    },
    toggleBulkEditModal(header) {
      if (!header) {
        this.bulkEditAttribute = null;
        return;
      }

      const attribute = this.getBulkEditAttribute(header);

      if (!attribute) return;

      this.bulkEditAttribute = attribute;
    },
    removeAssetRange() {
      this.$emit("removeAssetRange");
    },
    verifyAssetRange() {
      this.$emit("verifyAssetRange");
    },
    changePage() {
      this.$emit("changePage");
    },
    changeSorting() {
      this.$emit("changeSorting");
    },
    isDisplaySize(attributeDisplaySize) {
      return (
        this.currentAssetLayout.attributeDisplaySize === attributeDisplaySize
      );
    },
    summarizeAttribute(attribute, assets) {
      if (attribute.id === fixedAttributes.co2EmissionsId) {
        return this.summedEmissions;
      }

      if (attribute.id === fixedFieldAttributes.co2EmissionFactorId) {
        return this.summedEmissionsFactor;
      }

      if (attribute.id === fixedAttributes.co2EmissionsId) {
        return this.summedScope3Emissions;
      }

      if (attribute.id === fixedFieldAttributes.fixedAssetsDepreciationId) {
        return this.summedDepreciation;
      }

      if (attribute.format === "Currency") {
        const decimals = attribute.visibleDecimals ?? 2;
        const scale = Math.pow(10, decimals);

        const sum = assets
          .map((x) => x[attribute.id]?.decimalValue)
          .filter((x) => !!x)
          .reduce((total, current) => total + current * scale, 0);

        return (
          this.defaultCurrencyCode + " " + currencyFormatter.format(sum / scale)
        );
      }
    },
  },
  computed: {
    itemsToRender() {
      if (this.loadingSkeletonOn) {
        return Array(this.items.length).fill({});
      }
      return this.items;
    },
    mappedAttributes() {
      let mappedAttributes = [];
      this.attributes.forEach((attribute) => {
        mappedAttributes[attribute.id] = attribute;
      });
      return mappedAttributes;
    },
    dynamicHeaderIds() {
      return this.headers.filter((x) => x.dynamic).map((x) => x.value);
    },
    columnIdentifiers() {
      let identifiers = [];

      if (this.columnFilterProperties) {
        identifiers = identifiers.concat(this.columnFilterProperties);
      }

      if (this.dynamicHeaderIds) {
        identifiers = identifiers.concat(this.dynamicHeaderIds);
      }

      return identifiers;
    },
    groupsByKeys() {
      const result = [];
      let lastGroupKey = "";
      const groupKeys = ["group-1", "group-2"];

      for (const item of this.items) {
        const key =
          item[fixedAttributes.infoInvoiceId]?.assetAttributeFileLinks[0]
            ?.fileId;

        // if key is not same as last key
        if (key !== lastGroupKey) {
          // update last group key
          lastGroupKey = key;
          // flip group key array
          groupKeys.reverse();
        }

        // add group to key
        result[item.id] = groupKeys[0];
      }

      return result;
    },
  },
};
</script>

<style lang="scss" scoped>
.badge {
  padding: 6px 12px;
  font-size: 14px;
  font-weight: normal;
  border-radius: 4px;
  &.category-car {
    background-color: #e9f0ef;
    color: #266663;
  }

  &.category-minor-expense {
    background-color: #f6f0eb;
    color: #9b8675;
  }
  &.category-services {
    background-color: #fdece7;
    color: #f25d3b;
  }
}
</style>
