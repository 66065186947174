<template>
  <dialog-form
    :maxWidth="selectedImportStep === 3 ? '100%' : '929px'"
    :show-dialog="show"
    :persistent="true"
    @clickOutside="hideModal()"
  >
    <asset-import-step-one
      v-if="selectedImportStep === 1"
      :selectedImportFiles="selectedImportFiles"
      @errorChanged="handleStepOneError"
      @invalidFormatChanged="(value) => (invalidImportFileFormat = value)"
      @filesChanged="handleImportFilesChanged"
    />

    <asset-import-step-two
      v-if="selectedImportStep === 2"
      :selectedImportFiles="selectedImportFiles"
      :portfolios="portfolios"
      :selectedPortfolioId="form.portfolioId"
      @portfolioIdChanged="handlePortfolioIdChanged"
      :newPortfolioName="form.portfolioName"
      @newPortfolioNameChanged="handleNewPortfolioNameChanged"
    />
    <asset-import-step-three
      v-if="selectedImportStep === 3"
      :attributes="attributes"
      :headerMappings="headerMappings"
      :assetRows="form.assetRows"
      :portfolioSubcategories="portfolioSubcategories"
      :loading="tableIsLoading"
      :isEditing="tableIsEditing"
      :hasErrors="tableHasErrors"
      :updateOnId="form.updateOnId"
      :importStatusCode="importStatusCode"
      @assetAttributeLinkUpdated="handleAssetAttributeLinkUpdated"
      @assetRemoved="handleAssetRemoved"
      @assetsRemoved="handleAssetsRemoved"
      @headerMappingChanged="handleHeaderMappingChanged"
      @isEditingUpdated="(isEditing) => (tableIsEditing = isEditing)"
      @updateOnIdChanged="(value) => (form.updateOnId = value)"
    />
    <!--<asset-import-step-four
      v-if="
        selectedImportStep === 4 &&
        selectedImportFile?.type !== 'application/pdf'
      "
      :importCount="importSuccess.importCount"
      :updateCount="importSuccess.updateCount"
      :portfolioName="importSuccess.portfolioName"
      :saveImportedFile="saveImportedFile"
      :importedFileName="importedFileName"
      @saveImportedFileChanged="(value) => (saveImportedFile = value)"
      @importedFileNameChanged="(value) => (importedFileName = value)"
    />
    <asset-invoice-import-step-four
      v-if="
        selectedImportStep === 4 &&
        selectedImportFile?.type === 'application/pdf'
      "
      :importCount="importSuccess.importCount"
      :portfolioName="importSuccess.portfolioName"
    />-->
    <v-card-actions class="py-6 px-6 d-flex flex-column-reverse flex-md-row">
      <v-btn
        class="text-none px-4 mb-4 mb-md-0 align-self-stretch"
        color="#686868"
        large
        elevation="0"
        text
        tile
        x-large
        @click="hideModal"
        :loading="closeButtonLoading"
        ><v-icon class="mr-3" dark>mdi-close</v-icon
        >{{ $t("common.actions.close") }}
      </v-btn>
      <v-btn
        class="text-none px-4 mb-4 mb-md-0 align-self-stretch"
        color="#686868"
        large
        elevation="0"
        text
        tile
        x-large
        :loading="closeButtonLoading"
        v-if="selectedImportStep > 1 && selectedImportStep < 4"
        @click="moveToPreviousStep"
        ><v-icon class="mr-3" dark>mdi-chevron-left</v-icon
        >{{ $t("pages.assets.import.back") }}
      </v-btn>

      <v-spacer></v-spacer>
      <v-btn
        class="text-none ml-0 mb-4 mb-md-0 ml-md-4 px-6 align-self-stretch"
        large
        color="#266663"
        dark
        elevation="0"
        tile
        x-large
        @click="moveToNextStep()"
        :disabled="isNextButtonDisabled"
        :loading="nextButtonLoading"
      >
        <v-icon class="white--text">mdi-chevron-right</v-icon>
        {{
          selectedImportStep === 3
            ? $t("pages.assets.import.import")
            : $t("pages.assets.import.next")
        }}
      </v-btn>

      <!--<v-btn
        color="blue darken-1"
        text
        @click="hideModal"
        :loading="closeButtonLoading"
      >
        <v-icon class="black--text">mdi-close</v-icon>
        <p class="teal--text text--darken-4 mb-0">
          {{ $t("common.actions.close") }}
        </p>
      </v-btn>
      <v-btn
        v-if="selectedImportStep > 1 && selectedImportStep < 4"
        color="blue darken-1"
        text
        @click="moveToPreviousStep"
      >
        <v-icon class="black--text">mdi-chevron-left</v-icon>
        <p class="teal--text text--darken-4 mb-0">
          {{ $t("pages.assets.import.back") }}
        </p>
      </v-btn>
      <v-btn
        v-if="selectedImportStep < 4"
        color="blue darken-1"
        text
        @click="moveToNextStep()"
        :disabled="isNextButtonDisabled"
        :loading="nextButtonLoading"
      >
        <v-icon class="black--text">mdi-chevron-right</v-icon>
        <p
          :class="isNextButtonDisabled ? '' : 'teal--text text--darken-4'"
          class="mb-0"
        >
          {{ $t("pages.assets.import.next") }}
        </p>
      </v-btn>-->
    </v-card-actions>
    <v-alert
      dense
      v-if="errorMessage && errorMessageStep === selectedImportStep"
      type="error"
      class="mb-0"
    >
      {{ errorMessage }}
    </v-alert>
  </dialog-form>
  <!--<template #title>
      <i18n path="pages.assets.import.importStep" tag="span">
        <span
          v-if="selectedImportStep === 3 && tableHasErrors"
          style="margin-left: 4px"
          class="red--text"
        >
          {{ $t("pages.assets.import.invalidData") }}
        </span>
        <span
          v-else
          style="margin-left: 4px"
          :class="
            currentStep && currentStep.color ? currentStep.color + '--text' : ''
          "
        >
          {{
            currentStep
              ? " " + currentStep.title
              : $t("pages.assets.import.stepTitles.unknown")
          }}
        </span>
      </i18n>
    </template>
    <template #body>
      <asset-import-step-one
        v-if="selectedImportStep === 1"
        :selectedImportFile="selectedImportFile"
        @errorChanged="handleStepOneError"
        @invalidFormatChanged="(value) => (invalidImportFileFormat = value)"
        @fileChanged="handleImportFileChanged"
      />
      <asset-import-step-two
        v-if="selectedImportStep === 2"
        :selectedImportFile="selectedImportFile"
        :portfolios="portfolios"
        :selectedPortfolioId="form.portfolioId"
        @portfolioIdChanged="handlePortfolioIdChanged"
        :newPortfolioName="form.portfolioName"
        @newPortfolioNameChanged="handleNewPortfolioNameChanged"
      />
      <asset-import-step-three
        v-if="selectedImportStep === 3"
        :attributes="attributes"
        :headerMappings="headerMappings"
        :assetRows="form.assetRows"
        :portfolioSubcategories="portfolioSubcategories"
        :loading="tableIsLoading"
        :isEditing="tableIsEditing"
        :hasErrors="tableHasErrors"
        :updateOnId="form.updateOnId"
        @assetAttributeLinkUpdated="handleAssetAttributeLinkUpdated"
        @assetRemoved="handleAssetRemoved"
        @assetsRemoved="handleAssetsRemoved"
        @headerMappingChanged="handleHeaderMappingChanged"
        @isEditingUpdated="(isEditing) => (tableIsEditing = isEditing)"
        @updateOnIdChanged="(value) => (form.updateOnId = value)"
      />
      <asset-import-step-four
        v-if="selectedImportStep === 4"
        :importCount="importSuccess.importCount"
        :updateCount="importSuccess.updateCount"
        :portfolioName="importSuccess.portfolioName"
        :saveImportedFile="saveImportedFile"
        :importedFileName="importedFileName"
        @saveImportedFileChanged="(value) => (saveImportedFile = value)"
        @importedFileNameChanged="(value) => (importedFileName = value)"
      />
    </template>
    <template #actions>
      <v-alert
        dense
        v-if="errorMessage && errorMessageStep === selectedImportStep"
        type="error"
        class="mb-0"
      >
        {{ errorMessage }}
      </v-alert>
      <v-btn
        color="blue darken-1"
        text
        @click="hideModal"
        :loading="closeButtonLoading"
      >
        <v-icon class="black--text">mdi-close</v-icon>
        <p class="teal--text text--darken-4 mb-0">
          {{ $t("common.actions.close") }}
        </p>
      </v-btn>
      <v-btn
        v-if="selectedImportStep > 1 && selectedImportStep < 4"
        color="blue darken-1"
        text
        @click="moveToPreviousStep"
      >
        <v-icon class="black--text">mdi-chevron-left</v-icon>
        <p class="teal--text text--darken-4 mb-0">
          {{ $t("pages.assets.import.back") }}
        </p>
      </v-btn>
      <v-btn
        v-if="selectedImportStep < 4"
        color="blue darken-1"
        text
        @click="moveToNextStep()"
        :disabled="isNextButtonDisabled"
        :loading="nextButtonLoading"
      >
        <v-icon class="black--text">mdi-chevron-right</v-icon>
        <p
          :class="isNextButtonDisabled ? '' : 'teal--text text--darken-4'"
          class="mb-0"
        >
          {{ $t("pages.assets.import.next") }}
        </p>
      </v-btn>
    </template>
  </page-modal>-->
</template>
<script>
import { serialize } from "object-to-formdata";

import AssetImportStepOne from "./ImportSteps/AssetImportStepOne.vue";
import AssetImportStepTwo from "./ImportSteps/AssetImportStepTwo.vue";
import AssetImportStepThree from "./ImportSteps/AssetImportStepThree.vue";
import DialogForm from "../Dialog/DialogForm.vue";

export default {
  components: {
    AssetImportStepOne,
    AssetImportStepTwo,
    AssetImportStepThree,
    DialogForm,
  },
  props: {
    value: Boolean,
    filesFromParent: File,
    importStepFromParent: Number,
  },
  computed: {
    show: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit("input", value);
      },
    },
    currentStep() {
      return this.importSteps.find((x) => x.id === this.selectedImportStep);
    },
    isNextButtonDisabled() {
      switch (this.selectedImportStep) {
        case 1:
          return this.invalidImportFileFormat || !this.selectedImportFiles;
        case 2:
          return !this.form.portfolioId && !this.form.portfolioName;
        case 3:
          return (
            this.tableIsEditing ||
            Object.values(this.headerMappings).filter(
              (x) => x.type === "attribute" && x.attribute !== null
            ).length <= 0 ||
            !this.form.assetRows.length
          );
        default:
          return true;
      }
    },
    importSteps() {
      return [
        { id: 1, title: this.$t("pages.assets.import.stepTitles.method") },
        { id: 2, title: this.$t("pages.assets.import.stepTitles.portfolio") },
        { id: 3, title: this.$t("pages.assets.import.stepTitles.validate") },
        {
          id: 4,
          title: this.$t("pages.assets.import.stepTitles.success"),
          color: "light-green",
        },
      ];
    },
    tableIsLoading() {
      return this.tableIsLoadingCount > 0;
    },
  },
  data() {
    return {
      selectedImportStep: 1,
      //selectedImportFile: null, // Not used anymore ?
      selectedImportFiles: [],
      errorMessage: null,
      errorMessageStep: null,
      invalidImportFileFormat: false,
      portfolios: [],
      portfolioSubcategories: [],
      attributes: [],
      headerMappings: {},
      form: {
        portfolioId: null,
        portfolioName: null,
        headerToAttributeIdMappings: {},
        headerToFieldNameMappings: {},
        assetRows: [],
        updateOnId: true,
      },
      tableIsLoadingCount: 0,
      tableIsEditing: false,
      tableHasErrors: false,
      nextButtonLoading: false,
      closeButtonLoading: false,
      importSuccess: {
        importCount: 0,
        updateCount: 0,
        portfolioName: null,
      },
      saveImportedFile: true,
      importedFileName: null,
      importStatusCode: null,
    };
  },
  methods: {
    fetchParsedAssetsFromImportFilePDF() {
      if (
        this.selectedImportFiles.length <= 0 ||
        this.form.assetRows?.length > 0
      )
        return;

      const formData = new FormData();

      const files = this.selectedImportFiles.filter(
        (item) => item.type === "application/pdf"
      );
      files.forEach((file) => {
        formData.append("invoiceFiles", file);
      });
      formData.append("portfolioId", this.form.portfolioId);

      this.tableIsLoadingCount++;

      fetch(this.route("api.assets.invoices.parse"), {
        method: "POST",
        body: formData,
      })
        .then((res) => res.json())
        .then((data) => {
          this.attributes = data.attributes;
          this.headerMappings = data.headerMappings;
          this.portfolioSubcategories = data.subcategories;

          const headers = Object.keys(this.headerMappings);

          const encounteredAttributes = [];
          const encounteredFields = [];

          headers.forEach((header) => {
            const mapping = this.headerMappings[header];

            if (mapping.type === "attribute") {
              let attribute = mapping.attribute;

              if (encounteredAttributes.includes(attribute)) {
                this.headerMappings[header] = {
                  id: null,
                  name: this.$t("pages.assets.import.skip"),
                };
                attribute = null;
              }

              this.form.headerToAttributeIdMappings[header] =
                attribute?.id ?? null;

              if (attribute) {
                encounteredAttributes.push(attribute);
              }
            } else if (mapping.type === "field") {
              let field = mapping.fieldName;

              if (encounteredFields.includes(field)) {
                this.headerMappings[header] = {
                  type: "attribute",
                  attribute: {
                    id: null,
                    name: this.$t("pages.assets.import.skip"),
                  },
                };
                field = null;
              }

              this.form.headerToFieldNameMappings[header] = field ?? null;

              if (field) {
                encounteredFields.push(field);
              }
            }
          });

          this.form.assetRows = this.form.assetRows.concat(data.parsedAssets);
        })
        .finally(() => this.tableIsLoadingCount--);
    },
    moveToPreviousStep() {
      const newStep = this.selectedImportStep - 1;

      if (newStep < 1) {
        this.selectedImportStep = 1;
        return;
      }

      this.selectedImportStep = newStep;
    },
    async moveToNextStep(step3Validated) {
      if (this.selectedImportStep === 3) {
        if (!step3Validated) await this.submitImportData();

        if (
          !this.tableHasErrors &&
          this.importStatusCode >= 200 &&
          this.importStatusCode <= 299
        ) {
          this.hideModal(true);
        }

        return;
      }
      if (this.selectedImportFiles.length > 0) {
        this.selectedImportStep++;
      }

      this.runPageChangeSideEffects();
    },
    runPageChangeSideEffects() {
      if (this.selectedImportStep === 3) {
        // Check if we have PDF files, and run thoose
        if (
          this.selectedImportFiles.filter(
            (item) => item.type === "application/pdf"
          ).length > 0
        ) {
          this.fetchParsedAssetsFromImportFilePDF();
        }

        // Check if we have another files then PDF's and run thoose
        if (
          this.selectedImportFiles.filter(
            (item) => item.type !== "application/pdf"
          ).length > 0
        ) {
          this.fetchParsedAssetsFromImportFile();
        }
      }
    },
    fetchPortfolios() {
      fetch(this.route("api.portfolios.index"), {
        method: "GET",
      })
        .then((res) => res.json())
        .then((data) => {
          this.portfolios = data;

          if (this.portfolios.length > 0) {
            const firstPortfolio = this.portfolios[0];

            this.form.portfolioId = firstPortfolio.id;
          } else {
            this.form.portfolioId = null;
          }
        })
        .catch(() => {
          this.portfolios = [];
        });
    },
    fetchParsedAssetsFromImportFile() {
      if (
        this.selectedImportFiles.length <= 0 ||
        this.form.assetRows?.length > 0
      )
        return;

      const formData = new FormData();

      const files = this.selectedImportFiles.filter(
        (item) => item.type !== "application/pdf"
      );
      files.forEach((file) => {
        formData.append("assetImportSpreadsheet", file);
      });
      formData.append("portfolioId", this.form.portfolioId);

      this.tableIsLoadingCount++;

      fetch(this.route("api.assets.parse"), {
        method: "POST",
        body: formData,
      })
        .then((res) => res.json())
        .then((data) => {
          this.attributes = data.attributes;
          this.headerMappings = data.headerMappings;
          this.portfolioSubcategories = data.subcategories;

          const headers = Object.keys(this.headerMappings);

          const encounteredAttributes = [];
          const encounteredFields = [];

          headers.forEach((header) => {
            const mapping = this.headerMappings[header];

            if (mapping.type === "attribute") {
              let attribute = mapping.attribute;

              if (encounteredAttributes.includes(attribute)) {
                this.headerMappings[header] = {
                  id: null,
                  name: this.$t("pages.assets.import.skip"),
                };
                attribute = null;
              }

              this.form.headerToAttributeIdMappings[header] =
                attribute?.id ?? null;

              if (attribute) {
                encounteredAttributes.push(attribute);
              }
            } else if (mapping.type === "field") {
              let field = mapping.fieldName;

              if (encounteredFields.includes(field)) {
                this.headerMappings[header] = {
                  type: "attribute",
                  attribute: {
                    id: null,
                    name: this.$t("pages.assets.import.skip"),
                  },
                };
                field = null;
              }

              this.form.headerToFieldNameMappings[header] = field ?? null;

              if (field) {
                encounteredFields.push(field);
              }
            }
          });

          this.form.assetRows = this.form.assetRows.concat(data.parsedAssets);
        })
        .finally(() => this.tableIsLoadingCount--);
    },
    async submitImportData() {
      this.nextButtonLoading = true;

      const omittedMappings = [];

      const attributeMappings = { ...this.form.headerToAttributeIdMappings };

      for (let mapping of Object.keys(attributeMappings)) {
        if (!attributeMappings[mapping]) {
          delete attributeMappings[mapping];
          omittedMappings.push(mapping);
        }
      }

      const fieldMappings = { ...this.form.headerToFieldNameMappings };

      for (let mapping of Object.keys(fieldMappings)) {
        if (!fieldMappings[mapping]) {
          delete fieldMappings[mapping];
          omittedMappings.push(mapping);
        }
      }

      let assetRows = [...this.form.assetRows.map((x) => x.row)];

      for (let mapping of omittedMappings) {
        assetRows.forEach((row) => {
          delete row[mapping];
        });
      }

      const bodyContent = {
        ...this.form,
        assetRowsJson: JSON.stringify(assetRows),
        headerToAttributeIdMappingsJson: JSON.stringify(attributeMappings),
        headerToFieldNameMappingsJson: JSON.stringify(fieldMappings),
      };

      delete bodyContent.assetRows;
      delete bodyContent.headerToAttributeIdMappings;
      delete bodyContent.headerToFieldNameMappings;

      this.errorMessageStep = null;
      this.tableHasErrors = false;

      return fetch(this.route("api.assets.import"), {
        method: "POST",
        body: serialize(bodyContent, {
          indices: true,
          dotsForObjectNotation: true,
        }),
      })
        .then((res) => {
          const statusCode = this.getStatusCode(res);

          this.importStatusCode = statusCode;

          if (statusCode >= 200 && statusCode <= 299) {
            res.json().then((data) => {
              this.handleSuccessfulImport(data);
            });

            return;
          }

          if (statusCode !== 422) return;

          return res.json();
        })
        .then((data) => {
          if (!data || data.status !== "error") return;

          this.errorMessage = this.$t(
            `pages.assets.import.resultDescriptions.${data.description}`
          );
          this.errorMessageStep = 3;
          this.form.assetRows = data.assetRows;
          this.tableHasErrors = true;
        })
        .finally(() => {
          this.nextButtonLoading = false;
        });
    },
    async submitImportedFile() {
      this.closeButtonLoading = true;

      const blob = this.selectedImportFile.slice(
        0,
        this.selectedImportFile.size,
        this.selectedImportFile.type
      );
      const renamedFile = new File([blob], this.importedFileName, {
        type: this.selectedImportFile.type,
      });

      const formData = new FormData();
      formData.append("files", renamedFile);

      return fetch(this.route("api.files.store"), {
        method: "POST",
        body: formData,
      }).finally(() => {
        this.closeButtonLoading = false;
      });
    },
    resetModalState() {
      this.selectedImportStep = 1;
      this.selectedImportFile = null;
      this.selectedImportFiles = [];
      this.errorMessage = null;
      this.attributes = [];
      this.headerMappings = {};
      this.form = {
        portfolioId: this.portfolios.length > 0 ? this.portfolios[0].id : null,
        portfolioName: null,
        headerToAttributeIdMappings: {},
        headerToFieldNameMappings: {},
        assetRows: [],
      };
      this.tableIsEditing = false;
      this.tableHasErrors = false;
      this.nextButtonLoading = false;
      this.importStatusCode = null;
    },
    handleStepOneError(error) {
      this.errorMessage = error;
      this.errorMessageStep = 1;
    },
    handleImportFilesChanged(value) {
      this.selectedImportFiles = value;

      this.form.assetRows = [];
      this.form.headerToAttributeIdMappings = {};
      this.form.headerToFieldNameMappings = {};
      this.headerMappings = {};

      if (value) {
        this.importedFileName = value.name;
      }
    },
    handlePortfolioIdChanged(portfolioId) {
      this.form.portfolioId = portfolioId;
      this.form.portfolioName = null;
      this.form.assetRows = [];
    },
    handleNewPortfolioNameChanged(portfolioName) {
      this.form.portfolioName = portfolioName;
    },
    handleAssetRemoved(assetRow) {
      this.form.assetRows = this.form.assetRows.filter((x) => x !== assetRow);
    },
    handleAssetsRemoved(assetRows) {
      this.form.assetRows = this.form.assetRows.filter(
        (x) => !assetRows.includes(x)
      );
    },
    handleHeaderMappingChanged(header, attribute) {
      let mapping = this.headerMappings[header];

      if (!mapping) {
        mapping = {
          type: "attribute",
          attribute: null,
        };
      }

      this.headerMappings[header] = {
        ...mapping,
        attribute: attribute,
      };

      const attributeId = attribute?.id;

      this.form.headerToAttributeIdMappings[header] = attributeId;
    },
    handleAssetAttributeLinkUpdated(assetRow, header, value) {
      const index = this.form.assetRows.indexOf(assetRow);

      if (index === -1) return;

      this.form.assetRows[index].row[header] = value;

      if (this.form.assetRows[index].columnErrorMessages) {
        delete this.form.assetRows[index].columnErrorMessages[header];
      }
    },
    handleSuccessfulImport(data) {
      this.tableHasErrors = false;
      this.errorMessage = false;

      this.importSuccess.importCount = data.importedAssetCount;
      this.importSuccess.updateCount = data.updatedAssetCount;
      this.importSuccess.portfolioName = data.importedToPortfolioName;

      this.moveToNextStep(true);
    },
    async hideModal(skipConfirmation = false) {
      if (
        !skipConfirmation &&
        !window.confirm(this.$t("pages.assets.import.confirmClose"))
      ) {
        this.$emit("reloadPage");
        return;
      }

      // TODO: change save file functionality to run on step 3 (with dialog?)
      if (this.selectedImportStep === 4 && this.saveImportedFile) {
        await this.submitImportedFile();
      } else if (this.selectedImportStep === 3) {
        this.$emit("reloadPage");
      }

      this.show = false;
      this.resetModalState();
    },
    getStatusCode(res) {
      if (res.url.includes("/errors/")) {
        const index = res.url.lastIndexOf("/") + 1;

        const statusCodeString = res.url.substring(index);

        return parseInt(statusCodeString);
      }

      return res.status;
    },
  },
  mounted() {},
  watch: {
    filesFromParent(to) {
      this.handleImportFilesChanged(to);
      this.selectedImportStep = 2;
    },
    show(to) {
      if (to) {
        this.fetchPortfolios();
      }
    },
  },
};
</script>
<style scoped>
.import-card {
  position: relative;
}

.import-card .step-label {
  position: absolute;
  top: 8px;
  left: 0;
  right: 0;
  text-align: center;
  font-weight: bolder;
}
</style>
