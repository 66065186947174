<template>
  <div class="page-container fill-height grow d-flex flex-column flex-nowrap">
    <v-card
      elevation="0"
      min-height="17.625rem"
      class="d-flex flex-column ma-4"
    >
      <v-card-text class="pa-0 flex-grow-1">
        <v-tabs
          v-model="tab"
          class="mb-4 pl-sm-4"
          style="border-bottom: 1px solid #e8e8e8"
          show-arrows
        >
          <v-tab
            v-for="contentTab in tabs"
            :key="contentTab.id"
            :href="`#${getTabHref(contentTab.id)}`"
            @change="setTabInUrl(contentTab.id)"
          >
            {{ contentTab.name }}
          </v-tab>
        </v-tabs>
        <v-tabs-items v-model="tab" class="fill-height fill-width px-0 pb-4">
          <tab-general
            v-bind="generalBindings"
            @updateForm="updateForm"
            ref="generalTab"
            class="px-4"
          />
          <tab-system
            v-bind="systemBindings"
            @updateForm="updateForm"
            ref="systemTab"
            class="px-4"
          />
          <!--<tab-documents
            v-bind="documentsBindings"
            @updateForm="updateForm"
            @removeOrganizationalFile="removeOrganizationalFile"
          />-->
          <tab-fixed-assets
            v-if="moduleFixedAssetsEnabled"
            v-bind="fixedAssetsBindings"
            @updateForm="updateForm"
            class="px-4"
          />
          <tab-integrations
            v-bind="integrationsBindings"
            @setTab="setTabInUrl"
          />
          <tab-rules v-bind="rulesBindings" @setTab="setTabInUrl" />
          <tab-billing
            v-bind="billingBindings"
            @setTab="setTabInUrl"
          ></tab-billing>
          <tab-custom-emission-factors
            v-bind="customEmissionFactorsBindings"
            ref="customEmissionFactorsTab"
            @setTab="setTabInUrl"
            :currencies="currencies"
            :attributes="attributes"
            :defaultRegionId="settings.defaultRegionId"
          ></tab-custom-emission-factors>
        </v-tabs-items>
      </v-card-text>
      <v-card-actions v-if="!isCardActionsHidden">
        <v-spacer></v-spacer>
        <v-btn
          class="text-none ml-0 mb-4 mb-md-0 ml-md-4 px-6 align-self-stretch"
          large
          color="#F25D3B"
          dark
          elevation="0"
          tile
          x-large
          :loading="form.processing"
          @click="submit"
        >
          {{ $t("common.actions.saveChanges") }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </div>
</template>
<script>
import qs from "qs";
import { appLayout } from "@/util/layout";
import { serialize } from "object-to-formdata";
import TabGeneral from "./Tabs/TabGeneral.vue";
//import TabDocuments from "./Tabs/TabDocuments.vue";
import TabFixedAssets from "./Tabs/TabFixedAssets.vue";
import TabIntegrations from "./Tabs/TabIntegrations.vue";
import TabRules from "./Tabs/TabRules.vue";
import TabBilling from "./Tabs/TabBilling.vue";
import TabSystem from "./Tabs/TabSystem.vue";
import TabCustomEmissionFactors from "./Tabs/TabCustomEmissionFactors.vue";
import { currencyFormatter } from "../../util/formatters";

export default {
  layout: appLayout({ title: "pages.settings.index.title" }),
  components: {
    TabGeneral,
    //TabDocuments,
    TabFixedAssets,
    TabIntegrations,
    TabRules,
    TabBilling,
    TabSystem,
    TabCustomEmissionFactors,
  },
  props: {
    settings: Object,
    currencies: Array,
    organization: Object,
    fiscalYears: Array,
    assetRules: Object,
    assetRuleTriggerProperties: Array,
    assetRuleEntryTriggerProperties: Array,
    integrations: Object,
    integrationSyncPeriods: Array,
    installedIntegration: Object,
    integrationError: String,
    financialAccounts: Array,
    customEmissionFactors: Object,
    attributes: Array,
    companies: Array,
    showCompanies: Boolean,
    dynamicsInitializedIntegrationId: String,
    payment: Object,
    billingSubscription: Object,
    billingPaymentMethods: Array,
    billingCustomers: Array,
    billingTransactions: Array,
    billingLimits: Object,
    publishableKey: String,
    paymentReturnUrl: String,
    paymentTierIds: Object,
    isFreePlan: Boolean,
  },
  data() {
    return {
      form: this.$inertia.form(this.generateInitialForm()),
      tab: window.location.hash?.substring(1) ?? null,
      emissionRegions: [],
    };
  },
  mounted() {
    this.getEmissionRegions();
  },
  computed: {
    generalBindings() {
      return {
        tabHref: this.getTabHref(1),
        /*currencies: this.currencies,
        defaultCurrencyId: this.form.defaultCurrencyId,*/
        organization: this.organization,
      };
    },
    systemBindings() {
      return {
        tabHref: this.getTabHref(2),
        currencies: this.currencies,
        defaultCurrencyId: this.form.defaultCurrencyId,
        selectedDateFormat: "YYYY-MM-DD",
        dateFormats: ["YYYY-MM-DD", "DD-MM-YYYY"],
        emissionRegions: this.emissionRegions,
        defaultRegionId: this.form.defaultRegionId,
      };
    },
    /*documentsBindings() {
      return {
        tabHref: this.getTabHref(3),
        organizationalFiles: this.settings.organizationalFiles,
        organizationalFileIds: this.form.organizationalFileIds,
        newOrganizationalFiles: this.form.newOrganizationalFiles,
      };
    },*/
    fixedAssetsBindings() {
      return {
        tabHref: this.getTabHref(4),
        fiscalYear: this.form.fiscalYear,
        fiscalYears: this.fiscalYears,
      };
    },
    /*organizationBindings() {
      return {
        organization: this.organization,
        tabHref: this.getTabHref(4),
      };
    },*/
    integrationsBindings() {
      return {
        tabHref: this.getTabHref(5),
        tabId: 5,
        organization: this.organization,
        integrations: this.integrations,
        integrationSyncPeriods: this.integrationSyncPeriods,
        installedIntegration: this.installedIntegration,
        integrationError: this.integrationError,
        financialAccounts: this.financialAccounts,
        companies: this.companies,
        showCompanies: this.showCompanies,
        dynamicsInitializedIntegrationId: this.dynamicsInitializedIntegrationId,
        isFreePlan: this.isFreePlan,
        billingSubscription: this.billingSubscription,
        publishableKey: this.publishableKey,
        paymentReturnUrl: this.paymentReturnUrl,
        paymentTierIds: this.paymentTierIds,
      };
    },
    rulesBindings() {
      return {
        tabHref: this.getTabHref(6),
        tabId: 6,
        assetRules: this.assetRules,
        assetRuleTriggerProperties: this.assetRuleTriggerProperties,
        assetRuleEntryTriggerProperties: this.assetRuleEntryTriggerProperties,
        currencies: this.currencies,
      };
    },
    billingBindings() {
      return {
        tabHref: this.getTabHref(8),
        tabId: 8,
        payment: this.payment,
        billingSubscription: this.billingSubscription,
        billingPaymentMethods: this.billingPaymentMethods,
        billingCustomers: this.billingCustomers,
        billingTransactions: this.billingTransactions,
        billingLimits: this.billingLimits,
        publishableKey: this.publishableKey,
        paymentReturnUrl: this.paymentReturnUrl,
        paymentTierIds: this.paymentTierIds,
      };
    },
    customEmissionFactorsBindings() {
      var modifiedCustomEmissionFactors = {
        ...this.customEmissionFactors,
        data: this.customEmissionFactors.data.map((item) => {
          return {
            ...item,
            id: item.id, // change to verarca id
            sectorCategoryRegion:
              item.sector.name +
              "/" +
              item.category.name +
              "/" +
              item.region.name,
            emissionFactor:
              this.formatCurrency(
                item.kgCo2eAr5 !== 0 ? item.kgCo2eAr5 : item.kgCo2eAr4
              ) +
              "" +
              this.$t("common.units.kgCo2e") +
              "/" +
              item.activityUnit,
          };
        }),
      };

      return {
        tabHref: this.getTabHref(7),
        tabId: 7,
        customEmissionFactors: modifiedCustomEmissionFactors,
      };
    },
    isCardActionsHidden() {
      switch (this.tab) {
        case this.getTabHref(5):
        case this.getTabHref(6):
        case this.getTabHref(7):
          return true;
        default:
          return false;
      }
    },

    tabs() {
      let tabs = [
        {
          id: 1,
          name: this.$t("pages.settings.index.tabs.general"),
        },
        {
          id: 2,
          name: this.$t("pages.settings.index.tabs.system"),
        },
        /* {
          id: 3,
          name: this.$t("pages.settings.index.tabs.documents"),
        },
        {
          id: 4,
          name: this.$t("pages.settings.index.tabs.organization"),
        }, */
        {
          id: 5,
          name: this.$t("pages.settings.index.tabs.integrations"),
        },
        {
          id: 6,
          name: this.$t("pages.settings.index.tabs.rules"),
        },
        {
          id: 7,
          name: this.$t("pages.settings.index.tabs.customEmissionFactors"),
        },
        {
          id: 8,
          name: this.$t("pages.settings.index.tabs.billing"),
        },
      ];

      if (this.moduleFixedAssetsEnabled) {
        tabs.push({
          id: 4,
          name: this.$t("pages.settings.index.tabs.fixedAssets"),
        });
      }

      return tabs;
    },
    moduleFixedAssetsEnabled() {
      return this.$inertia.page.props.auth.settings.moduleFixedAssets;
    },
  },
  methods: {
    formatCurrency(value) {
      if (currencyFormatter.format(value) == "NaN") {
        return "0,00";
      }
      return currencyFormatter.format(value);
    },
    submit() {
      if (this.tab === "general") {
        this.$refs.generalTab.submit();
      } else if (this.tab === "integrations") {
        this.$refs.integrationsTab.submit();
      } else {
        this.form
          .transform((data) =>
            serialize(data, { noFilesWithArrayNotation: true })
          )
          .put(this.route("settings.update"), {
            onSuccess: () => {
              this.form = this.$inertia.form(this.generateInitialForm());

              if (this.tab) window.location.hash = `#${this.tab}`;
            },
          });
      }
    },
    reloadSettings(updatedSettings) {
      this.form.defaultCurrencyId = updatedSettings.defaultCurrencyId;
      this.form.defaultRegionId = updatedSettings.defaultRegionId;
    },
    updateForm(key, value) {
      this.form[key] = value;
    },
    generateInitialForm() {
      return {
        defaultCurrencyId: this.settings.defaultCurrencyId,
        fiscalYear: this.settings.fiscalYear,
        organizationalFileIds:
          this.settings.organizationalFiles?.map((x) => x.id) ?? [],
        newOrganizationalFiles: null,
        defaultRegionId: this.settings.defaultRegionId,
      };
    },
    getTabHref(tabId) {
      switch (tabId) {
        case 1:
          return "general";
        case 2:
          return "system";
        /*case 3:
          return "documents";*/
        case 4:
          return "fixed-assets";
        case 5:
          return "integrations";
        case 6:
          return "rules";
        case 7:
          return "custom-emission-factors";
        case 8:
          return "billing";
      }
    },
    setTabInUrl(tabId) {
      const tabHref = this.getTabHref(tabId);

      if (!tabHref) return;

      const existingQuery = qs.parse(window.location.search.substring(1));

      const newUrl =
        this.route("settings.index", existingQuery) + `#${tabHref}`;

      window.history.replaceState(null, "", newUrl);
    },
    removeOrganizationalFile(fileId) {
      this.form.organizationalFileIds = this.form.organizationalFileIds.filter(
        (x) => x !== fileId
      );
    },
    getEmissionRegions() {
      fetch(this.route("api.emission-regions.index"))
        .then((res) => res.json())
        .then((data) => {
          this.emissionRegions = data.map((x) => ({
            ...x,
            value: x.countryName ? x.countryName : x.name,
          }));
        });
    },
  },
  watch: {
    settings(value) {
      this.reloadSettings(value);
    },
  },
};
</script>
