<template>
  <v-tab-item :value="tabValue">
    <v-card flat>
      <dialog-form-section-two-cols no-bottom-border>
        <template slot="left">
          <div>
            <asset-attribute-link-input
              :attribute="nameAttribute"
              :value="nameData"
              @input="updateData(nameAttribute.id, $event)"
              @string_changed="handleNameChanged($event)"
            />
            <asset-attribute-link-input
              :attribute="purchasePriceAttribute"
              :value="purchasePriceData"
              :currencies="currencies"
              @input="
                updateData(fixedAttributes.generalPurchasePriceId, $event)
              "
              :secondValue="purchasePriceSecondValueData"
              @input_secondValue="
                updateSecondValueData(
                  fixedAttributes.generalPurchasePriceId,
                  arguments[0]
                )
              "
              @currency_changed="
                updateCurrencyValue(
                  purchasePriceAttribute,
                  $event,
                  purchasePriceSecondValueData
                )
              "
              @currencyCode_changed="
                updateCurrencyCodeValue(
                  purchasePriceAttribute,
                  purchasePriceData,
                  $event
                )
              "
            />
            <v-row dense>
              <v-col cols="6">
                <asset-attribute-link-input
                  :attribute="unitTypeAttribute"
                  :value="unitTypeData"
                  @input="updateData(unitTypeAttribute.id, $event)"
                  @select_changed="updateSelectValue(unitTypeAttribute, $event)"
                />
              </v-col>
              <v-col cols="6">
                <asset-attribute-link-input
                  :attribute="quantityAttribute"
                  :value="quantityData"
                  :number-min-value="1"
                  @input="updateData(quantityAttribute.id, $event)"
                  @decimal_changed="
                    updateDecimalValue(quantityAttribute, $event)
                  "
                />
              </v-col>
            </v-row>
            <asset-attribute-link-input
              :attribute="modelAttribute"
              :value="modelData"
              @input="updateData(modelAttribute.id, $event)"
              @string_changed="handleModelChanged($event)"
            />
            <asset-attribute-link-input
              :attribute="purchaseDateAttribute"
              :value="purchaseDateData"
              @input="updateData(fixedAttributes.generalPurchaseDateId, $event)"
              @dateTime_changed="updateDateValue(purchaseDateAttribute, $event)"
            />
            <v-row dense>
              <v-col cols="4" align-self="center">
                {{ $t("pages.assets.form.fixedLabels.purchasedAs") }}:
              </v-col>
              <v-col cols="8" align-self="center">
                <v-select
                  v-model="selectedPurchasedAs"
                  :items="purchasedAsOptions"
                  item-text="text"
                  item-value="id"
                  solo
                  hide-details
                  class="rounded-0 mb-2"
                  background-color="#F7F7F7"
                  item-color="#F25D3B"
                  color="#F25D3B"
                  flat
                  single-line
                  filled
                >
                </v-select>
              </v-col>
            </v-row>
            <asset-attribute-link-input
              v-if="selectedPurchasedAs === 2"
              :attribute="productionDateAttribute"
              :value="productionDateData"
              @input="
                updateData(fixedAttributes.generalProductionDateId, $event)
              "
              @dateTime_changed="
                updateDateValue(productionDateAttribute, $event)
              "
            />

            <v-row dense>
              <v-col cols="4" align-self="center">
                {{ $t("pages.assets.form.fixedLabels.portfolio") }}:
              </v-col>
              <v-col cols="8" align-self="center">
                <v-autocomplete
                  :items="portfolios"
                  :value="portfolioId"
                  hide-details
                  clearable
                  item-text="name"
                  item-value="id"
                  solo
                  @change="handlePortfolioChanged($event)"
                  class="rounded-0 mb-2"
                  background-color="#F7F7F7"
                  item-color="#000"
                  color="#F25D3B"
                  flat
                  single-line
                  filled
                ></v-autocomplete>
              </v-col>
            </v-row>
            <v-row dense v-if="portfolioSubcategories.length > 0">
              <v-col cols="4" align-self="center">
                {{ $t("pages.assets.form.fixedLabels.portfolioSubcategory") }}:
              </v-col>
              <v-col cols="8" align-self="center">
                <v-autocomplete
                  :items="portfolioSubcategories"
                  :value="portfolioSubcategoryId"
                  hide-details
                  clearable
                  item-text="name"
                  item-value="id"
                  solo
                  @change="handlePortfolioSubcategoryChanged"
                  class="rounded-0 mb-2"
                  background-color="#F7F7F7"
                  item-color="#000"
                  color="#F25D3B"
                  flat
                  single-line
                  filled
                ></v-autocomplete>
              </v-col>
            </v-row>
          </div>
        </template>
        <template slot="right">
          <div v-if="asset != null && asset.id != null">
            <v-row dense class="mt-3">
              <v-col cols="5">
                {{ $t("pages.assets.form.fixedLabels.verarcaId") }}:
              </v-col>
              <v-col cols="7">
                {{ asset.referenceKey }}
              </v-col>
            </v-row>
            <v-row dense class="mt-3">
              <v-col cols="5">
                {{ $t("pages.assets.form.fixedLabels.createdBy") }}:
              </v-col>
              <v-col cols="7">
                {{ formattedCreatedBy }}
              </v-col>
            </v-row>
            <v-row dense class="mt-3">
              <v-col cols="5">
                {{ $t("pages.assets.form.fixedLabels.created") }}:
              </v-col>
              <v-col cols="7">
                {{ formattedCreatedDate }}
              </v-col>
            </v-row>
            <v-row dense class="mt-3">
              <v-col cols="5">
                {{ $t("pages.assets.form.fixedLabels.updatedBy") }}:
              </v-col>
              <v-col cols="7">
                {{ asset.updatedBy ? asset.updatedBy.name : "" }}
              </v-col>
            </v-row>
            <v-row dense class="mt-3">
              <v-col cols="5">
                {{ $t("pages.assets.form.fixedLabels.updated") }}:
              </v-col>
              <v-col cols="7">
                {{ formattedUpdatedDate }}
              </v-col>
            </v-row>
          </div>
        </template>
      </dialog-form-section-two-cols>
    </v-card>
  </v-tab-item>
</template>
<script>
import { fixedAttributes } from "../../../util/fixedAssetData";
import { toLocaleUTCDateStringFiltered } from "@/util/dateTime";
import AssetAttributeLinkInput from "../../../Components/Attribute/AttributeLinkInput.vue";
import DialogFormSectionTwoCols from "../../../Components/Dialog/DialogFormSectionTwoCols.vue";

export default {
  components: { AssetAttributeLinkInput, DialogFormSectionTwoCols },
  props: {
    show: Boolean,
    assetAttributeLinkData: Object,
    attributes: Array,
    currencies: Array,
    portfolios: Array,
    portfolioId: String,
    portfolioSubcategoryId: String,
    asset: Object,
    tabValue: [String, Number],
  },
  data() {
    const purchasedAsOptions = [
      { id: 1, text: "New" },
      { id: 2, text: "Used" },
    ];
    return {
      fixedAttributes,
      purchasedAsOptions,
    };
  },
  methods: {
    updateData(attributeId, value) {
      this.$emit("asset_attribute_data_changed", attributeId, value);
    },
    updateSecondValueData(attributeId, secondValue) {
      this.$emit(
        "asset_attribute_data_second_value_changed",
        attributeId,
        secondValue
      );
    },
    updateSelectValue(attribute, selectedOption) {
      this.$emit("select_changed", selectedOption, attribute);
    },
    updateDateValue(attribute, value) {
      this.$emit("dateTime_changed", value, attribute);
    },
    updateCurrencyValue(attribute, price, currency) {
      this.$emit("currency_changed", price, currency, attribute);
    },
    updateCurrencyCodeValue(attribute, price, currency) {
      this.$emit("currencyCode_changed", price, currency, attribute);
    },
    updateDecimalValue(attribute, decimalValue) {
      this.$emit("decimal_changed", decimalValue, attribute);
    },
    handleNameChanged(value) {
      this.$emit("string_changed", value, this.nameAttribute);
    },
    handleModelChanged(value) {
      this.$emit("string_changed", value, this.modelAttribute);
    },
    handlePortfolioChanged(value) {
      this.$emit("portfolio_changed", value);
    },
    handleDateTimeChanged(attribute, value) {
      this.$emit("dateTime_changed", value, attribute);
    },
    handlePortfolioSubcategoryChanged(value) {
      this.$emit("portfolioSubcategory_changed", value);
    },
  },
  computed: {
    formattedCreatedDate() {
      if (!this.asset?.createdAt) return "";
      return toLocaleUTCDateStringFiltered(this.asset.createdAt, false);
    },
    formattedUpdatedDate() {
      if (!this.asset?.updatedAt) return "";
      return toLocaleUTCDateStringFiltered(this.asset.updatedAt, false);
    },
    nameData() {
      return this.assetAttributeLinkData[fixedAttributes.generalNameId];
    },
    unitValueData() {
      return this.assetAttributeLinkData[fixedAttributes.generalUnitValueId];
    },
    unitTypeData() {
      return this.assetAttributeLinkData[fixedAttributes.generalUnitTypeId];
    },
    quantityData() {
      if (
        this.assetAttributeLinkData[fixedAttributes.generalQuantityId] !==
          null &&
        this.assetAttributeLinkData[fixedAttributes.generalQuantityId] <= 0
      ) {
        return 1;
      }

      return this.assetAttributeLinkData[fixedAttributes.generalQuantityId];
    },
    modelData() {
      return this.assetAttributeLinkData[fixedAttributes.generalModelId];
    },
    nameAttribute() {
      return this.attributes.find(
        (x) => x.id === fixedAttributes.generalNameId
      );
    },
    unitValueAttribute() {
      return this.attributes.find(
        (x) => x.id === fixedAttributes.generalUnitValueId
      );
    },
    unitTypeAttribute() {
      return this.attributes.find(
        (x) => x.id === fixedAttributes.generalUnitTypeId
      );
    },
    quantityAttribute() {
      return this.attributes.find(
        (x) => x.id === fixedAttributes.generalQuantityId
      );
    },
    modelAttribute() {
      return this.attributes.find(
        (x) => x.id === fixedAttributes.generalModelId
      );
    },
    purchaseDateData() {
      return this.assetAttributeLinkData[fixedAttributes.generalPurchaseDateId];
    },
    productionDateData() {
      return this.assetAttributeLinkData[
        fixedAttributes.generalProductionDateId
      ];
    },
    purchaseDateAttribute() {
      return this.attributes.find(
        (x) => x.id === fixedAttributes.generalPurchaseDateId
      );
    },
    productionDateAttribute() {
      return this.attributes.find(
        (x) => x.id === fixedAttributes.generalProductionDateId
      );
    },
    purchasePriceData() {
      return this.assetAttributeLinkData[
        fixedAttributes.generalPurchasePriceId
      ];
    },
    purchasePriceSecondValueData() {
      return this.assetAttributeLinkData[
        fixedAttributes.generalPurchasePriceId + "-secondValue"
      ];
    },
    purchasePriceAttribute() {
      return this.attributes.find(
        (x) => x.id === fixedAttributes.generalPurchasePriceId
      );
    },
    selectedPortfolio() {
      if (!this.portfolioId) return null;
      return this.portfolios.find((x) => x.id === this.portfolioId);
    },
    portfolioSubcategories() {
      if (!this.selectedPortfolio) return [];

      return this.selectedPortfolio.subcategories;
    },
    selectedPurchasedAs: {
      get() {
        const productionDate =
          this.assetAttributeLinkData[fixedAttributes.generalProductionDateId];

        if (productionDate && isNaN(new Date(productionDate))) {
          this.updateDateValue(this.productionDateAttribute, null);
          this.updateData(fixedAttributes.generalProductionDateId, null);
          return 1;
        }

        if (productionDate) {
          return 2;
        } else {
          return 1;
        }
      },
      set(value) {
        if (value === 1) {
          this.updateDateValue(this.productionDateAttribute, null);
          this.updateData(fixedAttributes.generalProductionDateId, null);
        } else if (value === 2) {
          let productionDate = new Date();
          this.updateData(
            fixedAttributes.generalProductionDateId,
            productionDate
          );
          this.updateDateValue(this.productionDateAttribute, productionDate);
        }
      },
    },
    formattedCreatedBy() {
      if (!this.asset) return "";

      if (this.asset.integration) {
        return this.$t("pages.assets.form.fixedValues.integration", {
          number: this.asset.integration.referenceKey,
        });
      }

      return this.asset.createdBy?.name ?? "";
    },
  },
  watch: {
    portfolioId(value) {
      const portfolio = this.portfolios.find((x) => x.id === value);

      if (
        portfolio?.preselectedSubcategoryId &&
        this.portfolioSubcategoryId === null
      ) {
        this.$emit(
          "portfolioSubcategory_changed",
          portfolio.preselectedSubcategoryId
        );
      }
    },
  },
};
</script>
