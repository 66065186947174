<template>
  <v-btn
    class="mb-2"
    height="3rem"
    text
    color="primary"
    @click="saveLayout"
    :disabled="loading"
  >
    <div class="d-flex flex-column justify-center align-center">
      <v-icon class="black--text">mdi-content-save-outline</v-icon>
      <p class="teal--text text--darken-4 mb-0">
        {{ $t("pages.assets.index.toolbar.saveLayout") }}
      </p>
    </div>
  </v-btn>
</template>
<script>
export default {
  props: {
    currentLayout: Object,
    currentAttributeFilters: Array,
    rowsPerPage: Number,
    routePrefix: String,
    triggerSave: Boolean,
  },
  emits: ["resetTriggerSave"],
  data() {
    return {
      loading: false,
    };
  },
  methods: {
    saveLayout() {
      if (this.loading) return;
      this.loading = true;

      const form = this.$inertia.form({
        attributeIds: this.currentLayout.attributeIds,
        sortOrderMappings: this.currentLayout.sortOrderMappings,
        attributeFilters: this.currentAttributeFilters,
        rowsPerPage: this.rowsPerPage,
        attributeDisplaySize: this.currentLayout.attributeDisplaySize,
      });

      form.put(this.route(`${this.routePrefix}.layouts.update`), {
        preserveScroll: true,
        onFinish: () => {
          this.loading = false;
        },
      });
    },
  },
  watch: {
    triggerSave(value) {
      if (!value) return;

      this.saveLayout();

      this.$emit("resetTriggerSave");
    },
  },
};
</script>
