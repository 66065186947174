<template>
  <v-tab-item :transition="false" :value="tabHref">
    <div
      class="fill-height fill-width"
      style="padding-left: 15rem; padding-right: 15rem"
    >
      <div
        class="ma-2 d-flex justify-space-between"
        v-if="isCancelled"
        style="background-color: #fcdfd8; border-radius: 5px"
      >
        <p class="ma-4 py-4">
          {{
            this.$t(
              "pages.settings.billing.yourBilling.cancelledSubscriptionWarning",
              {
                subscriptionEndDate: formatDate(payment.subscriptionEndDate),
              }
            )
          }}
        </p>
        <div
          class="d-flex pr-6 align-center justify-end font-weight-bold green--text"
          style="width: 300px; cursor: pointer"
          @click="resumeSubscription()"
        >
          {{ $t("pages.settings.billing.yourBilling.resumeSubscription") }}
        </div>
      </div>
      <div>
        <h1 class="text-subtitle-1 black--text ma-2">
          {{ $t("pages.settings.billing.yourBilling.title") }}
        </h1>
      </div>
      <div class="d-flex flex-column ma-2 pa-2" style="border: 1px solid #ccc">
        <div class="d-flex justify-space-between">
          <div
            class="d-flex justify-space-between ma-2 pb-2"
            style="width: 100%; border-bottom: 1px solid #ccc"
          >
            <div class="d-flex justify-space-between" style="width: 300px">
              {{ $t("pages.settings.billing.yourBilling.billingCycle") }}
            </div>
            <div class="d-flex justify-space-between" style="width: 300px">
              {{ $t("pages.settings.billing.yourBilling.billingPeriod") }}
            </div>
            <div class="d-flex justify-space-between" style="width: 300px">
              {{ $t("pages.settings.billing.yourBilling.price") }}
            </div>
            <div
              class="d-flex justify-space-between"
              style="width: 300px"
            ></div>
          </div>
        </div>
        <div class="d-flex justify-space-between font-weight-bold">
          <div class="d-flex justify-space-between ma-2" style="width: 100%">
            <div
              class="d-flex justify-space-between align-self-end"
              style="width: 300px"
            >
              <div v-if="hasValidBillingSubscription">
                {{ billingSubscription.billingCycle }}
              </div>
            </div>
            <div
              class="d-flex justify-space-between align-self-end"
              style="width: 300px"
            >
              <div v-if="hasValidBillingSubscription">
                {{ formatDate(billingSubscription.currentPeriodStart) }} -
                {{ formatDate(billingSubscription.currentPeriodEnd) }}
              </div>
            </div>
            <div class="d-flex justify-start" style="width: 300px">
              <div v-if="hasValidBillingSubscription">
                {{ billingSubscription.currency }}
                {{ formatPrice(billingSubscription.price) }}
                <div class="tooltip-container">
                  <info-icon class="pl-2"></info-icon>
                  <div class="tooltip">
                    {{ $t("pages.settings.billing.yourBilling.info") }}
                  </div>
                </div>
              </div>
            </div>
            <div
              class="d-flex justify-end pr-4 align-self-end"
              style="width: 300px; color: #f25d3b; cursor: pointer"
              @click="openPaymentDialog()"
            >
              <div v-if="hasValidBillingSubscription">
                {{
                  $t("pages.settings.billing.yourBilling.updateSubscription")
                }}
              </div>
              <div v-else>
                {{
                  $t("pages.settings.billing.yourBilling.purchaseSubscription")
                }}
              </div>
            </div>
          </div>
        </div>
      </div>

      <div
        v-if="billingPaymentMethods && !isCancelled"
        class="d-flex justify-end mx-4"
      >
        <div
          class="d-flex justify-end pr-6 align-self-end font-weight-bold"
          style="width: 300px; color: #f25d3b; cursor: pointer"
          @click="cancelSubscription()"
        >
          {{ $t("pages.settings.billing.yourBilling.cancelSubscription") }}
        </div>
      </div>

      <div>
        <h1 class="text-subtitle-1 black--text ma-2 pt-6">
          {{ $t("pages.settings.billing.yourSubscription.title") }}
        </h1>
      </div>
      <div class="d-flex flex-column ma-2 pa-2" style="border: 1px solid #ccc">
        <div class="d-flex justify-space-between">
          <div
            class="d-flex justify-space-between ma-2 pb-2"
            style="width: 100%; border-bottom: 1px solid #ccc"
          >
            <div class="d-flex justify-space-between" style="width: 300px">
              {{ $t("pages.settings.billing.yourSubscription.billingPlan") }}
            </div>
            <div class="d-flex justify-space-between" style="width: 300px">
              {{ $t("pages.settings.billing.yourSubscription.files") }}
            </div>
            <div class="d-flex justify-space-between" style="width: 300px">
              {{ $t("pages.settings.billing.yourSubscription.orderLines") }}
            </div>
          </div>
        </div>
        <div class="d-flex justify-space-between font-weight-bold">
          <div class="d-flex justify-space-between ma-2" style="width: 100%">
            <div class="d-flex justify-space-between" style="width: 300px">
              {{ currentSubscriptionPrice }}
            </div>
            <div style="width: 300px">
              <div class="d-flex justify-space-between" v-if="billingLimits">
                {{ billingLimits.fileCount }}
                {{ $t("pages.settings.billing.yourSubscription.of") }}
                {{ billingLimits.allowedFiles }}
              </div>
              <div class="progress-bar" v-if="billingLimits">
                <div
                  class="progress-bar-fill"
                  :style="
                    'width:' +
                    billingLimits.percentageFilesUsed +
                    '%; background-color: ' +
                    getFillColor(billingLimits.percentageFilesUsed)
                  "
                ></div>
              </div>
            </div>
            <div style="width: 300px">
              <div class="d-flex justify-space-between" v-if="billingLimits">
                {{ billingLimits.lineCount }}
                {{ $t("pages.settings.billing.yourSubscription.of") }}
                {{ billingLimits.allowedLines }}
              </div>
              <div class="progress-bar" v-if="billingLimits">
                <div
                  class="progress-bar-fill"
                  :style="
                    'width:' +
                    billingLimits.percentageLinesUsed +
                    '%; background-color: ' +
                    getFillColor(billingLimits.percentageLinesUsed)
                  "
                ></div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="pl-2 d-flex justify-start">
        <div class="font-weight-bold black--text pr-2">
          {{ $t("pages.settings.billing.yourSubscription.noteTitle") }}
        </div>
        {{ $t("pages.settings.billing.yourSubscription.noteText") }}
      </div>

      <div class="d-flex justify-space-between">
        <h1 class="text-subtitle-1 black--text ma-2 pt-6">
          {{ $t("pages.settings.billing.paymentMethod.title") }}
        </h1>
        <div
          class="d-flex justify-space-between"
          style="cursor: pointer"
          @click="addCardModal()"
        >
          <card-add-icon class="align-self-end mb-4"></card-add-icon>
          <h1 class="text-subtitle-2 grey--text ma-2 pt-6">
            {{ $t("pages.settings.billing.paymentMethod.addCard") }}
          </h1>
        </div>
      </div>
      <div class="d-flex flex-column ma-2 pa-2" style="border: 1px solid #ccc">
        <div class="d-flex justify-space-between">
          <div
            class="d-flex justify-space-between ma-2 pb-2"
            style="width: 100%; border-bottom: 1px solid #ccc"
          >
            <div class="d-flex justify-space-between" style="width: 300px">
              {{ $t("pages.settings.billing.paymentMethod.cardInfo") }}
            </div>
            <div class="d-flex justify-space-between" style="width: 300px">
              {{ $t("pages.settings.billing.paymentMethod.expiration") }}
            </div>
            <div class="d-flex justify-space-between" style="width: 300px">
              {{ $t("pages.settings.billing.paymentMethod.cardOwner") }}
            </div>
            <div class="d-flex justify-space-between" style="width: 300px">
              {{ $t("pages.settings.billing.paymentMethod.billingAddress") }}
            </div>
            <div
              class="d-flex justify-space-between"
              style="width: 300px"
            ></div>
          </div>
        </div>
        <div
          v-for="billingPaymentMethod in billingPaymentMethods"
          :key="billingPaymentMethod.id"
          class="d-flex justify-space-between font-weight-bold"
          style="width: 100%; border-bottom: 1px solid #ccc"
        >
          <div class="d-flex justify-space-between ma-2" style="width: 100%">
            <div class="d-flex justify-start align-center" style="width: 300px">
              <div
                v-if="billingPaymentMethods"
                class="d-flex justify-start align-center"
              >
                <div
                  v-if="billingPaymentMethod.cardBrand === 'visa'"
                  class="pr-1"
                >
                  <card-visa></card-visa>
                </div>
                <div
                  v-else-if="billingPaymentMethod.cardBrand === 'mastercard'"
                  class="pr-1"
                >
                  <card-mastercard></card-mastercard>
                </div>
                <div v-else class="pr-1">
                  <card-generic></card-generic>
                </div>
                {{ billingPaymentMethod.cardBrand }}
                {{ $t("pages.settings.billing.paymentMethod.endingIn") }}
                {{ billingPaymentMethod.last4 }}
              </div>
            </div>
            <div
              v-if="billingPaymentMethods"
              class="d-flex justify-space-between align-center"
              style="width: 300px"
            >
              {{ formatExpiration(billingPaymentMethod.expiration) }}
            </div>
            <div
              class="d-flex justify-space-between align-center"
              style="width: 300px"
            >
              <div v-if="billingCustomers">
                {{ billingCustomers[0].name }}
              </div>
            </div>
            <div
              class="d-flex justify-space-between align-center"
              style="width: 300px"
            >
              <div v-if="billingCustomers">
                {{ billingCustomers[0].addressLine1 }}
                {{ billingCustomers[0].postalCode }}
                {{ billingCustomers[0].city }}
                {{ billingCustomers[0].country }}
              </div>
            </div>
            <div class="d-flex justify-end align-center" style="width: 300px">
              <div
                v-if="billingPaymentMethod.isPrimary"
                style="color: #f25d3b"
                class="pr-4"
              >
                {{ $t("pages.settings.billing.paymentMethod.primary") }}
              </div>

              <v-menu
                offset-y
                min-width="120px"
                class="px-4"
                left
                v-if="billingCustomers && billingPaymentMethods.length > 0"
              >
                <template
                  v-if="!billingPaymentMethod.isPrimary"
                  v-slot:activator="{ on, attrs }"
                >
                  <div class="d-flex justify-end">
                    <v-btn v-bind="attrs" v-on="on" icon
                      ><v-icon>mdi-dots-vertical</v-icon></v-btn
                    >
                  </div>
                </template>
                <v-list>
                  <v-list-item
                    @click="selectCardAsPrimary(billingPaymentMethod)"
                  >
                    <v-list-item-title>
                      {{
                        $t(
                          "pages.settings.billing.paymentMethod.menu.selectAsPrimary"
                        )
                      }}
                    </v-list-item-title>
                  </v-list-item>
                  <v-list-item
                    @click="removeCard(billingPaymentMethod)"
                    v-if="billingPaymentMethods.length > 1"
                  >
                    <v-list-item-title>
                      {{
                        $t("pages.settings.billing.paymentMethod.menu.remove")
                      }}
                    </v-list-item-title>
                  </v-list-item>
                </v-list>
              </v-menu>
            </div>
          </div>
        </div>
      </div>

      <div class="d-flex justify-space-between">
        <h1 class="text-subtitle-1 black--text ma-2 pt-6">
          {{ $t("pages.settings.billing.billingContact.title") }}
        </h1>
        <div
          class="d-flex justify-space-between"
          style="cursor: pointer"
          @click="addContactModal()"
        >
          <user-add-icon class="align-self-end mb-4"></user-add-icon>
          <h1 class="text-subtitle-2 grey--text ma-2 pt-6">
            {{ $t("pages.settings.billing.billingContact.addContact") }}
          </h1>
        </div>
      </div>
      <div class="d-flex flex-column ma-2 pa-2" style="border: 1px solid #ccc">
        <div class="d-flex justify-space-between">
          <div
            class="d-flex justify-space-between ma-2 pb-2"
            style="width: 100%; border-bottom: 1px solid #ccc"
          >
            <div class="d-flex justify-space-between" style="width: 300px">
              {{ $t("pages.settings.billing.billingContact.name") }}
            </div>
            <div class="d-flex justify-space-between" style="width: 300px">
              {{ $t("pages.settings.billing.billingContact.email") }}
            </div>
            <div
              class="d-flex justify-space-between"
              style="width: 300px"
            ></div>
            <div
              class="d-flex justify-space-between"
              style="width: 300px"
            ></div>
            <div
              class="d-flex justify-space-between"
              style="width: 300px"
            ></div>
          </div>
        </div>
        <div
          v-for="billingCustomer in billingCustomers"
          :key="billingCustomer.id"
          class="d-flex justify-space-between font-weight-bold"
          style="width: 100%; border-bottom: 1px solid #ccc"
        >
          <div class="d-flex justify-space-between ma-2" style="width: 100%">
            <div class="d-flex justify-space-between" style="width: 300px">
              <div v-if="billingCustomers">
                {{ billingCustomer.name }}
              </div>
            </div>
            <div class="d-flex justify-space-between" style="width: 300px">
              <div v-if="billingCustomers">
                {{ billingCustomer.email }}
              </div>
            </div>
            <div
              class="d-flex justify-space-between"
              style="width: 300px"
            ></div>
            <div
              class="d-flex justify-space-between"
              style="width: 300px"
            ></div>
            <div class="d-flex justify-end align-center" style="width: 300px">
              <v-menu
                offset-y
                min-width="120px"
                class="px-4"
                left
                v-if="billingCustomers"
              >
                <template v-slot:activator="{ on, attrs }">
                  <div class="d-flex justify-end">
                    <v-btn v-bind="attrs" v-on="on" icon
                      ><v-icon>mdi-dots-vertical</v-icon></v-btn
                    >
                  </div>
                </template>
                <v-list>
                  <v-list-item @click="editContactModal(billingCustomer)">
                    <v-list-item-title>
                      {{
                        $t("pages.settings.billing.billingContact.menu.edit")
                      }}
                    </v-list-item-title>
                  </v-list-item>
                </v-list>
              </v-menu>
            </div>
          </div>
        </div>
      </div>

      <div>
        <h1 class="text-subtitle-1 black--text ma-2 pt-6">
          {{ $t("pages.settings.billing.transactions.title") }}
        </h1>
      </div>
      <div class="d-flex flex-column ma-2 pa-2" style="border: 1px solid #ccc">
        <div class="d-flex justify-space-between">
          <div
            class="d-flex justify-space-between ma-2 pb-2"
            style="width: 100%; border-bottom: 1px solid #ccc"
          >
            <div class="d-flex justify-space-between" style="width: 300px">
              {{ $t("pages.settings.billing.transactions.type") }}
            </div>
            <div class="d-flex justify-space-between" style="width: 300px">
              {{ $t("pages.settings.billing.transactions.date") }}
            </div>
            <div class="d-flex justify-space-between" style="width: 300px">
              {{ $t("pages.settings.billing.transactions.amount") }}
            </div>
            <div class="d-flex justify-space-between" style="width: 300px">
              {{ $t("pages.settings.billing.transactions.invoice") }}
            </div>
            <div class="d-flex justify-space-between" style="width: 300px">
              {{ $t("pages.settings.billing.transactions.status") }}
            </div>
          </div>
        </div>
        <div
          v-for="transaction in billingTransactions"
          :key="transaction.id"
          class="d-flex justify-space-between font-weight-bold"
          style="width: 100%; border-bottom: 1px solid #ccc"
        >
          <div class="d-flex justify-space-between ma-2" style="width: 100%">
            <div class="d-flex justify-space-between" style="width: 300px">
              {{ transaction.object }}
            </div>
            <div class="d-flex justify-space-between" style="width: 300px">
              {{ formatInvoiceDate(transaction.createdInStripe) }}
            </div>
            <div class="d-flex justify-space-between" style="width: 300px">
              {{ transaction.currency }}
              {{ formatPrice(transaction.totalPrice) }}
            </div>
            <div
              class="d-flex justify-space-between"
              style="width: 300px; color: #f25d3b"
            >
              <div class="d-flex justify-start">
                {{ transaction.invoiceNumber }}
                <open-in-new-icon
                  class="align-self-end mb-1 ml-1"
                  style="cursor: pointer"
                  @click="openInvoiceLink(transaction.invoiceUrl)"
                >
                </open-in-new-icon>
              </div>
            </div>
            <div class="d-flex justify-space-between" style="width: 300px">
              {{ transaction.status }}
            </div>
          </div>
        </div>
      </div>
    </div>
    <billing-contact-modal
      v-if="hasValidBillingSubscription"
      v-model="showContactModalPopup"
      v-show="showContactModalPopup"
      :paymentId="billingSubscription.paymentId"
      :id="customerToEdit.id"
      :name="customerToEdit.name"
      :email="customerToEdit.email"
      :edit="isEditingContact"
    ></billing-contact-modal>
    <card-modal
      v-if="billingPaymentMethods"
      v-model="showCardModalPopup"
      v-show="showCardModalPopup"
      :publishable-key="publishableKey"
    >
    </card-modal>
    <payment-dialog
      v-model="paymentDialog"
      :publishableKey="publishableKey"
      :billingSubscription="billingSubscription"
      :paymentReturnUrl="paymentReturnUrl"
      :openedFromSettings="true"
      :paymentTierIds="paymentTierIds"
    />
    <confirm-dialog
      v-model="showCancelSubscriptionConfirmDialog"
      v-show="showCancelSubscriptionConfirmDialog"
      :confirm-message="confirmCancelSubscriptionMessage"
      @confirmed="handleCancelSubscriptionConfirmation"
    ></confirm-dialog>
    <confirm-dialog
      v-model="showResumeSubscriptionConfirmDialog"
      v-show="showResumeSubscriptionConfirmDialog"
      :confirm-message="confirmResumeSubscriptionMessage"
      @confirmed="handleResumeSubscriptionConfirmation"
    ></confirm-dialog>
  </v-tab-item>
</template>

<script>
import CardVisa from "../../../assets/svg/card-visa.svg";
import CardMastercard from "../../../assets/svg/card-mastercard.svg";
import CardGeneric from "../../../assets/svg/card-generic.svg";
import InfoIcon from "../../../assets/svg/info-icon.svg";
import CardAddIcon from "../../../assets/svg/card-add.svg";
import UserAddIcon from "../../../assets/svg/user-add.svg";
import OpenInNewIcon from "../../../assets/svg/open-in-new.svg";
import BillingContactModal from "../BillingContactModal.vue";
import CardModal from "../CardModal.vue";
import PaymentDialog from "../../../Components/Onboarding/PaymentDialog.vue";
import ConfirmDialog from "../../../Components/ConfirmDialog.vue";
import { FreeId } from "../../../util/paymentProductPrice.js";

export default {
  components: {
    CardVisa,
    CardMastercard,
    CardGeneric,
    InfoIcon,
    CardAddIcon,
    UserAddIcon,
    OpenInNewIcon,
    BillingContactModal,
    CardModal,
    PaymentDialog,
    ConfirmDialog,
  },
  props: {
    tabHref: String,
    payment: Object,
    billingSubscription: Object,
    billingPaymentMethods: Array,
    billingCustomers: Array,
    billingTransactions: Array,
    billingLimits: Object,
    publishableKey: String,
    paymentReturnUrl: String,
    paymentTierIds: Object,
  },
  data() {
    return {
      showContactModalPopup: false,
      isEditingContact: false,
      showCardModalPopup: false,
      showCancelSubscriptionConfirmDialog: false,
      showResumeSubscriptionConfirmDialog: false,
      paymentDialog: false,
      customerToEdit: { id: null, name: null, email: null },
      deleteCardForm: this.$inertia.form({
        paymentId: String,
        paymentMethodId: String,
        paymentCustomerId: String,
        stripePaymentMethodId: String,
      }),
      primaryCardForm: this.$inertia.form({
        paymentId: String,
        paymentMethodId: String,
        paymentCustomerId: String,
        stripePaymentMethodId: String,
      }),
      cancelSubscriptionForm: this.$inertia.form({
        paymentId: String,
      }),
      resumeSubscriptionForm: this.$inertia.form({
        paymentId: String,
      }),
    };
  },
  mounted() {
    const searchParams = new URLSearchParams(window.location.search);
    const openPayment = searchParams.get("openPayment");

    if (openPayment === "true") {
      this.openPaymentDialog();
    }
  },
  computed: {
    hasValidBillingSubscription() {
      return this.billingSubscription?.status === "active";
    },
    isCancelled() {
      if (this.payment.subscriptionEndDate) {
        return true;
      }
      return false;
    },
    productPriceFree() {
      return FreeId;
    },
    productPriceTier500() {
      return this.paymentTierIds[500];
    },
    productPriceTier1000() {
      return this.paymentTierIds[1000];
    },
    productPriceTier3000() {
      return this.paymentTierIds[3000];
    },
    productPriceTier6000() {
      return this.paymentTierIds[6000];
    },
    productPriceTier12000() {
      return this.paymentTierIds[12000];
    },
    productPriceTier20000() {
      return this.paymentTierIds[20000];
    },
    productPriceTier30000() {
      return this.paymentTierIds[30000];
    },
    currentSubscriptionPrice() {
      switch (this.payment.stripeProductPriceId) {
        case this.productPriceTier500:
          return this.$t(
            "pages.settings.billing.yourSubscription.subscriptionname.tier500"
          );
        case this.productPriceTier1000:
          return this.$t(
            "pages.settings.billing.yourSubscription.subscriptionname.tier1000"
          );
        case this.productPriceTier3000:
          return this.$t(
            "pages.settings.billing.yourSubscription.subscriptionname.tier3000"
          );
        case this.productPriceTier6000:
          return this.$t(
            "pages.settings.billing.yourSubscription.subscriptionname.tier6000"
          );
        case this.productPriceTier12000:
          return this.$t(
            "pages.settings.billing.yourSubscription.subscriptionname.tier12000"
          );
        case this.productPriceTier20000:
          return this.$t(
            "pages.settings.billing.yourSubscription.subscriptionname.tier20000"
          );
        case this.productPriceTier30000:
          return this.$t(
            "pages.settings.billing.yourSubscription.subscriptionname.tier30000"
          );

        default:
          return this.payment.stripeProductPriceId;
      }
    },
    confirmCancelSubscriptionMessage() {
      return this.$t(
        "pages.settings.billing.yourBilling.cancelSubscriptionMessage"
      );
    },
    confirmResumeSubscriptionMessage() {
      return this.$t(
        "pages.settings.billing.yourBilling.resumeSubscriptionMessage"
      );
    },
  },
  methods: {
    formatDate(dateToFormat) {
      const inputDate = new Date(dateToFormat);

      const options = {
        year: "numeric",
        month: "short",
        day: "numeric",
      };

      return inputDate.toLocaleDateString("en-US", options);
    },
    formatPrice(priceToFormat) {
      const numberString = priceToFormat.toString();
      const part1 = numberString.slice(0, -2);
      const part2 = numberString.slice(-2);

      const formattedPart1 = part1.replace(/\B(?=(\d{3})+(?!\d))/g, ",");

      return formattedPart1 + "." + part2;
    },
    formatExpiration(expirationToFormat) {
      const parts = expirationToFormat.split(/\s+/);

      if (parts.length === 2) {
        const [month, year] = parts;

        const formattedMonth = month.trim();
        const formattedYear = year.trim().slice(-2);

        const formattedDate = `${formattedMonth.padStart(
          2,
          "0"
        )}/${formattedYear}`;
        return formattedDate;
      } else {
        return expirationToFormat;
      }
    },
    formatInvoiceDate(date) {
      const originalDate = new Date(date);

      const options = {
        year: "numeric",
        month: "2-digit",
        day: "2-digit",
        hour: "2-digit",
        minute: "2-digit",
        second: "2-digit",
      };
      return originalDate.toLocaleDateString("en-US", options);
    },
    openInvoiceLink(url) {
      window.open(url, "_blank");
    },
    getFillColor(percentage) {
      if (percentage <= 50) {
        return "#266663";
      } else if (percentage > 50 && percentage <= 75) {
        return "#F2933B";
      } else {
        return "#D32C2C";
      }
    },
    addContactModal() {
      this.isEditingContact = false;
      this.showContactModalPopup = true;
    },
    editContactModal(billingCustomer) {
      this.customerToEdit.id = billingCustomer.id;
      this.customerToEdit.name = billingCustomer.name;
      this.customerToEdit.email = billingCustomer.email;

      this.isEditingContact = true;
      this.showContactModalPopup = true;
    },
    addCardModal() {
      this.showCardModalPopup = true;
    },
    openPaymentDialog() {
      this.paymentDialog = true;
    },
    selectCardAsPrimary(paymentMethod) {
      this.primaryCardForm.paymentId = paymentMethod.payment.id;
      this.primaryCardForm.paymentMethodId = paymentMethod.id;
      this.primaryCardForm.paymentCustomerId = paymentMethod.paymentCustomerId;
      this.primaryCardForm.stripePaymentMethodId =
        paymentMethod.stripePaymentMethodId;
      this.primaryCardForm.post(this.route("payments.select-card-as-primary"));
    },
    removeCard(paymentMethod) {
      this.deleteCardForm.paymentId = paymentMethod.payment.id;
      this.deleteCardForm.paymentMethodId = paymentMethod.id;
      this.deleteCardForm.paymentCustomerId = paymentMethod.paymentCustomerId;
      this.deleteCardForm.stripePaymentMethodId =
        paymentMethod.stripePaymentMethodId;
      this.deleteCardForm.post(this.route("payments.delete-card"));
    },
    cancelSubscription() {
      this.showCancelSubscriptionConfirmDialog = true;
    },
    handleCancelSubscriptionConfirmation(status) {
      this.isConfirmed = status;
      if (status) {
        this.confirmCancelSubscription();
      }
    },
    confirmCancelSubscription() {
      this.cancelSubscriptionForm.paymentId = this.payment.id;
      this.cancelSubscriptionForm.post(
        this.route("payments.cancel-subscription")
      );
    },
    resumeSubscription() {
      this.showResumeSubscriptionConfirmDialog = true;
    },
    handleResumeSubscriptionConfirmation(status) {
      this.isConfirmed = status;
      if (status) {
        this.confirmResumeSubscription();
      }
    },
    confirmResumeSubscription() {
      this.resumeSubscriptionForm.paymentId = this.payment.id;
      this.resumeSubscriptionForm.post(
        this.route("payments.resume-subscription")
      );
    },
  },
};
</script>

<style scoped>
.tooltip-container {
  position: relative;
  display: inline-block;
}

.tooltip {
  visibility: hidden;
  width: 350px;
  background-color: black;
  color: white;
  text-align: left;
  border-radius: 5px;
  padding: 10px;
  position: absolute;
  z-index: 1;
  top: 125%;
  left: 50%;
  transform: translateX(-50%);
  opacity: 0;
  transition: opacity 0.2s;
  font-weight: normal;
  white-space: normal;
}

.tooltip-container:hover .tooltip {
  visibility: visible;
  opacity: 1;
}

.progress-bar {
  width: 100%;
  height: 10px;
  background-color: #f7f7f7;
  border-radius: 5px;
  overflow: hidden;
}

.progress-bar-fill {
  height: 100%;
  background-color: #4caf50;
  border-radius: 5px;
  max-width: 100%;
}
</style>
