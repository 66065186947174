<template>
  <div class="page-container fill-height grow d-flex flex-column flex-nowrap">
    <v-container fluid class="py-0">
      <v-row no-gutters>
        <v-col
          v-for="(template, index) in reportTemplates"
          :key="index"
          cols="12"
          sm="6"
          md="4"
          xl="2"
          class="d-flex flex-column align-center pt-4"
        >
          <v-card
            elevation="0"
            tile
            class="d-flex flex-column align-center pa-6 ma-6 template"
          >
            <h3 class="title">{{ template.title }}</h3>
            <img class="thumbnail" :src="template.thumbnail" />
            <v-btn
              color="#f25d3b"
              tile
              class="text-bold white--text"
              elevation="0"
              width="140px"
              :href="template.path"
              ><v-icon class="mr-2">mdi-plus</v-icon
              >{{ $t("pages.reports.index.generate") }}</v-btn
            >
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import { appLayout } from "@/util/layout";
export default {
  layout: appLayout({ title: "pages.reports.index.title" }),
  data() {
    return {
      reportTemplates: [
        {
          title: "Climate Report",
          thumbnail: require("../../assets/report-template-thumbnails/carbon-footprint.png"),
          path: "/report/climate-report",
        },
      ],
    };
  },
};
</script>

<style lang="scss" scoped>
.page-container {
  .template {
    background: #fff;
    .title {
      text-transform: uppercase;
      text-align: center;
      margin-bottom: 20px;
      font-size: 14px !important;
    }
    .thumbnail {
      max-width: 250px;
      margin-bottom: 30px;
    }
  }
}
</style>
