<template>
  <div>
    <v-list class="category-account-mapper pt-0" tile dense>
      <v-list-group
        v-for="(accountGroup, aindex) in accounts"
        :key="aindex"
        v-model="accountGroup.active"
        class="category-groups"
        append-icon="mdi-menu-down"
        :hidden="
          readOnly && readOnlyAccounts(accountGroup.accountNumbers).length === 0
        "
      >
        <template v-slot:activator>
          <v-list-item-content>
            <v-list-item-title
              >{{ accountGroup.accountNumber }} - {{ accountGroup.name
              }}<v-chip
                :color="countInGroup(accountGroup) > 0 ? '#F25D3B' : '#F7F7F7'"
                :text-color="countInGroup(accountGroup) > 0 ? '#fff' : 'B4B4B4'"
                small
                class="pa-1 ml-2 align-center justify-center"
                style="height: 23px; min-width: 23px"
                >{{ countInGroup(accountGroup) }}</v-chip
              >
            </v-list-item-title>
          </v-list-item-content>
        </template>
        <div v-if="!readOnly" class="list-header">
          <p class="sync">
            {{ $t("components.onboarding.accountMapper.sync") }}
          </p>
          <p class="name">
            {{ $t("components.onboarding.accountMapper.name") }}
          </p>
          <p class="syncTo">
            {{ $t("components.onboarding.accountMapper.syncTo") }}
          </p>
        </div>
        <div v-else class="list-header">
          <p class="sync flex-grow-1">
            {{ $t("components.onboarding.accountMapper.nameOfCategory") }}
          </p>
          <p class="syncTo text-right">
            {{ $t("components.onboarding.accountMapper.suggestedMapping") }}
          </p>
        </div>
        <v-list-item
          v-for="(account, anindex) in readOnly
            ? readOnlyAccounts(accountGroup.accountNumbers)
            : accountGroup.accountNumbers"
          :key="anindex"
          class="account-number"
        >
          <toggle-button
            class="mr-8"
            :value="syncAccounts[account.id]"
            @change="(val) => updateSyncLines(account, val)"
            v-if="!readOnly"
          ></toggle-button>
          <v-list-item-title
            class="account-mapping-line"
            :class="syncAccounts[account.id] ? 'sync-account' : ''"
            ><div>{{ account.number }} - {{ account.name }}</div>
            <v-btn
              v-if="autoMappedAccounts[account.id]"
              class="d-flex flex-row align-center rounded px-2 py-3 red--text mx-2"
              x-small
              color="#F25D3B"
              text
              elevation="0"
              style="background-color: #fdece7; pointer-events: none"
              ><AIRobot class="mr-1"></AIRobot>
              {{
                $t("components.onboarding.accountMapper.autoSuggestion")
              }}</v-btn
            ></v-list-item-title
          >
          <div v-if="readOnly">
            <ArrowRightIcon class="arrow-icon"></ArrowRightIcon>
          </div>
          <div
            v-if="readOnly"
            class="read-only-category d-flex flex-row justify-end align-center"
          >
            {{ selectedCategoryForAccount(account).value }}
            <ScopeBadge
              class="ml-4"
              :scope="
                selectedCategoryForAccount(
                  account
                )?.dependentOption?.value?.split(' ')[1]
              "
            ></ScopeBadge>
          </div>
          <SelectGHGCategory
            v-else-if="syncAccounts[account.id]"
            :items="categories"
            @change="(val) => selectedGhgForAccount(account, val)"
            :initialValue="selectedCategoryForAccount(account)"
          ></SelectGHGCategory>
        </v-list-item>
      </v-list-group>
    </v-list>
  </div>
</template>
<script>
import Vue from "vue";

import ToggleButton from "../Buttons/ToggleButton.vue";
import SelectGHGCategory from "./SelectGHGCategory.vue";

import AIRobot from "../../assets/svg/ai-robot.svg";

import ArrowRightIcon from "../../assets/svg/arrow-right.svg";
import ScopeBadge from "../../Components/Attribute/ScopeBadge.vue";

export default {
  components: {
    ToggleButton,
    SelectGHGCategory,
    AIRobot,
    ArrowRightIcon,
    ScopeBadge,
  },
  props: {
    categories: Array,
    accounts: Array,
    syncAccounts: Object,
    autoMappedAccounts: Object,
    readOnly: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    readOnlyAccounts(accountNumbers) {
      return accountNumbers.filter(
        (accountNumber) => this.syncAccounts[accountNumber.id]
      );
    },
    selectedCategoryForAccount(account) {
      return this.categories.find((cat) =>
        cat.accountNumbers.find((acc) => acc.id === account.id)
      );
    },
    updateSyncLines(account, val) {
      this.$emit("toggleSync", { id: account.id, value: val });
    },
    selectedGhgForAccount(account, categoryId) {
      // copy object
      const categories = Vue.util.extend([], this.categories);

      // remove account from any categories that contain it
      categories.forEach((cat, catIndex) => {
        cat.accountNumbers.forEach(() => {
          categories[catIndex].accountNumbers = categories[
            catIndex
          ].accountNumbers.filter((a) => a.id !== account.id);
        });
      });

      // find index for category to update
      const categoryIndex = categories.findIndex(
        (category) => category.id === categoryId
      );

      // add account
      categories[categoryIndex].accountNumbers.push(account);

      // send updated categories to parent
      this.$emit("updateCategories", categories);
    },

    countInGroup(group) {
      let count = 0;

      group.accountNumbers.forEach((account) => {
        if (this.syncAccounts[account.id] === true) {
          count++;
        }
      });

      return count;
    },
  },
};
</script>

<style lang="scss" scoped>
.category-account-mapper {
  border-top: 1px solid #e8e8e8;
  /*border-bottom: 1px solid #e8e8e8;*/

  .v-list-item__title {
    font-weight: normal !important;
  }
  .category-groups {
    &:not(:last-child) {
      > :deep(.v-list-group__header) {
      }
    }

    > :deep(.v-list-group__header
        > .v-list-item__icon
        > .v-icon:before, .v-list-group__header
        > .v-list-item__icon
        > .v-icon:after) {
      color: #b4b4b4 !important;
    }
    &:deep(.v-list-group__header) {
      height: 63px;
      border-bottom: 1px solid #e8e8e8;
    }
  }

  &:deep(.v-list-group__header .v-list-item__icon) {
    margin-right: 0px !important;
    align-self: center;
  }
  > &:deep(.v-list-group__header.v-list-item) {
    padding: 0 16px !important;
  }

  .account-number {
    background: #fafafa !important;
    height: 48px;
    &:not(:last-child) {
      border-bottom: 1px solid #e8e8e8;
    }
  }
  .assign-account {
    background: #fff;
    border-bottom: 1px solid #e8e8e8;
    text-align: center;
    min-height: 50px;
    letter-spacing: unset;
    justify-content: center;
    padding: 0px !important;
  }
}

.list-header {
  border-bottom: 1px solid #e8e8e8;
  background: #ffffff;
  display: flex;
  align-items: center;
  height: 40px;
  padding: 0 16px;
  p {
    font-size: 12px;
    color: #b4b4b4;
    margin-bottom: 0;
  }
  .sync {
    width: 41px;
    margin-right: 32px;
  }

  .name {
    flex: 1;
  }

  .syncTo {
    width: 380px;
  }
}

.info-btn {
  float: right;
  min-width: 20px !important;
  height: 20px !important;
}

.footer-section {
  position: sticky;
  bottom: 0;
  background: #fff;
  border-top: 1px solid #e8e8e8;

  .add-account-number-btn {
    width: 100%;
  }
}
.account-mapping-line {
  flex: 1;
  color: #868686;
  display: flex;
  align-items: center;

  div {
    flex: 1;
  }

  &.sync-account {
    color: #000;
  }
}

.arrow-icon {
  path {
    fill: #b4b4b4;
  }
}
.read-only-category {
  flex: 1;
  font-weight: lighter;
  font-size: 14px;
}
</style>
