<template>
  <tr
    :class="`${filesDragging && onlyFileAttribute ? 'outline-drop-zone' : ''} ${
      hoveredRowId === item.id ? 'hovered' : ''
    }
    `"
    @dblclick="editItem(item)"
    @drop="
      (e) =>
        onlyFileAttribute
          ? handleDropEvent(e, onlyFileAttribute, item)
          : undefined
    "
    @dragover="
      (e) =>
        onlyFileAttribute
          ? handleDragOverEvent(e, onlyFileAttribute, item)
          : undefined
    "
    @dragenter="
      (e) =>
        onlyFileAttribute
          ? handleDragEnterEvent(e, onlyFileAttribute, item)
          : undefined
    "
    @dragleave="
      (e) =>
        onlyFileAttribute
          ? handleDragLeaveEvent(onlyFileAttribute, item)
          : undefined
    "
  >
    <td class="text-start fixed">
      <v-simple-checkbox
        v-if="!loadingSkeletonOn"
        color="red"
        :ripple="false"
        :value="isSelected"
        @input="() => select(!isSelected)"
      ></v-simple-checkbox>
    </td>
    <td
      v-for="header in headers"
      :key="header.value"
      class="text-start"
      :class="`${header.value === 'action' ? 'fixed' : ''}`"
    >
      <template
        v-if="
          header.value !== 'action' &&
          !isEmissionSourceCol(header.value) &&
          loadingSkeletonOn
        "
      >
        <div
          style="height: 29px"
          :style="'width: ' + (50 + Math.random() * 40) + 'px'"
          class="custom-skeleton"
        ></div>
      </template>
      <template v-else-if="header.value === infoInvoiceId">
        <a
          v-if="groupName !== 'no-group'"
          :href="
            formatInvoice?.file
              ? route('api.files.content', {
                  id: formatInvoice.file.id,
                  filename: formatInvoice.file.originalName,
                })
              : undefined
          "
          target="_blank"
          ><v-icon>{{
            groupName === "group-1"
              ? "mdi-file-document-outline"
              : "mdi-file-document"
          }}</v-icon></a
        >
      </template>
      <template v-else-if="header.value === 'createdAt'">
        {{ new Date(item.createdAt).toLocaleString() }}
      </template>
      <template v-else-if="header.value === 'updatedAt'">
        {{ new Date(item.updatedAt).toLocaleString() }}
      </template>
      <!-- Whats going on here? (header.value === 'emissionsSource' && item.emissionFactor) -->
      <template
        v-else-if="header.value === 'emissionsSource' && item.emissionFactor"
      >
        <a :href="item.emissionFactor.sourceLink" target="_blank">
          {{ item.emissionFactor.source }}
        </a>
      </template>
      <template v-else-if="isEmissionSourceCol(header.value)">
        <ScopeBadge
          :scope="badgeFormatBy(item[header.value]?.searchString)"
        ></ScopeBadge>
      </template>
      <template v-else-if="dynamicHeaderIds.includes(header.value)">
        <div
          v-if="mappedAttributes[header.value].format == 'File'"
          class="fill-height d-flex align-center px-2"
          :class="`${
            filesDragging && !onlyFileAttribute ? 'outline-drop-zone' : ''
          } ${
            hoveredRowId === item.id && hoveredAttributeId === header.value
              ? 'hovered'
              : ''
          }
          ${isDisplaySize('Minimal') ? 'flex-wrap' : ''}`"
          :key="header.value + item.id"
          @drop="
            (e) =>
              onlyFileAttribute
                ? handleDropEvent(e, onlyFileAttribute, item)
                : handleDropEvent(e, mappedAttributes[header.value], item)
          "
          @dragover="
            (e) =>
              onlyFileAttribute
                ? handleDragOverEvent(e, onlyFileAttribute, item)
                : handleDragOverEvent(e, mappedAttributes[header.value], item)
          "
          @dragenter="
            (e) =>
              onlyFileAttribute
                ? handleDragEnterEvent(e, onlyFileAttribute, item)
                : handleDragEnterEvent(e, mappedAttributes[header.value], item)
          "
          @dragleave="
            (e) =>
              onlyFileAttribute
                ? handleDragLeaveEvent(onlyFileAttribute, item)
                : handleDragLeaveEvent(mappedAttributes[header.value], item)
          "
        >
          <template
            v-if="
              item[header.value] != null &&
              (item[header.value].fileId != null ||
                (item[header.value][fileLinkProperty] &&
                  item[header.value][fileLinkProperty].length))
            "
          >
            <file-preview
              v-if="item[header.value].file"
              :file="item[header.value].file"
              className="mx-1"
            />
            <template v-if="item[header.value][fileLinkProperty]">
              <file-preview
                v-for="fileLink in item[header.value][fileLinkProperty]"
                :key="fileLink.id"
                :file="fileLink.file"
                className="mx-1"
              />
            </template>
          </template>
        </div>
        <div
          v-else
          :key="header.value + item.id"
          class="fill-height d-flex align-center"
        >
          <span
            :class="`
              ${isDisplaySize('Minimal') ? 'minimal-attribute-column' : ''}
            `"
            :title="undefined"
            :style="
              isEmissionCol(header.value) ||
              (isEmissionRelatedCol(header.value) &&
                factorStatusData == 'defining')
                ? { width: '100px' }
                : null
            "
          >
            {{ handleAttributeValue(item[header.value]) }}
          </span>
          <div
            v-if="
              isEmissionCol(header.value) ||
              (isEmissionRelatedCol(header.value) &&
                factorStatusData == 'defining')
            "
            class=""
          >
            <ai-status :type="factorStatusData" :expanded="true"></ai-status>
          </div>
        </div>
      </template>
      <template v-else-if="header.value === 'action'">
        <attribute-row-action-menu
          :row="item"
          :attributes="attributes"
          :entryType="entryType"
          @editRowClicked="($event) => editItem($event)"
          @copyRowClicked="$emit('copyRowClicked', $event)"
          @destroyRowClicked="$emit('destroyRowClicked', $event)"
        />
      </template>
      <template v-else>
        {{ item[header.value] }}
      </template>
    </td>
  </tr>
</template>
<script>
import { formatAttributeLink } from "@/util/formatters";
import AttributeRowActionMenu from "./AttributeRowActionMenu.vue";
import FilePreview from "./FilePreview.vue";
import {
  fixedAttributes,
  fixedFieldAttributes,
} from "../../util/fixedAssetData";
import AiStatus from "../Labels/AiStatus.vue";
import ScopeBadge from "./ScopeBadge.vue";

export default {
  components: {
    AttributeRowActionMenu,
    FilePreview,
    AiStatus,
    ScopeBadge,
  },
  props: {
    isSelected: Boolean,
    select: Function,
    item: Object,
    entryType: String,
    currentLayout: Object,
    onlyFileAttribute: Object,
    attributes: Array,
    headers: Array,
    filesDragging: Boolean,
    hoveredRowId: String,
    hoveredAttributeId: String,
    defaultCurrencyCode: String,
    groupName: String,
    loadingSkeletonOn: Boolean,
  },
  emits: [
    "hoveredRowIdUpdated",
    "hoveredAttributeIdUpdated",
    "linkFilesToRow",
    "editRowClicked",
    "copyRowClicked",
    "destroyRowClicked",
  ],
  computed: {
    infoInvoiceId() {
      return fixedAttributes.infoInvoiceId;
    },
    formatInvoice() {
      if (
        typeof this.item[fixedAttributes.infoInvoiceId]
          ?.assetAttributeFileLinks !== "undefined"
      ) {
        return this.item[fixedAttributes.infoInvoiceId]
          ?.assetAttributeFileLinks[0];
      }
      return "";
    },
    fileLinkProperty() {
      switch (this.entryType) {
        case "asset":
          return "assetAttributeFileLinks";
        case "solution":
          return "solutionAttributeFileLinks";
        default:
          return null;
      }
    },
    mappedAttributes() {
      let mappedAttributes = [];
      this.attributes.forEach((attribute) => {
        mappedAttributes[attribute.id] = attribute;
      });
      return mappedAttributes;
    },
    dynamicHeaderIds() {
      return this.headers.filter((x) => x.dynamic).map((x) => x.value);
    },
    factorStatusData() {
      /*const emissionFactorFromAiIdStringValue =
        this.item[fixedFieldAttributes.co2EmissionFactorFromAiId].searchString; */ // ??
      const emissionStatusStringValue =
        this.item[
          fixedFieldAttributes.co2EmissionFactorStatusId
        ]?.stringValue?.toLowerCase();
      if (emissionStatusStringValue) {
        return emissionStatusStringValue;
      }
      return null;
    },
  },
  watch: {
    factorStatusData(to) {
      if (to === "defining") {
        this.$emit("onHasDefining", true);
      }
    },
  },
  methods: {
    editItem(item) {
      if (!this.loadingSkeletonOn) {
        this.$emit("editRowClicked", item);
      }
    },
    badgeFormatBy(scopeName) {
      const scopes = {
        "SCOPE 1": "1",
        "SCOPE 2": "2",
        "SCOPE 3": "3",
      };

      return scopes[scopeName] ?? scopes["UNDEFINED"];
    },
    isEmissionCol(attributeId) {
      return attributeId === fixedAttributes.co2EmissionsId;
    },
    isEmissionSourceCol(attributeId) {
      return attributeId === fixedAttributes.co2EmissionSourceId;
    },
    isEmissionRelatedCol(attributeId) {
      return (
        attributeId === fixedAttributes.co2EmissionsId ||
        attributeId === fixedAttributes.co2MethodId ||
        attributeId === fixedAttributes.co2SectorId ||
        attributeId === fixedAttributes.co2CategoryId ||
        attributeId === fixedAttributes.co2RegionId ||
        attributeId === fixedAttributes.co2NameId ||
        attributeId === fixedFieldAttributes.co2EmissionFactorId ||
        attributeId === fixedFieldAttributes.co2EmissionFactorCurrencyId ||
        attributeId === fixedFieldAttributes.co2EmissionFactorStatusId ||
        attributeId === fixedFieldAttributes.co2EmissionFactorFromAiId ||
        attributeId === fixedFieldAttributes.co2SourceId ||
        attributeId === fixedFieldAttributes.co2UuidId ||
        attributeId === fixedFieldAttributes.co2FactorTypeId
      );
    },
    handleAttributeValue(propertyItem) {
      return formatAttributeLink(propertyItem, this.defaultCurrencyCode);
    },
    isDisplaySize(attributeDisplaySize) {
      return this.currentLayout.attributeDisplaySize === attributeDisplaySize;
    },
    handleDropEvent(event, attribute, row) {
      if (!attribute || !row) return;

      event.preventDefault();

      this.$emit("hoveredRowIdUpdated", null);
      this.$emit("hoveredAttributeIdUpdated", null);

      const selectedFileIds = event.dataTransfer.getData(
        "filemenu:selectedFileIds"
      );

      if (!selectedFileIds || selectedFileIds.trim() === "") return;

      const fileIds = JSON.parse(selectedFileIds);

      const formData = new FormData();
      fileIds.forEach((fileId, index) => {
        formData.append(`fileIds[${index}]`, fileId);
      });

      this.$emit("linkFilesToRow", row.id, attribute.id, formData);
    },
    handleDragOverEvent(event, attribute, row) {
      if (!attribute || !row) return;

      if (!event.dataTransfer.types.some((x) => x === "Files"))
        event.preventDefault();

      event.dataTransfer.dropEffect = "link";

      this.$emit("hoveredRowIdUpdated", row.id);
      this.$emit("hoveredAttributeIdUpdated", attribute.id);

      return false;
    },
    handleDragEnterEvent(event, attribute, row) {
      if (!attribute || !row) return;

      if (!event.dataTransfer.types.some((x) => x === "Files"))
        event.preventDefault();

      this.$emit("hoveredRowIdUpdated", row.id);
      this.$emit("hoveredAttributeIdUpdated", attribute.id);
    },
    handleDragLeaveEvent(attribute, row) {
      if (!attribute || !row) return;

      this.$emit("hoveredRowIdUpdated", null);
      this.$emit("hoveredAttributeIdUpdated", null);
    },
  },
};
</script>
<style scoped>
.minimal-attribute-column {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.group-1 {
  background-color: red;
}

.group-2 {
  background-color: blue;
}

.custom-skeleton {
  background: #f4f4f4;
  overflow: hidden;
  position: relative;
  border-radius: 2px;
}
.custom-skeleton::after {
  background: linear-gradient(
    90deg,
    rgba(255, 255, 255, 0),
    rgba(255, 255, 255, 0.4),
    rgba(255, 255, 255, 0)
  );
  animation: skeleton-loading 1.5s infinite;
  content: "";
  height: 100%;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
  transform: translateX(-100%);
  z-index: 1;
}

@keyframes skeleton-loading {
  from {
    transform: translateX(-100%);
  }
  to {
    transform: translateX(100%);
  }
}
</style>
