<template>
  <div>
    <DialogFormHeader
      :title="$t('pages.assets.import.title')"
    ></DialogFormHeader>
    <div class="border-bottom">
      <v-row
        v-if="hasErrors"
        class="d-flex justify-center align-center mb-2 mx-2 my-2"
      >
        <p class="mb-0 mr-4">
          {{ $t("pages.assets.import.stepThree.showAllData") }}
        </p>
        <v-switch v-model="onlyErroredRows" hide-details class="mt-0">
          <template #label>
            <i18n
              path="pages.assets.import.stepThree.onlyShow"
              tag="p"
              class="mb-0 ml-3"
            >
              <span class="red--text">{{
                $t("pages.assets.import.stepThree.invalid")
              }}</span>
            </i18n>
          </template>
        </v-switch>
      </v-row>
      <v-progress-linear
        v-if="loading"
        indeterminate
        color="#f25d3b"
      ></v-progress-linear>
      <div class="table-container">
        <v-row
          v-if="importStatusCode === 402"
          dense
          class="px-3 py-3 mx-0 align-center border-bottom"
        >
          <v-col cols="1">
            <v-icon color="red" large>mdi-alert-circle-outline</v-icon>
          </v-col>
          <v-col class="text-center">
            {{ $t("pages.assets.import.stepThree.exceedsLimit") }}
          </v-col>
          <v-col cols="1" class="d-flex">
            <v-spacer />
            <v-btn
              @click="redirectToBilling()"
              elevation="0"
              tile
              large
              style="text-transform: none; letter-spacing: normal"
              >{{ $t("components.cards.upgradeToPro.upgrade") }}
            </v-btn>
          </v-col>
        </v-row>
        <v-data-table
          v-model="selectedRows"
          :class="{
            compressed: hasErrors,
            'with-upgrade-notice': importStatusCode === 402,
          }"
          :headers="tableHeaders"
          height="calc(100% - 61px)"
          :items="filteredAssets"
          :item-class="getAssetRowClass"
          :loading="loading"
          item-key="index"
          show-select
          dense
          disable-sort
          fixed-header
          :options.sync="tableOptions"
          :footer-props="tableFooterProps"
        >
          <template
            v-for="header in headers"
            v-slot:[`header.row.${header.mapping}`]
          >
            <v-autocomplete
              v-if="getMapping(header.mapping).type === 'attribute'"
              class="my-2"
              flat
              :class="
                getMappingAttribute(header.mapping) === null ? 'unknown' : ''
              "
              :key="header.mapping"
              :items="attributeOptionsWithUnknown()"
              :item-disabled="getAttributeOptionDisabled"
              :value="
                getMappingAttribute(header.mapping) !== null
                  ? getMappingAttribute(header.mapping).id
                  : null
              "
              @change="
                (value) => handleHeaderMappingChanged(header.mapping, value)
              "
              item-text="name"
              item-value="id"
              item-color="#000"
              dense
              solo
              hide-details
            >
              <template #item="{ item }">
                <v-list-item-content>
                  <v-list-item-title>
                    {{
                      item.name.replace(
                        " " +
                          $t("pages.assets.import.stepThree.unknownSuffixPart"),
                        ""
                      )
                    }}
                    <span v-if="item.unknown" class="yellow--text accent-3 ml-1">
                      {{ $t("pages.assets.import.stepThree.unknownSuffixPart") }}
                    </span>
                  </v-list-item-title>
                  <v-list-item-subtitle v-if="item.attributeClass">
                    {{ item.attributeClass.name }}
                  </v-list-item-subtitle>
                </v-list-item-content>
              </template>
            </v-autocomplete>
            <v-autocomplete
              v-else-if="
                getMapping(header.mapping).type === 'field' &&
                !!getMappingField(header.mapping)
              "
              :items="[getMappingField(header.mapping)]"
              :value="getMappingField(header.mapping)"
              class="my-2"
              :key="header.mapping"
              disabled
              dense
              solo
              hide-details
            >
            </v-autocomplete>
          </template>
          <template
            v-for="header in headers"
            v-slot:[`item.row.${header.mapping}`]="{ item }"
          >
            <div
              :key="header.mapping"
              class="cell-container"
              :class="
                item.columnErrorMessages &&
                item.columnErrorMessages[header.mapping]
                  ? 'red'
                  : ''
              "
            >
              <v-autocomplete
                v-if="
                  editingRows.includes(item) &&
                  isSubcategoryColumn(header.mapping)
                "
                :items="portfolioSubcategories"
                :value="item.row[header.mapping]"
                item-text="name"
                item-value="name"
                solo
                dense
                hide-details
                clearable
                @change="updateAssetAttributeLink(item, header.mapping, $event)"
              ></v-autocomplete>
              <v-autocomplete
                v-else-if="
                  editingRows.includes(item) &&
                  getAttributeFormat(header.mapping) === 'Select'
                "
                :items="getAttributeSelectOptions(header.mapping)"
                :value="item.row[header.mapping]"
                item-text="value"
                item-value="value"
                solo
                dense
                hide-details
                clearable
                @change="updateAssetAttributeLink(item, header.mapping, $event)"
              ></v-autocomplete>
              <v-text-field
                v-else-if="editingRows.includes(item)"
                :value="item.row[header.mapping]"
                solo
                dense
                hide-details
                clearable
                class="my-2"
                @input="updateAssetAttributeLink(item, header.mapping, $event)"
                @keydown="(event) => saveEditingRow(event, item)"
              ></v-text-field>
              <span v-else>{{ item.row[header.mapping] }}</span>
              <div
                v-if="
                  item.columnErrorMessages &&
                  item.columnErrorMessages[header.mapping] &&
                  !editingRows.includes(item)
                "
                class="flex-grow-1"
              ></div>
              <v-tooltip
                v-if="
                  item.columnErrorMessages &&
                  item.columnErrorMessages[header.mapping] &&
                  !editingRows.includes(item)
                "
                bottom
              >
                <template #activator="{ on, attrs }">
                  <v-icon v-bind="attrs" v-on="on">
                    mdi-information-outline
                  </v-icon>
                </template>
                <div>
                  <h6 class="text-h6">
                    {{ $t("pages.assets.import.stepThree.formatInvalid") }}
                  </h6>
                  <p class="text-body-1">
                    {{
                      getCellFormatHelpText(
                        item.columnErrorMessages[header.mapping]
                      )
                    }}
                  </p>
                  <ul
                    v-if="
                      getCellValueExamples(
                        item.columnErrorMessages[header.mapping],
                        getMappingAttribute(header.mapping)
                      )
                    "
                  >
                    <li
                      v-for="format in getCellFormatExamples(
                        item.columnErrorMessages[header.mapping]
                      )"
                      :key="format"
                    >
                      {{ format }}
                    </li>
                    <li>{{ $t("pages.assets.import.stepThree.eg") }}</li>
                    <ul class="help-text-sublist">
                      <li
                        v-for="example in getCellValueExamples(
                          item.columnErrorMessages[header.mapping],
                          getMappingAttribute(header.mapping)
                        )"
                        :key="example"
                      >
                        {{ example }}
                      </li>
                    </ul>
                  </ul>
                </div>
              </v-tooltip>
            </div>
          </template>
          <template #item.action="{ item }">
            <v-row class="actionrow" justify="space-between">
              <v-icon class="ml-2" small @click="toggleAssetEditMode(item)">{{
                editingRows.includes(item) ? "mdi-content-save" : "mdi-pencil"
              }}</v-icon>
              <v-icon class="mr-2" small @click="removeAsset(item)"
                >mdi-trash-can-outline</v-icon
              >
            </v-row>
          </template>
          <template #footer.prepend>
            <v-row justify="space-between" class="ml-0 mr-4 align-center">
              <v-btn text x-small @click="removeAssetRange">
                {{ $t("pages.assets.import.stepThree.deleteSelected") }}
              </v-btn>
              <p
                class="mb-0"
                :class="hasErrors && onlyErroredRows ? 'red--text' : ''"
              >
                {{
                  $t("pages.assets.import.stepThree.assetsAttributes", {
                    assetAmount: filteredAssets.length,
                    attributeAmount: selectedAttributeCount,
                  })
                }}
              </p>
            </v-row>
          </template>
          <template #footer.page-text="pageTextProps">
            {{ $t("common.dataTable.visibleRows", pageTextProps) }}
          </template>
        </v-data-table>
      </div>
    </div>
  </div>
</template>
<script>
import { footerProps } from "@/util/dataTable";
import { fixedAttributes } from "../../../util/fixedAssetData";
import DialogFormHeader from "../../Dialog/DialogFormHeader.vue";

export default {
  props: {
    attributes: Array,
    headerMappings: Object,
    assetRows: Array,
    portfolioSubcategories: Array,
    loading: Boolean,
    isEditing: Boolean,
    hasErrors: Boolean,
    updateOnId: Boolean,
    hideUpdateOnId: Boolean,
    importStatusCode: Number,
  },
  emits: [
    "isEditingUpdated",
    "assetRemoved",
    "assetsRemoved",
    "headerMappingChanged",
    "assetAttributeLinkUpdated",
  ],
  data() {
    return {
      tableOptions: {
        page: 1,
        itemsPerPage: 50,
      },
      tableFooterProps: {
        ...footerProps,
        itemsPerPageOptions: [50, 100, 500, -1],
      },
      selectedRows: [],
      editingRows: [],
      onlyErroredRows: true,
    };
  },
  computed: {
    headers() {
      if (!this.headerMappings) return [];
      return Object.keys(this.headerMappings)
        .filter(
          (header) =>
            (this.updateOnId ||
              this.headerMappings[header].fieldName !== "ID") &&
            this.headerMappings[header].type !== "hidden"
        )
        .map((header) => {
          const splitIndex = header.lastIndexOf("|");
          return {
            name: header.substring(0, splitIndex),
            index: parseInt(header.substring(splitIndex + 1)),
            mapping: header,
          };
        });
    },
    tableHeaders() {
      const headers = [];
      this.headers.forEach((header) => {
        headers.push({
          text: header.name,
          value: `row.${header.mapping}`,
          divider: true,
          width: 300,
        });
      });
      headers.push({
        text: this.$t("pages.assets.import.stepThree.action"),
        value: "action",
        width: 80,
        divider: true,
        align: "center fixed",
        class: "fixed",
      });
      return headers;
    },
    assets() {
      if (!this.assetRows) return [];
      return this.assetRows.map((asset, index) => ({
        ...asset,
        index,
      }));
    },
    filteredAssets() {
      if (!this.hasErrors || !this.onlyErroredRows) return this.assets;
      return this.assets.filter(
        (x) =>
          !!x.errorMessages?.length ||
          (!!x.columnErrorMessages &&
            !!Object.keys(x.columnErrorMessages).length) ||
          this.editingRows.includes(x)
      );
    },
    attributeOptions() {
      return [...this.attributes];
    },
    selectedAttributeCount() {
      return Object.values(this.headerMappings).filter(
        (x) => x?.type === "attribute" && x.attribute?.id
      ).length;
    },
  },
  methods: {
    toggleAssetEditMode(assetRow) {
      if (this.editingRows.indexOf(assetRow) !== -1) {
        this.editingRows = this.editingRows.filter((x) => x !== assetRow);
      } else {
        this.editingRows.push(assetRow);
      }
      const isEditing = !!this.editingRows.length;
      if (isEditing !== this.isEditing) {
        this.$emit("isEditingUpdated", isEditing);
      }
    },
    removeAsset(assetRow) {
      if (!confirm(this.$t("pages.assets.import.stepThree.confirmDelete")))
        return;
      const originalAssetRow = this.assetRows[assetRow.index];
      this.$emit("assetRemoved", originalAssetRow);
      this.editingRows = this.editingRows.filter((x) => x !== assetRow);
    },
    removeAssetRange() {
      if (
        !this.selectedRows.length ||
        !confirm(
          this.$t("pages.assets.import.stepThree.confirmMultiDelete", {
            amount: this.selectedRows.length,
          })
        )
      )
        return;
      const originalAssetRows = [];
      this.selectedRows.forEach((row) => {
        originalAssetRows.push(this.assetRows[row.index]);
      });
      this.$emit("assetsRemoved", originalAssetRows);
      this.editingRows = this.editingRows.filter(
        (x) => !this.selectedRows.includes(x)
      );
      this.selectedRows = [];
    },
    attributeOptionsWithUnknown() {
      const attributes = [
        {
          id: null,
          name: this.$t("pages.assets.import.stepThree.unknownSuffix"),
          unknown: true,
        },
      ].concat(this.sortedAttributeOptions());
      return attributes;
    },
    sortedAttributeOptions() {
      let sortedAttributes = { ...this.attributeOptions };
      let arraySortedAttributes = Object.values(sortedAttributes);
      let result = arraySortedAttributes.sort((a, b) => {
        if (a.attributeClass && b.attributeClass) {
          return (
            b.attributeClass.fixed - a.attributeClass.fixed ||
            a.attributeClass.sortOrder - b.attributeClass.sortOrder ||
            a.attributeClass.name.localeCompare(b.attributeClass.name) ||
            a.name.localeCompare(b.name)
          );
        }
        return a.name.localeCompare(b.name);
      });
      return result;
    },
    handleHeaderMappingChanged(header, attributeId) {
      const attribute =
        this.attributeOptions.find((x) => x.id === attributeId) ?? null;
      this.$emit("headerMappingChanged", header, attribute);
    },
    getAssetRowClass(assetRow) {
      return this.editingRows.includes(assetRow) ? "yellow accent-3" : "";
    },
    getAttributeOptionDisabled(attributeOption) {
      return Object.values(this.headerMappings).some(
        (x) =>
          x?.type === "attribute" &&
          x.attribute?.id === attributeOption.id &&
          (x.attribute?.id ?? null) !== null
      );
    },
    updateAssetAttributeLink(assetRow, header, value) {
      const originalAssetRow = this.assetRows[assetRow.index];
      this.$emit("assetAttributeLinkUpdated", originalAssetRow, header, value);
    },
    saveEditingRow(event, assetRow) {
      if (event.key !== "Enter") return;
      this.toggleAssetEditMode(assetRow);
    },
    getCellFormatHelpText(errorType) {
      switch (errorType) {
        case "INVALID_VALUE_BOOLEAN":
          return this.$t("pages.assets.import.stepThree.invalidValues.boolean");
        case "INVALID_VALUE_CURRENCY":
          return this.$t(
            "pages.assets.import.stepThree.invalidValues.currency"
          );
        case "INVALID_VALUE_DATE":
          return this.$t("pages.assets.import.stepThree.invalidValues.date");
        case "INVALID_VALUE_DATETIME":
          return this.$t(
            "pages.assets.import.stepThree.invalidValues.dateTime"
          );
        case "INVALID_VALUE_NUMBER":
          return this.$t("pages.assets.import.stepThree.invalidValues.number");
        case "INVALID_VALUE_PERCENTAGE":
          return this.$t(
            "pages.assets.import.stepThree.invalidValues.percentage"
          );
        case "INVALID_VALUE_SELECT":
          return this.$t("pages.assets.import.stepThree.invalidValues.select");
        case "INVALID_VALUE_PORTFOLIO_SUBCATEGORY":
          return this.$t(
            "pages.assets.import.stepThree.invalidValues.portfolioSubcategory"
          );
        case "INVALID_VALUE_ID":
          return this.$t("pages.assets.import.stepThree.invalidValues.id");
        case "INACCESSIBLE_ID":
          return this.$t("pages.assets.import.stepThree.accessErrors.id");
        case "NONEXISTANT_ID":
          return this.$t(
            "pages.assets.import.stepThree.accessErrors.idDoesntExist"
          );
        default:
          return this.$t("pages.assets.import.stepThree.invalidValues.unknown");
      }
    },
    getCellFormatExamples(errorType) {
      switch (errorType) {
        case "INVALID_VALUE_DATE":
        case "INVALID_VALUE_DATETIME":
          return [
            "dd.mm.YYYY",
            "dd-mm-YYYY",
            "dd-mm-YYYY HH:ii:ss",
            "dd/mm-YYYY",
            "dd/mm-YY",
            "YYYY.mm.dd",
            "YYYY-mm-dd",
            "YYYY",
            "dd.mm.YYYY HH:ii:ss",
            "dd-mm-YYYY HH:ii:ss ",
            "dd/mm-YYYY HH:ii:ss",
            "dd/mm-YY HH:ii:ss ",
            "YYYY.mm.dd HH:ii:ss ",
            "YYYY-mm-dd HH:ii:ss ",
          ];
        default:
          return [];
      }
    },
    getCellValueExamples(errorType, attribute) {
      switch (errorType) {
        case "INVALID_VALUE_BOOLEAN":
          return [
            this.$t("pages.assets.import.stepThree.invalidExamples.boolean"),
          ];
        case "INVALID_VALUE_CURRENCY":
          return ["1,99"];
        case "INVALID_VALUE_DATE":
        case "INVALID_VALUE_DATETIME":
          return [
            this.$t("pages.assets.import.stepThree.invalidExamples.or", {
              example: "14.03.2012",
            }),
            this.$t("pages.assets.import.stepThree.invalidExamples.or", {
              example: "14-03-2012",
            }),
            this.$t("pages.assets.import.stepThree.invalidExamples.or", {
              example: "14/03-2012",
            }),
            this.$t("pages.assets.import.stepThree.invalidExamples.or", {
              example: "2012.03.14",
            }),
            this.$t("pages.assets.import.stepThree.invalidExamples.or", {
              example: "14.03.2012 12:50:00",
            }),
            this.$t("pages.assets.import.stepThree.invalidExamples.or", {
              example: "14-03-2012 12:50:00",
            }),
            "2012.03.14 12:50:00",
          ];
        case "INVALID_VALUE_ID":
        case "INVALID_VALUE_NUMBER":
          return ["14"];
        case "INVALID_VALUE_PERCENTAGE":
          return [
            this.$t("pages.assets.import.stepThree.invalidExamples.or", {
              example: "14",
            }),
            "14%",
          ];
        case "INVALID_VALUE_SELECT":
          return attribute.attributeSelectOptions.map((x) => x.value);
        case "INVALID_VALUE_PORTFOLIO_SUBCATEGORY":
          return this.portfolioSubcategories.map((x) => x.name);
        default:
          return undefined;
      }
    },
    getMapping(headerMapping) {
      return this.headerMappings[headerMapping];
    },
    getMappingAttribute(headerMapping) {
      const mapping = this.getMapping(headerMapping);
      if (!mapping || mapping.type !== "attribute") return null;
      return mapping.attribute;
    },
    getMappingField(headerMapping) {
      const mapping = this.getMapping(headerMapping);
      if (!mapping || mapping.type !== "field") return null;
      return mapping.fieldName;
    },
    getAttributeFormat(headerMapping) {
      const attribute = this.getMappingAttribute(headerMapping);
      return attribute?.format ?? null;
    },
    getAttributeSelectOptions(headerMapping) {
      const attribute = this.getMappingAttribute(headerMapping);
      return attribute?.attributeSelectOptions ?? [];
    },
    isSubcategoryColumn(headerMapping) {
      const attribute = this.getMappingAttribute(headerMapping);
      return attribute?.id === fixedAttributes.generalPortfolioSubcategoryId;
    },
    redirectToBilling() {
      const url = this.route("settings.index") + "#billing";
      this.$inertia.get(url, { openPayment: true });
    },
  },
  components: { DialogFormHeader },
};
</script>
<style scoped>
.table-container {
  height: 60vh;
}

.table-container.compressed {
  height: 56vh;
}

.cell-container {
  height: 100%;
  width: calc(100% + 32px);
  margin-left: -16px;
  padding: 0 16px;
  display: flex;
  align-items: center;
}

.help-text-sublist {
  list-style-type: circle;
}
</style>
<style>
.v-autocomplete.unknown .v-input__slot {
  background-color: #ffea00 !important;
  border-color: #ffea00 !important;
}

.table-container table thead tr th:nth-child(1),
.table-container table tbody tr td:nth-child(1) {
  border-right: thin solid rgba(0, 0, 0, 0.12);
}

.table-container table tbody tr td:first-child,
.table-container table thead tr th:first-child {
  position: sticky !important;
  position: -webkit-sticky !important;
  left: 0;
  z-index: 102;
  /*background: #e2dee3;*/
  background: #fff;
  -webkit-box-shadow: -1px 0px 3px -1px rgba(0, 0, 0, 0.19);
  -moz-box-shadow: -1px 0px 3px -1px rgba(0, 0, 0, 0.19);
  box-shadow: -1px 0px 3px -1px rgba(0, 0, 0, 0.19);
}
</style>
<style scoped>
.border-bottom {
  border-bottom: 1px solid #e8e8e8;
}

.table-container .v-data-table {
  height: 100%;
}

.table-container .v-data-table.with-upgrade-notice {
  height: calc(100% - 77px);
}
</style>
