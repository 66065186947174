<template>
  <v-autocomplete
    v-model="organizationId"
    :items="combinedOrganizations"
    item-text="name"
    item-value="id"
    :append-icon="mini ? '' : 'mdi-unfold-more-horizontal'"
    filled
    color="#ffffff80"
    background-color="transparent"
    hide-details
    class="organization-menu d-flex flex-row align-center pr-3"
    @update:search-input="(val) => (searchSync = val)"
    :search-input.sync="searchSync"
    auto-select-first
    :deletable-chips="false"
  >
    <template v-slot:selection="{ item }">
      <v-avatar
        color="#ffffff80"
        :size="mini ? 28 : 28"
        class="ml-4"
        style="margin: 0px 15px 0 0px"
      >
        <img :src="orgImage(item)" :alt="item.name" />
      </v-avatar>
      <div
        v-if="searchSync == null || searchSync.length == 0"
        class="block flex-grow-1 py-3"
        style="
          color: #e9e8e8;
          font-size: 14px;
          white-space: nowrap;
          transition: opacity 0.3s linear;
          text-overflow: ellipsis;
          overflow: hidden;
          white-space: nowrap;
        "
        :style="mini ? 'opacity: 0' : 'opacity: 1'"
      >
        {{ item.name }}
      </div>
    </template>
  </v-autocomplete>
</template>
<script>
import { serialize } from "object-to-formdata";
import { EventBus } from "@/eventBus";
export default {
  props: {
    mini: Boolean,
  },
  data() {
    return {
      searchSync: "",
    };
  },
  methods: {
    orgImage(item) {
      if (item.logoFile) {
        return this.route("api.files.content", {
          id: item.logoFile.id,
          filename: item.logoFile.originalName,
        });
      }
      return (
        "https://ui-avatars.com/api/?name=" +
        item.name.charAt(0) +
        "&color=fff&background=F25D3B"
      );
    },
    changeCurrentOrganization(organizationId) {
      fetch(this.route("users.current.organizations.current.update"), {
        method: "PUT",
        body: serialize({ organizationId }),
      }).then(() => {
        this.$inertia.get(
          this.route("dashboard.index"),
          {},
          {
            onSuccess: () => {
              EventBus.$emit("organizationChanged");
            },
          }
        );
      });
    },
  },
  computed: {
    auth() {
      return this.$inertia.page.props.auth;
    },
    organizationId: {
      get() {
        return this.auth.organizationId;
      },
      set(organizationId) {
        if (!organizationId) return;
        this.searchSync = "";
        this.changeCurrentOrganization(organizationId);
      },
    },
    combinedOrganizations() {
      return [...this.auth.organizations, ...this.auth.organizationGroups];
    },
  },
};
</script>
<style scoped>
/*.organization-menu,
.organization-menu:deep(.v-icon),
.organization-menu:deep(input) {
  color: #000 !important;
  caret-color: #000 !important;
}

.organization-menu:deep(.v-input__slot) {
  background: #fff !important;
}*/

.organization-menu {
  z-index: 102;
}

.organization-menu:deep(.v-input__control .v-input__slot::before) {
  border-color: rgba(255, 255, 255, 0.08) !important;
}
.organization-menu:deep(.v-input__control .v-input__slot:hover::before) {
  border-color: rgba(255, 255, 255, 0.08);
}
.organization-menu:deep(.v-input__control .v-input__slot::after) {
  border-color: rgba(255, 255, 255, 0.08) !important;
  background-color: transparent !important;
}
.organization-menu:deep(.v-input__icon--append .v-icon) {
  transform: rotate(0deg) !important;
}
</style>
