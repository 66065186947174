<template>
  <v-tab-item>
    <dialog-form-section-one-col no-bottom-border>
      <div class="fill-height fill-width">
        <v-row class="black--text">
          <v-col cols="12">
            <v-row dense>
              <v-col>
                <dialog-form-text-input
                  :title="$t('pages.portfolios.form.fields.portfolioName')"
                  :value="form.name"
                  name="name"
                  class="rounded-0"
                  type="text"
                  :hide-details="false"
                  :error-messages="form.errors.name"
                  @input="updateForm('name', $event)"
                ></dialog-form-text-input>
              </v-col>
            </v-row>
            <v-row dense>
              <v-col>
                <dialog-form-input-wrapper
                  :title="
                    $t('pages.portfolios.form.fields.portfolioDescription')
                  "
                >
                  <v-textarea
                    solo
                    flat
                    class="rounded-0 elevation-0"
                    name="input-7-4"
                    background-color="#F7F7F7"
                    :value="form.description"
                    :error-messages="form.errors.description"
                    @input="updateForm('description', $event)"
                  ></v-textarea>
                </dialog-form-input-wrapper>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </div>
    </dialog-form-section-one-col>
  </v-tab-item>
</template>
<script>
import DialogFormSectionOneCol from "../../../Components/Dialog/DialogFormSectionOneCol.vue";
import DialogFormInputWrapper from "../../../Components/Dialog/inputs/DialogFormInputWrapper.vue";
import DialogFormTextInput from "../../../Components/Dialog/inputs/DialogFormTextInput.vue";
export default {
  components: {
    DialogFormSectionOneCol,
    DialogFormTextInput,
    DialogFormInputWrapper,
  },
  props: {
    form: Object,
  },
  emits: ["updateForm"],
  methods: {
    updateForm(formKey, value) {
      this.$emit("updateForm", formKey, value);
    },
  },
};
</script>
