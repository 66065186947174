<template>
  <v-tab-item :value="tabValue">
    <v-card flat>
      <v-card-text v-if="hasEnoughDataForAI && !factorIsDefined">
        <v-alert tile prominent type="warning" elevation="0">
          <v-row align="center">
            <v-col class="grow">
              {{ $t("pages.assets.form.emissionNotDefinedCo2") }}
            </v-col>
            <v-col class="shrink">
              <v-btn
                :disabled="runningAI"
                :loading="runningAI"
                elevation="0"
                tile
                @click="runAIOnAsset"
              >
                {{ $t("pages.assets.form.runAI") }}
              </v-btn>
            </v-col>
          </v-row>
        </v-alert>
      </v-card-text>
      <v-card-text
        v-if="false /*factorIsDefined && asset.emissionFactorId === nameData*/"
      >
        <v-alert prominent tile type="warning" elevation="0">
          <v-row align="center">
            <v-col class="grow">
              {{ $t("pages.assets.form.emissionAiDefined") }}
            </v-col>
            <v-col class="shrink">
              <v-btn elevation="0" tile @click="approveEmission">
                {{ $t("pages.assets.form.approve") }}
              </v-btn>
            </v-col>
          </v-row>
        </v-alert>
      </v-card-text>
      <dialog-form-section-two-cols no-bottom-border>
        <template slot="left">
          <div v-if="!isPurchasedAsUsed">
            <asset-attribute-link-input
              :attribute="emissionSourceAttribute"
              :value="emissionSourceData"
              @input="updateData(fixedAttributes.co2EmissionSourceId, $event)"
              @select_changed="handleEmissionSourceChanged"
            />
            <asset-attribute-link-input
              v-if="emissionSourceData"
              :attribute="ghgCategoryAttribute"
              :value="ghgCategoryData"
              :select-options="filteredGhgCategories"
              @input="updateData(ghgCategoryAttribute.id, $event)"
              @select_changed="
                $emit('select_changed', $event, ghgCategoryAttribute)
              "
            />
            <asset-attribute-link-input
              :attribute="methodAttribute"
              :value="methodData"
              @input="updateData(fixedAttributes.co2MethodId, $event)"
              @select_changed="handleMethodChanged"
            />
            <template v-if="!isSupplierSpecificMethod">
              <asset-attribute-link-input
                :attribute="sectorAttribute"
                :value="sectorData"
                :selectOptions="emissionSectors"
                @input="updateData(fixedAttributes.co2SectorId, $event)"
                @select_changed="handleSectorChanged"
                :loading="loadingFields['sectors']"
              />
              <asset-attribute-link-input
                :attribute="categoryAttribute"
                :value="categoryData"
                :selectOptions="emissionCategories"
                @input="updateData(fixedAttributes.co2CategoryId, $event)"
                @select_changed="handleCategoryChanged"
                :loading="loadingFields['categories']"
              />
              <div
                v-if="categoryData === '08db14dc-e325-4653-83fe-48dea5f652cc'"
              >
                <v-radio-group
                  row
                  v-model="electricitySourceData"
                  @change="updateBool($event, electricitySourceAttribute)"
                >
                  <v-radio
                    :label="
                      $t('pages.assets.form.electricitySource.locationBased')
                    "
                    :value="false"
                  ></v-radio>
                  <v-radio
                    :label="
                      $t('pages.assets.form.electricitySource.marketBased')
                    "
                    :value="true"
                  ></v-radio>
                </v-radio-group>
              </div>
              <asset-attribute-link-input
                :attribute="regionAttribute"
                :value="regionData"
                :selectOptions="emissionRegions"
                @input="updateData(fixedAttributes.co2RegionId, $event)"
                @select_changed="handleRegionChanged"
                :loading="loadingFields['regions']"
              />
              <asset-attribute-link-input
                :attribute="nameAttribute"
                :value="nameData"
                :selectOptions="emissionFactors"
                @input="updateData(fixedAttributes.co2NameId, $event)"
                @select_changed="handleNameChanged"
                :loading="loadingFields['emissionFactors']"
              >
                <template #selectItem="{ item }">
                  {{ item.name }} {{ getFactorVersionLabel(item) }}
                </template>
              </asset-attribute-link-input>
            </template>
            <template v-else>
              <asset-attribute-link-input
                :attribute="emissionsAttribute"
                :value="emissionsData"
                @input="updateData(emissionsAttribute.id, $event)"
                @decimal_changed="
                  $emit('decimal_changed', $event, emissionsAttribute)
                "
              />
              <asset-attribute-link-input
                :attribute="documentationAttribute"
                :value="documentationData"
                :secondValue="documentationSecondValueData"
                @input="updateData(documentationAttribute.id, $event)"
                @input_secondValue="
                  updateSecondValueData(documentationAttribute.id, $event)
                "
                @file_changed="
                  $emit('file_changed', $event, documentationAttribute)
                "
                @file_removed="
                  $emit('file_removed', $event, documentationAttribute)
                "
              />
            </template>
          </div>
        </template>
        <template slot="right">
          <div>
            <v-row v-if="chosenByData" dense>
              <v-col cols="5">
                {{ $t("pages.assets.form.fixedLabels.chosenBy") }}:
              </v-col>
              <v-col cols="7">
                {{ chosenByData }}
              </v-col>
            </v-row>
            <v-row v-if="isSpendBasedMethod" dense class="mt-3">
              <v-col cols="5">
                {{ $t("pages.assets.form.fixedLabels.purchasePrice") }}:
              </v-col>
              <v-col cols="7">
                <span v-if="isPurchasedAsUsed" class="blue--text">
                  {{ $t("pages.assets.form.validation.assetPurchasedAsUsed") }}
                </span>
                <span
                  v-else-if="!purchasePrice || !purchasePriceCurrency"
                  class="red--text"
                >
                  {{ $t("pages.assets.form.validation.missingPurchasePrice") }}
                </span>
                <span
                  v-else-if="!validCurrencies.includes(purchasePriceCurrency)"
                  class="red--text"
                >
                  {{
                    $t(
                      "pages.assets.form.validation.wrongPurchasePriceCurrency"
                    )
                  }}
                </span>
                <template v-else>
                  {{ formattedPurchasePrice }}
                </template>
              </v-col>
            </v-row>
            <v-row v-else-if="isAverageDataMethod" dense class="mt-3">
              <v-col cols="5">
                {{
                  $t("pages.assets.form.fixedLabels.quantity", {
                    type: formattedUnitType,
                  })
                }}:
              </v-col>
              <v-col cols="7">
                <span v-if="isPurchasedAsUsed" class="blue--text">
                  {{ $t("pages.assets.form.validation.assetPurchasedAsUsed") }}
                </span>
                <span v-else-if="!assetQuantity" class="red--text">
                  {{ $t("pages.assets.form.validation.missingQuantity") }}
                </span>
                <template v-else>
                  {{ formattedQuantity }}
                </template>
              </v-col>
            </v-row>
            <template v-if="!isSupplierSpecificMethod">
              <v-row dense class="mt-4">
                <v-col cols="5">
                  {{
                    $t("pages.assets.form.fixedLabels.emissionFactor", {
                      currencyType: emissionFactor
                        ? emissionFactor.activityUnit
                        : "?",
                    })
                  }}:
                </v-col>
                <v-col cols="7">
                  <span v-if="isPurchasedAsUsed"> </span>
                  <span v-else-if="!emissionFactor" class="red--text">
                    {{
                      $t("pages.assets.form.validation.missingEmissionFactor")
                    }}
                  </span>
                  <span v-else>
                    {{ formattedEmissionFactor }}
                  </span>
                </v-col>
              </v-row>
              <v-row dense class="mt-4">
                <v-col cols="5">
                  {{ $t("pages.assets.form.fixedLabels.factorSource") }}:
                </v-col>
                <v-col cols="7">
                  <a
                    v-if="emissionFactor"
                    :href="emissionFactor?.sourceLink"
                    target="_blank"
                  >
                    {{
                      isCustomEmissionFactor
                        ? $t(
                            "pages.assets.form.fixedLabels.customEmissionFactor"
                          )
                        : emissionFactor.source
                    }}
                  </a>
                </v-col>
              </v-row>
              <v-row dense class="mt-4">
                <v-col cols="5">
                  {{ $t("pages.assets.form.fixedLabels.uuid") }}:
                </v-col>
                <v-col cols="7">
                  <span v-if="emissionFactor">
                    {{ emissionFactor.id }}
                  </span>
                </v-col>
              </v-row>
            </template>
            <v-row dense class="mt-4">
              <v-col cols="5">
                {{ $t("pages.assets.form.fixedLabels.emissions") }}:
              </v-col>
              <v-col cols="7">
                <span
                  v-if="isAverageDataMethod && !activityUnitMatch"
                  class="red--text"
                >
                  {{
                    $t("pages.assets.form.validation.activityTypeMismatch", {
                      activityUnit: activityUnit,
                      unitType: formattedUnitType,
                    })
                  }}
                </span>
                <span
                  v-else-if="isSpendBasedMethod && !purchasePriceCurrencyMatch"
                  class="red--text"
                >
                  {{
                    $t("pages.assets.form.validation.currencyMismatch", {
                      activityUnit: activityUnit,
                      currency: purchasePriceCurrency,
                    })
                  }}
                </span>
                <span v-else-if="isSupplierSpecificMethod">
                  {{ formattedEmissionsData }}
                </span>
                <span v-else>
                  {{ calculatedEmissions }}
                </span>
              </v-col>
            </v-row>
            <v-row dense class="mt-4" v-if="isSupplierSpecificMethod">
              <v-col cols="4" class="d-flex align-center">
                <span>
                  {{ $t("pages.assets.form.fixedLabels.documentation") }}:
                </span>
              </v-col>
              <v-col cols="8">
                <v-chip
                  v-if="documentationUploadedFile"
                  :href="
                    route('api.files.content', {
                      id: documentationUploadedFile.id,
                      filename: documentationUploadedFile.originalName,
                    })
                  "
                  target="_blank"
                  color="primary"
                  label
                  outlined
                >
                  <v-icon>mdi-attachment</v-icon>
                  {{ documentationUploadedFile.originalName }}
                </v-chip>
              </v-col>
            </v-row>
            <v-row
              dense
              class="mt-4"
              v-if="!isSupplierSpecificMethod && emissionFactor"
            >
              <v-col cols="5">
                {{ $t("pages.assets.form.fixedLabels.factorCalculationType") }}
              </v-col>
              <v-col cols="7">
                {{
                  isCustomEmissionFactor
                    ? $t("pages.assets.form.fixedLabels.customEmissionFactor")
                    : selectedFactor
                }}
              </v-col>
            </v-row>
            <v-row
              dense
              class="mt-4"
              v-if="isCustomEmissionFactor && emissionFactor?.description"
            >
              <v-col cols="12">
                {{ $t("pages.assets.form.fixedLabels.info") }}
              </v-col>
              <v-col cols="12">{{ emissionFactor?.description }}</v-col>
            </v-row>
            <v-row
              dense
              class="mt-4"
              v-if="isCustomEmissionFactor && emissionFactor?.documentationFile"
            >
              <v-col cols="12">
                {{ $t("pages.assets.form.fixedLabels.documentation") }}
              </v-col>
              <v-col cols="12">
                <div
                  class="documentation-item d-flex flex-row align-center pl-4 mb-2"
                >
                  <PdfIcon></PdfIcon>
                  <v-btn
                    class="documentation-btn flex-grow-1 mb-0 ml-0 px-2 justify-start text-none font-weight-regular"
                    :href="
                      route('api.files.content', {
                        id: emissionFactor.documentationFile.id,
                        filename: emissionFactor.documentationFile.originalName,
                      })
                    "
                    target="_blank"
                    left
                    plain
                    >{{ emissionFactor.documentationFile.originalName }}</v-btn
                  >
                </div>
              </v-col>
            </v-row>
          </div>
        </template>
      </dialog-form-section-two-cols>
    </v-card>
  </v-tab-item>
</template>
<script>
import AssetAttributeLinkInput from "../../../Components/Attribute/AttributeLinkInput.vue";
import {
  fixedAttributes,
  fixedFieldAttributes,
  emissionCurrencies,
  emissionConvertibleCurrencies,
  co2MethodOptions,
  emissionFactorStatus,
} from "../../../util/fixedAssetData";
import { fullNumberFormatter } from "../../../util/formatters";
import {
  calculateEmissionsSpendBased,
  calculateEmissionsAverageData,
} from "../../../util/calculators";
import DialogFormSectionTwoCols from "../../../Components/Dialog/DialogFormSectionTwoCols.vue";
import PdfIcon from "../../../assets/svg/pdf.svg";

export default {
  components: { AssetAttributeLinkInput, DialogFormSectionTwoCols, PdfIcon },
  props: {
    asset: Object,
    assetAttributeLinkData: Object,
    attributes: Array,
    emissionFactor: Object,
    defaultCurrencyCode: String,
    tabValue: [String, Number],
    resetEmissionFactor: Boolean,
    autoApplyAssetAttributeLinks: Object,
  },
  data() {
    return {
      fixedAttributes,
      emissionSectors: [],
      emissionCategories: [],
      emissionRegions: [],
      emissionFactors: [],
      validCurrencies: emissionCurrencies,
      runningAI: false,
      loadingFields: {
        sectors: false,
        categories: false,
        regions: false,
        emissionFactors: false,
      },
    };
  },
  methods: {
    async runAIOnAsset() {
      this.runningAI = true;

      // Save asset first so fields on general aren't cleared.
      this.$emit("onRunAi", () => {
        this.runningAI = false;
      });
    },
    updateData(attributeId, value) {
      this.$emit("asset_attribute_data_changed", attributeId, value);
    },
    updateSecondValueData(attributeId, value) {
      this.$emit(
        "asset_attribute_data_second_value_changed",
        attributeId,
        value
      );
    },
    updateBool(value, attribute) {
      this.$emit("bool_changed", value, attribute);
    },
    handleMethodChanged(value) {
      this.$emit("select_changed", value, this.methodAttribute);

      if (this.sectorData) {
        this.$emit("string_changed", null, this.sectorAttribute);
        this.updateData(fixedAttributes.co2SectorId, null);
      }

      if (this.categoryData) {
        this.$emit("string_changed", null, this.categoryAttribute);
        this.updateData(fixedAttributes.co2CategoryId, null);
      }

      if (this.electricitySourceData) {
        this.$emit("bool_changed", null, this.electricitySourceAttribute);
        this.updateData(fixedAttributes.co2ElectricitySourceId, null);
      }

      if (this.regionData) {
        this.$emit("string_changed", null, this.regionAttribute);
        this.updateData(fixedAttributes.co2RegionId, null);
      }

      if (this.nameData) {
        this.$emit("string_changed", null, this.nameAttribute);
        this.updateData(fixedAttributes.co2NameId, null);
        this.$emit("emission_factor_changed", null);
      }

      if (this.emissionCategories.length) this.emissionCategories = [];
      if (this.emissionRegions.length) this.emissionRegions = [];
      if (this.emissionFactors.length) this.emissionFactors = [];
    },
    handleSectorChanged(value) {
      this.$emit("string_changed", value, this.sectorAttribute);

      if (this.categoryData) {
        this.$emit("string_changed", null, this.categoryAttribute);
        this.updateData(fixedAttributes.co2CategoryId, null);
      }

      if (this.electricitySourceData) {
        this.$emit("bool_changed", null, this.electricitySourceAttribute);
        this.updateData(fixedAttributes.co2ElectricitySourceId, null);
      }

      if (this.regionData) {
        this.$emit("string_changed", null, this.regionAttribute);
        this.updateData(fixedAttributes.co2RegionId, null);
      }

      if (this.nameData) {
        this.$emit("string_changed", null, this.nameAttribute);
        this.updateData(fixedAttributes.co2NameId, null);
        this.$emit("emission_factor_changed", null);
      }

      if (this.emissionRegions.length) this.emissionRegions = [];
      if (this.emissionFactors.length) this.emissionFactors = [];
    },
    handleCategoryChanged(value) {
      this.$emit("string_changed", value, this.categoryAttribute);

      if (this.electricitySourceData) {
        this.$emit("bool_changed", null, this.electricitySourceAttribute);
        this.updateData(fixedAttributes.co2ElectricitySourceId, null);
      }

      if (this.regionData) {
        this.$emit("string_changed", null, this.regionAttribute);
        this.updateData(fixedAttributes.co2RegionId, null);
      }

      if (this.nameData) {
        this.$emit("string_changed", null, this.nameAttribute);
        this.updateData(fixedAttributes.co2NameId, null);
        this.$emit("emission_factor_changed", null);
      }

      if (this.emissionFactors.length) this.emissionFactors = [];
    },
    handleElectricitySourceChanged(value) {
      this.$emit("bool_changed", value, this.electricitySourceAttribute);
    },
    handleRegionChanged(value) {
      this.$emit("string_changed", value, this.regionAttribute);

      if (this.nameData) {
        this.$emit("string_changed", null, this.nameAttribute);
        this.updateData(fixedAttributes.co2NameId, null);
        this.$emit("emission_factor_changed", null);
      }
    },
    handleNameChanged(value) {
      this.$emit("string_changed", value, this.nameAttribute);

      const emissionFactor =
        this.emissionFactors.find((x) => x.id === value) ?? null;

      this.$emit("emission_factor_changed", emissionFactor);
    },
    handleEmissionSourceChanged(value) {
      this.$emit("select_changed", value, this.emissionSourceAttribute);

      if (this.ghgCategoryData) {
        this.$emit("select_changed", null, this.ghgCategoryAttribute);
        this.updateData(this.ghgCategoryAttribute.id, null);
      }
    },
    getEmissionSectors() {
      this.loadingFields["sectors"] = true;
      fetch(
        this.route("api.emission-sectors.index", {
          spendBased: this.isSpendBasedMethod,
          unitType: this.formattedUnitType,
        })
      )
        .then((res) => res.json())
        .then((data) => {
          this.emissionSectors = data.map((x) => ({
            ...x,
            value: x.name,
          }));
        })
        .finally(() => {
          this.loadingFields["sectors"] = false;
        });
    },
    getEmissionCategories(sectorId) {
      this.loadingFields["categories"] = true;
      fetch(
        this.route("api.emission-categories.index", {
          emissionSectorId: sectorId,
          spendBased: this.isSpendBasedMethod,
          unitType: this.formattedUnitType,
        })
      )
        .then((res) => res.json())
        .then((data) => {
          this.emissionCategories = data.map((x) => ({
            ...x,
            value: x.name,
          }));
        })
        .finally(() => {
          this.loadingFields["categories"] = false;
        });
    },
    getEmissionRegions(categoryId) {
      this.loadingFields["regions"] = true;
      fetch(
        this.route("api.emission-regions.index", {
          emissionCategoryId: categoryId,
          spendBased: this.isSpendBasedMethod,
          unitType: this.formattedUnitType,
        })
      )
        .then((res) => res.json())
        .then((data) => {
          this.emissionRegions = data.map((x) => ({
            ...x,
            value: x.countryName ? x.countryName : x.name,
          }));
        })
        .finally(() => {
          this.loadingFields["regions"] = false;
        });
    },
    getEmissionFactors(sectorId, categoryId, regionId) {
      if (!sectorId || !categoryId || !regionId) return;

      this.loadingFields["emissionFactors"] = true;
      fetch(
        this.route("api.emission-factors.index", {
          emissionSectorId: sectorId,
          emissionCategoryId: categoryId,
          emissionRegionId: regionId,
          emissionFactorId: this.emissionFactor?.id ?? null,
          spendBased: this.isSpendBasedMethod,
          unitType: this.formattedUnitType,
        })
      )
        .then((res) => res.json())
        .then((data) => {
          this.emissionFactors = data.map((x) => ({
            ...x,
            value: x.organizationId ? x.name + " (Local)" : x.name,
          }));
        })
        .finally(() => {
          this.loadingFields["emissionFactors"] = false;
        });
    },
    approveEmission() {
      if (!this.asset?.id) return;

      this.$inertia.patch(
        this.route("assets.emission-factor.approve", this.asset.id),
        {},
        {
          onSuccess: () => this.$emit("reload"),
        }
      );
    },
    getFactorVersionLabel(factor) {
      const { activityId, yearReleased, yearsCalculatedFrom } =
        this.emissionFactor ?? {};

      if (factor.activityId !== activityId) return "";

      const year = yearsCalculatedFrom ?? yearReleased;
      const factorYear = factor.yearsCalculatedFrom ?? factor.yearReleased;

      const labelText =
        factorYear > year
          ? this.$t("common.statuses.updated")
          : this.$t("common.statuses.outdated");

      return `(${labelText})`;
    },
  },
  computed: {
    isCustomEmissionFactor() {
      return (
        this.emissionFactor?.source == null &&
        this.emissionFactor?.organizationId !== null
      );
    },
    hasEnoughDataForAI() {
      return (
        typeof this.calculatedEmissions === "undefined" &&
        this.assetQuantity &&
        this.purchasePrice &&
        this.purchasePriceCurrency &&
        this.validCurrencies.includes(this.purchasePriceCurrency) &&
        this.formattedUnitType !== "unknown"
      );
    },
    emissionSourceData() {
      return this.assetAttributeLinkData[fixedAttributes.co2EmissionSourceId];
    },
    emissionSourceAttribute() {
      return this.attributes.find(
        (x) => x.id === fixedAttributes.co2EmissionSourceId
      );
    },
    ghgCategoryData() {
      return this.assetAttributeLinkData[fixedAttributes.co2GhgCategoryId];
    },
    ghgCategoryAttribute() {
      return this.attributes.find(
        (x) => x.id === fixedAttributes.co2GhgCategoryId
      );
    },
    methodData() {
      return this.assetAttributeLinkData[fixedAttributes.co2MethodId];
    },
    methodAttribute() {
      return this.attributes.find((x) => x.id === fixedAttributes.co2MethodId);
    },
    sectorData() {
      return this.assetAttributeLinkData[fixedAttributes.co2SectorId];
    },
    sectorAttribute() {
      return this.attributes.find((x) => x.id === fixedAttributes.co2SectorId);
    },
    categoryData() {
      return this.assetAttributeLinkData[fixedAttributes.co2CategoryId];
    },
    electricitySourceData: {
      get() {
        var result =
          this.assetAttributeLinkData[fixedAttributes.co2ElectricitySourceId];
        if (
          this.categoryData !== null &&
          this.categoryData === "08db14dc-e325-4653-83fe-48dea5f652cc" &&
          result === null
        ) {
          this.updateBool(false, this.electricitySourceAttribute);
          return false;
        }

        return result;
      },
      set() {},
    },
    categoryAttribute() {
      return this.attributes.find(
        (x) => x.id === fixedAttributes.co2CategoryId
      );
    },
    electricitySourceAttribute() {
      return this.attributes.find(
        (x) => x.id === fixedAttributes.co2ElectricitySourceId
      );
    },
    regionData() {
      // Moved from if statement below to here to fix VER-509
      this.getEmissionFactors(
        this.sectorData,
        this.categoryData,
        this.$page.props.settings["defaultRegionId"]
      );

      // if null default to settings region id
      if (
        this.assetAttributeLinkData[fixedAttributes.co2RegionId] === null ||
        this.assetAttributeLinkData[fixedAttributes.co2RegionId] === ""
      ) {
        // When region data is automatically set to default region emission factors are not updated correctly.
        // Added getEmissionFactors to force an update (ref VER-333)

        return this.$page.props.settings["defaultRegionId"];
      }

      return this.assetAttributeLinkData[fixedAttributes.co2RegionId];
    },
    regionAttribute() {
      return this.attributes.find((x) => x.id === fixedAttributes.co2RegionId);
    },
    nameData() {
      return this.assetAttributeLinkData[fixedAttributes.co2NameId];
    },
    nameAttribute() {
      return this.attributes.find((x) => x.id === fixedAttributes.co2NameId);
    },
    purchasePrice() {
      return this.assetAttributeLinkData[
        fixedAttributes.generalPurchasePriceId
      ];
    },
    purchasePriceCurrency() {
      const currency =
        this.assetAttributeLinkData[
          fixedAttributes.generalPurchasePriceId + "-secondValue"
        ];

      return currency?.trim() ?? this.defaultCurrencyCode;
    },
    unitType() {
      return this.assetAttributeLinkData[fixedAttributes.generalUnitTypeId];
    },
    unitTypeAttribute() {
      return this.attributes.find(
        (x) => x.id === fixedAttributes.generalUnitTypeId
      );
    },
    formattedUnitType() {
      const defaultValue = this.$t(
        "pages.assets.form.fixedLabels.unknownUnitType"
      );

      if (!this.unitType || !this.unitTypeAttribute) return defaultValue;

      const selectedType = this.unitTypeAttribute.attributeSelectOptions.find(
        (x) => x.id === this.unitType
      )?.value;

      return selectedType ?? defaultValue;
    },
    emissionsAttribute() {
      return this.attributes.find(
        (x) => x.id === fixedAttributes.co2EmissionsId
      );
    },
    emissionsData() {
      return this.assetAttributeLinkData[fixedAttributes.co2EmissionsId];
    },
    documentationAttribute() {
      return this.attributes.find(
        (x) => x.id === fixedAttributes.co2DocumentationId
      );
    },
    documentationData() {
      return this.assetAttributeLinkData[fixedAttributes.co2DocumentationId];
    },
    documentationSecondValueData() {
      return this.assetAttributeLinkData[
        fixedAttributes.co2DocumentationId + "-secondValue"
      ];
    },
    documentationUploadedFile() {
      if (!this.documentationSecondValueData?.length) return null;

      return this.documentationSecondValueData[0];
    },
    factorTypeAttribute() {
      return this.attributes.find(
        (x) => x.id === fixedFieldAttributes.co2FactorTypeId
      );
    },
    assetQuantity() {
      return this.assetAttributeLinkData[fixedAttributes.generalQuantityId];
    },
    formattedPurchasePrice() {
      if (!this.purchasePrice || !this.purchasePriceCurrency) return null;

      return `${this.purchasePriceCurrency} ${fullNumberFormatter.format(
        this.purchasePrice
      )}`;
    },
    formattedEmissionsData() {
      if (!this.emissionsData) return null;

      return fullNumberFormatter.format(this.emissionsData);
    },
    formattedQuantity() {
      if (!this.assetQuantity) return null;

      return fullNumberFormatter.format(this.assetQuantity);
    },
    formattedEmissionFactor() {
      if (!this.emissionFactor) return "";

      const factor = this.emissionFactor.kgCo2eAr5
        ? this.emissionFactor.kgCo2eAr5
        : this.emissionFactor.kgCo2eAr4;

      return fullNumberFormatter.format(factor);
    },
    calculatedEmissions() {
      if (this.isPurchasedAsUsed) {
        return "0";
      }

      if (this.isSpendBasedMethod) {
        return calculateEmissionsSpendBased(
          this.purchasePrice,
          this.purchasePriceCurrency,
          this.emissionFactor
        );
      } else if (this.isAverageDataMethod && this.activityUnitMatch) {
        return calculateEmissionsAverageData(
          this.assetQuantity,
          this.formattedUnitType,
          this.emissionFactor
        );
      }
      return undefined;
    },
    selectedFactor() {
      if (!this.emissionFactor) return;

      if (this.isSpendBasedMethod && !this.purchasePrice) return;

      if (
        this.isAverageDataMethod &&
        (!this.assetQuantity || !this.activityUnitMatch)
      )
        return;

      return this.emissionFactor.kgCo2eAr5 ? "AR 5" : "AR 4";
    },
    isAverageDataMethod() {
      return this.methodData === co2MethodOptions.averageDataMethodId;
    },
    isSpendBasedMethod() {
      return this.methodData === co2MethodOptions.spendBasedMethodId;
    },
    isSupplierSpecificMethod() {
      return this.methodData === co2MethodOptions.supplierSpecificMethodId;
    },
    activityUnit() {
      if (!this.emissionFactor)
        return this.$t("pages.assets.form.fixedLabels.unknownUnitType");

      return this.emissionFactor.activityUnit;
    },
    activityUnitMatch() {
      return this.activityUnit === this.formattedUnitType;
    },
    purchasePriceCurrencyMatch() {
      const priceCurrency = this.purchasePriceCurrency;
      const activityCurrency = this.activityUnit;

      // We don't handle missing values in this computed value
      if (!priceCurrency || !activityCurrency) return true;
      else if (priceCurrency === activityCurrency) return true;
      else if (
        emissionConvertibleCurrencies.includes(priceCurrency) &&
        emissionConvertibleCurrencies.includes(activityCurrency)
      )
        return true;
      else return false;
    },
    factorStatusData() {
      return this.assetAttributeLinkData[
        fixedFieldAttributes.co2EmissionFactorStatusId
      ];
    },
    factorIsDefined() {
      return this.factorStatusData === emissionFactorStatus.defined;
    },
    isPurchasedAsUsed() {
      let isPurchasedAsUsed =
        this.assetAttributeLinkData[fixedAttributes.generalProductionDateId];

      if (isPurchasedAsUsed) {
        this.$emit("emission_factor_changed", null);
        this.$emit("decimal_changed", 0, this.emissionsAttribute);
      }

      return isPurchasedAsUsed;
    },
    filteredGhgCategories() {
      if (!this.emissionSourceData) return [];

      const categories = this.ghgCategoryAttribute.attributeSelectOptions
        .filter((x) => x.dependentOptionId === this.emissionSourceData)
        .sort((a, b) => ((a.sortOrder ?? 0) > (b.sortOrder ?? 0) ? 1 : -1));

      return categories;
    },
    chosenByData() {
      return this.assetAttributeLinkData[fixedFieldAttributes.co2ChosenById];
    },
  },
  mounted() {
    this.getEmissionSectors();

    if (this.sectorData) {
      this.getEmissionCategories(this.sectorData);
    }

    if (this.categoryData) {
      this.getEmissionRegions(this.categoryData);
    }

    if (this.sectorData && this.categoryData && this.regionData) {
      this.getEmissionFactors(
        this.sectorData,
        this.categoryData,
        this.regionData
      );
    }

    // Copilot auto apply attribute links
    if (this.autoApplyAssetAttributeLinks) {
      this.updateData(
        fixedAttributes.co2SectorId,
        this.autoApplyAssetAttributeLinks[fixedAttributes.co2SectorId]
      );
      this.updateData(
        fixedAttributes.co2CategoryId,
        this.autoApplyAssetAttributeLinks[fixedAttributes.co2CategoryId]
      );
      this.updateData(
        fixedAttributes.co2RegionId,
        this.autoApplyAssetAttributeLinks[fixedAttributes.co2RegionId]
      );
      this.updateData(
        fixedAttributes.co2NameId,
        this.autoApplyAssetAttributeLinks[fixedAttributes.co2NameId]
      );
      setTimeout(() => {
        this.handleNameChanged(
          this.autoApplyAssetAttributeLinks[fixedAttributes.co2NameId]
        );
      }, 4000);
    }
  },
  watch: {
    methodData() {
      this.getEmissionSectors();
    },
    sectorData(value) {
      this.getEmissionCategories(value);
    },
    categoryData(value) {
      this.getEmissionRegions(value);
    },
    regionData(value) {
      if (!this.sectorData || !this.categoryData) return;
      this.getEmissionFactors(this.sectorData, this.categoryData, value);
    },
    resetEmissionFactor(value) {
      if (!value) return;

      if (!this.isAverageDataMethod) {
        this.$emit("emissionFactorReset");
        return;
      }

      if (this.sectorData) {
        this.$emit("string_changed", null, this.sectorAttribute);
        this.updateData(fixedAttributes.co2SectorId, null);
      }

      if (this.categoryData) {
        this.$emit("string_changed", null, this.categoryAttribute);
        this.updateData(fixedAttributes.co2CategoryId, null);
      }

      if (this.electricitySourceData) {
        this.$emit("bool_changed", null, this.electricitySourceAttribute);
        this.updateData(fixedAttributes.co2ElectricitySourceId, null);
      }

      if (this.regionData) {
        this.$emit("string_changed", null, this.regionAttribute);
        this.updateData(fixedAttributes.co2RegionId, null);
      }

      if (this.nameData) {
        this.$emit("string_changed", null, this.nameAttribute);
        this.updateData(fixedAttributes.co2NameId, null);
        this.$emit("emission_factor_changed", null);
      }

      if (this.emissionCategories.length) this.emissionCategories = [];
      if (this.emissionRegions.length) this.emissionRegions = [];
      if (this.emissionFactors.length) this.emissionFactors = [];

      this.getEmissionSectors();

      this.$emit("emissionFactorReset");
    },
  },
};
</script>

<style lang="scss" scoped>
.documentation-item {
  background: #fef2ef;
  border: 1px solid #fef2ef;
  .documentation-btn {
    color: #686868;
    flex: 1;
    overflow: hidden;
    letter-spacing: 0;
  }
  &:hover {
    border-color: #f25d3b;
    .documentation-btn * {
      color: #f25d3b;
    }
  }
}
</style>
