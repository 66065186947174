<template>
  <v-tab-item>
    <v-card flat>
      <v-card-text>
        <v-row>
          <v-col cols="6">
            <attribute-link-input
              :attribute="nameAttribute"
              :value="solutionName"
              :customLabel="$t('pages.solutions.form.fixedLabels.solutionName')"
              @string_changed="$emit('updateForm', 'name', $event)"
            />
            <attribute-link-input
              :attribute="locationAttribute"
              :selectOptions="locations"
              selectItemText="name"
              :value="locationId"
              :customLabel="$t('pages.solutions.form.fixedLabels.location')"
              @select_changed="$emit('updateForm', 'locationId', $event)"
            />
            <attribute-link-input
              v-if="!isTemplatePage"
              :attribute="templateAttribute"
              :selectOptions="solutionTemplates"
              selectItemText="name"
              :value="solutionTemplateId"
              :customLabel="$t('pages.solutions.form.fixedLabels.template')"
              @select_changed="
                $emit('updateForm', 'solutionTemplateId', $event)
              "
            />
            <attribute-link-input
              :attribute="quantityAttribute"
              :numberMinValue="1"
              :value="quantity"
              :customLabel="$t('pages.solutions.form.fixedLabels.quantity')"
              @decimal_changed="$emit('updateForm', 'quantity', $event)"
            />
          </v-col>
          <v-col cols="5" offset="1">
            <v-row dense>
              <v-col cols="5">
                {{ $t("pages.solutions.form.fixedLabels.createdBy") }}:
              </v-col>
              <v-col cols="7">
                {{ createdBy }}
              </v-col>
            </v-row>
            <v-row dense class="mt-3">
              <v-col cols="5">
                {{ $t("pages.solutions.form.fixedLabels.updatedBy") }}:
              </v-col>
              <v-col cols="7">
                {{ updatedBy }}
              </v-col>
            </v-row>
            <v-row dense class="mt-3">
              <v-col cols="5">
                {{ $t("pages.solutions.form.fixedLabels.created") }}:
              </v-col>
              <v-col cols="7">
                {{ createdAt }}
              </v-col>
            </v-row>
            <v-row dense class="mt-3">
              <v-col cols="5">
                {{ $t("pages.solutions.form.fixedLabels.updated") }}:
              </v-col>
              <v-col cols="7">
                {{ updatedAt }}
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
  </v-tab-item>
</template>
<script>
import AttributeLinkInput from "../../../Components/Attribute/AttributeLinkInput.vue";
import { fixedAttributes } from "../../../util/fixedSolutionData";
import { normalizeISOString } from "../../../util/dateTime";
import { DateTime } from "luxon";

export default {
  components: { AttributeLinkInput },
  props: {
    solution: Object,
    solutionName: String,
    locationId: String,
    solutionTemplateId: String,
    quantity: Number,
    locations: Array,
    attributes: Array,
    solutionTemplates: Array,
    isTemplatePage: Boolean,
  },
  emits: ["updateForm"],
  computed: {
    nameAttribute() {
      return this.getAttribute(fixedAttributes.generalNameId);
    },
    locationAttribute() {
      return this.getAttribute(fixedAttributes.generalLocationId);
    },
    templateAttribute() {
      return this.getAttribute(fixedAttributes.generalTemplateId);
    },
    quantityAttribute() {
      return this.getAttribute(fixedAttributes.generalQuantityId);
    },
    createdBy() {
      return this.solution?.createdBy?.name ?? "";
    },
    updatedBy() {
      return this.solution?.updatedBy?.name ?? "";
    },
    createdAt() {
      return this.formatDate(this.solution?.createdAt);
    },
    updatedAt() {
      return this.formatDate(this.solution?.updatedAt);
    },
  },
  methods: {
    getAttribute(attributeId) {
      return this.attributes?.find((x) => x.id === attributeId) ?? null;
    },
    formatDate(date) {
      if (!date) return "";

      const normalizedDate = normalizeISOString(date);

      return DateTime.fromISO(normalizedDate).toFormat("yyyy-MM-dd");
    },
  },
};
</script>
