import dashboard from "./dashboard.json";
import assetIndex from "./assets/index.json";
import assetForm from "./assets/form.json";
import assetAttributes from "./assets/attributes.json";
import assetExport from "./assets/export.json";
import assetFilters from "./assets/filters.json";
import assetImport from "./assets/import.json";
import assetLayoutOptions from "./assets/layoutOptions.json";
import attributeIndex from "./attributes/index.json";
import attributeForm from "./attributes/form.json";
import attributeClassIndex from "./attributeClasses/index.json";
import attributeClassForm from "./attributeClasses/form.json";
import login from "./login.json";
import forgotPassword from "./forgotPassword.json";
import resetPassword from "./resetPassword.json";
import documentRequest from "./documentRequest.json";
import featureRequest from "./featureRequest.json";
import fileCreate from "./files/create.json";
import fileEdit from "./files/edit.json";
import fileIndex from "./files/index.json";
import dataTypeCreate from "./dataTypes/create.json";
import dataTypeEdit from "./dataTypes/edit.json";
import dataTypeIndex from "./dataTypes/index.json";
import locationForm from "./locations/form.json";
import locationImport from "./locations/import.json";
import locationIndex from "./locations/index.json";
import locationOldImport from "./locations/oldImport.json";
import locationTypeForm from "./locationTypes/form.json";
import locationTypeIndex from "./locationTypes/index.json";
import logIndex from "./logs/index.json";
import organizationForm from "./organizations/form.json";
import organizationIndex from "./organizations/index.json";
import permissions from "./permissions.json";
import portfolioForm from "./portfolios/form.json";
import portfolioIndex from "./portfolios/index.json";
import profile from "./profile.json";
import roleForm from "./roles/form.json";
import roleIndex from "./roles/index.json";
import settingsIndex from "./settings/index.json";
import solutionForm from "./solutions/form.json";
import solutionIndex from "./solutions/index.json";
import solutionTypeForm from "./solutionTypes/form.json";
import solutionTypeIndex from "./solutionTypes/index.json";
import userForm from "./users/form.json";
import userIndex from "./users/index.json";
import reportsIndex from "./reports/index.json";
import reportsCarbonFootprint from "./reports/carbonFootprint.json";

export default {
  dashboard,
  assets: {
    index: assetIndex,
    form: assetForm,
    attributes: assetAttributes,
    export: assetExport,
    filters: assetFilters,
    import: assetImport,
    layoutOptions: assetLayoutOptions,
  },
  attributes: {
    index: attributeIndex,
    form: attributeForm,
  },
  attributeClasses: {
    index: attributeClassIndex,
    form: attributeClassForm,
  },
  login,
  forgotPassword,
  resetPassword,
  documentRequest,
  featureRequest,
  files: {
    create: fileCreate,
    edit: fileEdit,
    index: fileIndex,
  },
  dataTypes: {
    create: dataTypeCreate,
    edit: dataTypeEdit,
    index: dataTypeIndex,
  },
  locations: {
    form: locationForm,
    import: locationImport,
    index: locationIndex,
    oldImport: locationOldImport,
  },
  locationTypes: {
    form: locationTypeForm,
    index: locationTypeIndex,
  },
  logs: {
    index: logIndex,
  },
  organizations: {
    form: organizationForm,
    index: organizationIndex,
  },
  permissions,
  portfolios: {
    form: portfolioForm,
    index: portfolioIndex,
  },
  profile,
  roles: {
    form: roleForm,
    index: roleIndex,
  },
  settings: {
    index: settingsIndex,
  },
  solutions: {
    form: solutionForm,
    index: solutionIndex,
  },
  solutionTypes: {
    form: solutionTypeForm,
    index: solutionTypeIndex,
  },
  users: {
    form: userForm,
    index: userIndex,
  },
  reports: {
    index: reportsIndex,
    carbonFootprint: reportsCarbonFootprint,
  }
};
