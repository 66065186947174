<template>
  <dialog-form :show-dialog="show">
    <asset-invoice-import-step-one
      v-if="selectedImportStep === 1"
      :selectedImportFiles="selectedImportFiles"
      @errorChanged="handleStepOneError"
      @invalidFormatChanged="(value) => (invalidImportFileFormat = value)"
      @filesChanged="handleImportFilesChanged"
    />
    <asset-import-step-two
      v-if="selectedImportStep === 2"
      :portfolios="portfolios"
      :selectedPortfolioId="form.portfolioId"
      @portfolioIdChanged="handlePortfolioIdChanged"
      :newPortfolioName="form.portfolioName"
      @newPortfolioNameChanged="handleNewPortfolioNameChanged"
    />
    <asset-import-step-three
      v-if="selectedImportStep === 3"
      :attributes="attributes"
      :headerMappings="headerMappings"
      :assetRows="form.assetRows"
      :portfolioSubcategories="portfolioSubcategories"
      :loading="tableIsLoading"
      :isEditing="tableIsEditing"
      :hasErrors="tableHasErrors"
      hideUpdateOnId
      @assetAttributeLinkUpdated="handleAssetAttributeLinkUpdated"
      @assetRemoved="handleAssetRemoved"
      @assetsRemoved="handleAssetsRemoved"
      @headerMappingChanged="handleHeaderMappingChanged"
      @isEditingUpdated="(isEditing) => (tableIsEditing = isEditing)"
    />
    <asset-invoice-import-step-four
      v-if="selectedImportStep === 4"
      :importCount="importSuccess.importCount"
      :portfolioName="importSuccess.portfolioName"
    />
    <v-card-actions class="py-6 px-6 d-flex flex-column-reverse flex-md-row">
      <v-btn
        class="text-none px-4 mb-4 mb-md-0 align-self-stretch"
        color="#686868"
        large
        elevation="0"
        text
        tile
        x-large
        @click="hideModal"
        :loading="closeButtonLoading"
        ><v-icon class="mr-3" dark>mdi-close</v-icon
        >{{ $t("common.actions.close") }}
      </v-btn>
      <v-btn
        class="text-none px-4 mb-4 mb-md-0 align-self-stretch"
        color="#686868"
        large
        elevation="0"
        text
        tile
        x-large
        :loading="closeButtonLoading"
        v-if="selectedImportStep > 1 && selectedImportStep < 4"
        @click="moveToPreviousStep"
        ><v-icon class="mr-3" dark>mdi-chevron-left</v-icon
        >{{ $t("pages.assets.import.back") }}
      </v-btn>

      <v-spacer></v-spacer>

      <v-btn
        v-if="selectedImportStep < 4"
        class="text-none ml-0 mb-4 mb-md-0 ml-md-4 px-6 align-self-stretch"
        large
        color="#266663"
        dark
        elevation="0"
        tile
        x-large
        @click="moveToNextStep()"
        :disabled="isNextButtonDisabled"
        :loading="nextButtonLoading"
      >
        <v-icon class="white--text">mdi-chevron-right</v-icon>
        {{ $t("pages.assets.import.next") }}
      </v-btn>
    </v-card-actions>
    <v-alert
      dense
      v-if="errorMessage && errorMessageStep === selectedImportStep"
      type="error"
      class="mb-0"
    >
      {{ errorMessage }}
    </v-alert>
  </dialog-form>
</template>
<script>
import { serialize } from "object-to-formdata";

import AssetInvoiceImportStepOne from "./ImportSteps/AssetInvoiceImportStepOne.vue";
import AssetImportStepTwo from "./ImportSteps/AssetImportStepTwo.vue";
import AssetImportStepThree from "./ImportSteps/AssetImportStepThree.vue";
import AssetInvoiceImportStepFour from "./ImportSteps/AssetInvoiceImportStepFour.vue";
import DialogForm from "../Dialog/DialogForm.vue";

export default {
  components: {
    AssetInvoiceImportStepOne,
    AssetImportStepTwo,
    AssetImportStepThree,
    AssetInvoiceImportStepFour,
    DialogForm,
  },
  props: {
    value: Boolean,
  },
  computed: {
    show: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit("input", value);
      },
    },
    currentStep() {
      return this.importSteps.find((x) => x.id === this.selectedImportStep);
    },
    isNextButtonDisabled() {
      switch (this.selectedImportStep) {
        case 1:
          return (
            this.invalidImportFileFormat || !this.selectedImportFiles.length
          );
        case 2:
          return !this.form.portfolioId && !this.form.portfolioName;
        case 3:
          return (
            this.tableIsEditing ||
            Object.values(this.headerMappings).filter(
              (x) => x.type === "attribute" && x.attribute !== null
            ).length <= 0 ||
            !this.form.assetRows.length
          );
        default:
          return true;
      }
    },
    importSteps() {
      return [
        { id: 1, title: "choose import files" },
        { id: 2, title: this.$t("pages.assets.import.stepTitles.portfolio") },
        { id: 3, title: this.$t("pages.assets.import.stepTitles.validate") },
        {
          id: 4,
          title: this.$t("pages.assets.import.stepTitles.success"),
          color: "light-green",
        },
      ];
    },
  },
  data() {
    return {
      selectedImportStep: 1,
      selectedImportFiles: [],
      errorMessage: null,
      errorMessageStep: null,
      invalidImportFileFormat: false,
      portfolios: [],
      portfolioSubcategories: [],
      attributes: [],
      headerMappings: {},
      form: {
        portfolioId: null,
        portfolioName: null,
        headerToAttributeIdMappings: {},
        headerToFieldNameMappings: {},
        assetRows: [],
        updateOnId: false,
      },
      tableIsLoading: false,
      tableIsEditing: false,
      tableHasErrors: false,
      nextButtonLoading: false,
      closeButtonLoading: false,
      importSuccess: {
        importCount: 0,
        updateCount: 0,
        portfolioName: null,
      },
      saveImportedFile: true,
      importedFileName: null,
    };
  },
  methods: {
    moveToPreviousStep() {
      const newStep = (this.currentStep?.id ?? 2) - 1;

      if (newStep < 1) {
        this.selectedImportStep = 1;
        return;
      }

      this.selectedImportStep = newStep;
    },
    moveToNextStep(step3Validated) {
      if (this.selectedImportStep === 3 && !step3Validated) {
        this.submitImportData();
        return;
      }

      this.selectedImportStep = (this.currentStep?.id ?? 0) + 1;

      this.runPageChangeSideEffects();
    },
    runPageChangeSideEffects() {
      if (this.selectedImportStep === 3) {
        this.fetchParsedAssetsFromImportFiles();
      }
    },
    fetchPortfolios() {
      fetch(this.route("api.portfolios.index"), {
        method: "GET",
      })
        .then((res) => res.json())
        .then((data) => {
          this.portfolios = data;

          if (this.portfolios.length > 0) {
            const firstPortfolio = this.portfolios[0];

            this.form.portfolioId = firstPortfolio.id;
          } else {
            this.form.portfolioId = null;
          }
        })
        .catch(() => {
          this.portfolios = [];
        });
    },
    fetchParsedAssetsFromImportFiles() {
      if (!this.selectedImportFiles.length || this.form.assetRows?.length > 0)
        return;

      const formData = new FormData();

      this.selectedImportFiles.forEach((file) => {
        formData.append("invoiceFiles", file);
      });
      formData.append("portfolioId", this.form.portfolioId);

      this.tableIsLoading = true;

      fetch(this.route("api.assets.invoices.parse"), {
        method: "POST",
        body: formData,
      })
        .then((res) => res.json())
        .then((data) => {
          this.attributes = data.attributes;
          this.headerMappings = data.headerMappings;
          this.portfolioSubcategories = data.subcategories;

          const headers = Object.keys(this.headerMappings);

          const encounteredAttributes = [];
          const encounteredFields = [];

          headers.forEach((header) => {
            const mapping = this.headerMappings[header];

            if (mapping.type === "attribute") {
              let attribute = mapping.attribute;

              if (encounteredAttributes.includes(attribute)) {
                this.headerMappings[header] = {
                  id: null,
                  name: this.$t("pages.assets.import.skip"),
                };
                attribute = null;
              }

              this.form.headerToAttributeIdMappings[header] =
                attribute?.id ?? null;

              if (attribute) {
                encounteredAttributes.push(attribute);
              }
            } else if (mapping.type === "field") {
              let field = mapping.fieldName;

              if (encounteredFields.includes(field)) {
                this.headerMappings[header] = {
                  type: "attribute",
                  attribute: {
                    id: null,
                    name: this.$t("pages.assets.import.skip"),
                  },
                };
                field = null;
              }

              this.form.headerToFieldNameMappings[header] = field ?? null;

              if (field) {
                encounteredFields.push(field);
              }
            }
          });

          this.form.assetRows = data.parsedAssets;
        })
        .finally(() => {
          this.tableIsLoading = false;
        });
    },
    submitImportData() {
      this.nextButtonLoading = true;

      const omittedMappings = [];

      const attributeMappings = { ...this.form.headerToAttributeIdMappings };

      for (let mapping of Object.keys(attributeMappings)) {
        if (!attributeMappings[mapping]) {
          delete attributeMappings[mapping];
          omittedMappings.push(mapping);
        }
      }

      const fieldMappings = { ...this.form.headerToFieldNameMappings };

      for (let mapping of Object.keys(fieldMappings)) {
        if (!fieldMappings[mapping]) {
          delete fieldMappings[mapping];
          omittedMappings.push(mapping);
        }
      }

      let assetRows = [...this.form.assetRows.map((x) => x.row)];

      for (let mapping of omittedMappings) {
        assetRows.forEach((row) => {
          delete row[mapping];
        });
      }

      const bodyContent = {
        ...this.form,
        assetRowsJson: JSON.stringify(assetRows),
        headerToAttributeIdMappingsJson: JSON.stringify(attributeMappings),
        headerToFieldNameMappingsJson: JSON.stringify(fieldMappings),
      };

      delete bodyContent.assetRows;
      delete bodyContent.headerToAttributeIdMappings;
      delete bodyContent.headerToFieldNameMappings;

      fetch(this.route("api.assets.import"), {
        method: "POST",
        body: serialize(bodyContent, {
          indices: true,
          dotsForObjectNotation: true,
        }),
      })
        .then((res) => {
          if (res.ok) {
            res.json().then((data) => {
              this.handleSuccessfulImport(data);
            });

            return;
          }

          return res.json();
        })
        .then((data) => {
          if (!data || data.status !== "error") return;

          this.errorMessage = this.$t(
            `pages.assets.import.resultDescriptions.${data.description}`
          );
          this.errorMessageStep = 3;
          this.form.assetRows = data.assetRows;
          this.tableHasErrors = true;
        })
        .finally(() => {
          this.nextButtonLoading = false;
        });
    },
    resetModalState() {
      this.selectedImportStep = 1;
      this.selectedImportFiles = [];
      this.errorMessage = null;
      this.attributes = [];
      this.headerMappings = {};
      this.form = {
        portfolioId: this.portfolios.length > 0 ? this.portfolios[0].id : null,
        portfolioName: null,
        headerToAttributeIdMappings: {},
        headerToFieldNameMappings: {},
        assetRows: [],
      };
      this.tableIsEditing = false;
      this.tableHasErrors = false;
      this.nextButtonLoading = false;
    },
    handleStepOneError(error) {
      this.errorMessage = error;
      this.errorMessageStep = 1;
    },
    handleImportFilesChanged(value) {
      this.selectedImportFiles = value;

      this.form.assetRows = [];
      this.form.headerToAttributeIdMappings = {};
      this.form.headerToFieldNameMappings = {};
      this.headerMappings = {};
    },
    handlePortfolioIdChanged(portfolioId) {
      this.form.portfolioId = portfolioId;
      this.form.portfolioName = null;
      this.form.assetRows = [];
    },
    handleNewPortfolioNameChanged(portfolioName) {
      this.form.portfolioName = portfolioName;
    },
    handleAssetRemoved(assetRow) {
      this.form.assetRows = this.form.assetRows.filter((x) => x !== assetRow);
    },
    handleAssetsRemoved(assetRows) {
      this.form.assetRows = this.form.assetRows.filter(
        (x) => !assetRows.includes(x)
      );
    },
    handleHeaderMappingChanged(header, attribute) {
      let mapping = this.headerMappings[header];

      if (!mapping) {
        mapping = {
          type: "attribute",
          attribute: null,
        };
      }

      this.headerMappings[header] = {
        ...mapping,
        attribute: attribute,
      };

      const attributeId = attribute?.id;

      this.form.headerToAttributeIdMappings[header] = attributeId;
    },
    handleAssetAttributeLinkUpdated(assetRow, header, value) {
      const index = this.form.assetRows.indexOf(assetRow);

      if (index === -1) return;

      this.form.assetRows[index].row[header] = value;

      if (this.form.assetRows[index].columnErrorMessages) {
        delete this.form.assetRows[index].columnErrorMessages[header];
      }
    },
    handleSuccessfulImport(data) {
      this.tableHasErrors = false;
      this.errorMessage = false;

      this.importSuccess.importCount = data.importedAssetCount;
      this.importSuccess.portfolioName = data.importedToPortfolioName;

      this.moveToNextStep(true);
    },
    async hideModal() {
      if (this.selectedImportStep !== 4) {
        if (!window.confirm(this.$t("pages.assets.import.confirmClose")))
          return;
      } else {
        this.$emit("reloadPage");
      }

      this.show = false;
      this.resetModalState();
    },
  },
  mounted() {},
  watch: {
    show(to) {
      if (to) {
        this.fetchPortfolios();
      }
    },
  },
};
</script>
<style scoped>
.import-card {
  position: relative;
}

.import-card .step-label {
  position: absolute;
  top: 8px;
  left: 0;
  right: 0;
  text-align: center;
  font-weight: bolder;
}
</style>
