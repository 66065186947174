<template>
  <div
    @dragover="onDragover"
    class="page-container fill-height grow d-flex flex-column flex-nowrap"
  >
    <v-container fluid class="py-0" style="margin-top: -66px">
      <!-- co2EmissionsId: "041a251a-2138-4e14-bdfd-876194f4e04f", /* total co2 used */ /* filter to "last year" purchase date (generalPurchaseDateId) */ -->
      <v-row v-if="selectedLayoutOption == 0" class="px-2">
        <v-col cols="12" md="6" lg="3" class="px-2 py-2 pt-4 d-flex">
          <amount-change-card
            :title="$t('components.cards.titles.numberOfAssets')"
            iconBackColor="#6493BE1f"
            :value="numberOfAssets"
            :hideBenchmark="true"
            :bars="getAssetCountBars"
          >
            <template v-slot:icon>
              <Package style="width: 20px; height: 20px"></Package>
            </template>
          </amount-change-card>
        </v-col>
        <v-col cols="12" md="6" lg="3" class="px-2 py-2 pt-4 d-flex">
          <amount-change-card
            :title="$t('components.cards.titles.accumulatedDepreciation')"
            iconBackColor="#F25D3B1f"
            :value="
              formatCurrency(assetCardData?.accumulatedDepreciationCard?.value)
            "
            :unit="assetCardData?.accumulatedDepreciationCard?.unit"
            :hideBenchmark="true"
          >
            <template v-slot:icon>
              <MoneyBag style="width: 20px; height: 20px"></MoneyBag>
            </template>
          </amount-change-card>
        </v-col>
        <v-col cols="12" md="6" lg="3" class="px-2 py-2 pt-4 d-flex">
          <amount-change-card
            :title="$t('components.cards.titles.bookValue')"
            iconBackColor="#FFF6EA"
            :value="formatCurrency(assetCardData?.bookValueCard?.value)"
            :unit="assetCardData?.bookValueCard?.unit"
            :hideBenchmark="true"
          >
            <template v-slot:icon>
              <Sun style="width: 20px; height: 20px"></Sun>
            </template>
          </amount-change-card>
        </v-col>
        <v-col cols="12" md="6" lg="3" class="px-2 py-2 pt-4 d-flex">
          <amount-change-card
            :title="$t('components.cards.titles.monthlyDepreciation')"
            iconBackColor="#0000ff1f"
            :value="
              formatCurrency(assetCardData?.monthlyDepreciationCard?.value)
            "
            :unit="assetCardData?.monthlyDepreciationCard?.unit"
            :hideBenchmark="true"
          >
            <template v-slot:icon>
              <Flash style="width: 20px; height: 20px"></Flash>
            </template>
          </amount-change-card>
        </v-col>
      </v-row>
      <v-row v-else class="px-2">
        <v-col cols="12" md="6" lg="3" class="px-2 py-2 pt-4 d-flex">
          <amount-change-card
            :title="$t('components.cards.titles.numberOfAssets')"
            iconBackColor="#6493BE1f"
            :value="numberOfAssets"
            :hideBenchmark="true"
            :bars="getAssetCountBars"
          >
            <template v-slot:icon>
              <Package style="width: 20px; height: 20px"></Package>
            </template>
          </amount-change-card>
        </v-col>
        <v-col cols="12" md="6" lg="3" class="px-2 py-2 pt-4 d-flex">
          <amount-change-card
            :title="$t('components.cards.titles.totalCo2')"
            iconBackColor="#E5EDEC"
            :value="totalCo2Used"
            :unit="totalCo2UsedUnit"
            :hideBenchmark="true"
            :bars="getScopeCountBars"
          >
            <template v-slot:icon>
              <Leaf style="width: 20px; height: 20px"></Leaf>
            </template>
          </amount-change-card>
        </v-col>
        <v-col cols="12" md="6" lg="3" class="px-2 py-2 pt-4 d-flex">
          <amount-change-card
            :title="$t('components.cards.titles.co2Ytd')"
            iconBackColor="#EDDDE6"
            :value="co2Ytd"
            :unit="co2YtdUnit"
            :hideBenchmark="true"
          >
            <template v-slot:icon>
              <DonateCoin style="width: 20px; height: 20px"></DonateCoin>
            </template>
          </amount-change-card>
        </v-col>
        <v-col cols="12" md="6" lg="3" class="px-2 py-2 pt-4 d-flex">
          <amount-change-card
            :title="$t('components.cards.titles.trees')"
            iconBackColor="#E5EDEC"
            :value="treesToOffset"
            :unit="$t('common.units.trees')"
            :hideBenchmark="true"
          >
            <template v-slot:icon>
              <Tree style="width: 20px; height: 20px"></Tree>
            </template>
          </amount-change-card>
        </v-col>
      </v-row>
      <asset-list-filter
        :currentAssetLayout="currentAssetLayout"
        :attributeDisplaySizes="attributeDisplaySizes"
        :changeLayout="updateAssetLayout"
        :saveLayout="saveLayout"
        :attributes="attributes"
        :portfolios="portfolios"
        :assets="assets"
        :selectedAssets="selectedAssets"
        :currentOrganizationId="currentOrganizationId"
        :postFilters="postFilters"
        :attributeClasses="attributeClasses"
        :updateAssetLayout="updateAssetLayout"
        :resetAssetLayout="resetAssetLayout"
        :currentAttributeFilters="currentAttributeFilters"
        :updateAttributeFilters="(value) => updateAttributeFilters(value)"
        :layoutOptions="layoutOptions"
        :selected-layout-option="selectedLayoutOption"
        @selectLayoutOption="(key) => (selectedLayoutOption = key)"
        @clickCreateSingle="formDialog = true"
        @clickImportFromCSV="attemptOpenImportDialog()"
        @clickImportFromPDF="importInvoiceAssetsDialog = true"
        @searchAttributes="(query) => searchAttributes(query)"
        @updatePortfolioIds="(ids) => (portfolioIds = ids)"
        :selectedPortfolios="portfolioIds"
        @commitPortfolioIds="updatePortfolioIds"
      ></asset-list-filter>

      <v-row class="px-2">
        <v-col cols="12" class="px-2 pt-0">
          <table-card
            ref="tableCard"
            v-model="selectedAssets"
            :selectedAssets="selectedAssets"
            :headers="headers"
            :items="mappedAssets"
            :totalItems="assets.totalCount > 0 ? assets.totalCount : 5"
            :pagination="pagination"
            :currentAttributeFilters="currentAttributeFilters"
            :updateAttributeFilters="(val) => (currentAttributeFilters = val)"
            :attributes="attributes"
            :columnFilterData="columnFilterData"
            :postFilters="postFilters"
            :updatePostFilters="(val) => (postFilters = val)"
            :showFileMenu="showFileMenu"
            :onlyFileAttribute="onlyFileAttribute"
            :defaultCurrencyCode="defaultCurrencyCode"
            :editAsset="editAsset"
            :copyAsset="copyAsset"
            :destroyAsset="destroyAsset"
            :handleAssetFileLinking="handleAssetFileLinking"
            :currentAssetLayout="currentAssetLayout"
            :deleteRangeLoading="deleteRangeLoading"
            @removeAssetRange="() => removeAssetRange()"
            @verifyAssetRange="verifyAssetRange"
            :tableRerenderCounter="tableRerenderCounter"
            :tableFooterProps="tableFooterProps"
            @changePage="() => changePage(pagination)"
            @changeSorting="() => changeSorting(pagination)"
            @updatePagination="(p) => (pagination = p)"
            :sortTableHeaders="sortTableHeaders"
            :columnFilterProperties="columnFilterProperties"
            @updateColumnFilterData="
              (key, value) => (columnFilterData[key] = value)
            "
            :loadingSkeletonOn="loadingPage"
            :updatingAssetIds="updatingAssetIds"
          ></table-card>

          <asset-form
            v-model="formDialog"
            :asset="asset"
            :attributeClasses="attributeClasses"
            :attributes="attributes"
            :currencies="currencies"
            :portfolios="portfolios"
            :organizationId="currentOrganizationId"
            :isTemplatePage="isTemplatePage"
            :defaultCurrencyCode="defaultCurrencyCode"
            @clear="asset = null"
            @reload="(assetId) => reloadAsset(assetId)"
            :disableCreateButton="true"
            :autoApplyAssetAttributeLinks="autoApplyAssetAttributeLinks"
          >
          </asset-form>
          <import-assets-modal
            v-model="importAssetsDialog"
            :organizationId="currentOrganizationId"
            @reloadPage="
              () => {
                fetchData(pagination.dateRange);
                changePage(pagination);
              }
            "
            :filesFromParent="selectedImportFiles"
          />
          <import-invoice-assets-modal
            v-model="importInvoiceAssetsDialog"
            :organizationId="currentOrganizationId"
            @reloadPage="
              () => {
                fetchData(pagination.dateRange);
                changePage(pagination);
              }
            "
          />
          <payment-dialog
            v-model="paymentDialog"
            :publishableKey="publishableKey"
            :billingSubscription="billingSubscription"
            :paymentReturnUrl="paymentReturnUrl"
            :openedFromSettings="true"
            :paymentTierIds="paymentTierIds"
          />
          <dialog-form :showDialog="showUpgradeModal" maxWidth="580px">
            <dialog-form-section-one-col>
              <div
                class="d-flex align-center flex-column"
                style="padding-top: 100px; padding-bottom: 20px"
              >
                <v-list-item-avatar
                  color="#FDECE7"
                  class="d-flex align-center justify-center items-center mb-4"
                  width="62px"
                  height="62px"
                >
                  <WarningError
                    style="width: 32px; height: 32px; border-radius: 0"
                  ></WarningError>
                </v-list-item-avatar>
                <p class="text-h6 black--text mb-3 font-weight-regular">
                  Upgrade to import more data
                </p>
                <p class="text-body-1 mb-5">
                  You have reached the limit for import of data.
                </p>
                <p class="text-body-1 mb-3 text-center">
                  Please press "Upgrade" to continue using easy import of data
                  to Verarca, or stay on our free plan and enter your data
                  manually.
                </p>
              </div>
            </dialog-form-section-one-col>
            <v-card-actions
              class="py-6 px-6 d-flex flex-column-reverse flex-md-row"
            >
              <v-spacer></v-spacer>
              <v-btn
                class="text-none ml-0 mb-4 mb-md-0 align-self-stretch"
                color="#686868"
                elevation="0"
                tile
                dark
                x-large
                text
                @click="cancelUpgradeModal"
              >
                <p class="mb-0">
                  {{ $t("common.actions.cancel") }}
                </p>
              </v-btn>
              <v-btn
                class="text-none ml-0 mb-4 mb-md-0 ml-md-4 px-6 align-self-stretch"
                color="#F25D3B"
                dark
                elevation="0"
                tile
                x-large
                @click="upgrade()"
              >
                <p class="white--text mb-0">
                  {{ $t("common.actions.upgrade") }}
                </p>
              </v-btn>
            </v-card-actions>
          </dialog-form>
        </v-col>
      </v-row>
    </v-container>
    <!-- TODO 3 confirm dialogs for each of the confirms -->
    <confirm-dialog
      v-model="showDestroyAssetConfirmDialog"
      v-show="showDestroyAssetConfirmDialog"
      :confirm-message="confirmDestroyAssetMessage"
      @confirmed="handleDestroyAssetConfirmation"
    ></confirm-dialog>
    <confirm-dialog
      v-model="showRemoveAssetRangeConfirmDialog"
      v-show="showRemoveAssetRangeConfirmDialog"
      :confirm-message="confirmRemoveAssetRangeMessage"
      @confirmed="handleRemoveAssetRangeConfirmation"
    ></confirm-dialog>
    <confirm-dialog
      v-model="showVerifyAssetRangeConfirmDialog"
      v-show="showVerifyAssetRangeConfirmDialog"
      :confirm-message="confirmVerifyAssetRangeMessage"
      @confirmed="handleVerifyAssetRangeConfirmation"
    ></confirm-dialog>
    <dialog-form-file-upload-input
      v-if="dndActive"
      @dragStateChanged="(state) => (dndActive = state)"
      :selectedImportFiles="selectedImportFiles"
      @errorChanged="handleStepOneError"
      @onFileUploadChanged="() => {}"
      @filesChanged="setSelectedImportFiles($event)"
      :fullPage="true"
      :selectedImportFilesFilter="filterDroppedFiles"
      multiple
    ></dialog-form-file-upload-input>
    <v-snackbar
      :value="importFileError"
      v-if="importFileError"
      :timeout="3600"
      color="#D32C2C"
      tile
      elevation="3"
    >
      {{ importFileError }}
    </v-snackbar>
  </div>
</template>

<script>
import debounce from "lodash/debounce";
import pickBy from "lodash/pickBy";
import { appLayout } from "@/util/layout";
import {
  footerProps,
  generateAttributeTableHeaders,
  sortableTableDirective,
  sortTableColumnLayout,
} from "@/util/dataTable";
import AssetForm from "./Form.vue";
import ImportInvoiceAssetsModal from "../../Components/Asset/ImportInvoiceAssetsModal.vue";
import qs from "qs";
import {
  currencyFormatter,
  decimalFormatter,
  integerNumberFormatter,
  formatAndConvertStringToFloat,
} from "../../util/formatters";
import { fixedAttributes, fixedFieldAttributes } from "@/util/fixedAssetData";
import { calculateDepreciations } from "@/util/calculators";
import { DateTime } from "luxon";
import Decimal from "decimal.js";
import {
  calculateBookValue,
  calculateEmissionsSpendBased,
} from "@/util/calculators";

import "../../assets/css/attributeTable.css";
import { serialize } from "object-to-formdata";
import AmountChangeCard from "../../Components/Cards/AmountChangeCard.vue";

import MoneyBag from "../../../src/assets/svg/money-bag.svg";
import Sun from "../../../src/assets/svg/sun.svg";
import Leaf from "../../../src/assets/svg/leaf.svg";
import Tree from "../../../src/assets/svg/tree.svg";
import DonateCoin from "../../../src/assets/svg/donate-coin.svg";
import Flash from "../../../src/assets/svg/flash.svg";
import Package from "../../../src/assets/svg/package.svg";
import TableCard from "../../Components/Cards/TableCard.vue";
import AssetListFilter from "../../Components/Filters/AssetListFilter.vue";
import ImportAssetsModal from "../../Components/Asset/ImportAssetsModal.vue";
import GlobalDateRange from "../../mixins/GlobalDateRange.vue";
import DialogFormFileUploadInput from "../../Components/Dialog/inputs/DialogFormFileUploadInput.vue";
import { EventBus } from "../../eventBus";
import ConfirmDialog from "../../Components/ConfirmDialog.vue";
import PaymentDialog from "../../Components/Onboarding/PaymentDialog.vue";
import DialogForm from "../../Components/Dialog/DialogForm.vue";
import DialogFormSectionOneCol from "../../Components/Dialog/DialogFormSectionOneCol.vue";
import WarningError from "../../assets/svg/warning-error.svg";
import { fixedClasses } from "../../util/fixedAssetData";

const ALLOWED_IMPORT_MIME_TYPES = [
  "text/csv",
  "application/pdf",
  "application/vnd.ms-excel",
  "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
];

export default {
  layout: appLayout({ title: "pages.assets.index.title", pushContent: true }),
  mixins: [GlobalDateRange],
  components: {
    AssetForm,
    AmountChangeCard,
    MoneyBag,
    Sun,
    Flash,
    Package,
    TableCard,
    AssetListFilter,
    ImportAssetsModal,
    ImportInvoiceAssetsModal,
    Leaf,
    DonateCoin,
    Tree,
    DialogFormFileUploadInput,
    ConfirmDialog,
    PaymentDialog,
    DialogForm,
    DialogFormSectionOneCol,
    WarningError,
  },
  props: {
    settings: Object,
    assetLayout: Object,
    attributes: Array,
    attributeClasses: Array,
    currentOrganizationId: String,
    portfolios: Array,
    currencies: Array,
    attributeDisplaySizes: Array,
    modifiedAsset: Object,
    billingLimits: Object,
    billingSubscription: Object,
    publishableKey: String,
    paymentReturnUrl: String,
    paymentTierIds: Object,
    dummyAssets: Boolean,
    assetFormFactorId: String,
    assetFormSectorId: String,
    assetFormCategoryId: String,
    assetFormRegionId: String,
  },
  data() {
    let searchParams = qs.parse(window.location.search.substring(1));

    let columnFilterProperties = ["referenceKey"];
    let columnFilterData = {};
    let sortBy = [];
    let sortDesc = [];

    columnFilterProperties.forEach((x) => {
      columnFilterData[`${x}-filter`] = null;
    });
    this.attributes.forEach((x) => {
      columnFilterData[`${x.id}-filter`] = null;
    });

    if (this.assetLayout.attributeFilters != null) {
      this.assetLayout.attributeFilters.forEach((x) => {
        if (x.filterBy != null) {
          if (x.attributeId != null) {
            columnFilterData[`${x.attributeId}-filter`] = x.filter;
            columnFilterData[`${x.attributeId}-filterBy`] = x.filterBy;
          } else {
            columnFilterData[`${x.propertyName}-filter`] = x.filter;
            columnFilterData[`${x.propertyName}-filterBy`] = x.filterBy;
          }
        }
      });

      var filters = this.assetLayout.attributeFilters.filter(
        (x) => x.sortDesc != null
      );
      sortBy = filters.map((x) => {
        if (x.sortDesc != null) {
          if (x.attributeId != null) {
            return x.attributeId;
          }
          return x.propertyName;
        }
      });
      sortDesc = filters.map((x) => {
        if (x.sortDesc != null) {
          return x.sortDesc;
        }
      });
    }

    let _sortBy = JSON.parse(localStorage.getItem("pagination-sort-by"));
    let _sortDesc = JSON.parse(localStorage.getItem("pagination-sort-desc"));

    if (_sortBy !== null && _sortDesc !== null) {
      sortBy = _sortBy;
      sortDesc = _sortDesc;
    }

    let itemsPerPage = 50;
    if (localStorage.getItem("saved-asset-layout")) {
      itemsPerPage = JSON.parse(
        localStorage.getItem("saved-asset-layout")
      ).itemsPerPage;
    }

    return {
      asset: null,
      autoApplyAssetAttributeLinks: null,
      currentAssetLayout: this.getAssetLayoutForFilterType(),
      currentAttributeFilters: this.assetLayout.attributeFilters,

      formDialog: false,
      chooseAttributesDialog: false,
      layoutOptionsDialog: false,
      exportAssetsDialog: false,
      filtersDialog: false,
      importAssetsDialog: false,
      importInvoiceAssetsDialog: false,
      paymentDialog: false,
      showFileMenu: false,
      hasSearched: false,
      bulkEditAttribute: null,
      pagination: {
        page: 1,
        itemsPerPage: itemsPerPage,
        sortBy,
        sortDesc,
        dateRange: { from: null, to: null },
      },
      tableFooterProps: footerProps,
      postFilters: {
        search: searchParams.postFilters?.search ?? null,
        allAttributes: searchParams.postFilters?.allAttributes ?? null,
        allResults: searchParams.postFilters?.allResults,
      },
      organizationId:
        this.currentOrganizationId ?? searchParams.organizationId ?? null,
      portfolioIds: searchParams.portfolioIds ?? [],
      columnFilterProperties,
      columnFilterData,
      refreshFileMenu: false,
      selectedAssets: [],
      tableRerenderCounter: 0,
      tableRefreshCounter: 0,
      isTemplatePage: this.routeRaw().current() === "asset-templates.index",
      triggerSaveLayout: false,
      selectedLayoutOption: 1,
      currentTab: 0,
      tableRefresher: null,
      assetCardData: null,
      selectedImportFiles: null,
      dndActive: false,
      importFileError: null,
      deleteRangeLoading: false,

      assetToDestroyId: String,
      showDestroyAssetConfirmDialog: false,
      confirmDestroyAssetMessage: this.$t("pages.assets.index.confirmDelete"),

      showRemoveAssetRangeConfirmDialog: false,

      showVerifyAssetRangeConfirmDialog: false,

      showUpgradeModal: false,
      loadingPage: false,

      initialDateFetch: true,

      assets: {
        data: [],
      },

      updatingAssetIds: [],
    };
  },
  computed: {
    getAssetCountBars() {
      const approvedCount = this.assetCardData?.numberOfAssetsApproved?.value;
      const definedCount = this.assetCardData?.numberOfAssetsDefined?.value;
      const undefinedCount = this.assetCardData?.numberOfAssetsUndefined?.value;

      const maxCount = Math.max(
        approvedCount,
        definedCount,
        undefinedCount,
        1 // Avoid dividing by zero
      );

      return [
        {
          label: this.$t("components.cards.titles.defined"),
          renderedValue: definedCount,
          fractionValue: this.barChartCalculateScale(definedCount / maxCount),
          color: "#898989",
          rawValue: definedCount,
        },
        {
          label: this.$t("components.cards.titles.undefined"),
          renderedValue: undefinedCount,
          fractionValue: this.barChartCalculateScale(undefinedCount / maxCount),
          color: "#F25D3B",
          rawValue: undefinedCount,
        },
        {
          label: this.$t("components.cards.titles.verified"),
          renderedValue: approvedCount,
          fractionValue: this.barChartCalculateScale(approvedCount / maxCount),
          color: "#266663",
          rawValue: approvedCount,
        },
      ];
    },
    getScopeCountBars() {
      if (this.scopes !== null) {
        const scope1 = this.assetCardData?.scope1Emissions;
        const scope2 = this.assetCardData?.scope2Emissions;
        const scope3 = this.assetCardData?.scope3Emissions;

        let maxCount = Math.max(
          scope1?.value ?? 0,
          scope2?.value ?? 0,
          scope3?.value ?? 0,
          1 // Avoid dividing by zero
        );

        return [
          {
            label: "Scope 1",
            renderedValue:
              this.formatCurrency(scope1?.value ?? 0) +
              " " +
              this.$t("common.units.kgCo2e"),
            fractionValue: this.barChartCalculateScale(
              scope1?.value / maxCount
            ),
            color: "#266663",
            rawValue: scope1?.value,
          },
          {
            label: "Scope 2",
            renderedValue:
              this.formatCurrency(scope2?.value ?? 0) +
              " " +
              this.$t("common.units.kgCo2e"),
            fractionValue: this.barChartCalculateScale(
              scope2?.value / maxCount
            ),
            color: "#6493BE",
            rawValue: scope2?.value,
          },
          {
            label: "Scope 3",
            renderedValue:
              this.formatCurrency(scope3?.value) +
              " " +
              this.$t("common.units.kgCo2e"),
            fractionValue: this.barChartCalculateScale(
              scope3?.value / maxCount
            ),
            color: "#E35E3E",
            rawValue: scope3?.value,
          },
        ];
      }

      return [];
    },
    hasAIDefiningRows() {
      const statusId = fixedFieldAttributes.co2EmissionFactorStatusId;

      return this.mappedAssets.some(
        (asset) => asset[statusId]?.stringValue === "Defining"
      );
    },
    filteredFilterOptions() {
      return this.filterOptions.filter((option) => option.text !== "");
    },
    summedEmissions() {
      let emissionsList = [];
      this.assets.data?.forEach((asset) => {
        let attribute = asset.assetAttributeLinks.find(
          (element) => element.attributeId === fixedAttributes.co2EmissionsId
        );
        if (attribute !== undefined) {
          emissionsList.push(attribute.decimalValue);
        }
      });
      return this.getFormattedSum(emissionsList);
    },
    summedEmissionsFactor() {
      let emissionsList = [];
      this.assets.data?.forEach((asset) => {
        let emission =
          asset.emissionFactor?.kgCo2eAr5 ?? asset.emissionFactor?.kgCo2eAr4;
        if (emission !== undefined) {
          emissionsList.push(emission);
        }
      });
      return this.getFormattedSum(emissionsList);
    },
    summedScope3Emissions() {
      let emissionsList = [];
      this.assets.data?.forEach((asset) => {
        let attribute = asset.assetAttributeLinks.find(
          (element) =>
            element.attributeId === fixedAttributes.generalPurchasePriceId
        );
        if (attribute !== undefined) {
          let scope3Emissions = calculateEmissionsSpendBased(
            attribute.decimalValue,
            attribute.stringValue?.trim(),
            asset.emissionFactor
          );

          if (scope3Emissions !== undefined) {
            emissionsList.push(formatAndConvertStringToFloat(scope3Emissions));
          }
        }
      });

      return this.getFormattedSum(emissionsList);
    },
    summedDepreciation() {
      let depreciationList = [];
      this.assets.data?.forEach((asset) => {
        let depreciations = this.getDepreciations(asset);

        const currentYear = DateTime.utc().year;

        const depreciation = depreciations[currentYear] ?? new Decimal(0);

        Decimal.set({ rounding: Decimal.ROUND_HALF_UP });

        const roundedNumber = depreciation.round().toNumber();

        depreciationList.push(roundedNumber);
      });

      return this.getFormattedSum(depreciationList);
    },
    summedBookValue() {
      let bookvaluesList = [];
      let purchasePriceDecimalValue = undefined;
      this.assets.data?.forEach((asset) => {
        let depreciations = this.getDepreciations(asset);

        let purchasePriceAttribute = asset.assetAttributeLinks.find(
          (element) =>
            element.attributeId === fixedAttributes.generalPurchasePriceId
        );
        if (purchasePriceAttribute !== undefined) {
          purchasePriceDecimalValue = purchasePriceAttribute.decimalValue;
        }

        let bookValue = calculateBookValue(
          purchasePriceDecimalValue,
          undefined,
          depreciations
        );

        bookvaluesList.push(formatAndConvertStringToFloat(bookValue));
      });

      return this.getFormattedSum(bookvaluesList);
    },
    headers() {
      let _headers = generateAttributeTableHeaders(
        this.currentAssetLayout,
        this.attributes,
        false /*this.showFileMenu*/
      );

      return _headers;
    },
    mappedAssets() {
      let mappedAssets = [];
      if (this.loadingPage || (this.dummyAssets && this.initialDateFetch)) {
        // @todo dont use dummt assets on asset update
        var dummyAssets = Array(this.pagination.itemsPerPage).fill({
          assetAttributeLinks: [],
          category: null,
          categoryId: null,
          createdAt: "0001-01-01T00: 00: 00",
          createdBy: null,
          createdById: null,
          emissionFactor: null,
          emissionFactorId: null,
          id: "00000000-0000-0000-0000-000000000000",
          integration: null,
          integrationId: null,
          organization: null,
          organizationId: "00000000-0000-0000-0000-000000000000",
          portfolio: null,
          portfolioId: null,
          portfolioSubcategory: null,
          portfolioSubcategoryId: null,
          referenceKey: 0,
          serialNumber: null,
          solution: null,
          solutionId: null,
          template: false,
          updatedAt: "0001-01-01T00:00:00",
          updatedBy: null,
          updatedById: null,
        });

        dummyAssets.forEach((asset) => {
          let mappedDummyAsset = {
            ...asset,
          };
          if (asset.assetAttributeLinks !== null) {
            asset.assetAttributeLinks.forEach((assetAttributeLink) => {
              mappedDummyAsset[assetAttributeLink.attributeId] =
                assetAttributeLink;
            });
          }

          mappedAssets.push(mappedDummyAsset);
        });

        return mappedAssets;
      }

      this.assets.data?.forEach((asset) => {
        let mappedAsset = {
          ...asset,
        };
        if (asset.assetAttributeLinks !== null) {
          asset.assetAttributeLinks.forEach((assetAttributeLink) => {
            mappedAsset[assetAttributeLink.attributeId] = assetAttributeLink;
          });
        }

        this.setMissingAssetName(mappedAsset);

        mappedAssets.push(mappedAsset);
      });

      return mappedAssets;
    },

    visibleAttributes() {
      if (!this.attributes || !this.currentAssetLayout) return [];

      return this.attributes.filter((x) =>
        this.currentAssetLayout.attributeIds.includes(x.id)
      );
    },
    onlyFileAttribute() {
      if (this.attributes.filter((x) => x.format === "File").length <= 1)
        return this.attributes.find((x) => x.format === "File") ?? null;

      if (this.visibleAttributes.filter((x) => x.format === "File").length <= 1)
        return this.visibleAttributes.find((x) => x.format === "File") ?? null;

      return null;
    },
    allPortfoliosSelected() {
      if (!this.portfolioIds) return false;

      return this.portfolios.every((x) => this.portfolioIds.includes(x.id));
    },
    defaultCurrencyId() {
      return this.$inertia.page.props.auth.settings.defaultCurrencyId;
    },
    defaultCurrencyCode() {
      const currency = this.currencies.find(
        (x) => x.id === this.defaultCurrencyId
      );

      return currency?.currencyCode ?? "";
    },
    layoutOptions() {
      return [
        this.$t("pages.assets.index.layoutOptions.co2"),
        this.$t("pages.assets.index.layoutOptions.co2"),
      ];
    },
    numberOfAssets() {
      let number = this.assetCardData?.numberOfAssetsCard?.value;

      if (!number) return "-";

      return integerNumberFormatter.format(number);
    },
    treesToOffset() {
      let totalCo2 = this.assetCardData?.totalCo2UsedCard?.value;

      if (!totalCo2) return "-";

      const trees = Math.ceil(totalCo2 / 25)?.toString();

      return integerNumberFormatter.format(trees);
    },
    totalCo2Used() {
      let totalCo2 = this.assetCardData?.totalCo2UsedCard?.value ?? 0;

      if (totalCo2 >= 1000) totalCo2 = totalCo2 / 1000;

      return currencyFormatter.format(totalCo2);
    },
    totalCo2UsedUnit() {
      let totalCo2 = this.assetCardData?.totalCo2UsedCard?.value ?? 0;

      return totalCo2 >= 1000
        ? this.$t("common.units.tCo2e")
        : this.$t("common.units.kgCo2e");
    },
    co2Ytd() {
      let co2Ytd = this.assetCardData?.co2CurrentYearCard?.value ?? 0;

      if (co2Ytd >= 1000) co2Ytd = co2Ytd / 1000;

      return currencyFormatter.format(co2Ytd);
    },
    co2YtdUnit() {
      let co2Ytd = this.assetCardData?.co2CurrentYearCard?.value ?? 0;

      return co2Ytd >= 1000
        ? this.$t("common.units.tCo2e")
        : this.$t("common.units.kgCo2e");
    },
    confirmRemoveAssetRangeMessage() {
      return this.$t("pages.assets.index.confirmDeleteSelected", {
        amount: this.selectedAssets.length,
      });
    },
    confirmVerifyAssetRangeMessage() {
      return this.$t("pages.assets.index.confirmVerifySelected", {
        amount: this.selectedAssets.length,
      });
    },
  },
  methods: {
    setAssetFactorId(sectorId, categoryId, regionId, factorId) {
      this.autoApplyAssetAttributeLinks = {
        "a2728615-2215-4d06-9326-3db17787268d": sectorId,
        "5ac7671d-fb9d-4441-8203-a09763fbcff8": categoryId,
        "a6db55d4-f887-48ed-815c-785b419d8dba": regionId,
        "9b748631-0d19-4b09-a570-9f43d842b6fa": factorId,
      };

      this.formDialog = true;
    },
    cleanUrl() {
      const searchParams = new URLSearchParams(window.location.search);
      searchParams.delete("assetFormFactorIdPreset");
      const newUrl = `${window.location.pathname}?${searchParams.toString()}`;
      window.history.replaceState({}, "", newUrl);
    },
    barChartCalculateScale(value) {
      // Set height to 0 if value is 0
      if (value === 0 || value === null) {
        return 0;
      }

      // If value is really small but still above 0 return 0.1
      //just to make sure there's something there for the cursor to hover.
      else if (value <= 0.1 && value !== 0) {
        return 0.1;
      }

      // Else return value
      return value;
    },
    onDragover(event) {
      var dt = event.dataTransfer;
      if (
        dt.types &&
        (dt.types.indexOf
          ? dt.types.indexOf("Files") != -1
          : dt.types.contains("Files"))
      ) {
        this.dndActive = true;
      }
    },
    handleStepOneError() {},
    setSelectedImportFiles(files) {
      if (
        !files?.length ||
        !files.every((file) => ALLOWED_IMPORT_MIME_TYPES.includes(file.type))
      ) {
        this.importFileError = this.$t(
          "pages.assets.import.stepOne.filetypeNotSupported"
        );
        setTimeout(() => (this.importFileError = null), 5000);
        return;
      }

      this.attemptOpenImportDialog(files);
    },
    filterDroppedFiles(files) {
      if (!files?.length) return [];

      let filteredFiles = [];
      let firstFiletype = null;

      files.forEach((file) => {
        if (!firstFiletype) firstFiletype = file.type;

        if (firstFiletype !== file.type) return;

        if (filteredFiles.length >= 1 && file.type !== "application/pdf")
          return;

        filteredFiles.push(file);
      });

      return filteredFiles;
    },
    async fetchData(
      dateRange,
      pageNumber = 1,
      reload = true,
      disableLoadingUI = false
    ) {
      // Amount change card data
      await fetch(
        this.route("api.dashboard.infographics.assets.card-data", {
          fromDate: dateRange.from,
          toDate: dateRange.to,
        })
      )
        .then((response) => {
          if (!response.ok) {
            return Promise.reject();
          }
          return response.json();
        })
        .then((data) => {
          this.assetCardData = data;
        })
        .catch((error) => {
          console.warn("Could not load data", error);
        });

      // Apply daterange to pagination object
      this.pagination.dateRange = dateRange;

      // Global daterange picker breaks if change page is not called after the fetch (VER-293)
      if (reload) {
        this.changePage(
          {
            page: pageNumber,
            itemsPerPage: this.pagination.itemsPerPage,
            dateRange: this.pagination.dateRange,
          },
          disableLoadingUI
        );
      }
    },
    getAssetLayoutForFilterType(type = 0) {
      let filteredAssetLayout = this.assetLayout;

      // Check if a saved asset layout exists and return is thats the case
      const savedAssetLayout = JSON.parse(
        localStorage.getItem("saved-asset-layout")
      );

      if (savedAssetLayout !== null) {
        filteredAssetLayout = {
          ...filteredAssetLayout,
          attributeIds: savedAssetLayout["attributeIds"],
        };

        return filteredAssetLayout;
      }

      // Default layouts
      const fieldFilters = {
        //finance
        0: [
          // [2023-09-05] AHA: Enforce finance layout for now to CO2 layout
          /*fixedAttributes.generalNameId,
          fixedAttributes.fixedAssetsTimeOfCommissioningId,
          fixedAttributes.generalPurchasePriceId,
          fixedAttributes.fixedAssetsDepreciationPeriodId,
          fixedFieldAttributes.fixedAssetsAccumulatedDepreciationId,
          fixedFieldAttributes.fixedAssetsBookValueId,
          fixedAttributes.fixedAssetsScrapValueId,
          fixedFieldAttributes.fixedAssetsDepreciationMonthId,*/

          fixedAttributes.generalNameId,
          fixedAttributes.co2CategoryId,
          fixedAttributes.co2EmissionsId,
          fixedAttributes.co2MethodId,
          fixedAttributes.co2EmissionSourceId,
          fixedAttributes.generalPurchasePriceId,
          fixedAttributes.generalQuantityId,
        ],
        //co2
        1: [
          fixedAttributes.generalNameId,
          fixedAttributes.co2CategoryId,
          fixedAttributes.co2EmissionsId,
          fixedAttributes.co2MethodId,
          fixedAttributes.co2EmissionSourceId,
          fixedAttributes.generalPurchasePriceId,
          fixedAttributes.generalQuantityId,
        ],
      };

      filteredAssetLayout = {
        ...filteredAssetLayout,
        attributeIds: fieldFilters[type],
      };

      return filteredAssetLayout;
    },
    getDepreciations(asset) {
      let purchaseDate = undefined;
      let purchasePriceDecimalValue = undefined;
      let purchasePriceCurrency = undefined;
      let scrapDecimalValue = undefined;
      let scrapCurrency = undefined;
      let depreciationPeriod = undefined;
      let startOfDepreciation = undefined;

      let purchaseDateAttribute = asset.assetAttributeLinks.find(
        (element) =>
          element.attributeId === fixedAttributes.generalPurchaseDateId
      );
      if (purchaseDateAttribute !== undefined) {
        purchaseDate = new Date(purchaseDateAttribute.dateTimeValue);
      }

      let purchasePriceAttribute = asset.assetAttributeLinks.find(
        (element) =>
          element.attributeId === fixedAttributes.generalPurchasePriceId
      );
      if (purchasePriceAttribute !== undefined) {
        purchasePriceDecimalValue = purchasePriceAttribute.decimalValue;
        purchasePriceCurrency = purchasePriceAttribute.stringValue?.trim();
      }

      let scrapValueAttribute = asset.assetAttributeLinks.find(
        (element) =>
          element.attributeId === fixedAttributes.fixedAssetsScrapValueId
      );
      if (scrapValueAttribute !== undefined) {
        scrapDecimalValue = scrapValueAttribute.decimalValue;
        scrapCurrency = scrapValueAttribute.stringValue?.trim();
      }

      let depreciationPeriodAttribute = asset.assetAttributeLinks.find(
        (element) =>
          element.attributeId ===
          fixedAttributes.fixedAssetsDepreciationPeriodId
      );
      if (depreciationPeriodAttribute !== undefined) {
        depreciationPeriod =
          depreciationPeriodAttribute.attributeSelectOptionId;
      }

      let startOfDepreciationAttribute = asset.assetAttributeLinks.find(
        (element) =>
          element.attributeId ===
          fixedAttributes.fixedAssetsStartOfDepreciationId
      );
      if (startOfDepreciationAttribute !== undefined) {
        startOfDepreciation =
          startOfDepreciationAttribute.attributeSelectOptionId;
      }

      let depreciations = calculateDepreciations(
        purchaseDate,
        purchasePriceDecimalValue,
        purchasePriceCurrency,
        scrapDecimalValue,
        scrapCurrency,
        depreciationPeriod,
        startOfDepreciation
      );

      return depreciations;
    },
    getFormattedSum(listtoSum) {
      var summedListValue = listtoSum.reduce(
        (partialSum, a) => partialSum + a,
        0
      );

      const scale = Math.pow(1, 4);
      return decimalFormatter.format(summedListValue / scale);
    },
    sortTableHeaders(event) {
      this.currentAssetLayout = sortTableColumnLayout(
        event,
        this.headers,
        this.currentAssetLayout
      );

      this.tableRerenderCounter++;
    },
    editAsset(item) {
      this.asset = { ...item };
      this.formDialog = true;
    },
    copyAsset(item) {
      this.asset = { ...item, id: null };
      this.formDialog = true;
    },
    destroyAsset(id) {
      this.assetToDestroyId = id;
      this.showDestroyAssetConfirmDialog = true;
    },
    handleDestroyAssetConfirmation(status) {
      this.isConfirmed = status;
      if (status) {
        this.confirmDestroyAsset();
      }
    },
    confirmDestroyAsset() {
      this.$inertia.delete(this.route("assets.destroy", this.assetToDestroyId));
      this.assetToDestroyId = "";
    },
    changePage(options, disableLoadingUI = false) {
      if (!disableLoadingUI) {
        this.loadingPage = true;
      }

      let query = {
        portfolioIds: this.portfolioIds,
        postFilters: pickBy(this.postFilters),
        page: options.page,
        pageSize: options.itemsPerPage,
        dateRangeFrom: options.dateRange.from,
        dateRangeTo: options.dateRange.to,
        assetFormFactorIdPreset: this.assetFormFactorId,
      };

      if (this.currentAssetLayout.itemsPerPage !== options.itemsPerPage) {
        this.currentAssetLayout.itemsPerPage = options.itemsPerPage;
        this.updateAssetLayout(this.currentAssetLayout, false);
      }

      /*this.$inertia.post(
        this.route("assets.index", query),
        {
          ...this.currentAssetLayout,
          attributeFilters: this.currentAttributeFilters,
        },
        {
          only: ["assets", "assetLayout", "dummyAssets"],
          preserveState: true,
          preserveScroll: true,
          onSuccess: () => {
            this.setupTableRefresher();
            this.loadingPage = false;
            this.cleanUrl();
          },
        }
      );*/

      // Changed from inertia requeset to fetch request
      const url = this.route("assets.index", query);
      const body = JSON.stringify({
        ...this.currentAssetLayout,
        attributeFilters: this.currentAttributeFilters,
      });

      fetch(url, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          // Add any other headers if needed
        },
        body: body,
      })
        .then((response) => {
          if (!response.ok) {
            throw new Error("Network response was not ok");
          }
          return response.json();
        })
        .then((data) => {
          // Handle success

          this.assets = data.assets;

          /*pagination: {
            page: this.assets?.currentPage,
            itemsPerPage: this.assets?.pageSize,
            sortBy,
            sortDesc,
            dateRange: { from: null, to: null },
        },*/

          this.setupTableRefresher();
          this.loadingPage = false;
          this.updatingAssetIds = [];
          this.cleanUrl();
        })
        .catch((error) => {
          // Handle errors
          console.error("There was a problem with the fetch operation:", error);
        });
    },
    updatePortfolioIds() {
      this.changePageReset({
        page: 1,
        itemsPerPage: this.pagination.itemsPerPage,
        dateRange: this.pagination.dateRange,
        portfolioIds: this.portfolioIds,
      });
    },
    changePageReset(options) {
      let query = {
        portfolioIds: options.portfolioIds,
        page: options.page,
        pageSize: options.itemsPerPage,
        dateRangeFrom: options.dateRange.from,
        dateRangeTo: options.dateRange.to,
      };

      this.$inertia.get(this.route("assets.index", query), {
        preserveState: true,
        onSuccess: () => {
          this.setupTableRefresher();
        },
      });
    },
    changeSorting(options, reload = true) {
      this.currentAttributeFilters = this.currentAttributeFilters.filter(
        (attributeFilter) => attributeFilter.filter != null
      );

      this.currentAttributeFilters.forEach((attributeFilter) => {
        attributeFilter.sortDesc = null;
      });

      options.sortBy.forEach((x, index) => {
        if (this.attributes.some((attribute) => attribute.id == x)) {
          let attributeFilter = this.currentAttributeFilters.find(
            (attributeFilter) => attributeFilter.attributeId == x
          );
          if (attributeFilter != null) {
            attributeFilter.sortDesc = options.sortDesc[index];
            attributeFilter.sortOrder = index;
          } else {
            this.currentAttributeFilters.push({
              attributeId: x,
              sortDesc: options.sortDesc[index],
              sortOrder: index,
              attribute: this.attributes.find((attribute) => attribute.id == x),
            });
          }
        } else {
          let attributeFilter = this.currentAttributeFilters.find(
            (attributeFilter) => attributeFilter.propertyName == x
          );
          if (attributeFilter != null) {
            attributeFilter.sortDesc = options.sortDesc[index];
            attributeFilter.sortOrder = index;
          } else {
            this.currentAttributeFilters.push({
              propertyName: x,
              sortDesc: options.sortDesc[index],
              sortOrder: index,
            });
          }
        }
      });

      if (reload) {
        this.changePage({
          page: 1,
          itemsPerPage: this.pagination.itemsPerPage,
          dateRange: this.pagination.dateRange,
        });
      }
    },
    updateAssetLayout(value, reloadPage = true) {
      localStorage.setItem("saved-asset-layout", JSON.stringify(value));

      this.currentAssetLayout = {
        ...this.currentAssetLayout,
        ...value,
      };

      if (this.currentAssetLayout.sortOrderMappings) {
        const sortOrderKeys = Object.keys(
          this.currentAssetLayout.sortOrderMappings
        );

        for (let key of sortOrderKeys) {
          let sortValue =
            this.currentAssetLayout.sortOrderMappings[key]?.attributeId;

          if (!this.currentAssetLayout.attributeIds.includes(sortValue)) {
            delete this.currentAssetLayout.sortOrderMappings[key];
          }
        }

        this.tableRerenderCounter++;
      }

      EventBus.$emit("assetLayoutUpdated", value);

      if (
        this.postFilters.search != null &&
        !this.postFilters.allAttributes &&
        reloadPage
      ) {
        this.changePage({
          page: 1,
          itemsPerPage: this.pagination.itemsPerPage,
          dateRange: this.pagination.dateRange,
        });
      }
    },
    resetAssetLayout() {
      this.updateAssetLayout(null);
      localStorage.removeItem("saved-asset-layout");
      this.currentAssetLayout = this.getAssetLayoutForFilterType();
    },
    updateAttributeFilters(filters) {
      this.currentAttributeFilters = filters;
      this.setColumnFilters(this.currentAttributeFilters ?? []);
      this.setColumnSorting(this.currentAttributeFilters ?? []);
      this.postFilters = { ...this.postFilters, allResults: false };

      localStorage.setItem("data-table-filters", JSON.stringify(filters));
    },
    setColumnSorting(filters) {
      filters = filters.filter((x) => x.sortDesc != null);
      let sortBy = filters.map((x) => {
        if (x.sortDesc != null) {
          if (x.attributeId != null) {
            return x.attributeId;
          }
          return x.propertyName;
        }
      });
      let sortDesc = filters.map((x) => {
        if (x.sortDesc != null) {
          return x.sortDesc;
        }
      });
      this.pagination.sortBy = sortBy;
      this.pagination.sortDesc = sortDesc;
    },
    setColumnFilters(filters) {
      var columnFilterData = {};

      this.columnFilterProperties.forEach((x) => {
        columnFilterData[`${x}-filter`] = null;
      });
      this.attributes.forEach((x) => {
        columnFilterData[`${x.id}-filter`] = null;
      });
      filters.forEach((x) => {
        if (x.filterBy != null) {
          if (x.attributeId != null) {
            columnFilterData[`${x.attributeId}-filter`] = x.filter;
            columnFilterData[`${x.attributeId}-filterBy`] = x.filterBy;
          } else {
            columnFilterData[`${x.propertyName}-filter`] = x.filter;
            columnFilterData[`${x.propertyName}-filterBy`] = x.filterBy;
          }
        }
      });

      this.columnFilterData = columnFilterData;
    },
    toggleFileMenu() {
      this.showFileMenu = !this.showFileMenu;
    },
    handleAssetFileLinking(assetId, attributeId, formData) {
      fetch(
        this.route("api.assets.attributes.files.link", {
          assetid: assetId,
          attributeid: attributeId,
        }),
        {
          method: "POST",
          body: formData,
        }
      ).then((res) => {
        if (!res.ok) return Promise.reject();

        this.changePage({
          page: this.pagination.page,
          itemsPerPage: this.pagination.itemsPerPage,
        });

        this.refreshFileMenu = true;
      });
    },
    formatCurrency(value) {
      if (currencyFormatter.format(value) == "NaN") {
        return "0,00";
      }
      return currencyFormatter.format(value);
    },
    async removeAssetRange() {
      if (!this.selectedAssets.length) {
        return;
      }

      this.showRemoveAssetRangeConfirmDialog = true;
    },
    handleRemoveAssetRangeConfirmation(status) {
      this.isConfirmed = status;
      if (status) {
        this.confirmRemoveAssetRange();
      }
    },
    async confirmRemoveAssetRange() {
      this.deleteRangeLoading = true;

      const assetIds = this.selectedAssets.map((x) => x.id);

      this.updatingAssetIds = assetIds;

      const form = serialize({
        assetIds: assetIds,
      });

      await fetch(this.route("api.assets.destroy.range"), {
        method: "POST",
        body: form,
      });

      this.deleteRangeLoading = false;

      this.selectedAssets = [];
      setTimeout(() => {
        this.fetchData(this.pagination.dateRange, 1, true, true);
        this.$forceUpdate();
      }, 500);
    },
    verifyAssetRange() {
      if (!this.selectedAssets.length) {
        return;
      }

      this.showVerifyAssetRangeConfirmDialog = true;
    },
    handleVerifyAssetRangeConfirmation(status) {
      this.isConfirmed = status;
      if (status) {
        this.confirmVerifyAssetRange();
      }
    },
    confirmVerifyAssetRange() {
      const form = serialize({
        assetIds: this.selectedAssets.map((x) => x.id),
      });

      this.$inertia.post(
        this.route("assets.emission-factor.bulk-approve"),
        form,
        { preserveScroll: true, preserveState: true }
      );

      this.selectedAssets = [];
      this.changeSorting(this.pagination);
    },
    toggleSelectAllPortfolios() {
      if (this.portfolioIds?.length < this.portfolios.length)
        this.portfolioIds = this.portfolios.map((x) => x.id);
      else this.portfolioIds = [];
    },
    openFormByQueryId() {
      const params = qs.parse(window.location.search.substring(1));

      if (!params.editAssetId) return;

      const asset = this.mappedAssets.find((x) => x.id === params.editAssetId);

      if (!asset) return;

      this.editAsset(asset);

      delete params.editAssetId;

      const path =
        window.location.pathname +
        "?" +
        qs.stringify(params, { arrayFormat: "brackets" });

      window.history.replaceState(null, "", path);
    },
    searchAttributes(value) {
      this.postFilters.search = value;
      this.postFilters.page = 1;
      this.pagination.page = 1;
      if (!value || value.length === 0) {
        this.hasSearched = false;
      } else {
        this.hasSearched = true;
      }
    },

    saveLayout() {
      this.triggerSaveLayout = true;
    },
    reloadAsset(assetId = null) {
      if (!this.modifiedAsset) return;

      let mappedAsset = {
        ...this.modifiedAsset,
      };
      this.modifiedAsset.assetAttributeLinks.forEach((assetAttributeLink) => {
        mappedAsset[assetAttributeLink.attributeId] = assetAttributeLink;
      });

      this.asset = { ...mappedAsset };

      this.updatingAssetIds.push(assetId);
      console.log("updating asset", assetId);

      this.fetchData(
        this.pagination.dateRange,
        this.pagination.page,
        true,
        true // disable skeleton
      );
    },
    handleScroll() {
      // Store horizontal scroll
      const scrollX =
        this.$refs["tableCard"].$refs["dataTable"].$el.children[0].scrollLeft; // child 0 is "v-data-table__wrapper"
      localStorage.setItem("tableScrollX", scrollX);
    },
    disableTableRefresher() {
      if (!this.tableRefresher) return;

      clearInterval(this.tableRefresher);
      this.tableRefresher = null;
    },
    setupTableRefresher() {
      this.disableTableRefresher();

      let refreshDelay = 60000;
      let refreshMaxCount = 10;

      // switch (this.pagination.itemsPerPage) {
      //   case 100:
      //     refreshDelay = 15000;
      //     refreshMaxCount = 7;
      //     break;
      //   case 250:
      //     refreshDelay = 20000;
      //     refreshMaxCount = 5;
      //     break;
      //   case 500:
      //     refreshDelay = 35000;
      //     refreshMaxCount = 3;
      //     break;
      // }

      this.tableRefreshCounter = 0;

      this.tableRefresher = setInterval(() => {
        if (!this.hasAIDefiningRows) return;

        // refreshMaxCount is available through closure
        if (this.tableRefreshCounter >= refreshMaxCount) {
          this.disableTableRefresher();
          return;
        }

        this.tableRerenderCounter++;

        this.tableRefreshCounter++;

        this.fetchData(
          this.pagination.dateRange,
          this.pagination.page,
          true,
          true
        );
      }, refreshDelay);
    },
    attemptOpenImportDialog(selectedFiles) {
      const { percentageFilesUsed, percentageLinesUsed } = this.billingLimits;

      if (percentageFilesUsed >= 100 || percentageLinesUsed >= 100) {
        this.showUpgradeModal = true;
        return;
      }

      this.importAssetsDialog = true;

      if (selectedFiles) {
        this.selectedImportFiles = selectedFiles;
      }
    },
    upgrade() {
      // show upgrade modal
      this.showUpgradeModal = false;
      this.paymentDialog = true;
    },
    cancelUpgradeModal() {
      this.showUpgradeModal = false;
    },
    setMissingAssetName(mappedAsset) {
      const name = mappedAsset[fixedAttributes.generalNameId];
      const entryText = mappedAsset[fixedAttributes.infoEntryTextId];

      if (!name && !!entryText) {
        if (!mappedAsset[fixedAttributes.generalNameId]) {
          mappedAsset[fixedAttributes.generalNameId] = {
            assetId: mappedAsset.id,
            attributeId: fixedAttributes.generalNameId,
            stringValue: entryText?.stringValue,
            searchString: entryText?.stringValue?.toUpperCase(),
            attributeClassId: fixedClasses.generalId,
            attributeClass: {
              id: fixedClasses.generalId,
              fixed: true,
              referenceKey: -1,
            },
            attribute: {
              format: "Text",
              inFixedClass: true,
              id: fixedAttributes.generalNameId,
              stringValue: entryText?.stringValue,
              name: "Asset name",
              referenceKey: -7,
            },
          };
        } else {
          mappedAsset[fixedAttributes.generalNameId].stringValue =
            entryText?.stringValue;
        }
      }
    },
  },
  directives: {
    "sortable-table": sortableTableDirective,
  },
  watch: {
    postFilters: {
      handler: debounce(function () {
        if (
          this.pagination.dateRange.from !== null &&
          this.pagination.dateRange.from !== null
        ) {
          this.changePage({
            page: 1,
            itemsPerPage: this.pagination.itemsPerPage,
            dateRange: this.pagination.dateRange,
          });
        }
      }, 150),
      deep: true,
    },
    selectedLayoutOption: function (to) {
      // Clear sorting order so hidden columns wont effect visible columns
      this.pagination = { ...this.pagination, ...{ sortBy: [], sortDesc: [] } };

      this.currentAssetLayout = this.getAssetLayoutForFilterType(to);
    },
    "currentAssetLayout.sortOrderMappings": function (to) {
      localStorage.setItem("asset-table-header-order", JSON.stringify(to));
    },
    "pagination.sortBy": function (to) {
      localStorage.setItem("pagination-sort-by", JSON.stringify(to));
    },
    "pagination.sortDesc": function (to) {
      localStorage.setItem("pagination-sort-desc", JSON.stringify(to));
    },
  },
  updated() {
    // Set horizontal scroll to the same as before fetching data
    const scrollX = localStorage.getItem("tableScrollX");

    if (scrollX !== null) {
      this.$refs["tableCard"].$refs["dataTable"].$el.children[0].scrollLeft =
        scrollX;

      // make sure handler is removed before adding again
      this.$refs["tableCard"].$refs[
        "dataTable"
      ].$el.children[0].removeEventListener("scroll", this.handleScroll);

      this.$refs["tableCard"].$refs[
        "dataTable"
      ].$el.children[0].addEventListener("scroll", this.handleScroll);
    }
  },
  unmounted() {
    this.$refs["tableCard"].$refs[
      "dataTable"
    ].$el.children[0].removeEventListener("scroll", this.handleScroll);
  },
  mounted() {
    setTimeout(() => this.openFormByQueryId(), 1000);

    this.setupTableRefresher();

    const storedHeaderOrder = JSON.parse(
      localStorage.getItem("asset-table-header-order")
    );

    if (storedHeaderOrder) {
      this.currentAssetLayout.sortOrderMappings = storedHeaderOrder;
    }

    const filters = JSON.parse(localStorage.getItem("data-table-filters"));
    if (filters !== null) {
      this.updateAttributeFilters(filters);
    }

    let _sortBy = JSON.parse(localStorage.getItem("pagination-sort-by"));
    let _sortDesc = JSON.parse(localStorage.getItem("pagination-sort-desc"));

    if (
      _sortBy !== null &&
      _sortDesc !== null &&
      _sortBy.length > 0 &&
      _sortDesc.length > 0
    ) {
      this.pagination.sortBy = _sortBy;
      this.pagination.sortDesc = _sortDesc;
      // triggers reload
      this.changeSorting(this.pagination, false);
    }

    // load once at the end ?
    this.syncDateRange((dateRange) => {
      // Reset page number on date change
      if (
        this.pagination.dateRange.to !== dateRange.to ||
        this.pagination.dateRange.from !== dateRange.from
      ) {
        this.pagination.page = 1;
      }

      this.fetchData(dateRange, this.pagination.page, !this.initialDateFetch);

      if (this.initialDateFetch) {
        this.initialDateFetch = false;
        this.changePage({
          page: 1,
          itemsPerPage: this.pagination.itemsPerPage,
          sortBy: this.pagination.sortBy,
          sortDesc: this.pagination.sortDesc,
          dateRange: dateRange,
        });
      }
    });

    if (
      this.assetFormFactorId &&
      this.assetFormSectorId &&
      this.assetFormCategoryId &&
      this.assetFormRegionId
    ) {
      this.setAssetFactorId(
        this.assetFormSectorId,
        this.assetFormCategoryId,
        this.assetFormRegionId,
        this.assetFormFactorId
      );
    }
  },
  beforeDestroy() {
    this.disableTableRefresher();
  },
};
</script>

<style scoped>
:deep(td > div > span) {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  max-width: 300px;
}
</style>
