<template>
  <div class="flex-grow-1 d-flex justify-center align-center">
    <div class="d-flex justify-center align-center">
      <div class="px-0" fluid style="width: 1313px">
        <v-card
          light
          color="#fff"
          elevation="0"
          rounded="0"
          max-width="100%"
          class="flex-grow-1"
        >
          <div class="px-10 py-10">
            <div class="d-flex flex-row align-center">
              <div class="flex-grow-1">
                <p class="page-title">
                  Emissions overview based on GHG categories
                </p>
              </div>
              <div class="d-flex flex-row">
                <div
                  v-for="(item, index) in [
                    { color: '#266663', name: 'Scope 1' },
                    { color: '#6493BE', name: 'Scope 2' },
                    { color: '#E35E3E', name: 'Scope 3' },
                  ]"
                  :key="index"
                  class="d-flex ml-sm-4 mb-4 mb-sm-0 align-center"
                >
                  <div
                    class="mr-2 rounded-4"
                    :style="
                      'background-color:' +
                      item.color +
                      ';display: inline-block; min-width: 16px; height: 16px; border-radius: 4px;'
                    "
                  ></div>
                  <div style="color: #b4b4b4">{{ item.name }}</div>
                </div>
              </div>
            </div>
            <!---->
            <v-row>
              <v-col v-for="category in categories" :key="category.id" cols="3">
                <icon-with-percentage
                  :title="category.name"
                  :icon-back-color="category.iconColor"
                  :percent-value="category.emissions"
                >
                  <template v-slot:icon>
                    <!-- Dynamically render the icon based on the category -->
                    <component
                      :is="category.iconComponent"
                      :width="category.iconWidth"
                      :height="category.iconHeight"
                      :style="{
                        width: category.iconWidth,
                        height: category.iconHeight,
                        borderRadius: 0,
                      }"
                    ></component>
                  </template>
                </icon-with-percentage>
              </v-col>
              <!--<v-col cols="3">
                
                <icon-with-percentage
                  :title="
                    findCategoryById('e82fdc22-1ae0-4066-a662-ffa744de11a3')
                      ?.name
                  "
                  icon-back-color="#6493BE33"
                  :percent-value="
                    getPercentage(
                      findCategoryById('e82fdc22-1ae0-4066-a662-ffa744de11a3')
                        ?.emissions
                    )
                  "
                >
                  <template v-slot:icon>
                    <ElectricityHeatingIcon
                      width="36px"
                      height="34px"
                      style="width: 36px; height: 34px; border-radius: 0"
                    ></ElectricityHeatingIcon>
                  </template>
                </icon-with-percentage>
                <icon-with-percentage
                  :title="
                    findCategoryById('a588c70c-a620-4d67-b65e-5ef7b11a8f87')
                      ?.name
                  "
                  icon-back-color="#F25D3B33"
                  :percent-value="
                    getPercentage(
                      findCategoryById('a588c70c-a620-4d67-b65e-5ef7b11a8f87')
                        ?.emissions
                    )
                  "
                >
                  <template v-slot:icon>
                    <PurchasedGoodsServicesIcon
                      width="32px"
                      height="30px"
                      style="width: 32px; height: 30px; border-radius: 0"
                    ></PurchasedGoodsServicesIcon>
                  </template>
                </icon-with-percentage>
                <icon-with-percentage
                  :title="
                    findCategoryById('3b8537c0-102c-40a1-be40-796ee826b9f8')
                      ?.name
                  "
                  icon-back-color="#F25D3B33"
                  :percent-value="
                    getPercentage(
                      findCategoryById('3b8537c0-102c-40a1-be40-796ee826b9f8')
                        ?.emissions
                    )
                  "
                >
                  <template v-slot:icon>
                    <UpstreamLeasedAssetsIcon
                      width="30px"
                      height="28px"
                      style="width: 30px; height: 28px; border-radius: 0"
                    ></UpstreamLeasedAssetsIcon>
                  </template>
                </icon-with-percentage>
                <icon-with-percentage
                  :title="
                    findCategoryById('783b4f4b-54c0-48c4-8949-b2cdf4a03911')
                      ?.name
                  "
                  icon-back-color="#F25D3B33"
                  :percent-value="
                    getPercentage(
                      findCategoryById('783b4f4b-54c0-48c4-8949-b2cdf4a03911')
                        ?.emissions
                    )
                  "
                >
                  <template v-slot:icon>
                    <DownstreamTransportationAndDistributionIcon
                      width="32px"
                      height="32px"
                      style="width: 32px; height: 32px; border-radius: 0"
                    ></DownstreamTransportationAndDistributionIcon>
                  </template>
                </icon-with-percentage>
                <icon-with-percentage
                  :title="
                    findCategoryById('7595baac-87cd-40b5-a907-b1899b7fc62c')
                      ?.name
                  "
                  icon-back-color="#F25D3B33"
                  :percent-value="
                    getPercentage(
                      findCategoryById('7595baac-87cd-40b5-a907-b1899b7fc62c')
                        ?.emissions
                    )
                  "
                >
                  <template v-slot:icon>
                    <DownstreamTransportationAndDistributionIcon
                      width="32px"
                      height="32px"
                      style="width: 32px; height: 32px; border-radius: 0"
                    ></DownstreamTransportationAndDistributionIcon>
                  </template>
                </icon-with-percentage>
              </v-col>
              <v-col cols="3">
                <icon-with-percentage
                  :title="
                    findCategoryById('3edee4c8-a1e4-4a77-a978-5da86d2ad7ba')
                      ?.name
                  "
                  icon-back-color="#26666333"
                  :percent-value="
                    getPercentage(
                      findCategoryById('3edee4c8-a1e4-4a77-a978-5da86d2ad7ba')
                        ?.emissions
                    )
                  "
                >
                  <template v-slot:icon>
                    <CompanyFacilitiesIcon
                      width="29px"
                      height="31px"
                      style="width: 29px; height: 31px; border-radius: 0"
                    ></CompanyFacilitiesIcon>
                  </template>
                </icon-with-percentage>
                <icon-with-percentage
                  :title="
                    findCategoryById('4eddd004-d305-4f4c-8ed5-a7d131f31db2')
                      ?.name
                  "
                  icon-back-color="#F25D3B33"
                  :percent-value="
                    getPercentage(
                      findCategoryById('4eddd004-d305-4f4c-8ed5-a7d131f31db2')
                        ?.emissions
                    )
                  "
                >
                  <template v-slot:icon>
                    <ProcessingOfSoldProductsIcon
                      width="32px"
                      height="32px"
                      style="width: 32px; height: 32px; border-radius: 0"
                    ></ProcessingOfSoldProductsIcon>
                  </template>
                </icon-with-percentage>
                <icon-with-percentage
                  :title="
                    findCategoryById('0fcb2df8-35bf-429e-bf6f-f73851616b58')
                      ?.name
                  "
                  icon-back-color="#F25D3B33"
                  :percent-value="
                    getPercentage(
                      findCategoryById('0fcb2df8-35bf-429e-bf6f-f73851616b58')
                        ?.emissions
                    )
                  "
                >
                  <template v-slot:icon>
                    <UseOfSoldProductsIcon
                      width="43px"
                      height="28px"
                      style="width: 34px; height: 28px; border-radius: 0"
                    ></UseOfSoldProductsIcon>
                  </template>
                </icon-with-percentage>
                <icon-with-percentage
                  :title="
                    findCategoryById('06b4a64c-d4be-43d9-bf73-6968fc778414')
                      ?.name
                  "
                  icon-back-color="#F25D3B33"
                  :percent-value="
                    getPercentage(
                      findCategoryById('06b4a64c-d4be-43d9-bf73-6968fc778414')
                        ?.emissions
                    )
                  "
                >
                  <template v-slot:icon>
                    <EmploteeCommutingIcon
                      width="36px"
                      height="27px"
                      style="width: 36px; height: 27px; border-radius: 0"
                    ></EmploteeCommutingIcon>
                  </template>
                </icon-with-percentage>
                <icon-with-percentage
                  :title="
                    findCategoryById('a431af20-eb08-420d-8a62-9e2d2f011f28')
                      ?.name
                  "
                  icon-back-color="#F25D3B33"
                  :percent-value="
                    getPercentage(
                      findCategoryById('a431af20-eb08-420d-8a62-9e2d2f011f28')
                        ?.emissions
                    )
                  "
                >
                  <template v-slot:icon>
                    <FranchisesIcon
                      width="32px"
                      height="28px"
                      style="width: 32px; height: 28px; border-radius: 0"
                    ></FranchisesIcon>
                  </template>
                </icon-with-percentage>
              </v-col>
              <v-col cols="3">
                <icon-with-percentage
                  :title="
                    findCategoryById('24f8d5db-3871-464a-a52f-af31df23c978')
                      ?.name
                  "
                  icon-back-color="#F25D3B33"
                  :percent-value="
                    getPercentage(
                      findCategoryById('24f8d5db-3871-464a-a52f-af31df23c978')
                        ?.emissions
                    )
                  "
                >
                  <template v-slot:icon>
                    <CapitalGoodsIcon
                      width="31px"
                      height="34px"
                      style="width: 31px; height: 34px; border-radius: 0"
                    ></CapitalGoodsIcon>
                  </template>
                </icon-with-percentage>
                <icon-with-percentage
                  :title="
                    findCategoryById('97b1cb84-a7a0-4992-a0af-24d61f415510')
                      ?.name
                  "
                  icon-back-color="#F25D3B33"
                  :percent-value="
                    getPercentage(
                      findCategoryById('97b1cb84-a7a0-4992-a0af-24d61f415510')
                        ?.emissions
                    )
                  "
                >
                  <template v-slot:icon>
                    <BusinessTravelIcon
                      width="28px"
                      height="28px"
                      style="width: 28px; height: 28px; border-radius: 0"
                    ></BusinessTravelIcon>
                  </template>
                </icon-with-percentage>
                <icon-with-percentage
                  :title="
                    findCategoryById('e966141a-3d58-4096-967d-2f4e0776c75a')
                      ?.name
                  "
                  icon-back-color="#F25D3B33"
                  :percent-value="
                    getPercentage(
                      findCategoryById('e966141a-3d58-4096-967d-2f4e0776c75a')
                        ?.emissions
                    )
                  "
                >
                  <template v-slot:icon>
                    <WasteGeneratedInOperationsIcon
                      width="27px"
                      height="29px"
                      style="width: 27px; height: 29px; border-radius: 0"
                    ></WasteGeneratedInOperationsIcon>
                  </template>
                </icon-with-percentage>
                <icon-with-percentage
                  :title="
                    findCategoryById('3057fd53-9d3b-412a-9050-f8ad989e3727')
                      ?.name
                  "
                  icon-back-color="#F25D3B33"
                  :percent-value="
                    getPercentage(
                      findCategoryById('3057fd53-9d3b-412a-9050-f8ad989e3727')
                        ?.emissions
                    )
                  "
                >
                  <template v-slot:icon>
                    <DownStreamLeasedAssetsIcon
                      width="30px"
                      height="28px"
                      style="width: 30px; height: 28px; border-radius: 0"
                    ></DownStreamLeasedAssetsIcon>
                  </template>
                </icon-with-percentage>
              </v-col>
              <v-col cols="3">
                <icon-with-percentage
                  :title="
                    findCategoryById('b0b345b4-87ad-4de5-b98f-4648d4c51edf')
                      ?.name
                  "
                  icon-back-color="#26666333"
                  :percent-value="
                    getPercentage(
                      findCategoryById('b0b345b4-87ad-4de5-b98f-4648d4c51edf')
                        ?.emissions
                    )
                  "
                >
                  <template v-slot:icon>
                    <CompanyVeiclesIcon
                      width="32px"
                      height="24px"
                      style="width: 32px; height: 24px; border-radius: 0"
                    ></CompanyVeiclesIcon>
                  </template>
                </icon-with-percentage>
                <icon-with-percentage
                  :title="
                    findCategoryById('023053e9-84e3-47cb-9692-dc0243794af9')
                      ?.name
                  "
                  icon-back-color="#F25D3B33"
                  :percent-value="
                    getPercentage(
                      findCategoryById('023053e9-84e3-47cb-9692-dc0243794af9')
                        ?.emissions
                    )
                  "
                >
                  <template v-slot:icon>
                    <FueldAndEnergyRelatedActivitiesIcon
                      width="20px"
                      height="23px"
                      style="width: 20px; height: 23px; border-radius: 0"
                    ></FueldAndEnergyRelatedActivitiesIcon>
                  </template>
                </icon-with-percentage>
                <icon-with-percentage
                  :title="
                    findCategoryById('c3e8af39-30ee-44ab-84f7-58b28077a0de')
                      ?.name
                  "
                  icon-back-color="#F25D3B33"
                  :percent-value="
                    getPercentage(
                      findCategoryById('c3e8af39-30ee-44ab-84f7-58b28077a0de')
                        ?.emissions
                    )
                  "
                >
                  <template v-slot:icon>
                    <InvestmentsIcon
                      width="32px"
                      height="32px"
                      style="width: 32px; height: 32px; border-radius: 0"
                    ></InvestmentsIcon>
                  </template>
                </icon-with-percentage>
                <icon-with-percentage
                  :title="
                    findCategoryById('a147d878-dca6-436d-89ff-ab210f302d96')
                      ?.name
                  "
                  icon-back-color="#F25D3B33"
                  :percent-value="
                    getPercentage(
                      findCategoryById('a147d878-dca6-436d-89ff-ab210f302d96')
                        ?.emissions
                    )
                  "
                >
                  <template v-slot:icon>
                    <EndOfLifeTreatmentOfSoldProductsIcon
                      width="30px"
                      height="28px"
                      style="width: 30px; height: 28px; border-radius: 0"
                    ></EndOfLifeTreatmentOfSoldProductsIcon>
                  </template>
                </icon-with-percentage>
              </v-col>-->
            </v-row>
          </div>
        </v-card>
      </div>
    </div>
  </div>
</template>

<script>
import IconWithPercentage from "../Outputs/IconWithPercentage.vue";
import CompanyFacilitiesIcon from "../../../src/assets/svg/report-company-facilities.svg";
import CapitalGoodsIcon from "../../../src/assets/svg/report-capital-goods.svg";
import ElectricityHeatingIcon from "../../../src/assets/svg/report-electricity-and-heating.svg";
import ElectricityMarketBasedIcon from "../../../src/assets/svg/report-electricity-market-based.svg";
import ElectricityLocationBasedIcon from "../../../src/assets/svg/report-electricity-location-based.svg";
import SteamHeatingCoolingIcon from "../../../src/assets/svg/report-steam-heating-cooling.svg";
import BusinessTravelIcon from "../../../src/assets/svg/report-business-travel.svg";
import CompanyVehiclesIcon from "../../../src/assets/svg/report-company-vehicles.svg";
import DownstreamLeasedAssetsIcon from "../../../src/assets/svg/report-downstream-leased-assets.svg";
import DownstreamTransportationAndDistributionIcon from "../../../src/assets/svg/report-downstream-transportation-and-distribution.svg";
import EmployeeCommutingIcon from "../../../src/assets/svg/report-employee-commuting.svg";
import EndOfLifeTreatmentOfSoldProductsIcon from "../../../src/assets/svg/report-end-of-life-treatment-of-sold-products.svg";
import FranchisesIcon from "../../../src/assets/svg/report-franchises.svg";
import FuelAndEnergyRelatedActivitiesIcon from "../../../src/assets/svg/report-fuel-and-energy-related-activities.svg";
import ProcessingOfSoldProductsIcon from "../../../src/assets/svg/report-processing-of-sold-products.svg";
import PurchasedGoodsServicesIcon from "../../../src/assets/svg/report-purchased-goods-services.svg";
import UpstreamLeasedAssetsIcon from "../../../src/assets/svg/report-upstream-leased-assets.svg";
import WasteGeneratedInOperationsIcon from "../../../src/assets/svg/report-waste-generated-in-operations.svg";
import UseOfSoldProductsIcon from "../../../src/assets/svg/report-use-of-sold-products.svg";
import InvestmentsIcon from "../../../src/assets/svg/report-investments.svg";
import GlobalDateRange from "../../mixins/GlobalDateRange.vue";

export default {
  components: {
    IconWithPercentage,
    CompanyFacilitiesIcon,
    CapitalGoodsIcon,
    ElectricityHeatingIcon,
    ElectricityMarketBasedIcon,
    ElectricityLocationBasedIcon,
    SteamHeatingCoolingIcon,
    BusinessTravelIcon,
    CompanyVehiclesIcon,
    DownstreamLeasedAssetsIcon,
    DownstreamTransportationAndDistributionIcon,
    EmployeeCommutingIcon,
    EndOfLifeTreatmentOfSoldProductsIcon,
    FranchisesIcon,
    FuelAndEnergyRelatedActivitiesIcon,
    ProcessingOfSoldProductsIcon,
    PurchasedGoodsServicesIcon,
    UpstreamLeasedAssetsIcon,
    WasteGeneratedInOperationsIcon,
    UseOfSoldProductsIcon,
    InvestmentsIcon,
  },
  mixins: [GlobalDateRange],
  data() {
    return {
      ghgCategorySummariesCurrentPeriod: [],
      categories: [
        //NOTE: Removed because of the split of el sources on the backend
        // {
        //   id: "e82fdc22-1ae0-4066-a662-ffa744de11a3",
        //   name: "Electricity Heating",
        //   iconColor: "#6493BE33",
        //   emissionPercent: 0,
        //   iconComponent: "ElectricityHeatingIcon",
        //   iconWidth: "36px",
        //   iconHeight: "34px",
        // },
        {
          id: "292656bd-cae4-4265-ba52-7e69c1d3e78f",
          name: "Purchased electricity Market Based",
          iconColor: "#6493BE33",
          emissionPercent: 0,
          iconComponent: "ElectricityMarketBasedIcon",
          iconWidth: "21px",
          iconHeight: "30px",
        },
        {
          id: "b0672a9c-3008-406a-b6f8-02ea99465b7e",
          name: "Purchased electricity Location Based",
          iconColor: "#6493BE33",
          emissionPercent: 0,
          iconComponent: "ElectricityLocationBasedIcon",
          iconWidth: "32px",
          iconHeight: "29px",
        },
        {
          id: "b2a165b7-5e04-482f-92bd-9366707ddd0e",
          name: "Steam, heating & cooling for own use",
          iconColor: "#6493BE33",
          emissionPercent: 0,
          iconComponent: "SteamHeatingCoolingIcon",
          iconWidth: "26px",
          iconHeight: "34px",
        },
        {
          id: "a588c70c-a620-4d67-b65e-5ef7b11a8f87",
          name: "Purchased Goods/Services",
          iconColor: "#F25D3B33",
          emissions: 0,
          iconComponent: "PurchasedGoodsServicesIcon",
          iconWidth: "32px",
          iconHeight: "30px",
        },
        {
          id: "3b8537c0-102c-40a1-be40-796ee826b9f8",
          name: "Upstream Leased Assets",
          iconColor: "#F25D3B33",
          emissions: 0,
          iconComponent: "UpstreamLeasedAssetsIcon",
          iconWidth: "30px",
          iconHeight: "28px",
        },
        {
          id: "783b4f4b-54c0-48c4-8949-b2cdf4a03911",
          name: "Downstream Transportation and Distribution",
          iconColor: "#F25D3B33",
          emissions: 0,
          iconComponent: "DownstreamTransportationAndDistributionIcon",
          iconWidth: "32px",
          iconHeight: "32px",
        },
        {
          id: "7595baac-87cd-40b5-a907-b1899b7fc62c",
          name: "Upstream Transportation and Distribution",
          iconColor: "#F25D3B33",
          emissions: 0,
          iconComponent: "DownstreamTransportationAndDistributionIcon",
          iconWidth: "32px",
          iconHeight: "32px",
        },
        {
          id: "3edee4c8-a1e4-4a77-a978-5da86d2ad7ba",
          name: "Company Facilities",
          iconColor: "#26666333",
          emissions: 0,
          iconComponent: "CompanyFacilitiesIcon",
          iconWidth: "29px",
          iconHeight: "31px",
        },
        {
          id: "4eddd004-d305-4f4c-8ed5-a7d131f31db2",
          name: "Processing of Sold Products",
          iconColor: "#F25D3B33",
          emissions: 0,
          iconComponent: "ProcessingOfSoldProductsIcon",
          iconWidth: "32px",
          iconHeight: "32px",
        },
        {
          id: "0fcb2df8-35bf-429e-bf6f-f73851616b58",
          name: "Use of Sold Products",
          iconColor: "#F25D3B33",
          emissions: 0,
          iconComponent: "UseOfSoldProductsIcon",
          iconWidth: "43px",
          iconHeight: "28px",
        },
        {
          id: "06b4a64c-d4be-43d9-bf73-6968fc778414",
          name: "Employee Commuting",
          iconColor: "#F25D3B33",
          emissions: 0,
          iconComponent: "EmployeeCommutingIcon",
          iconWidth: "36px",
          iconHeight: "27px",
        },
        {
          id: "a431af20-eb08-420d-8a62-9e2d2f011f28",
          name: "Franchises",
          iconColor: "#F25D3B33",
          emissions: 0,
          iconComponent: "FranchisesIcon",
          iconWidth: "32px",
          iconHeight: "28px",
        },
        {
          id: "24f8d5db-3871-464a-a52f-af31df23c978",
          name: "Capital Goods",
          iconColor: "#F25D3B33",
          emissions: 0,
          iconComponent: "CapitalGoodsIcon",
          iconWidth: "31px",
          iconHeight: "34px",
        },
        {
          id: "97b1cb84-a7a0-4992-a0af-24d61f415510",
          name: "Business Travel",
          iconColor: "#F25D3B33",
          emissions: 0,
          iconComponent: "BusinessTravelIcon",
          iconWidth: "28px",
          iconHeight: "28px",
        },
        {
          id: "e966141a-3d58-4096-967d-2f4e0776c75a",
          name: "Waste Generated in Operations",
          iconColor: "#F25D3B33",
          emissions: 0,
          iconComponent: "WasteGeneratedInOperationsIcon",
          iconWidth: "27px",
          iconHeight: "29px",
        },
        {
          id: "3057fd53-9d3b-412a-9050-f8ad989e3727",
          name: "Downstream Leased Assets",
          iconColor: "#F25D3B33",
          emissions: 0,
          iconComponent: "DownstreamLeasedAssetsIcon",
          iconWidth: "30px",
          iconHeight: "28px",
        },
        {
          id: "b0b345b4-87ad-4de5-b98f-4648d4c51edf",
          name: "Company Vehicles",
          iconColor: "#26666333",
          emissions: 0,
          iconComponent: "CompanyVehiclesIcon",
          iconWidth: "32px",
          iconHeight: "24px",
        },
        {
          id: "023053e9-84e3-47cb-9692-dc0243794af9",
          name: "Fuel and Energy-related Activities",
          iconColor: "#F25D3B33",
          emissions: 0,
          iconComponent: "FuelAndEnergyRelatedActivitiesIcon",
          iconWidth: "20px",
          iconHeight: "23px",
        },
        {
          id: "c3e8af39-30ee-44ab-84f7-58b28077a0de",
          name: "Investments",
          iconColor: "#F25D3B33",
          emissions: 0,
          iconComponent: "InvestmentsIcon",
          iconWidth: "32px",
          iconHeight: "32px",
        },
        {
          id: "a147d878-dca6-436d-89ff-ab210f302d96",
          name: "End of Life Treatment of Sold Products",
          iconColor: "#F25D3B33",
          emissions: 0,
          iconComponent: "EndOfLifeTreatmentOfSoldProductsIcon",
          iconWidth: "30px",
          iconHeight: "28px",
        },
      ],
    };
  },
  mounted() {
    this.syncDateRange((dateRange) => {
      this.fetchData(dateRange);
    });
  },
  methods: {
    async fetchData(dateRange) {
      await fetch(
        this.route("api.dashboard.infographics.lists.ghgCategorySummaries", {
          fromDate: dateRange.from,
          toDate: dateRange.to,
        })
      )
        .then((response) => {
          if (!response.ok) {
            return Promise.reject();
          }
          return response.json();
        })
        .then((data) => {
          //Removing the total of the el and heating since we split it in 3
          const filteredData = data.filter(
            (category) => category.id !== "e82fdc22-1ae0-4066-a662-ffa744de11a3"
          );

          this.ghgCategorySummariesCurrentPeriod = filteredData;
          this.addEmissionPercentToCategories();
          this.orderCategories();
        })
        .catch((error) => {
          console.warn("Could not load data", error);
        });
    },
    addEmissionPercentToCategories() {
      Object.keys(this.categories).forEach((key) => {
        const category = this.categories[key];

        this.categories[key].emissions = this.getPercentage(
          this.findCategoryById(category.id)?.emissions
        );
      });
    },
    orderCategories() {
      this.categories = this.categories.sort((a, b) => {
        return a.emissions >= b.emissions ? -1 : 1;
      });
    },
    findCategoryById(categoryId) {
      return this.ghgCategorySummariesCurrentPeriod.find(
        (cat) => cat.id === categoryId
      );
    },
    getPercentage(emissions) {
      const total = this.ghgCategorySummariesCurrentPeriod.reduce(
        (acc, e) => acc + e.emissions,
        0
      );

      if (isNaN(total) || isNaN(emissions) || total === 0) return 0;

      return Math.round((emissions / total) * 1000) / 10;
    },
  },
};
</script>

<style lang="scss" scoped>
.page-title {
  font-size: 35px;
}
</style>
