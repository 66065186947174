<template>
  <div>
    <v-row no-gutters>
      <v-col
        cols="12"
        v-for="content in solution.contents"
        :key="content.id"
        class="mb-4"
      >
        <v-card elevation="3">
          <v-card-title>{{ content.informationType.name }}</v-card-title>
          <v-card-text class="pb-0">
            <div v-html="content.description"></div>
          </v-card-text>
          <v-divider v-if="contentHasFiles(content)" class="mx-4"></v-divider>
          <v-card-text>
            <v-row>
              <v-col
                cols="6"
                sm="3"
                lg="1"
                v-for="file in content.files"
                :key="file.id"
              >
                <a
                  :href="
                    route('api.files.content', {
                      id: file.id,
                      filename: file.originalName,
                    })
                  "
                  :title="file.originalName"
                  target="_blank"
                  class="d-flex flex-column fill-height"
                >
                  <v-img
                    contain
                    :src="getFileThumbnail(file)"
                    class="mb-1 file-image"
                  ></v-img>
                  <span class="file-title text-center">
                    {{ file.originalName }}
                  </span>
                </a>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>
<script>
import { appLayout } from "@/util/layout";

import SvgPdf from "../../assets/svg/file-pdf-regular.svg";
import SvgExcel from "../../assets/svg/file-excel-regular.svg";
import SvgWord from "../../assets/svg/file-word-regular.svg";
import SvgImage from "../../assets/svg/image-regular.svg";
import SvgFile from "../../assets/svg/file-regular.svg";

export default {
  layout: appLayout({ title: "pages.solutions.index.title", hideMenu: true }),
  props: {
    solution: Object,
  },
  data() {
    return {
      icons: {
        SvgPdf,
        SvgExcel,
        SvgWord,
        SvgImage,
        SvgFile,
      },
    };
  },
  methods: {
    getFileThumbnail(file) {
      switch (file.fileType) {
        case "pdf":
          return this.icons.SvgPdf;
        case "spreadsheet":
          return this.icons.SvgExcel;
        case "document":
          return this.icons.SvgWord;
        case "image":
          return this.route("api.files.content", {
            id: file.id,
            filename: file.originalName,
            thumbnail: true,
          });
        case "folder":
          return this.icons.SvgFolder;
        default:
          return this.icons.SvgFile;
      }
    },
    contentHasFiles(content) {
      return !!content?.files?.length;
    },
  },
};
</script>
<style scoped>
.file-title {
  display: block;
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.file-image {
  max-height: 4rem;
}
</style>
