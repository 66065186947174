<template>
  <v-tab-item :transition="false" :value="tabHref">
    <div class="fill-height fill-width">
      <v-row>
        <v-col cols="12" sm="6" xl="3">
          <div class="flex-grow-1 white pa-4 pr-2 d-flex align-center">
            <v-text-field
              :label="'Search in Custom emission factors'"
              color="#686868"
              class="search-field rounded-0 pt-0"
              height="44"
              light
              hide-details="auto"
              background-color="#F7F7F7"
              filled
              solo
              dense
              prepend-inner-icon="mdi-magnify"
              flat
              clearable
              @change="(val) => updateSearch(val)"
              :value="filters.emissionFactorSearch"
            ></v-text-field>
          </div>
        </v-col>
        <v-spacer></v-spacer>
        <v-col class="d-flex justify-end">
          <v-btn
            large
            class="align-self-stretch align-self-sm-center mt-5 mr-4 mt-sm-0"
            elevation="0"
            tile
            color="#F25D3B"
            dark
            @click="showForm = true"
          >
            <v-icon class="pr-3 block" color="#fff" small>mdi-plus</v-icon>
            {{ $t("common.actions.create") }}
          </v-btn>
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <v-data-table
            v-model="selectedCustomEmissionFactors"
            class="data-table elevation-0 row-pointer rounded-r-0 fill-height"
            :headers="headers"
            :items="customEmissionFactors.data"
            :server-items-length="customEmissionFactors.totalCount"
            :options.sync="pagination"
            :footer-props="tableFooterProps"
            :no-data-text="$t('common.dataTable.noData')"
            @dblclick:row="(event, { item }) => editCustomEmissionFactors(item)"
            @update:page="changePage(pagination)"
            @update:items-per-page="changePage(pagination)"
            @update:sort-by="changePage(pagination)"
            @update:sort-desc="changePage(pagination)"
            hide-default-footer
            disable-sort
          >
            <template #item.edit="{ item }">
              <v-menu offset-y min-width="120px" class="px-4" left>
                <template v-slot:activator="{ on, attrs }">
                  <div class="d-flex justify-end">
                    <v-btn v-bind="attrs" v-on="on" icon
                      ><v-icon>mdi-dots-vertical</v-icon></v-btn
                    >
                  </div>
                </template>
                <v-list>
                  <v-list-item @click="editCustomEmissionFactors(item)">
                    <v-list-item-title>
                      {{ $t("common.actions.edit") }}
                    </v-list-item-title>
                  </v-list-item>
                  <v-list-item @click="destroyEmissionFactor(item.id)">
                    <v-list-item-title>
                      {{ $t("common.actions.delete") }}
                    </v-list-item-title>
                  </v-list-item>
                </v-list>
              </v-menu>
            </template>
          </v-data-table>
        </v-col>
      </v-row>
    </div>
    <custom-emission-factors-form
      v-model="showForm"
      :custom-emission-factor="editingForm"
      @setTab="$emit('setTab', tabId)"
      @ruleChanged="() => (editingForm = null)"
      :currencies="currencies"
      :attributes="attributes"
      :defaultRegionId="defaultRegionId"
    />
    <confirm-dialog
      v-model="showConfirmDialog"
      v-show="showConfirmDialog"
      :confirm-message="confirmMessage"
      @confirmed="handleConfirmation"
    ></confirm-dialog>
  </v-tab-item>
</template>
<script>
import debounce from "lodash/debounce";
import qs from "qs";
import { footerProps } from "@/util/dataTable";
import CustomEmissionFactorsForm from "../CustomEmissionFactorsForm.vue";
import ConfirmDialog from "../../../Components/ConfirmDialog.vue";

export default {
  components: { CustomEmissionFactorsForm, ConfirmDialog },
  props: {
    tabHref: String,
    tabId: Number,
    customEmissionFactors: Object,
    currencies: Array,
    attributes: Array,
    defaultRegionId: String,
  },
  emits: ["updateCustomEmissionFactorsSearch", "setTab"],
  data() {
    const searchParams = qs.parse(window.location.search.substring(1));

    return {
      pagination: {
        page: this.customEmissionFactors.currentPage,
        itemsPerPage: this.customEmissionFactors.pageSize,
        sortBy: ["name"],
        sortDesc: [false],
      },
      filters: {
        emissionFactorSearch: searchParams["emissionFactorSearch"] ?? null,
      },
      tableFooterProps: footerProps,
      selectedCustomEmissionFactors: [],
      showForm: false,
      editingForm: null,
      showConfirmDialog: false,
      confirmMessage: this.$t(
        "pages.settings.index.confirmDeleteCustomEmissionFactor"
      ),
      itemId: String,
    };
  },
  computed: {
    headers() {
      return [
        {
          text: this.$t("common.fieldNames.uuid"),
          value: "activityId",
        },
        {
          text: this.$t("common.fieldNames.activityName"),
          value: "name",
        },
        {
          text: this.$t("common.fieldNames.calculationMethod"),
          value: "calculationMethod",
        },
        {
          text: this.$t("common.fieldNames.sectorCategoryRegion"),
          value: "sectorCategoryRegion",
        },
        {
          text: this.$t("common.fieldNames.activityUnit"),
          value: "activityUnit",
        },
        {
          text: this.$t("common.fieldNames.emissionFactor"),
          value: "emissionFactor",
        },
        {
          text: "",
          value: "edit",
          sortable: false,
        },
      ];
    },
  },

  methods: {
    updateSearch(value) {
      this.filters.emissionFactorSearch = value;
    },
    changePage(options) {
      const existingQuery = qs.parse(window.location.search.substring(1));

      const query = {
        ...existingQuery,
        rulePage: options.page,
        rulePageSize: options.itemsPerPage,
        emissionFactorSearch: this.filters?.emissionFactorSearch ?? null,
        ruleSortBy:
          typeof options.sortBy !== "undefined" ? options.sortBy[0] : null,
        ruleSortDesc:
          typeof options.sortDesc !== "undefined" ? options.sortDesc[0] : null,
      };

      this.$inertia.get(
        this.route("settings.index", query),
        {},
        {
          preserveState: true,
          preserveScroll: true,
          onFinish: () => {
            this.$emit("setTab", this.tabId);
          },
        }
      );
    },
    editCustomEmissionFactors(item) {
      this.editingForm = this.$inertia.form({
        ...item,
        applyToExisting: true,
      });

      this.showForm = true;
    },
    destroyEmissionFactor(id) {
      this.itemId = id;
      this.showConfirmDialog = true;
    },
    handleConfirmation(status) {
      this.isConfirmed = status;
      if (status) {
        this.confirmDestroyEmissionFactor();
      }
    },
    confirmDestroyEmissionFactor() {
      this.$inertia.delete(
        this.route("settings.emissionFactor.destroy", this.itemId),
        {
          preserveState: true,
          preserveScroll: true,
          onFinish: () => {
            this.$emit("setTab", this.tabId);
          },
        }
      );
    },
    getActionName(action) {
      const attributeName = action.attribute?.name;

      return attributeName ?? action.propertyName;
    },
  },
  watch: {
    filters: {
      handler: debounce(function () {
        this.pagination = {
          page: 1,
          itemsPerPage: 100,
          sortBy: ["name"],
          sortDesc: [false],
        };
        this.changePage(this.pagination);
      }, 250),
      deep: true,
    },
  },
};
</script>
<style lang="scss" scoped>
.data-table :deep(tr) {
  height: 70px !important;
}
</style>
