<template>
  <v-card light color="#fff" elevation="0" rounded="0" max-width="100%">
    <div class="px-5 py-5 fill-height d-flex flex-column">
      <div class="d-flex flex-column flex-sm-row">
        <h3 class="pb-8 font-weight-regular text-h5 flex-grow-1">
          {{ title }}
        </h3>
        <div class="d-flex flex-column flex-sm-row">
          <div v-for="(value, index) in chartSeries" :key="index">
            <div class="d-flex ml-sm-4 mb-4 mb-sm-0">
              <div
                class="mr-2 rounded-4"
                :style="
                  'background-color:' +
                  chartOptions.colors[index] +
                  ';display: inline-block; min-width: 16px; height: 16px; border-radius: 4px;'
                "
              ></div>
              <div style="color: #b4b4b4">{{ value.name }}</div>
            </div>
          </div>
        </div>
      </div>

      <div
        class="d-flex flex-column flex-sm-row align-center flex-grow-1 overflow-hidden"
      >
        <apexchart
          v-if="maxVal > 0"
          style="width: 100%"
          type="area"
          :options="chartOptions"
          :series="chartSeries"
          height="300px"
          ref="chart"
        ></apexchart>
        <p v-else>{{ $t("components.cards.chart.noData") }}</p>
      </div>
    </div>
  </v-card>
</template>

<script>
import { integerNumberFormatter } from "../../util/formatters";

export default {
  props: {
    title: String,
    labels: Array,
    colors: Array,
    series: Array,
    compareSeries: Array,
    xAxisCategories: Array,
    maxVal: Number,
    unit: {
      type: String,
      default: "kg CO2e",
    },
    seriesLabel: String,
    compareSeriesLabel: String,
  },
  computed: {
    chartSeries() {
      return [
        {
          name: this.seriesLabel,
          data: this.series,
        },
        {
          name: this.compareSeriesLabel,
          data: this.compareSeries,
        },
      ];
    },
  },
  data() {
    return {
      chartOptions: {
        fill: {
          type: "gradient",
          gradient: {
            shadeIntensity: 1,
            inverseColors: false,
            opacityFrom: 0.45,
            opacityTo: 0.05,
            stops: [20, 100, 100, 100],
          },
        },
        chart: {
          type: "line",
          toolbar: {
            show: false,
          },
          animations: {
            dynamicAnimation: {
              enabled: true,
              speed: 1350,
            },
          },
          foreColor: "#B4B4B4",
        },
        tooltip: {
          style: {
            fontSize: "15px",
          },
          y: {
            formatter: (seriesName) => {
              const formattedNumber = integerNumberFormatter.format(seriesName);

              return (
                formattedNumber + " " + this.unit
              ); /* @todo: cant use unit string prop here */
            },
          },
        },
        colors: ["#266663", "#F25D3B"],
        dataLabels: {
          enabled: false,
        },
        stroke: {
          width: 2,
          curve: "straight",
        },
        title: {
          show: false,
        },
        grid: {
          borderColor: "#F4F2F2",
          row: {
            colors: ["transparent", "transparent"], // takes an array which will be repeated on columns
            opacity: 0.5,
          },
        },
        xaxis: {
          categories: this.xAxisCategories,
          title: {
            text: "",
          },
        },
        yaxis: {
          title: {
            text: "",
          },
          forceNiceScale: true,
          labels: {
            formatter: function (val) {
              if (val > 999) {
                return Math.abs(val) > 999
                  ? Math.sign(val) * (Math.abs(val) / 1000).toFixed(1) + "k"
                  : Math.sign(val) * Math.abs(val);
              }
              return parseFloat(val).toFixed(2);
            },
          },
          min: 0,
          max: this.maxVal,
        },
        legend: {
          show: false,
          position: "top",
          horizontalAlign: "right",
          floating: true,
          offsetY: -25,
          offsetX: -5,
        },
      },
    };
  },
  mounted() {},
};
</script>

<style lang="scss" scoped>
:deep(.apexcharts-tooltip) {
  background: #1e1e1e !important;
  color: white;
  padding: 13px 15px;
  min-width: 300px;
  font-family: "Lexend", Arial, sans-serif !important;
  border: none !important;
}

:deep(.apexcharts-tooltip.apexcharts-theme-light .apexcharts-tooltip-title) {
  display: none;
}

:deep(.apexcharts-tooltip-series-group.apexcharts-active, .apexcharts-tooltip-series-group:last-child) {
  padding-bottom: 0px;
}

:deep(.apexcharts-tooltip-marker) {
  border-radius: 4px;
  width: 14px;
  height: 14px;
}

:deep(.apexcharts-tooltip-text) {
  flex: 1;
  font-family: "Lexend", Arial, sans-serif !important;
}
:deep(.apexcharts-tooltip-y-group) {
  display: flex;
}
:deep(.apexcharts-tooltip-text-y-label) {
  font-weight: 500;
}
:deep(.apexcharts-tooltip-text-y-value) {
  flex: 1;
  text-align: right;
  font-weight: 100;
}

:deep(.apexcharts-xaxistooltip-bottom.apexcharts-active) {
  background-color: transparent;
  border: none;
}
:deep(.apexcharts-xaxistooltip-bottom.apexcharts-active):before,
:deep(.apexcharts-xaxistooltip-bottom.apexcharts-active):after {
  display: none;
}
</style>
