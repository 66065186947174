import Vue from "vue";
import VueI18n from "vue-i18n";

import merge from "lodash/merge";

import defaultEn from "@/locales/en";
import defaultDa from "@/locales/da";

Vue.use(VueI18n);

const messages = {};

const languageCodeString = localStorage.getItem("language-code-list");

if (languageCodeString) {
  const languageCodes = JSON.parse(languageCodeString);

  languageCodes.forEach((languageCode) => {
    let translations = localStorage.getItem(
      `language-${languageCode}-translations`
    );
    translations = JSON.parse(translations);

    if (!translations) return;

    // Some keys might not exist in remote translations file yet.
    // Fallback to local translations
    if (languageCode === "en") {
      translations = merge(defaultEn, translations);
    }

    messages[languageCode] = translations;
  });
}

if (!messages.en) messages.en = defaultEn;
if (!messages.da) messages.da = defaultDa;

const i18n = new VueI18n({
  locale: "en",
  fallbackLocale: "en",
  messages,
});

export default i18n;

export function initializeRemoteLanguages(languageFileUrls) {
  if (!languageFileUrls) return;

  const languageCodes = Object.keys(languageFileUrls);

  localStorage.setItem("language-code-list", JSON.stringify(languageCodes));

  languageCodes.forEach((languageCode) => {
    const existingUrl = localStorage.getItem(`language-${languageCode}-url`);
    const settingsUrl = languageFileUrls[languageCode];

    if (!settingsUrl) return;

    const existingTranslations = localStorage.getItem(
      `language-${languageCode}-translations`
    );

    if (existingUrl === settingsUrl && !!existingTranslations) return;

    fetch(settingsUrl)
      .then((res) => res.json())
      .then((data) => {
        localStorage.setItem(
          `language-${languageCode}-translations`,
          JSON.stringify(data)
        );
        localStorage.setItem(`language-${languageCode}-url`, settingsUrl);

        i18n.setLocaleMessage(languageCode, data);
      });
  });
}
