<template>
  <v-tab-item :transition="false" :value="tabHref">
    <div class="fill-height fill-width">
      <v-row>
        <v-col cols="12" md="4" class="pr-xl-16">
          <!--<v-autocomplete
            v-model="defaultCurrencyIdProxy"
            :items="currencies"
            item-text="currencyCode"
            item-value="id"
            filled
            hide-details
            :label="$t('pages.settings.index.fields.standardCurrency')"
          />-->
          <dialog-form-text-input
            v-model="form.name"
            :title="$t('pages.settings.general.organizationName.label')"
            :placeholder="
              $t('pages.settings.general.organizationName.placeholder')
            "
            :error-messages="form.errors.name"
          ></dialog-form-text-input>
          <dialog-form-text-input
            v-model="form.subName"
            :title="$t('pages.settings.general.subName.label')"
            :placeholder="$t('pages.settings.general.subName.placeholder')"
            :error-messages="form.errors.subName"
          ></dialog-form-text-input>
          <dialog-form-text-input
            v-model="form.contentManagementSystemUrl"
            :title="$t('pages.settings.general.cmsUrl.label')"
            :placeholder="$t('pages.settings.general.cmsUrl.placeholder')"
            hidden
          ></dialog-form-text-input>
          <dialog-form-input-wrapper
            :title="$t('pages.settings.general.description')"
            class="mb-4"
          >
            <editor v-model="form.description" />
          </dialog-form-input-wrapper>
          <dialog-form-upload-image
            :value="form.logo"
            @change="(val) => (form.logo = val)"
            @clickClearFile="() => (form.logo = null)"
            :rules="logoRules"
            :preview-image-src="
              organization.logoFile
                ? route('api.files.content', {
                    id: organization.logoFile?.id,
                    filename: organization.logoFile?.originalName,
                  })
                : null
            "
            :title="$t('pages.settings.general.organizationLogo.label')"
            :button-text="
              $t('pages.settings.general.organizationLogo.changeLogo')
            "
            :sub-text="
              $t('pages.settings.general.organizationLogo.instructions')
            "
            :error-messages="form.errors.logo"
            hidden
          ></dialog-form-upload-image>
        </v-col>
        <v-col cols="12" md="4" class="pr-xl-16">
          <dialog-form-text-input
            v-model="form.taxNumber"
            :title="$t('pages.settings.general.taxNumber.label')"
            :placeholder="$t('pages.settings.general.taxNumber.placeholder')"
            :error-messages="form.errors.taxNumber"
          ></dialog-form-text-input>
          <dialog-form-text-input
            v-model="form.address"
            :title="$t('pages.settings.general.organizationAddress.label')"
            :placeholder="
              $t('pages.settings.general.organizationAddress.placeholder')
            "
            :error-messages="form.errors.address"
          ></dialog-form-text-input>
          <dialog-form-text-input
            v-model="form.email"
            :title="$t('pages.settings.general.organizationEmail.label')"
            :placeholder="
              $t('pages.settings.general.organizationEmail.placeholder')
            "
            :error-messages="form.errors.email"
          ></dialog-form-text-input>
          <dialog-form-text-input
            v-model="form.contactName"
            name="contactName"
            :title="$t('pages.settings.general.primaryContactName.label')"
            :placeholder="
              $t('pages.settings.general.primaryContactName.placeholder')
            "
            :error-messages="form.errors.contactName"
          ></dialog-form-text-input>
          <dialog-form-text-input
            v-model="form.contactTitle"
            :title="$t('pages.settings.general.primaryContactTitle.label')"
            :placeholder="
              $t('pages.settings.general.primaryContactTitle.placeholder')
            "
            :error-messages="form.errors.contactTitle"
          ></dialog-form-text-input>
          <dialog-form-text-input
            v-model="form.contactEmail"
            :title="$t('pages.settings.general.primaryContactEmail.label')"
            :placeholder="
              $t('pages.settings.general.primaryContactEmail.placeholder')
            "
            :error-messages="form.errors.contactEmail"
          ></dialog-form-text-input>
        </v-col>
      </v-row>
    </div>
  </v-tab-item>
</template>

<script>
import DialogFormInputWrapper from "../../../Components/Dialog/inputs/DialogFormInputWrapper.vue";
import DialogFormTextInput from "../../../Components/Dialog/inputs/DialogFormTextInput.vue";
import Editor from "../../../Shared/Editor.vue";
import DialogFormUploadImage from "../../../Components/Dialog/inputs/DialogFormUploadImage.vue";

import { objectToFormData } from "@/util/formData";

export default {
  components: {
    Editor,
    DialogFormInputWrapper,
    DialogFormTextInput,
    DialogFormUploadImage,
  },
  props: {
    tabHref: String,
    organization: Object,
  },
  data() {
    return {
      logoRules: [
        (value) =>
          !value ||
          value.size < 1000000 ||
          this.$t("pages.settings.index.fileSizeRule"),
      ],
      form: this.$inertia.form({
        id: this.organization.id,
        name: this.organization.name,
        subName: this.organization.subName,
        subdomain: this.organization.subdomain,
        contentManagementSystemUrl:
          this.organization.contentManagementSystemUrl,
        logo: null,
        description: this.organization.description,
        taxNumber: this.organization.taxNumber,
        address: this.organization.address,
        email: this.organization.email,
        contactName: this.organization.contactName,
        contactTitle: this.organization.contactTitle,
        contactEmail: this.organization.contactEmail,
        contents: this.organization.contents || [],
        defaultLocationContents:
          this.organization.defaultLocationContents || [],
      }),
    };
  },
  methods: {
    submit() {
      const preparedForm = this.form.transform((data) => {
        const transformedData = {
          ...data,
          contents: data.contents.map((c, index) => ({
            ...c,
            sortOrder: index + 1,
          })),
          defaultLocationContents: data.defaultLocationContents.map(
            (c, index) => ({ ...c, sortOrder: index + 1 })
          ),
        };
        return objectToFormData(transformedData);
      });

      preparedForm.put(
        this.route("settings.organization.update", this.organization.id),
        {
          preserveScroll: true,
          onSucces: () => {
            this.form.logo = null;
          },
        }
      );
    },
  },
};

/*
export default {
  components: {
    DialogFormTextInput,
    DialogFormUploadImage,
    Editor,
    DialogFormInputWrapper,
  },
  props: {
    tabHref: String,
    //defaultCurrencyId: String,
    //currencies: Array,
    organizationName: String,
    subName: String,
    cmsUrl: String,
    description: String,
    organizationLogo: String,
    taxNumber: String,
    organizationAddress: String,
    organizationEmail: String,
    primaryContactName: String,
    primaryContactTitle: String,
    primaryContactEmail: String,
  },
  data() {
    return {};
  },
  emits: ["updateForm"],
  computed: {
    organizationNameProxy: {
      get() {
        return this.organizationName;
      },
      set(value) {
        this.$emit("updateForm", "organizationName", value);
      },
    },
    subNameProxy: {
      get() {
        return this.subName;
      },
      set(value) {
        this.$emit("updateForm", "subName", value);
      },
    },
    cmsUrlProxy: {
      get() {
        return this.cmsUrl;
      },
      set(value) {
        this.$emit("updateForm", "cmsUrl", value);
      },
    },
    descriptionProxy: {
      get() {
        return this.description;
      },
      set(value) {
        this.$emit("updateForm", "description", value);
      },
    },
    organizationLogoProxy: {
      get() {
        return this.organizationLogo;
      },
      set(value) {
        this.$emit("updateForm", "organizationLogo", value);
      },
    },
    taxNumberProxy: {
      get() {
        return this.taxNumber;
      },
      set(value) {
        this.$emit("updateForm", "taxNumber", value);
      },
    },
    organizationAddressProxy: {
      get() {
        return this.organizationAddress;
      },
      set(value) {
        this.$emit("updateForm", "organizationAddress", value);
      },
    },
    organizationEmailProxy: {
      get() {
        return this.organizationEmail;
      },
      set(value) {
        this.$emit("updateForm", "organizationEmail", value);
      },
    },
    primaryContactNameProxy: {
      get() {
        return this.primaryContactName;
      },
      set(value) {
        this.$emit("updateForm", "primaryContactName", value);
      },
    },
    primaryContactTitleProxy: {
      get() {
        return this.primaryContactTitle;
      },
      set(value) {
        this.$emit("updateForm", "primaryContactTitle", value);
      },
    },
    primaryContactEmailProxy: {
      get() {
        return this.primaryContactEmail;
      },
      set(value) {
        this.$emit("updateForm", "primaryContactEmail", value);
      },
    },
  },
};*/
</script>
