<template>
  <div>
    <DialogFormHeader
      :title="$t('pages.assets.import.title')"
    ></DialogFormHeader>
    <DialogFormSectionOneCol>
      <dialog-form-select-input
        :value="selectedPortfolioId"
        :items="portfolioOptions"
        @change="handlePortfolioChanged"
        itemText="name"
        itemValue="id"
        :title="$t('pages.assets.import.stepTwo.toPortfolio')"
      ></dialog-form-select-input>
      <dialog-form-text-input
        v-if="!selectedPortfolioId"
        :value="newPortfolioName"
        @input="handlePortfolioNameInput"
        :title="$t('pages.assets.import.stepTwo.enterName')"
      ></dialog-form-text-input>
      <dialog-form-text-input
        v-if="selectedImportFile"
        disabled
        :value="selectedImportFile?.name"
        :title="$t('pages.assets.import.stepTwo.fileName')"
      ></dialog-form-text-input>
    </DialogFormSectionOneCol>
  </div>
</template>
<script>
import DialogFormHeader from "../../Dialog/DialogFormHeader.vue";
import DialogFormSectionOneCol from "../../Dialog/DialogFormSectionOneCol.vue";
import DialogFormSelectInput from "../../Dialog/inputs/DialogFormSelectInput.vue";
import DialogFormTextInput from "../../Dialog/inputs/DialogFormTextInput.vue";

export default {
  components: {
    DialogFormHeader,
    DialogFormSectionOneCol,
    DialogFormSelectInput,
    DialogFormTextInput,
  },
  props: {
    selectedImportFile: File,
    portfolios: Array,
    selectedPortfolioId: String,
    newPortfolioName: String,
  },
  emits: ["portfolioIdChanged", "newPortfolioNameChanged"],
  data() {
    return {};
  },
  computed: {
    portfolioOptions() {
      const portfolios = this.portfolios ?? [];
      portfolios.push({
        id: null,
        name: this.$t("pages.assets.import.stepTwo.newPortfolio"),
      });
      return portfolios;
    },
  },
  methods: {
    handlePortfolioChanged(portfolioId) {
      this.$emit("portfolioIdChanged", portfolioId);
    },
    handlePortfolioNameInput(portfolioName) {
      this.$emit("newPortfolioNameChanged", portfolioName);
    },
  },
};
</script>
