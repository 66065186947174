<template>
    <div class="page-container fill-height grow d-flex flex-column flex-nowrap">
    <!--<div>
      <h4 class="text-h4">
        {{
          !!user.id
            ? $t("pages.users.form.edit.title")
            : $t("pages.users.form.create.title")
        }}
      </h4>
      <v-spacer class="mb-6"></v-spacer>-->
    <v-card elevation="0" class="d-flex flex-column ma-4">
      <v-card-text class="pa-0 flex-grow-1">
        <div>
          <v-row no-gutters>
            <v-col col="12" lg="8">
              <form @submit.prevent="submit">
                <v-card elevation="1">
                  <v-card-text>
                    <v-row no-gutters class="align-start">
                      <v-col cols="4">
                        <v-subheader>{{
                          $t("common.fieldNames.name")
                        }}</v-subheader>
                      </v-col>
                      <v-col cols="8">
                        <v-text-field
                          v-model="form.name"
                          name="name"
                          type="text"
                          :error-messages="form.errors.name"
                        />
                      </v-col>
                    </v-row>
                    <v-row no-gutters class="align-start">
                      <v-col cols="4">
                        <v-subheader>{{
                          $t("pages.users.form.fields.email")
                        }}</v-subheader>
                      </v-col>
                      <v-col cols="8">
                        <v-text-field
                          v-model="form.email"
                          name="email"
                          type="email"
                          :error-messages="form.errors.email"
                        />
                      </v-col>
                    </v-row>
                    <v-row no-gutters class="align-start" v-if="!user.id">
                      <v-col cols="4">
                        <v-subheader>{{
                          $t("pages.users.form.fields.password")
                        }}</v-subheader>
                      </v-col>
                      <v-col cols="8">
                        <v-text-field
                          v-model="form.password"
                          name="name"
                          type="password"
                          :error-messages="form.errors.password"
                        />
                      </v-col>
                    </v-row>
                    <v-row no-gutters class="align-start mt-4">
                      <v-col cols="4">
                        <v-subheader>{{
                          $t("pages.users.form.fields.role")
                        }}</v-subheader>
                      </v-col>
                      <v-col cols="8">
                        <v-select
                          :items="computedAvailableRoles"
                          v-model="form.roleId"
                          name="role"
                          :label="$t('pages.users.form.fields.role')"
                          :error-messages="form.errors.roleId"
                        />
                      </v-col>
                    </v-row>
                    <v-row
                      no-gutters
                      class="align-start mt-4"
                      v-if="isOrganizationChanged()"
                    >
                      <v-col cols="4">
                        <v-subheader>
                          {{ $t("pages.users.form.fields.yourPassword") }}
                        </v-subheader>
                      </v-col>
                      <v-col cols="8">
                        <v-text-field
                          v-model="form.userPassword"
                          name="userPassword"
                          type="password"
                          autocomplete="current-password"
                          :error-messages="form.errors.userPassword"
                        />
                      </v-col>
                    </v-row>
                    <v-alert v-if="errors.alert" type="error" class="mt-2 mb-0">
                      {{ errors.alert }}
                    </v-alert>
                  </v-card-text>
                  <v-card-actions>
                    <flash-messages />
                    <v-spacer></v-spacer>
                    <v-btn
                      type="button"
                      outlined
                      color="primary"
                      :disabled="form.processing"
                      :href="route('users.index')"
                      >{{ $t("pages.users.form.actions.back") }}</v-btn
                    >
                    <v-btn
                      outlined
                      text
                      color="error"
                      :disabled="sending || form.processing"
                      @click="deactivate"
                      v-if="user.id && !user.disabledAt"
                      >{{ $t("pages.users.form.actions.deactivate") }}</v-btn
                    >
                    <v-btn
                      outlined
                      text
                      color="warning"
                      :disabled="sending || form.processing"
                      @click="reactivate"
                      v-else-if="user.id && user.disabledAt"
                      >{{ $t("pages.users.form.actions.reactivate") }}</v-btn
                    >
                    <v-btn
                      type="submit"
                      outlined
                      color="primary"
                      :disabled="form.processing"
                      >{{ $t("pages.users.form.actions.save") }}</v-btn
                    >
                  </v-card-actions>
                </v-card>
              </form>
            </v-col>
          </v-row>
        </div>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import { appLayout } from "@/util/layout";
import FlashMessages from "@/Shared/FlashMessages";

export default {
  layout: appLayout({ title: "pages.users.form.pageTitle" }),
  props: {
    roles: Array,
    user: Object,
    errors: Object,
  },
  components: {
    FlashMessages,
  },
  data() {
    return {
      sending: false,
      organizationIds: this.user.organizations?.map((x) => x.id) || [],
      form: this.$inertia.form({
        id: this.user.id,
        name: this.user.name,
        email: this.user.email,
        password: null,
        roleId: this.user.roleId,
        disabledAt: null,
        userPassword: null,
      }),
      availableRoles: this.roles,
    };
  },
  computed: {
    organizationLocations() {
      return this.locations.filter((x) =>
        this.organizationIds.includes(x.organizationId)
      );
    },
    allLocationsSelected() {
      return this.organizationLocations.every((l) =>
        this.form.locations.includes(l.id)
      );
    },
    computedAvailableRoles() {
      return this.availableRoles.map((x) => {
        return {
          text: x.isSystemRole
            ? this.$t("pages.users.form.systemRole", { role: x.name })
            : x.name,
          value: x.id,
        };
      });
    },
    roleClaims() {
      let role = this.availableRoles.find((x) => x.id == this.form.roleId);
      return role ? role.roleClaims.map((x) => x.claimType) : [];
    },
  },
  methods: {
    isOrganizationRoles() {
      return this.roleClaims.includes("Primeportal");
    },
    isBackofficeRoles() {
      return this.roleClaims.includes("Backoffice");
    },
    isSystemRole() {
      let role = this.availableRoles.find((x) => x.id == this.form.roleId);
      return role ? role.isSystemRole : false;
    },
    isGlobalRole() {
      let role = this.availableRoles.find((x) => x.id == this.form.roleId);
      return role ? role.organizationId == null : false;
    },
    isOrganizationChanged() {
      if (!this.user || !this.user.id) return false;

      let newRole = this.availableRoles.find((x) => x.id == this.form.roleId);
      if (!newRole) return false;

      let previousRole = this.availableRoles.find(
        (x) => x.id == this.user.roleId
      );

      return (
        previousRole.isSystemRole !== newRole.isSystemRole ||
        (!previousRole.isSystemRole &&
          previousRole.organizationId != newRole.organizationId)
      );
    },
    submit() {
      if (this.user.id) {
        this.form.put(this.route("users.update", this.user.id), {
          preserveScroll: true,
          preserveState: true,
        });
      } else {
        this.form.post(this.route("users.store"));
      }
    },
    deactivate() {
      this.$inertia.delete(this.route("users.destroy", this.user.id), {
        onStart: () => (this.sending = true),
        onFinish: () => (this.sending = false),
      });
    },
    reactivate() {
      this.$inertia.post(this.route("users.restore", this.user.id), {
        onStart: () => (this.sending = true),
        onFinish: () => (this.sending = false),
      });
    },
    toggleSelectAllLocations() {
      if (!this.allLocationsSelected) {
        this.form.locations = this.organizationLocations.map((l) => l.id);
      } else {
        this.form.locations = [];
      }
    },
    fixSelectAllLocationsCheck() {
      setTimeout(() => {
        const tempLocations = this.form.locations;
        this.form.locations = [];

        this.$nextTick(() => {
          this.form.locations = tempLocations;
        });
      }, 25);
    },
    fetchRoles() {
      this.rolesLoading = true;

      fetch(this.route("organization.roles"), {
        method: "GET",
      })
        .then((res) => res.json())
        .then((data) => {
          this.availableRoles = data;
          if (!this.isGlobalRole(this.form.roleId)) {
            this.form.roleId = null;
          }
        })
        .finally(() => (this.rolesLoading = false));
    },
  },
};
</script>
