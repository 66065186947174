<template>
  <form autocomplete="off" @submit.prevent="() => {}">
    <v-autocomplete
      ref="locationInput"
      :items="locations"
      v-model="formValue"
      :name="name"
      :label="label"
      :disabled="disabled"
      :multiple="multiple"
      :clearable="clearable"
      :error-messages="errorMessages"
      :filter="filterInput"
      :search-input.sync="currentSearchValue"
      :class="className"
      :menu-props="menuProps"
      item-text="name"
      item-value="id"
      @change="$emit('change', $event)"
      @input="$emit('input', $event)"
      @focus="$emit('focus', $event)"
    >
      <template #item="{ item, on, attrs }">
        <v-list-item
          :two-line="itemHasTwoLines(item)"
          v-on="on"
          v-bind="attrs"
          :input-value="
            multiple ? formValue.includes(item.id) : item.id === formValue
          "
        >
          <v-list-item-action v-if="multiple">
            <v-simple-checkbox
              :value="attrs.inputValue"
              :ripple="false"
              v-on="on"
            />
          </v-list-item-action>
          <v-list-item-content>
            <v-list-item-title>
              <template
                v-for="(titleText, index) in getItemTitleTextParts(item)"
              >
                <span
                  v-if="index === 1 && titleText !== getItemTitleText(item)"
                  class="v-list-item__mask"
                  :key="index"
                  >{{ titleText }}</span
                >
                <template v-else>{{ titleText }}</template>
              </template>
            </v-list-item-title>
            <v-list-item-subtitle v-if="itemHasTwoLines(item)">
              <template
                v-for="(subtitleText, index) in getItemSubtitleTextParts(item)"
              >
                <span
                  v-if="
                    index === 1 && subtitleText !== getItemSubtitleText(item)
                  "
                  class="v-list-item__mask"
                  :key="index"
                  >{{ subtitleText }}</span
                >
                <template v-else>{{ subtitleText }}</template>
              </template>
            </v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>
      </template>
      <template #append-item>
        <slot name="append-item" />
      </template>
      <template #prepend-item>
        <slot name="prepend-item" />
      </template>
    </v-autocomplete>
  </form>
</template>
<script>
export default {
  props: {
    locations: Array,
    multiple: Boolean,
    name: String,
    className: String,
    label: String,
    disabled: Boolean,
    clearable: Boolean,
    errorMessages: Array,
    menuProps: Object,
    value: [String, Array],
  },
  data() {
    return {
      currentSearchValue: "",
    };
  },
  methods: {
    filterInput(item, queryText) {
      let name = item.name?.toLocaleLowerCase();
      let customName = item.customName?.toLocaleLowerCase();
      let locationNumber = item.locationNumber?.toLocaleLowerCase();
      const email = item.email?.toLocaleLowerCase();

      if ((locationNumber ?? "").trim() !== "") {
        locationNumber = `[${locationNumber}] `;

        if (this.itemHasTwoLines(item))
          customName = locationNumber + customName;
        else name = locationNumber + name;
      }

      const search = queryText.toLocaleLowerCase();

      return (
        (name && name.includes(search)) ||
        (customName && customName.includes(search)) ||
        (email && email.includes(search))
      );
    },
    itemHasTwoLines(location) {
      return (location.customName ?? "").trim() !== "";
    },
    getItemTitleText(location) {
      let prefix = "";

      const locationNumber = (location.locationNumber ?? "").trim();

      if (locationNumber !== "") {
        prefix = `[${locationNumber}] `;
      }

      return (
        prefix +
        (this.itemHasTwoLines(location)
          ? location.customName ?? ""
          : location.name)
      );
    },
    getItemTitleTextParts(location) {
      const titleText = this.getItemTitleText(location);

      return this.getFilteredText(titleText);
    },
    getItemSubtitleText(location) {
      return this.itemHasTwoLines(location) ? location.name : "";
    },
    getItemSubtitleTextParts(location) {
      const subtitleText = this.getItemSubtitleText(location);

      return this.getFilteredText(subtitleText);
    },
    getFilteredText(text) {
      text = text || "";

      if (!this.currentSearchValue) return [text];

      const { start, middle, end } = this.getMaskedCharacters(text);

      return [start, middle, end];
    },
    getMaskedCharacters(text) {
      const search = (this.currentSearchValue ?? "").toLocaleLowerCase();
      const index = text.toLocaleLowerCase().indexOf(search);

      if (index < 0) return { start: text, middle: "", end: "" };

      const start = text.slice(0, index);
      const middle = text.slice(index, index + search.length);
      const end = text.slice(index + search.length);
      return { start, middle, end };
    },
    closeMenu() {
      this.$refs.locationInput.isMenuActive = false;
      this.$refs.locationInput.blur();
    },
  },
  expose: ["closeMenu"],
  computed: {
    formValue: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit("input", value);
      },
    },
  },
};
</script>
