<template>
  <div>
    <h4 class="text-h4">{{ $t("pages.locations.form.edit.title") }}</h4>
    <v-spacer class="mb-6"></v-spacer>
    <div>
      <v-row no-gutters>
        <v-col col="12" lg="8">
          <form @submit.prevent="submit">
            <v-card elevation="1">
              <v-card-text>
                <flash-messages />
                <v-row no-gutters class="align-start mt-4">
                  <v-col cols="4">
                    <v-subheader>{{
                      $t("pages.locations.form.locationType")
                    }}</v-subheader>
                  </v-col>
                  <v-col cols="8">
                    <v-select
                      :items="locationTypes"
                      v-model="form.locationTypeId"
                      name="locationType"
                      :label="$t('pages.locations.form.locationType')"
                      :disabled="isContentDragging"
                      :error-messages="form.errors.locationTypeId"
                      item-text="name"
                      item-value="id"
                    />
                  </v-col>
                </v-row>
                <v-row no-gutters class="align-start">
                  <v-col cols="4">
                    <v-subheader>{{
                      $t("pages.locations.form.description")
                    }}</v-subheader>
                  </v-col>
                  <v-col cols="8">
                    <editor
                      v-model="form.description"
                      :disabled="isContentDragging"
                    />
                  </v-col>
                </v-row>
                <v-row no-gutters class="align-start">
                  <v-col cols="4">
                    <v-subheader>{{
                      $t("pages.locations.form.locationNumber")
                    }}</v-subheader>
                  </v-col>
                  <v-col cols="8">
                    <v-text-field
                      v-model="form.locationNumber"
                      name="locationNumber"
                      type="text"
                      :disabled="isContentDragging"
                      :error-messages="form.errors.locationNumber"
                    />
                  </v-col>
                </v-row>
                <v-row no-gutters class="align-start">
                  <v-col cols="4">
                    <v-subheader>{{
                      $t("pages.locations.form.locationName")
                    }}</v-subheader>
                  </v-col>
                  <v-col cols="8">
                    <v-text-field
                      v-model="form.name"
                      name="name"
                      type="text"
                      :disabled="isContentDragging"
                      :error-messages="form.errors.name"
                    />
                  </v-col>
                </v-row>
                <v-row no-gutters class="align-start">
                  <v-col cols="4">
                    <v-subheader>{{
                      $t("pages.locations.form.email")
                    }}</v-subheader>
                  </v-col>
                  <v-col cols="8">
                    <v-text-field
                      v-model="form.email"
                      name="email"
                      type="text"
                      :disabled="isContentDragging"
                      :error-messages="form.errors.email"
                    />
                  </v-col>
                </v-row>
                <v-row no-gutters class="align-start">
                  <v-col cols="4">
                    <v-subheader>{{
                      $t("pages.locations.form.phoneNumber")
                    }}</v-subheader>
                  </v-col>
                  <v-col cols="8">
                    <v-text-field
                      v-model="form.phoneNumber"
                      name="phoneNumber"
                      type="text"
                      :disabled="isContentDragging"
                      :error-messages="form.errors.phoneNumber"
                    />
                  </v-col>
                </v-row>
                <v-row no-gutters class="align-start">
                  <v-col cols="4">
                    <v-subheader>{{
                      $t("pages.locations.form.installedAt")
                    }}</v-subheader>
                  </v-col>
                  <v-col cols="8">
                    <v-menu
                      v-model="installedAtMenu"
                      :close-on-content-click="false"
                      :nudge-right="40"
                      transition="scale-transition"
                      offset-y
                      min-width="auto"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-text-field
                          v-model="form.installedAt"
                          append-icon="mdi-calendar"
                          @click:append="installedAtMenu = true"
                          :disabled="isContentDragging"
                          readonly
                          v-bind="attrs"
                          v-on="on"
                        ></v-text-field>
                      </template>
                      <v-date-picker
                        first-day-of-week="1"
                        v-model="form.installedAt"
                        @input="installedAtMenu = false"
                      ></v-date-picker>
                    </v-menu>
                  </v-col>
                </v-row>
                <v-row no-gutters class="align-start">
                  <v-col cols="4">
                    <v-subheader>{{
                      $t("pages.locations.form.supportRegion")
                    }}</v-subheader>
                  </v-col>
                  <v-col cols="8">
                    <v-select
                      :items="regions"
                      v-model="form.regionId"
                      name="Region"
                      :label="$t('pages.locations.form.region.label')"
                      :disabled="isContentDragging"
                      :error-messages="form.errors.regionId"
                      item-text="name"
                      item-value="id"
                    />
                  </v-col>
                </v-row>
                <v-row no-gutters class="align-start">
                  <v-col cols="4">
                    <v-subheader>{{
                      $t("pages.locations.form.visibility")
                    }}</v-subheader>
                  </v-col>
                  <v-col cols="8">
                    <v-checkbox
                      v-model="form.hideInCustomerFrontend"
                      :label="$t('pages.locations.form.hideLocation')"
                    />
                  </v-col>
                </v-row>
                <v-row no-gutters class="align-start mt-4">
                  <v-col cols="4">
                    <v-subheader>{{
                      $t("pages.locations.form.address")
                    }}</v-subheader>
                  </v-col>
                  <v-col cols="8">
                    <v-autocomplete
                      :items="countries"
                      v-model="form.country"
                      color="white"
                      :label="$t('pages.locations.form.country')"
                      :disabled="isContentDragging"
                      :error-messages="form.errors.country"
                    ></v-autocomplete>
                    <v-text-field
                      v-model="form.addressLine1"
                      :label="$t('pages.locations.form.address')"
                      name="addressLine1"
                      type="text"
                      :disabled="isContentDragging"
                      :error-messages="form.errors.addressLine1"
                    />
                    <v-text-field
                      v-model="form.addressLine2"
                      :label="$t('pages.locations.form.addressLine2')"
                      name="addressLine2"
                      type="text"
                      :disabled="isContentDragging"
                      :error-messages="form.errors.addressLine2"
                    />
                    <v-row no-gutters class="align-start">
                      <v-col cols="4" class="pr-2">
                        <v-text-field
                          v-model="form.city"
                          :label="$t('pages.locations.form.city')"
                          name="city"
                          type="text"
                          :disabled="isContentDragging"
                          :error-messages="form.errors.city"
                        />
                      </v-col>
                      <v-col cols="4" class="pl-2">
                        <v-text-field
                          v-model="form.postalCode"
                          name="postalCode"
                          :label="$t('pages.locations.form.postalCode')"
                          type="text"
                          :disabled="isContentDragging"
                          :error-messages="form.errors.postalCode"
                        />
                      </v-col>
                      <v-col cols="4" class="pl-2">
                        <v-text-field
                          v-model="form.state"
                          name="state"
                          :label="$t('pages.locations.form.state')"
                          type="text"
                          :disabled="isContentDragging"
                          :error-messages="form.errors.state"
                        />
                      </v-col>
                    </v-row>
                    <v-row no-gutters class="align-start">
                      <v-col cols="6" class="pr-2">
                        <v-text-field
                          v-model="form.latitude"
                          name="latitude"
                          :label="$t('pages.locations.form.latitude')"
                          type="text"
                          :disabled="isContentDragging"
                          :error-messages="form.errors.latitude"
                        />
                      </v-col>
                      <v-col cols="6" class="pl-2">
                        <v-text-field
                          v-model="form.longitude"
                          name="longitude"
                          :label="$t('pages.locations.form.longitude')"
                          type="text"
                          :disabled="isContentDragging"
                          :error-messages="form.errors.longitude"
                        />
                      </v-col>
                    </v-row>
                  </v-col>
                </v-row>
              </v-card-text>
              <v-card-actions class="d-block">
                <div>
                  <v-alert v-if="mainFormHasErrors" dense type="error">
                    {{ $t("pages.locations.form.formErrors") }}
                  </v-alert>
                </div>
                <request-size-error :errors="form.errors" />
                <flash-messages class="px-2" />
              </v-card-actions>
            </v-card>
            <vue-draggable
              v-model="form.slaContents"
              group="slacontents"
              @choose="onDraggingStart"
              @unchoose="onDraggingEnd"
              @end="onDraggingEnd"
            >
              <v-card
                elevation="1"
                v-for="(content, index) in form.slaContents"
                :key="'sla' + index"
                class="mt-4 cursor-grab"
              >
                <v-card-title class="pb-1">
                  {{ $t("components.contents.sla") }} <v-spacer />
                  <v-btn icon @click="removeSla(index)"
                    ><v-icon>mdi-trash-can-outline</v-icon></v-btn
                  >
                </v-card-title>
                <v-card-text>
                  <v-row no-gutters class="align-center">
                    <v-col cols="4">
                      <v-subheader>{{
                        $t("components.contents.visibility")
                      }}</v-subheader>
                    </v-col>
                    <v-col cols="8">
                      <v-radio-group
                        v-model="content.visibility"
                        row
                        class="mt-1 visibility-buttons"
                        :error-messages="
                          form.errors[`slaContents${index}Visibility`]
                        "
                      >
                        <v-radio
                          v-for="visibility in visibilities"
                          :key="visibility"
                          :label="$t(`common.visibilities.${visibility}`)"
                          :value="visibility"
                        />
                      </v-radio-group>
                    </v-col>
                  </v-row>
                  <v-row no-gutters class="align-center">
                    <v-col cols="4">
                      <v-subheader>{{
                        $t("components.contents.activeTimeperiod")
                      }}</v-subheader>
                    </v-col>
                    <v-col cols="8">
                      <v-row>
                        <v-col cols="6">
                          <v-menu
                            v-model="content.startsAtMenu"
                            :close-on-content-click="false"
                            :nudge-right="40"
                            transition="scale-transition"
                            offset-y
                            min-width="auto"
                          >
                            <template v-slot:activator="{ on, attrs }">
                              <v-text-field
                                v-model="content.startsAt"
                                :label="$t('components.contents.startDate')"
                                prepend-icon="mdi-calendar"
                                readonly
                                v-bind="attrs"
                                v-on="on"
                                :error-messages="
                                  form.errors[`slaContents${index}StartsAt`]
                                "
                              ></v-text-field>
                            </template>
                            <v-date-picker
                              first-day-of-week="1"
                              v-model="content.startsAt"
                              @input="() => slaStartsAtChanged(index)"
                            ></v-date-picker>
                          </v-menu>
                        </v-col>
                        <v-col cols="6">
                          <v-menu
                            v-model="content.endsAtMenu"
                            :close-on-content-click="false"
                            :nudge-right="40"
                            transition="scale-transition"
                            offset-y
                            min-width="auto"
                          >
                            <template v-slot:activator="{ on, attrs }">
                              <v-text-field
                                v-model="content.endsAt"
                                :label="$t('components.contents.endDate')"
                                prepend-icon="mdi-calendar"
                                readonly
                                v-bind="attrs"
                                v-on="on"
                                :error-messages="
                                  form.errors[`slaContents${index}EndsAt`]
                                "
                              ></v-text-field>
                            </template>
                            <v-date-picker
                              first-day-of-week="1"
                              v-model="content.endsAt"
                              :min="content.startsAt"
                              @input="content.endsAtMenu = false"
                            ></v-date-picker>
                          </v-menu>
                        </v-col>
                      </v-row>
                    </v-col>
                  </v-row>
                  <editor
                    v-model="content.description"
                    :disabled="isContentDragging"
                  />
                </v-card-text>
                <v-card-text>
                  <v-file-input
                    v-model="content.newFiles"
                    :placeholder="
                      $t('components.contents.addFiles.placeholder')
                    "
                    :label="$t('components.contents.addFiles.label')"
                    multiple
                    small-chips
                    prepend-icon="mdi-paperclip"
                  />
                </v-card-text>
                <v-card-text v-if="content.files && content.files.length">
                  <v-simple-table fixed-header>
                    <template v-slot:default>
                      <thead>
                        <tr>
                          <th class="text-left">
                            {{ $t("common.fieldNames.name") }}
                          </th>
                          <th class="text-left">
                            {{ $t("common.fieldNames.createdAt") }}
                          </th>
                          <th class="text-right"></th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr
                          v-for="(item, fIndex) in content.files"
                          :key="item.id"
                        >
                          <td>{{ item.name }}{{ item.extension }}</td>
                          <td>
                            {{ new Date(item.createdAt).toLocaleString() }}
                          </td>
                          <td class="text-right">
                            <v-btn
                              small
                              text
                              @click.stop
                              :href="
                                route('api.files.content', {
                                  id: item.id,
                                  filename: item.originalName,
                                })
                              "
                              target="_blank"
                            >
                              <v-icon class="mr-2">mdi-attachment</v-icon>
                            </v-btn>
                            <v-btn
                              small
                              text
                              @click.stop="removeSlaFile(index, fIndex)"
                            >
                              <v-icon class="mr-2"
                                >mdi-trash-can-outline</v-icon
                              >
                            </v-btn>
                          </td>
                        </tr>
                      </tbody>
                    </template>
                  </v-simple-table>
                </v-card-text>
              </v-card>
            </vue-draggable>
            <vue-draggable
              v-model="form.contents"
              group="contents"
              @choose="onDraggingStart"
              @unchoose="onDraggingEnd"
              @end="onDraggingEnd"
            >
              <v-card
                elevation="1"
                v-for="(content, index) in form.contents"
                :key="index"
                class="mt-4 cursor-grab"
              >
                <v-card-title class="pb-1">
                  {{ content.informationType.name }} <v-spacer />
                  <v-btn icon @click="removeContent(content, index)"
                    ><v-icon>mdi-trash-can-outline</v-icon></v-btn
                  >
                </v-card-title>
                <v-card-text>
                  <v-row no-gutters class="align-center">
                    <v-col cols="4">
                      <v-subheader>{{
                        $t("components.contents.visibility")
                      }}</v-subheader>
                    </v-col>
                    <v-col cols="8">
                      <v-radio-group
                        v-model="content.visibility"
                        row
                        class="mt-1 visibility-buttons"
                        :error-messages="
                          form.errors[`contents${index}Visibility`]
                        "
                      >
                        <v-radio
                          v-for="visibility in visibilities"
                          :key="visibility"
                          :label="$t(`common.visibilities.${visibility}`)"
                          :value="visibility"
                        />
                      </v-radio-group>
                    </v-col>
                  </v-row>
                  <editor
                    v-model="content.description"
                    :disabled="isContentDragging"
                  />
                </v-card-text>
                <v-card-text>
                  <v-file-input
                    v-model="content.newFiles"
                    :placeholder="
                      $t('components.contents.addFiles.placeholder')
                    "
                    :label="$t('components.contents.addFiles.label')"
                    multiple
                    small-chips
                    prepend-icon="mdi-paperclip"
                  />
                </v-card-text>
                <v-card-text v-if="content.files && content.files.length">
                  <v-simple-table fixed-header>
                    <template v-slot:default>
                      <thead>
                        <tr>
                          <th class="text-left">
                            {{ $t("common.fieldNames.name") }}
                          </th>
                          <th class="text-left">
                            {{ $t("common.fieldNames.createdAt") }}
                          </th>
                          <th class="text-right"></th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr
                          v-for="(item, fIndex) in content.files"
                          :key="item.id"
                        >
                          <td>{{ item.name }}{{ item.extension }}</td>
                          <td>
                            {{ new Date(item.createdAt).toLocaleString() }}
                          </td>
                          <td class="text-right">
                            <v-btn
                              small
                              text
                              @click.stop
                              :href="
                                route('api.files.content', {
                                  id: item.id,
                                  filename: item.originalName,
                                })
                              "
                              target="_blank"
                            >
                              <v-icon class="mr-2">mdi-attachment</v-icon>
                            </v-btn>
                            <v-btn
                              small
                              text
                              @click.stop="removeFile(index, fIndex)"
                            >
                              <v-icon class="mr-2"
                                >mdi-trash-can-outline</v-icon
                              >
                            </v-btn>
                          </td>
                        </tr>
                      </tbody>
                    </template>
                  </v-simple-table>
                </v-card-text>
              </v-card>
            </vue-draggable>
          </form>
        </v-col>
      </v-row>
    </div>
    <content-dialog
      v-model="dialog"
      :contents="informationTypes"
      @submit="addContent"
    />
    <v-speed-dial v-model="fab" fixed fab bottom right>
      <template v-slot:activator>
        <v-btn
          fab
          color="primary"
          v-if="fab"
          :disabled="sending || form.processing"
          @click="submit"
        >
          <v-icon>mdi-content-save</v-icon>
        </v-btn>
        <v-btn fab color="secondary" v-else>
          <v-icon>mdi-pencil</v-icon>
        </v-btn>
      </template>
      <v-tooltip
        :nudge-left="20"
        left
        :value="true"
        class="v-tooltip--speed-dial"
      >
        <template #activator="{ on }">
          <v-btn fab dark small color="indigo" v-on="on" @click="dialog = true">
            <v-icon>mdi-plus</v-icon>
          </v-btn>
        </template>
        <span>{{ $t("pages.locations.form.menu.addContent") }}</span>
      </v-tooltip>
      <v-tooltip
        :nudge-left="20"
        left
        :value="true"
        class="v-tooltip--speed-dial"
      >
        <template #activator="{ on }">
          <v-btn fab dark small color="indigo" v-on="on" @click="addSla">
            <v-icon>mdi-plus</v-icon>
          </v-btn>
        </template>
        <span>{{ $t("pages.locations.form.menu.addSla") }}</span>
      </v-tooltip>
      <v-tooltip
        :nudge-left="20"
        left
        :value="true"
        class="v-tooltip--speed-dial"
      >
        <template #activator="{ on }">
          <v-btn
            fab
            dark
            small
            color="red"
            v-on="on"
            :disabled="sending || form.processing"
            @click="destroy"
          >
            <v-icon>mdi-trash-can</v-icon>
          </v-btn>
        </template>
        <span>{{ $t("pages.locations.form.menu.deleteLocation") }}</span>
      </v-tooltip>
    </v-speed-dial>
  </div>
</template>

<script>
import { appLayout } from "@/util/layout";
import { objectToFormData } from "@/util/formData";
import { pascalToSpaces } from "@/util/text";
import FlashMessages from "@/Shared/FlashMessages";
import ContentDialog from "@/Shared/ContentDialog";
import Editor from "@/Shared/Editor";
import RequestSizeError from "@/Shared/RequestSizeError";

export default {
  layout: appLayout({ title: "pages.locations.form.create.pageTitle" }),
  props: {
    countries: Array,
    locationTypes: Array,
    informationTypes: Array,
    location: Object,
    visibilities: Array,
    regions: Array,
  },
  components: {
    FlashMessages,
    Editor,
    ContentDialog,
    RequestSizeError,
  },
  data() {
    return {
      fab: false,
      dialog: false,
      form: this.$inertia.form({
        id: this.location.id,
        name: this.location.name,
        description: this.location.description,
        longitude: this.location.longitude,
        latitude: this.location.latitude,
        country: this.location.country,
        addressLine1: this.location.addressLine1,
        addressLine2: this.location.addressLine2,
        city: this.location.city,
        postalCode: this.location.postalCode,
        state: this.location.state,
        locationTypeId: this.location.locationTypeId,
        regionId: this.location.regionId,
        contents: this.location.contents,
        slaContents: this.location.slaContents,
        locationNumber: this.location.locationNumber,
        email: this.location.email,
        phoneNumber: this.location.phoneNumber,
        installedAt: this.location.installedAt,
        hideInCustomerFrontend: this.location.hideInCustomerFrontend,
      }),
      installedAtMenu: false,
      sending: false,
      isContentDragging: false,
    };
  },
  methods: {
    removeFile(contentIndex, fileIndex) {
      this.form.contents[contentIndex].files.splice(fileIndex, 1);
    },
    removeSlaFile(slaContentIndex, fileIndex) {
      this.form.slaContents[slaContentIndex].files.splice(fileIndex, 1);
    },
    removeContent(content, index) {
      this.form.contents.splice(index, 1);
    },
    removeSla(index) {
      this.form.slaContents.splice(index, 1);
    },
    addContent(contents) {
      this.form.contents = this.form.contents.concat(contents);
    },
    addSla() {
      this.form.slaContents.push({
        description: null,
        visibility: this.visibilities[0],
        startsAt: null,
        endsAt: null,
        newFiles: [],
      });
    },
    submit() {
      this.form
        .transform((data) => {
          const transformedData = {
            ...data,
            hideInCustomerFrontend: data.hideInCustomerFrontend + "",
            contents: data.contents.map((c, index) => ({
              ...c,
              sortOrder: index + 1,
            })),
            slaContents: data.slaContents.map((sc, index) => ({
              ...sc,
              sortOrder: index + 1,
            })),
          };

          return objectToFormData(transformedData);
        })
        .put(this.route("locations.update", this.location.id), {
          preserveScroll: true,
          preserveState: false,
        });
    },
    destroy() {
      if (window.confirm(this.$t("pages.locations.form.confirmDelete"))) {
        this.$inertia.delete(
          this.route("locations.destroy", this.location.id),
          {
            onStart: () => (this.sending = true),
            onFinish: () => (this.sending = false),
          }
        );
      }
    },
    pascalToSpaces(text) {
      return pascalToSpaces(text);
    },
    slaStartsAtChanged(index) {
      const content = this.form.slaContents[index];

      let fromDate = new Date(content.startsAt);
      let toDate = content.endsAt ? new Date(content.endsAt) : null;

      if (toDate && toDate < fromDate) {
        content.endsAt = null;
      }

      content.startsAtMenu = false;
    },
    onDraggingStart() {
      this.isContentDragging = true;
    },
    onDraggingEnd() {
      this.isContentDragging = false;
    },
  },
  computed: {
    mainFormHasErrors() {
      return (
        Object.keys(this.form.errors).filter(
          (e) => !e.toLowerCase().includes("contents") && !!e
        ).length > 0
      );
    },
  },
};
</script>
<style>
.editor-content .ProseMirror {
  min-height: 150px;
}
</style>
