<template>
  <dialog-form :show-dialog="show" max-width="600px">
    <dialog-form-section-one-col class="py-6 px-2">
      <div class="py-8 d-flex justify-center">
        <LogoBlack />
      </div>
      <h1 class="text-subtitle-1 grey--text mb-1 font-weight-light text-center">
        {{
          $t(
            "pages.settings.integrationDynamics365.companySelection.selectCompany"
          )
        }}
      </h1>

      <div v-if="companies.length === 0">
        <h1
          class="text-subtitle-1 grey--text mb-1 font-weight-light text-center"
        >
          {{
            $t(
              "pages.settings.integrationDynamics365.companySelection.noCompanies"
            )
          }}
        </h1>
      </div>

      <div v-for="company in companies" :key="company.id">
        <v-checkbox
          v-model="form.companyId"
          :value="company.id"
          :label="company.name"
        ></v-checkbox>
      </div>

      <v-btn
        tile
        block
        elevation="0"
        color="orange"
        class="font-weight-regular text-capitalize mb-2 white--text"
        type="submit"
        @click="submitCompany"
      >
        {{ $t("common.actions.submit") }}
      </v-btn>
    </dialog-form-section-one-col>
  </dialog-form>
</template>

<script>
import DialogForm from "../../Components/Dialog/DialogForm.vue";
import DialogFormSectionOneCol from "../../Components/Dialog/DialogFormSectionOneCol.vue";
import LogoBlack from "../../assets/svg/verarca-logo-black.svg";
export default {
  components: {
    DialogForm,
    DialogFormSectionOneCol,
    LogoBlack,
  },
  props: {
    value: Boolean,
    companies: Array,
    dynamicsInitializedIntegrationId: String,
  },
  data() {
    return {
      form: this.$inertia.form({
        companyId: null,
        companyName: null,
      }),
    };
  },
  computed: {
    show: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit("input", value);
      },
    },
  },
  methods: {
    submitCompany() {
      if (this.form.companyId) {
        const selectedCompany = this.companies.find(
          (company) => company.id === this.form.companyId
        );
        if (selectedCompany) {
          this.form.companyId = selectedCompany.id;
          this.form.companyName = selectedCompany.name;

          this.form.put(
            this.route(
              "settings.integrations.set-dynamics365-company",
              this.dynamicsInitializedIntegrationId
            )
          );
        }
      }
    },
  },
};
</script>

<style></style>
