var render = function render(){var _vm=this,_c=_vm._self._c;return _c('tr',{class:`${_vm.filesDragging && _vm.onlyFileAttribute ? 'outline-drop-zone' : ''} ${
    _vm.hoveredRowId === _vm.item.id ? 'hovered' : ''
  }
  `,on:{"dblclick":function($event){return _vm.editItem(_vm.item)},"drop":(e) =>
      _vm.onlyFileAttribute
        ? _vm.handleDropEvent(e, _vm.onlyFileAttribute, _vm.item)
        : undefined,"dragover":(e) =>
      _vm.onlyFileAttribute
        ? _vm.handleDragOverEvent(e, _vm.onlyFileAttribute, _vm.item)
        : undefined,"dragenter":(e) =>
      _vm.onlyFileAttribute
        ? _vm.handleDragEnterEvent(e, _vm.onlyFileAttribute, _vm.item)
        : undefined,"dragleave":(e) =>
      _vm.onlyFileAttribute
        ? _vm.handleDragLeaveEvent(_vm.onlyFileAttribute, _vm.item)
        : undefined}},[_c('td',{staticClass:"text-start fixed"},[(!_vm.loadingSkeletonOn)?_c('v-simple-checkbox',{attrs:{"color":"red","ripple":false,"value":_vm.isSelected},on:{"input":() => _vm.select(!_vm.isSelected)}}):_vm._e()],1),_vm._l((_vm.headers),function(header){return _c('td',{key:header.value,staticClass:"text-start",class:`${header.value === 'action' ? 'fixed' : ''}`},[(
        header.value !== 'action' &&
        !_vm.isEmissionSourceCol(header.value) &&
        _vm.loadingSkeletonOn
      )?[_c('div',{staticClass:"custom-skeleton",staticStyle:{"height":"29px"},style:('width: ' + (50 + Math.random() * 40) + 'px')})]:(header.value === _vm.infoInvoiceId)?[(_vm.groupName !== 'no-group')?_c('a',{attrs:{"href":_vm.formatInvoice?.file
            ? _vm.route('api.files.content', {
                id: _vm.formatInvoice.file.id,
                filename: _vm.formatInvoice.file.originalName,
              })
            : undefined,"target":"_blank"}},[_c('v-icon',[_vm._v(_vm._s(_vm.groupName === "group-1" ? "mdi-file-document-outline" : "mdi-file-document"))])],1):_vm._e()]:(header.value === 'createdAt')?[_vm._v(" "+_vm._s(new Date(_vm.item.createdAt).toLocaleString())+" ")]:(header.value === 'updatedAt')?[_vm._v(" "+_vm._s(new Date(_vm.item.updatedAt).toLocaleString())+" ")]:(header.value === 'emissionsSource' && _vm.item.emissionFactor)?[_c('a',{attrs:{"href":_vm.item.emissionFactor.sourceLink,"target":"_blank"}},[_vm._v(" "+_vm._s(_vm.item.emissionFactor.source)+" ")])]:(_vm.isEmissionSourceCol(header.value))?[_c('ScopeBadge',{attrs:{"scope":_vm.badgeFormatBy(_vm.item[header.value]?.searchString)}})]:(_vm.dynamicHeaderIds.includes(header.value))?[(_vm.mappedAttributes[header.value].format == 'File')?_c('div',{key:header.value + _vm.item.id,staticClass:"fill-height d-flex align-center px-2",class:`${
          _vm.filesDragging && !_vm.onlyFileAttribute ? 'outline-drop-zone' : ''
        } ${
          _vm.hoveredRowId === _vm.item.id && _vm.hoveredAttributeId === header.value
            ? 'hovered'
            : ''
        }
        ${_vm.isDisplaySize('Minimal') ? 'flex-wrap' : ''}`,on:{"drop":(e) =>
            _vm.onlyFileAttribute
              ? _vm.handleDropEvent(e, _vm.onlyFileAttribute, _vm.item)
              : _vm.handleDropEvent(e, _vm.mappedAttributes[header.value], _vm.item),"dragover":(e) =>
            _vm.onlyFileAttribute
              ? _vm.handleDragOverEvent(e, _vm.onlyFileAttribute, _vm.item)
              : _vm.handleDragOverEvent(e, _vm.mappedAttributes[header.value], _vm.item),"dragenter":(e) =>
            _vm.onlyFileAttribute
              ? _vm.handleDragEnterEvent(e, _vm.onlyFileAttribute, _vm.item)
              : _vm.handleDragEnterEvent(e, _vm.mappedAttributes[header.value], _vm.item),"dragleave":(e) =>
            _vm.onlyFileAttribute
              ? _vm.handleDragLeaveEvent(_vm.onlyFileAttribute, _vm.item)
              : _vm.handleDragLeaveEvent(_vm.mappedAttributes[header.value], _vm.item)}},[(
            _vm.item[header.value] != null &&
            (_vm.item[header.value].fileId != null ||
              (_vm.item[header.value][_vm.fileLinkProperty] &&
                _vm.item[header.value][_vm.fileLinkProperty].length))
          )?[(_vm.item[header.value].file)?_c('file-preview',{attrs:{"file":_vm.item[header.value].file,"className":"mx-1"}}):_vm._e(),(_vm.item[header.value][_vm.fileLinkProperty])?_vm._l((_vm.item[header.value][_vm.fileLinkProperty]),function(fileLink){return _c('file-preview',{key:fileLink.id,attrs:{"file":fileLink.file,"className":"mx-1"}})}):_vm._e()]:_vm._e()],2):_c('div',{key:header.value + _vm.item.id,staticClass:"fill-height d-flex align-center"},[_c('span',{class:`
            ${_vm.isDisplaySize('Minimal') ? 'minimal-attribute-column' : ''}
          `,style:(_vm.isEmissionCol(header.value) ||
            (_vm.isEmissionRelatedCol(header.value) &&
              _vm.factorStatusData == 'defining')
              ? { width: '100px' }
              : null),attrs:{"title":undefined}},[_vm._v(" "+_vm._s(_vm.handleAttributeValue(_vm.item[header.value]))+" ")]),(
            _vm.isEmissionCol(header.value) ||
            (_vm.isEmissionRelatedCol(header.value) &&
              _vm.factorStatusData == 'defining')
          )?_c('div',{},[_c('ai-status',{attrs:{"type":_vm.factorStatusData,"expanded":true}})],1):_vm._e()])]:(header.value === 'action')?[_c('attribute-row-action-menu',{attrs:{"row":_vm.item,"attributes":_vm.attributes,"entryType":_vm.entryType},on:{"editRowClicked":($event) => _vm.editItem($event),"copyRowClicked":function($event){return _vm.$emit('copyRowClicked', $event)},"destroyRowClicked":function($event){return _vm.$emit('destroyRowClicked', $event)}}})]:[_vm._v(" "+_vm._s(_vm.item[header.value])+" ")]],2)})],2)
}
var staticRenderFns = []

export { render, staticRenderFns }