<template>
  <dialog-form :show-dialog="show" max-width="450px" :persistent="true">
    <div class="pa-4">
      <div class="pb-8 d-flex justify-center">
        <LogoBlack />
      </div>

      <div v-if="elementsOptions.clientSecret">
        <h1 class="text-subtitle-1 black--text pb-2">
          {{ $t("pages.payment.titles.addCard") }}
        </h1>
      </div>

      <stripe-element-payment
        ref="paymentRef"
        :pk="publishableKey"
        :elements-options="elementsOptions"
        :confirm-params="confirmParams"
      />

      <div>
        <h1 class="text-subtitle-1 black--text pt-2">
          {{ $t("pages.payment.titles.billingAddress") }}
        </h1>
      </div>

      <dialog-form-text-input
        v-model="form.name"
        :title="$t('pages.payment.form.name')"
        :placeholder="$t('pages.payment.form.name')"
        :error-messages="form.errors.name"
      ></dialog-form-text-input>

      <dialog-form-text-input
        v-model="form.email"
        :title="$t('pages.payment.form.email')"
        :placeholder="$t('pages.payment.form.email')"
        :error-messages="form.errors.email"
      ></dialog-form-text-input>

      <dialog-form-text-input
        v-model="form.address1"
        :title="$t('pages.payment.form.addres1')"
        :placeholder="$t('pages.payment.form.addres1')"
        :error-messages="form.errors.address1"
      ></dialog-form-text-input>

      <dialog-form-text-input
        v-model="form.address2"
        :title="$t('pages.payment.form.addres2')"
        :placeholder="$t('pages.payment.form.optional')"
        :error-messages="form.errors.address2"
      ></dialog-form-text-input>

      <div class="d-flex justify-space-between">
        <dialog-form-text-input
          class="pr-2"
          style="width: 110px"
          v-model="form.postCode"
          :title="$t('pages.payment.form.postCode')"
          :placeholder="$t('pages.payment.form.postCode')"
          :error-messages="form.errors.postCode"
        ></dialog-form-text-input>

        <dialog-form-text-input
          style="width: 300px"
          v-model="form.city"
          :title="$t('pages.payment.form.city')"
          :placeholder="$t('pages.payment.form.city')"
          :error-messages="form.errors.city"
        ></dialog-form-text-input>
      </div>

      <dialog-form-text-input
        v-model="form.region"
        :title="$t('pages.payment.form.region')"
        :placeholder="$t('pages.payment.form.optional')"
        :error-messages="form.errors.region"
      ></dialog-form-text-input>

      <div class="pt-4 d-flex justify-end sticky-buttons">
        <v-btn
          tile
          outlined
          elevation="0"
          bg-color="white"
          class="border-sm text-capitalize black--text black--border white"
          @click="closePaymentPopup"
          >{{ $t("pages.payment.buttons.cancel") }}</v-btn
        >
        <v-btn
          tile
          elevation="0"
          color="orange"
          class="font-weight-regular text-capitalize mb-4 mx-4 white--text"
          type="submit"
          @click="pay"
          :disabled="!areFieldsFilled"
        >
          {{ $t("pages.payment.buttons.pay") }}
        </v-btn>
      </div>
    </div>
  </dialog-form>
</template>

<script>
import { StripeElementPayment } from "@vue-stripe/vue-stripe";
import DialogForm from "../../Components/Dialog/DialogForm.vue";
import DialogFormTextInput from "../../Components/Dialog/inputs/DialogFormTextInput.vue";
import LogoBlack from "../../assets/svg/verarca-logo-black.svg";
// Add the lock whenever you add the check if this is inital payment of conming from the billing to add  anew payment method
// import LockIcon from "../../assets/svg/lock-icon.svg";
export default {
  name: "paymentInfo",
  components: {
    StripeElementPayment,
    DialogForm,
    DialogFormTextInput,
    LogoBlack,
    // LockIcon,
  },
  props: {
    value: Boolean,
    subscriptionClientSecret: String,
    publishableKey: String,
    paymentReturnUrl: String,
  },
  data() {
    return {
      elementsOptions: {
        appearance: {
          theme: "stripe",

          variables: {
            colorPrimary: "#000",
            colorBackground: "#ffffff",
            colorText: "#30313d",
            colorDanger: "#df1b41",
            fontFamily: "Lexend, system-ui, sans-serif",
            spacingUnit: "2px",
            borderRadius: "0px",
            gridRowSpacing: "16px",
          },
          rules: {
            ".Tab, .Input, .Block, .CheckboxInput, .CodeInput": {
              boxShadow: "0px 3px 10px rgba(18, 42, 66, 0.08)",
            },
            ".Block": {
              borderColor: "transparent",
            },
            ".BlockDivider": {
              backgroundColor: "#ebebeb",
            },
            ".Tab, .Tab:hover, .Tab:focus": {
              border: "0",
            },
            ".Tab--selected, .Tab--selected:hover": {
              backgroundColor: "#f25d3b",
              color: "#fff",
            },
          },
        },
      },

      form: this.$inertia.form({
        name: null,
        email: null,
        address1: null,
        address2: null,
        postCode: null,
        city: null,
        region: null,
      }),

      confirmParams: {
        return_url: this.paymentReturnUrl,
      },
    };
  },
  computed: {
    show: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit("input", value);
      },
    },

    areFieldsFilled() {
      return (
        this.form.name &&
        this.form.email &&
        this.form.address1 &&
        this.form.postCode &&
        this.form.city
      );
    },
  },
  watch: {
    subscriptionClientSecret(to) {
      this.elementsOptions.clientSecret = to;
    },
  },
  methods: {
    pay() {
      this.form.post(this.route("payments.attempt"));

      this.$refs.paymentRef.submit();
    },
    closePaymentPopup() {
      this.show = false;
    },
  },
};
</script>

<style scoped>
:deep(#stripe-payment-element-errors) {
  color: red;
}

.sticky-buttons {
  position: sticky;
  background: white;
  bottom: 0;
  z-index: 1;
}
</style>
