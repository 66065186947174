<template>
  <div style="position: relative">
    <v-btn
      @click="showDateRangePicker = !showDateRangePicker"
      height="auto"
      plain
      light
      elevation="0"
      text
      class="date-range-button pa-1 d-flex flex-row align-center justify-center border-right white--text text--lighten-1"
    >
      <CalendarIcon class="mr-4 d-none d-md-block"></CalendarIcon>
      <div class="d-flex flex-sm-row align-center">
        <span
          class="caption text-capitalize text-center text-sm-left"
          :class="small ? 'text-lg-body-2' : 'text-lg-body-1'"
          >{{ formatDates[0] }}</span
        >
        <ArrowRightIcon class="mx-4"></ArrowRightIcon>
        <span
          class="caption text-capitalize text-center text-sm-left"
          :class="small ? 'text-lg-body-2' : 'text-lg-body-1'"
          >{{ formatDates[1] }}</span
        >
      </div>
    </v-btn>
    <v-dialog
      ref="dialog"
      class="dialog"
      v-model="showDateRangePicker"
      persistent
      max-width="836px"
      @keydown.esc="showDateRangePicker = false"
      v-click-outside="{
        handler: onClickOutside,
      }"
    >
      <DateRangePicker
        opens="inline"
        class="date-range-picker"
        ref="picker"
        :locale-data="{ firstDay: 1, format: 'yyyy-mm-dd' }"
        v-model="rawDates"
        autoApply
        @update="dateRangeChanged"
        :ranges="ranges"
      >
        <template v-slot:header="">
          <div class="date-range-header">
            {{ $t("components.inputs.dateRangePicker.title") }}
          </div>
        </template>
        <template v-slot:date="date">
          <div class="date-range-pin">
            <span>{{ new Date(date.date).getDate() }}</span>
          </div>
        </template>
      </DateRangePicker>
    </v-dialog>
  </div>
</template>

<script>
import ArrowRightIcon from "../../assets/svg/arrow-right.svg";
import CalendarIcon from "../../assets/svg/calendar.svg";
import DateRangePicker from "vue2-daterange-picker";
//you need to import the CSS manually

import "./../../sass/date-range-selector.scss";
// On mobile write date as fx Lør, 28 Feb 2023 => 28/3/2023
import { DateTime } from "luxon";

export default {
  components: {
    ArrowRightIcon,
    CalendarIcon,
    DateRangePicker,
  },
  props: {
    fromDate: String,
    toDate: String,
    small: { type: Boolean, default: false },
  },
  data() {
    const today = DateTime.utc().startOf("day");
    const yesterday = today.minus({ days: 1 });

    let ranges = {};
    ranges[this.$t("components.inputs.dateRangePicker.ranges.today")] = [
      today.toJSDate(),
      today.toJSDate(),
    ];
    ranges[this.$t("components.inputs.dateRangePicker.ranges.yesterday")] = [
      yesterday.toJSDate(),
      yesterday.toJSDate(),
    ];
    ranges[this.$t("components.inputs.dateRangePicker.ranges.allTime")] = [
      today.toJSDate(),
      today.toJSDate(),
    ];
    ranges[this.$t("components.inputs.dateRangePicker.ranges.thisWeek")] = [
      today.startOf("week").toJSDate(),
      today.endOf("week").startOf("day").startOf("day").toJSDate(),
    ];
    ranges[this.$t("components.inputs.dateRangePicker.ranges.lastWeek")] = [
      today.minus({ weeks: 1 }).startOf("week").toJSDate(),
      today.minus({ weeks: 1 }).endOf("week").startOf("day").toJSDate(),
    ];
    ranges[this.$t("components.inputs.dateRangePicker.ranges.thisMonth")] = [
      today.startOf("month").toJSDate(),
      today.endOf("month").startOf("day").toJSDate(),
    ];
    ranges[this.$t("components.inputs.dateRangePicker.ranges.lastMonth")] = [
      today.minus({ months: 1 }).startOf("month").toJSDate(),
      today.minus({ months: 1 }).endOf("month").startOf("day").toJSDate(),
    ];
    ranges[this.$t("components.inputs.dateRangePicker.ranges.last30Days")] = [
      today.minus({ days: 30 }).toJSDate(),
      today.toJSDate(),
    ];
    ranges[this.$t("components.inputs.dateRangePicker.ranges.last90Days")] = [
      today.minus({ days: 90 }).toJSDate(),
      today.toJSDate(),
    ];
    ranges[this.$t("components.inputs.dateRangePicker.ranges.thisYear")] = [
      today.startOf("year").toJSDate(),
      today.endOf("Year").startOf("day").toJSDate(),
    ];
    ranges[this.$t("components.inputs.dateRangePicker.ranges.lastYear")] = [
      today.minus({ years: 1 }).startOf("year").toJSDate(),
      today.minus({ years: 1 }).endOf("year").startOf("day").toJSDate(),
    ];

    return {
      dates: [this.fromDate, this.toDate],
      showDateRangePicker: false,
      rawDates: { startDate: this.fromDate, endDate: this.toDate },
      ranges: ranges,
    };
  },
  watch: {
    fromDate(to) {
      this.dates = [to, this.toDate];
      this.rawDates = {
        startDate: new Date(to),
        endDate: new Date(this.toDate),
      };
    },
    toDate(to) {
      this.dates = [this.fromDate, to];
      this.rawDates = {
        startDate: new Date(this.fromDate),
        endDate: new Date(to),
      };
    },
    rawDates(to) {
      const fromDate = new Date(to.startDate).toISOString().split("T")[0];
      const toDate = new Date(to.endDate).toISOString().split("T")[0];
      this.dates = [fromDate, toDate];
    },
    dates(to) {
      this.dateRangeChanged(to);
    },
  },
  computed: {
    formatDates() {
      const formattedFrom = this.formatDate(this.dates[0]);
      const formattedTo = this.formatDate(this.dates[1]);
      return [formattedFrom, formattedTo];
    },
  },
  methods: {
    async getOldestAssetDate() {
      try {
        const response = await fetch(this.route("api.assets.oldest", {}));
        if (!response.ok) {
          return Promise.reject();
        }
        const data = await response.json();
        const formattedDate = data.split(".")[0];
        const oldestDate = new Date(formattedDate);

        const allTimeKey = this.$t(
          "components.inputs.dateRangePicker.ranges.allTime"
        );
        this.$set(this.ranges, allTimeKey, [
          oldestDate,
          DateTime.utc().endOf("day").toJSDate(),
        ]);
      } catch (error) {
        console.error("Error fetching oldest asset date:", error);
      }
    },

    onClickOutside(e) {
      if (
        this.showDateRangePicker &&
        e.target.classList.contains("v-overlay__scrim")
      ) {
        this.showDateRangePicker = false;
      }
    },
    formatDate(dateString) {
      const date = new Date(dateString);
      const options = {
        weekday: "short",
        day: "numeric",
        month: "short",
        year: "numeric",
      };
      const localDateString = date.toLocaleDateString("en-us", options);
      return localDateString;
    },
    dateRangeChanged(dates) {
      // Flip the dates if from is larger than to
      if (new Date(dates[0]).getTime() > new Date(dates[1]).getTime()) {
        dates.unshift(dates[1]);
        dates.pop();
      }

      if (dates[0] && dates[1]) {
        this.showDateRangePicker = false;
        this.$emit("change", {
          from: dates[0],
          to: dates[1],
        });
      }
    },
  },
  mounted() {
    this.getOldestAssetDate();
  },
};
</script>

<style lang="scss" scoped>
.date-picker {
  z-index: 99999;
  top: 50%;
  position: fixed;
  left: 50%;
  transform: translate(-50%, -50%);
}

@media screen and (min-width: 600px) {
  .date-picker {
    z-index: 99999;
    top: 100%;
    position: absolute;

    right: 0;
    transform: translate(0%, 0%);
  }
}
</style>
