<template>
  <dialog-form :show-dialog="show" @showStateChanged="(val) => (show = val)">
    <dialog-header
      :title="$t('components.integrations.chooseAccounts.title')"
    ></dialog-header>
    <dialog-form-section-one-col style="padding: 0 !important">
      <!--<v-row>
        <v-col cols="12" sm="5">
          <v-text-field
            v-model="accountSearch"
            class="rounded-0 mb-4"
            background-color="#F7F7F7"
            autocomplete="financial-accounts"
            solo
            flat
            :label="$t('components.integrations.chooseAccounts.search')"
            single-line
            filled
            clearable
            type="search"
            :hide-details="true"
          ></v-text-field>
        </v-col>
      </v-row>-->
      <v-row>
        <v-col cols="12">
          <CategoryAccountMapper
            v-show="!loadingAccountMapper"
            style="flex: 1"
            :categories="categoryMap"
            :accounts="accounts"
            :syncAccounts="accountsToSync"
            :autoMappedAccounts="autoMapptedAccounts"
            @toggleSync="({ id, value }) => setAccountSync(id, value)"
          ></CategoryAccountMapper>
          <div
            v-if="loadingAccountMapper"
            class="flex-grow-1 d-flex align-center justify-center"
            style="height: 50px"
          >
            <activity-indicator></activity-indicator>
          </div>
          <!--<v-expansion-panels accordion class="accordion">
            <v-expansion-panel
              v-for="accountGroup in filteredAccountGroups"
              :key="accountGroup.accountNumber"
            >
              <v-expansion-panel-header>
                <v-checkbox
                  class="no-gutters"
                  hide-details
                  :indeterminate="
                    accountGroupPartiallyChecked(accountGroup.accountNumber)
                  "
                  :input-value="form.financialAccountIds"
                  :value="accountGroup.id"
                  @change="toggleAccountGroup(accountGroup, $event)"
                  @click="stopPropagation($event)"
                ></v-checkbox>
                <span class="font-weight-bold">
                  {{ accountGroup.accountNumber }} - {{ accountGroup.name }}
                </span>
              </v-expansion-panel-header>
              <v-expansion-panel-content
                v-for="account in accountGroup.accounts"
                :key="account.accountNumber"
              >
                <v-checkbox
                  class="no-gutters"
                  hide-details
                  :label="`${account.accountNumber} - ${account.name}`"
                  v-model="form.financialAccountIds"
                  :value="account.id"
                ></v-checkbox>
              </v-expansion-panel-content>
            </v-expansion-panel>
          </v-expansion-panels>-->
        </v-col>
      </v-row>
    </dialog-form-section-one-col>
    <v-card-actions class="px-6 py-6 footer-section">
      <v-spacer></v-spacer>
      <v-btn
        @click="show = false"
        class="text-none px-4 mb-4 mb-md-0 align-self-stretch"
        color="#686868"
        elevation="0"
        text
        tile
        x-large
      >
        <v-icon class="mr-3" color="#686868">mdi-close</v-icon>
        {{ $t("common.actions.cancel") }}
      </v-btn>
      <v-btn
        class="text-none ml-0 mb-4 mb-md-0 ml-md-4 align-self-stretch white--text"
        color="#F25D3B"
        elevation="0"
        tile
        x-large
        :disabled="loading"
        @click="submit"
      >
        <v-icon class="mr-3" color="#fff">
          mdi-content-save-check-outline
        </v-icon>
        {{ $t("common.actions.saveAndClose") }}
      </v-btn>
    </v-card-actions>
  </dialog-form>
</template>
<script>
import { serialize } from "object-to-formdata";
import DialogForm from "../../Components/Dialog/DialogForm.vue";
import DialogHeader from "../../Components/Dialog/DialogFormHeader.vue";
import DialogFormSectionOneCol from "../Dialog/DialogFormSectionOneCol.vue";
import CategoryAccountMapper from "../Inputs/CategoryAccountMapperNew.vue";
import ActivityIndicator from "../Outputs/ActivityIndicator.vue";
import Vue from "vue";

export default {
  components: {
    DialogForm,
    DialogHeader,
    DialogFormSectionOneCol,
    CategoryAccountMapper,
    ActivityIndicator,
  },
  props: {
    value: Boolean,
    financialAccounts: Array,
    integration: Object,
  },
  emits: ["input"],
  data() {
    return {
      accountSearch: "",
      form: this.$inertia.form({
        financialAccountIds: [],
      }),
      loading: false,
      categoryMap: [],
      accounts: [],
      loadingAccountMapper: false,
      accountsToSync: {},
      autoMapptedAccounts: {},
      assetAttributeSelectOptionIdSuggestion: {},
    };
  },
  mounted() {},
  computed: {
    show: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit("input", value);
      },
    },
    filteredAccounts() {
      return this.financialAccounts.filter((account) => {
        return account.system === this.integration.system;
      });
    },
    accountGroups() {
      if (!this.filteredAccounts) return [];

      const groups = [];

      let currentGroup = null;

      this.filteredAccounts.forEach((account, index) => {
        const accountIsHeading = this.getHeading(account.accountType);

        if (currentGroup !== null && accountIsHeading) {
          groups.push(currentGroup);
          currentGroup = null;
        }

        if (currentGroup === null) {
          currentGroup = {
            id: account.id,
            accountNumber: account.accountNumber,
            name: account.name,
            accounts: [],
          };

          return;
        }

        currentGroup.accounts.push(account);

        if (index === this.filteredAccounts.length - 1) {
          groups.push(currentGroup);
          currentGroup = null;
          return;
        }
      });

      return groups;
    },
    filteredAccountGroups() {
      const filteredGroups = [];

      const searchTerm = this.accountSearch?.toLowerCase() ?? "";

      const searchTermMatches = (account) =>
        account.name.toLowerCase().includes(searchTerm) ||
        account.accountNumber.toString().includes(searchTerm);

      this.accountGroups.forEach((group) => {
        const groupMatched = searchTermMatches(group);

        const filteredGroup = {
          ...group,
          accounts: group.accounts.filter(searchTermMatches),
        };

        if (groupMatched || filteredGroup.accounts.length)
          filteredGroups.push(filteredGroup);
      });

      return filteredGroups;
    },
  },
  methods: {
    setAccountSync(id, value) {
      Vue.set(this.accountsToSync, id, value);
    },
    setLinkedAccounts(callback) {
      // Add updated
      const links = [];

      this.categoryMap.forEach((category) => {
        category.accountNumbers.forEach((x) => {
          if (this.accountsToSync[x.id]) {
            links.push({
              integrationId: this.integration.id,
              assetAttributeSelectOptionId: category.id,
              financialAccountId: x.id,
              autoMappingSuggestion: this.autoMapptedAccounts[x.id],
              assetAttributeSelectOptionIdSuggestion:
                this.assetAttributeSelectOptionIdSuggestion[x.id],
            });
          }
        });
      });

      // Find accounts that have sync enabled but are not included in a category
      const accountsToSyncWithoutCategory = Object.keys(
        this.accountsToSync
      ).filter(
        (key) =>
          this.accountsToSync[key] === true &&
          links.filter((link) => link.financialAccountId === key).length === 0
      );

      // Add to links
      accountsToSyncWithoutCategory.forEach((accountId) =>
        links.push({
          integrationId: this.integration.id,
          assetAttributeSelectOptionId: null,
          financialAccountId: accountId,
          autoMappingSuggestion: this.autoMapptedAccounts[accountId],
          assetAttributeSelectOptionIdSuggestion:
            this.assetAttributeSelectOptionIdSuggestion[accountId],
        })
      );
      console.log("links", links);

      const formData = serialize(
        {
          links: links,
          integrationId: this.integration.id,
        },
        {
          indices: true,
        }
      );

      fetch(this.route("asset-attribute-select-option-financial.update"), {
        method: "POST",
        body: formData,
      })
        .then((res) => res.json())
        .then(() => {
          callback();
        });
    },
    formatCategories(data) {
      let _categoryMap = [];

      data.scopes.forEach((scope) => {
        const catsInScope = data.categories.filter(
          (category) => category.dependentOptionId === scope.id
        );
        catsInScope.forEach((category) => {
          _categoryMap.push({
            ...category,
            accountNumbers: [],
          });
        });
      });

      return _categoryMap.sort((a, b) => a.sortOrder - b.sortOrder);
    },
    formatAccounts(data) {
      // console.log("test", data);
      // console.log("integration", this.integration);
      const _accounts = [];
      const currentParent = {};

      if (this.integration.system === "economic") {
        data.forEach((account) => {
          if (account.accountType === "heading") {
            // Add last parentobject when encountering a new heading (Ignore first time since no parent object data has been added yet)
            if (currentParent.name && currentParent.accountNumbers.length > 0) {
              _accounts.push({ ...currentParent });
            }
            currentParent.name = account.name;
            currentParent.accountNumber = account.accountNumber;
            currentParent.accountNumbers = [];
          } else {
            const accountNumber = {
              name: account.name,
              number: account.accountNumber,
              id: account.id,
            };
            currentParent.accountNumbers.push(accountNumber);
          }
        });
      } else if (this.integration.system === "dynamics365") {
        console.log("data", data);
        data.forEach((account) => {
          if (
            account.accountType.includes("Begin") ||
            account.accountType === "Heading"
          ) {
            // Add last parentobject when encountering a new heading (Ignore first time since no parent object data has been added yet)
            if (currentParent.name && currentParent.accountNumbers.length > 0) {
              _accounts.push({ ...currentParent });
            }
            currentParent.name = account.name;
            currentParent.accountNumber = this.padDynamicsAccountNumber(
              account.accountNumber,
              5
            );
            currentParent.accountNumbers = [];
          } else {
            const accountNumber = {
              name: account.name,
              number: this.padDynamicsAccountNumber(account.accountNumber, 5),
              id: account.id,
            };
            currentParent.accountNumbers.push(accountNumber);
          }
        });
      }

      return _accounts;
    },
    syncAccounts() {
      this.loadingAccountMapper = true;
      fetch(
        this.route("api.onboarding.integrations.economic-accounts.sync", {
          integrationid: this.integration.id,
        }),
        {
          method: "POST",
        }
      )
        .then(() => {
          this.getAccountsAndCategories();
        })
        .catch(() => {
          this.loadingAccountMapper = false;
        });
    },
    async getAccountsAndCategories() {
      fetch(this.route("onboarding.accountNumberMapper"), {})
        .then((res) => res.json())
        .then((data) => {
          this.categoryMap = [];
          this.accounts = [];

          this.categoryMap = [...this.formatCategories(data)];
          this.accounts = [
            ...this.formatAccounts(
              data.economicAccounts.filter(
                (x) => x.system === this.integration.system
              )
            ),
          ];

          this.getSelectedOptions();
        })
        .catch(() => {
          this.loadingAccountMapper = false;
        });
    },
    getSelectedOptions() {
      fetch(
        this.route("asset-attribute-select-option-financial.list", {
          integrationid: this.integration.id,
        }),
        {
          method: "GET",
        }
      )
        .then((res) => res.json())
        .then((data) => {
          console.log(data);
          // Loop though all relations
          data.forEach((attributeAccountLink) => {
            const account = attributeAccountLink.financialAccount;
            const autoMappingSuggestion =
              attributeAccountLink.autoMappingSuggestion;
            const assetAttributeSelectOptionIdSuggestion =
              attributeAccountLink.assetAttributeSelectOptionIdSuggestion;

            // Set account sync to true if present
            Vue.set(this.accountsToSync, account.id, true);

            // add to list of automapped
            Vue.set(
              this.autoMapptedAccounts,
              account.id,
              autoMappingSuggestion
            );

            // add to list of automapped
            Vue.set(
              this.assetAttributeSelectOptionIdSuggestion,
              account.id,
              assetAttributeSelectOptionIdSuggestion
            );

            // loop though all category groups
            this.categoryMap.forEach((category, index) => {
              // If category id matches
              if (
                category.id ===
                attributeAccountLink?.assetAttributeSelectOption?.id
              ) {
                // add account number to category
                this.categoryMap[index].accountNumbers.push({
                  name: account.name,
                  number: account.accountNumber,
                  id: account.id,
                });
              }
            });
          });
        })
        .finally(() => {
          this.loadingAccountMapper = false;
        });
    },
    getHeading(accountType) {
      if (this.integration.system === "economic") {
        return accountType?.includes("heading") ?? false;
      } else {
        return accountType?.includes("Begin") ?? false;
      }
    },
    accountGroupPartiallyChecked(accountNumber) {
      const group = this.accountGroups.find(
        (x) => x.accountNumber === accountNumber
      );

      const allChecked = group.accounts.every((x) =>
        this.form.financialAccountIds.includes(x.id)
      );

      const noneChecked = !group.accounts.some((x) =>
        this.form.financialAccountIds.includes(x.id)
      );

      return !allChecked && !noneChecked;
    },
    toggleAccountGroup(accountGroup, updatedList) {
      const toggleOn = updatedList.includes(accountGroup.id);

      if (toggleOn) {
        accountGroup.accounts.forEach((account) => {
          if (updatedList.includes(account.id)) return;

          updatedList.push(account.id);
        });
      } else {
        const accountIds = accountGroup.accounts.map((x) => x.id);

        updatedList = updatedList.filter((x) => !accountIds.includes(x));
      }

      this.form.financialAccountIds = updatedList;
    },
    stopPropagation(event) {
      event.stopPropagation();
    },
    submit() {
      if (this.loading || !this.integration) return;

      this.loading = true;

      this.setLinkedAccounts(() => {
        this.show = false;
        this.loading = false;
      });

      // old ?
      /*this.form
        .transform((data) => serialize(data))
        .patch(
          this.route("api.integrations.accounts.update", this.integration.id),
          {
            onSuccess: () => (this.show = false),
            onFinish: () => (this.loading = false),
          }
        );*/
    },
    padDynamicsAccountNumber(num, size) {
      num = num.toString();
      while (num.length < size) num = "0" + num;
      return num;
    },
  },
  watch: {
    integration(to, from) {
      if (to !== from) {
        this.syncAccounts();
      }
    },
    show(value) {
      if (!value) {
        this.form = this.$inertia.form({ financialAccountIds: [] });
        return;
      }

      if (!this.integration) return;

      this.form = this.$inertia.form({
        financialAccountIds: this.integration.financialAccounts.map(
          (x) => x.id
        ),
      });
    },
  },
};
</script>
<style lang="scss" scoped>
.no-gutters {
  margin: 0;
  padding: 0;
}

.accordion .v-expansion-panel-header > :not(.v-expansion-panel-header__icon) {
  flex: unset !important;
}

.footer-section {
  position: sticky;
  bottom: 0;
  background: #fff;

  .add-account-number-btn {
    width: 100%;
  }
}
</style>
