<template>
  <div>
    <v-btn
      class="text-none font-weight-medium"
      style="color: #686868"
      outlined
      tile
      large
      @click="() => (show = !show)"
    >
      <slot name="trigger-button"></slot>
    </v-btn>
    <dialog-form
      :showDialog="show"
      @showStateChanged="(state) => (show = state)"
    >
      <dialog-form-header
        :title="$t('components.attributes.filters.title')"
      ></dialog-form-header>
      <dialog-form-section-one-col>
        <v-row
          v-for="(attributeFilter, index) in form.attributeFilters"
          :key="index"
          class="black--text"
        >
          <v-col>
            <div>
              {{ $t("components.attributes.filters.fields.attribute.label") }}
            </div>
            <v-autocomplete
              class="rounded-0 mb-4"
              style="cursor: pointer"
              background-color="#F7F7F7"
              flat
              :label="
                $t('components.attributes.filters.labels.selectAttribute')
              "
              single-line
              filled
              light
              :menu-props="{ top: false, offsetY: true }"
              color="red"
              item-color="red"
              :hide-details="true"
              dense
              v-model="attributeFilter.attributeId"
              :items="availableAttributes"
              :error-messages="form.errors[index]"
              :placeholder="
                $t('components.attributes.filters.fields.attribute.placeholder')
              "
              hide-selected
              item-text="name"
              item-value="id"
              solo
            >
            </v-autocomplete>
          </v-col>

          <v-col>
            <div>
              {{ $t("components.attributes.filters.fields.filter.label") }}
            </div>
            <v-select
              style="cursor: pointer"
              background-color="#F7F7F7"
              flat
              :label="$t('components.attributes.filters.labels.selectFilter')"
              single-line
              filled
              light
              :menu-props="{ top: false, offsetY: true }"
              color="red"
              item-color="red"
              :hide-details="true"
              dense
              v-model="attributeFilter.filter"
              :items="filterOptions"
              :error-messages="form.errors[index + '-data']"
              :placeholder="
                $t('components.attributes.filters.fields.filter.placeholder')
              "
              item-value="value"
              solo
              clearable
            >
              <template v-slot:item="{ item }">
                <v-icon class="mr-2">{{ item.icon }}</v-icon>
                <p class="mb-0">{{ item.text }}</p>
              </template>
              <template v-slot:selection="{ item }">
                <v-icon class="mr-2">{{ item.icon }}</v-icon>
                <p class="mb-0">{{ item.text }}</p>
              </template>
            </v-select>
          </v-col>

          <v-col>
            <div>
              {{ $t("components.attributes.filters.fields.filterBy") }}
            </div>
            <v-text-field
              class="rounded-0 mb-4"
              background-color="#F7F7F7"
              solo
              flat
              single-line
              filled
              type="text"
              v-model="attributeFilter.filterBy"
              dense
            />
          </v-col>

          <v-col>
            <div>
              {{ $t("components.attributes.filters.fields.sorting") }}
            </div>
            <v-select
              style="cursor: pointer"
              background-color="#F7F7F7"
              flat
              label="Select region"
              single-line
              filled
              light
              :menu-props="{ top: false, offsetY: true }"
              color="red"
              item-color="red"
              :hide-details="true"
              dense
              v-model="attributeFilter.sortDesc"
              :error-messages="form.errors[index + '-data']"
              :items="sortingOptions"
              item-text="text"
              item-value="value"
              solo
            >
            </v-select>
          </v-col>
        </v-row>
        <v-row dense align="center">
          <v-btn color="blue darken-1" text @click="addAttributeFilter()">
            <v-icon class="black--text">mdi-plus</v-icon>
            <p class="black--text mb-0">
              {{ $t("components.attributes.filters.addAnother") }}
            </p>
          </v-btn>
        </v-row>

        <v-row class="black--text">
          <v-col>{{
            $t("components.attributes.filters.appliedFilters")
          }}</v-col>
        </v-row>

        <v-col
          v-for="(attributeFilter, index) in form.appliedAttributeFilters"
          :key="index + '-applied'"
          class="black--text"
        >
          <v-divider></v-divider>
          <v-row no-gutters justify="space-between">
            <v-col>
              {{
                attributeFilter.attributeId != null
                  ? attributes.find((x) => x.id == attributeFilter.attributeId)
                      .name
                  : propertyOptions.find(
                      (x) => x.value == attributeFilter.propertyName
                    ).text
              }}
            </v-col>
            <v-col v-if="attributeFilter.filter != null">
              <v-icon class="black--text">{{
                filterOptions.find((x) => attributeFilter.filter == x.value)
                  .icon
              }}</v-icon>
              {{
                filterOptions.find((x) => attributeFilter.filter == x.value)
                  .text
              }}
            </v-col>
            <v-col v-else />
            <v-col>
              {{ attributeFilter.filterBy }}
            </v-col>
            <v-col>
              {{
                sortingOptions.find((x) => x.value == attributeFilter.sortDesc)
                  .text
              }}
            </v-col>
            <v-icon
              @click="removeAppliedAttributeFilter(attributeFilter)"
              class="black--text"
              >mdi-close</v-icon
            >
          </v-row>
          <v-divider></v-divider>
        </v-col>
      </dialog-form-section-one-col>
      <v-card-actions class="py-6 px-6 d-flex flex-column-reverse flex-md-row">
        <v-btn
          class="text-none ml-0 mb-4 mb-md-0 px-6 align-self-stretch"
          color="#686868"
          dark
          elevation="0"
          tile
          x-large
          text
          @click="removeAllAttributeFilters()"
        >
          <v-icon color="#686868">mdi-trash-can-outline</v-icon>
          <p class="mb-0">
            {{ $t("components.attributes.filters.removeAllFilters") }}
          </p>
        </v-btn>
        <v-spacer></v-spacer>
        <v-alert dense v-if="form.errors.alert" type="error" class="mb-0">
          {{ form.errors.alert }}
        </v-alert>
        <v-btn
          class="text-none ml-0 mb-4 mb-md-0 ml-md-4 align-self-stretch"
          color="#686868"
          elevation="0"
          tile
          dark
          x-large
          text
          @click="show = false"
        >
          <v-icon color="#686868">mdi-close</v-icon>
          <p class="mb-0">
            {{ $t("common.actions.close") }}
          </p>
        </v-btn>
        <v-btn
          class="text-none ml-0 mb-4 mb-md-0 ml-md-4 px-6 align-self-stretch"
          color="#266663"
          dark
          elevation="0"
          tile
          x-large
          @click="applyAttributeFilter()"
          :disabled="loading"
        >
          <v-icon class="white--text">mdi-content-save-outline</v-icon>
          <p class="white--text mb-0">
            {{ $t("common.actions.apply") }}
          </p>
        </v-btn>
      </v-card-actions>
    </dialog-form>
  </div>
</template>

<script>
import DialogForm from "../Dialog/DialogForm.vue";
import DialogFormHeader from "../Dialog/DialogFormHeader.vue";
import DialogFormSectionOneCol from "../Dialog/DialogFormSectionOneCol.vue";
export default {
  components: { DialogForm, DialogFormSectionOneCol, DialogFormHeader },
  props: {
    value: Boolean,
    attributeFilters: Array,
    attributes: Array,
  },
  computed: {
    show: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit("input", value);
      },
    },
    availableAttributes() {
      return this.attributes.map((x) => {
        x.disabled =
          this.form.appliedAttributeFilters.some(
            (filter) => filter.attributeId == x.id
          ) ||
          this.form.attributeFilters.some(
            (filter) => filter.attributeId == x.id
          );
        return x;
      });
    },
    propertyOptions() {
      return [
        {
          text: this.$t("common.fieldNames.id"),
          value: "referenceKey",
        },
        {
          text: this.$t("common.dataTable.locationType"),
          value: "locationType",
        },
        {
          text: this.$t("common.dataTable.solutionType"),
          value: "solutionType",
        },
      ];
    },
    sortingOptions() {
      return [
        {
          text: this.$t("common.sorting.ascending"),
          value: false,
        },
        {
          text: this.$t("common.sorting.descending"),
          value: true,
        },
        {
          text: this.$t("common.sorting.noSorting"),
          value: null,
        },
      ];
    },
    filterOptions() {
      return [
        {
          text: this.$t("common.filters.include"),
          value: "Include",
          icon: "mdi-alphabetical-variant",
        },
        {
          text: this.$t("common.filters.notInclude"),
          value: "NotInclude",
          icon: "mdi-alphabetical-variant-off",
        },
        {
          text: this.$t("common.filters.startsWith"),
          value: "Starts",
          icon: "mdi-format-letter-starts-with",
        },
        {
          text: this.$t("common.filters.endsWith"),
          value: "Ends",
          icon: "mdi-format-letter-ends-with",
        },
        {
          text: this.$t("common.filters.equalTo"),
          value: "Equal",
          icon: "mdi-equal",
        },
        {
          text: this.$t("common.filters.notEqualTo"),
          value: "NotEqual",
          icon: "mdi-not-equal-variant",
        },
        {
          text: "",
          value: "Range",
          icon: "",
        },
      ];
    },
  },
  data() {
    return {
      loading: false,
      form: this.$inertia.form({
        attributeFilters: [],
        appliedAttributeFilters: this.attributeFilters,
      }),
      search: "",
    };
  },
  watch: {
    attributeFilters() {
      this.form.appliedAttributeFilters = this.attributeFilters;
    },
  },
  methods: {
    changeAttributeFilters() {
      this.$emit("changeAttributeFilters", this.form.appliedAttributeFilters);
      this.show = false;
    },
    removeAttributeFilter(option) {
      this.form.attributeFilters = this.form.attributeFilters.filter(
        (x) => x !== option
      );
    },
    removeAppliedAttributeFilter(option) {
      this.form.appliedAttributeFilters =
        this.form.appliedAttributeFilters.filter((x) => x !== option);
    },
    removeAllAttributeFilters() {
      this.form.attributeFilters = [];
      this.form.appliedAttributeFilters = [];
      this.changeAttributeFilters();
    },
    addAttributeFilter() {
      this.form.attributeFilters.push({ sortDesc: null });
    },
    applyAttributeFilter() {
      this.form.errors = {};
      this.form.attributeFilters.forEach((filter, index) => {
        if (filter.attributeId == null) {
          this.form.errors[index] = this.$t(
            "components.attributes.filters.errors.noAttribute"
          );
        } else if (filter.filter == null && filter.sortDesc == null) {
          this.form.errors[index + "-data"] = this.$t(
            "components.attributes.filters.errors.filteringOrSorting"
          );
        }
      });
      if (Object.keys(this.form.errors).length) {
        return;
      }

      this.form.attributeFilters.forEach((x) => {
        x.attribute = this.attributes.find(
          (attribute) => attribute.id == x.attributeId
        );
      });

      this.form.appliedAttributeFilters =
        this.form.appliedAttributeFilters.concat(this.form.attributeFilters);

      this.form.appliedAttributeFilters.forEach((x, index) => {
        x.sortOrder = index;
      });

      this.form.attributeFilters = [];
      this.changeAttributeFilters();
    },
  },
};
</script>
<style scoped>
.solo-item-list-area {
  height: 30vh;
  overflow-y: auto;
  border-radius: 4px;
  box-sizing: border-box;
  color: rgba(0, 0, 0, 0.87);
  background: #ffffff;
  background-origin: padding-box;
  background-clip: border-box;
  background-position-x: 0%;
  background-position-y: 0%;
  background-repeat: repeat;
  background-attachment: scroll;
  background-image: none;
  background-size: auto;
  box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2),
    0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
  transition: background 0.3s cubic-bezier(0.25, 0.8, 0.5, 1);
}
.no-gutters {
  margin: 0;
  padding: 0;
}
</style>
