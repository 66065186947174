<template>
  <div>
    <h4 class="text-h4">
      {{
        !!organization.id
          ? $t("pages.organizations.form.edit.title")
          : $t("pages.organizations.form.create.title")
      }}
    </h4>
    <v-spacer class="mb-6"></v-spacer>
    <div>
      <v-row>
        <v-col col="12" lg="8">
          <form @submit.prevent="submit">
            <v-card elevation="1">
              <v-card-text>
                <v-row no-gutters class="align-start">
                  <v-col cols="4">
                    <v-subheader>{{
                      $t("common.fieldNames.name")
                    }}</v-subheader>
                  </v-col>
                  <v-col cols="8">
                    <v-text-field
                      v-model="form.name"
                      name="name"
                      type="text"
                      :disabled="isContentDragging"
                      :error-messages="form.errors.name"
                    />
                  </v-col>
                </v-row>
                <v-row no-gutters class="align-start">
                  <v-col cols="4">
                    <v-subheader>{{
                      $t("pages.organizations.form.fields.subName")
                    }}</v-subheader>
                  </v-col>
                  <v-col cols="8">
                    <v-text-field
                      v-model="form.subName"
                      name="name"
                      type="text"
                      :disabled="isContentDragging"
                      :error-messages="$page.props.errors.subName"
                    />
                  </v-col>
                </v-row>
                <v-row no-gutters class="align-start">
                  <v-col cols="4">
                    <v-subheader>{{
                      $t("pages.organizations.form.fields.description")
                    }}</v-subheader>
                  </v-col>
                  <v-col cols="8">
                    <editor
                      v-model="form.description"
                      :disabled="isContentDragging"
                    />
                  </v-col>
                </v-row>
                <v-row no-gutters class="align-start">
                  <v-col cols="4">
                    <v-subheader>{{
                      $t("pages.organizations.form.fields.cmsUrl")
                    }}</v-subheader>
                  </v-col>
                  <v-col cols="8">
                    <v-text-field
                      v-model="form.contentManagementSystemUrl"
                      name="cms"
                      type="text"
                      :disabled="isContentDragging"
                      :error-messages="form.errors.contentManagementSystemUrl"
                    />
                  </v-col>
                </v-row>
                <v-row no-gutters class="align-start">
                  <v-col cols="4">
                    <v-subheader>{{
                      $t("pages.organizations.form.fields.logo.label")
                    }}</v-subheader>
                  </v-col>
                  <v-col cols="8" class="mb-4">
                    <v-file-input
                      v-model="form.logo"
                      :rules="logoRules"
                      accept="image/png, image/jpeg, image/bmp"
                      name="file"
                      show-size
                      :placeholder="
                        $t('pages.organizations.form.fields.logo.placeholder')
                      "
                      :error-messages="form.errors.logo"
                    />
                    <img
                      v-if="organization.logoFile"
                      :src="
                        route('api.files.content', {
                          id: organization.logoFile.id,
                          filename: organization.logoFile.originalName,
                        })
                      "
                      class="pt-4 pl-8 max-w-1/2"
                    />
                  </v-col>
                </v-row>
                <v-row v-if="false" no-gutters class="align-start">
                  <v-col cols="4">
                    <v-subheader>{{
                      $t("pages.organizations.form.fields.primaryColor")
                    }}</v-subheader>
                  </v-col>
                  <v-col cols="8" class="justify-end d-flex">
                    <label
                      for="color8"
                      :style="{ backgroundColor: form.color8 }"
                      class="color-label"
                    >
                      <input type="color" v-model="form.color8" id="color8" />
                    </label>
                    <label
                      for="color7"
                      :style="{ backgroundColor: form.color7 }"
                      class="color-label"
                    >
                      <input type="color" v-model="form.color7" id="color7" />
                    </label>
                    <label
                      for="color6"
                      :style="{ backgroundColor: form.color6 }"
                      class="color-label"
                    >
                      <input type="color" v-model="form.color6" id="color6" />
                    </label>
                    <label
                      for="color5"
                      :style="{ backgroundColor: form.color5 }"
                      class="color-label"
                    >
                      <input type="color" v-model="form.color5" id="color5" />
                    </label>
                    <label
                      for="color4"
                      :style="{ backgroundColor: form.color4 }"
                      class="color-label"
                    >
                      <input type="color" v-model="form.color4" id="color4" />
                    </label>
                    <label
                      for="color3"
                      :style="{ backgroundColor: form.color3 }"
                      class="color-label"
                    >
                      <input type="color" v-model="form.color3" id="color3" />
                    </label>
                    <label
                      for="color2"
                      :style="{ backgroundColor: form.color2 }"
                      class="color-label"
                    >
                      <input type="color" v-model="form.color2" id="color2" />
                    </label>
                    <label
                      for="color1"
                      :style="{ backgroundColor: form.color1 }"
                      class="color-label"
                    >
                      <input type="color" v-model="form.color1" id="color1" />
                    </label>
                  </v-col>
                </v-row>
              </v-card-text>
              <v-card-actions>
                <flash-messages />
                <request-size-error :errors="form.errors" />
                <v-spacer></v-spacer>
              </v-card-actions>
            </v-card>
            <h5 class="text-h5 mt-4" v-if="form.contents.length">
              {{ $t("pages.organizations.form.organizationContents") }}
            </h5>
            <vue-draggable
              v-model="form.contents"
              group="contents"
              @choose="onDraggingStart"
              @unchoose="onDraggingEnd"
              @end="onDraggingEnd"
            >
              <v-card
                elevation="1"
                v-for="(content, index) in form.contents"
                :key="index"
                class="mt-4 cursor-grab"
              >
                <v-card-title class="pb-1">
                  {{
                    (content.informationType && content.informationType.name) ||
                    content.name
                  }}
                  <v-spacer />
                  <v-btn icon @click="removeContent(index)"
                    ><v-icon>mdi-trash-can-outline</v-icon></v-btn
                  >
                </v-card-title>
                <v-card-text>
                  <v-row no-gutters class="align-center">
                    <v-col cols="4">
                      <v-subheader>{{
                        $t("components.contents.visibility")
                      }}</v-subheader>
                    </v-col>
                    <v-col cols="8">
                      <v-radio-group
                        v-model="content.visibility"
                        row
                        class="mt-1 visibility-buttons"
                        :error-messages="
                          form.errors[`contents${index}Visibility`]
                        "
                      >
                        <v-radio
                          v-for="visibility in visibilities"
                          :key="visibility"
                          :label="$t(`common.visibilities.${visibility}`)"
                          :value="visibility"
                        />
                      </v-radio-group>
                    </v-col>
                  </v-row>
                  <editor v-model="content.description" />
                </v-card-text>
                <v-card-text>
                  <v-file-input
                    v-model="content.newFiles"
                    :placeholder="
                      $t('components.contents.addFiles.placeholder')
                    "
                    :label="$t('components.contents.addFiles.label')"
                    multiple
                    small-chips
                    prepend-icon="mdi-paperclip"
                  />
                </v-card-text>
                <v-card-text v-if="content.files && content.files.length">
                  <v-simple-table fixed-header>
                    <template v-slot:default>
                      <thead>
                        <tr>
                          <th class="text-left">
                            {{ $t("common.fieldNames.name") }}
                          </th>
                          <th class="text-left">
                            {{ $t("common.fieldNames.createdAt") }}
                          </th>
                          <th class="text-right"></th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr
                          v-for="(item, fIndex) in content.files"
                          :key="item.id"
                        >
                          <td>{{ item.name }}{{ item.extension }}</td>
                          <td>
                            {{ new Date(item.createdAt).toLocaleString() }}
                          </td>
                          <td class="text-right">
                            <v-btn
                              small
                              text
                              @click.stop
                              :href="
                                route('api.files.content', {
                                  id: item.id,
                                  filename: item.originalName,
                                })
                              "
                              target="_blank"
                            >
                              <v-icon class="mr-2">mdi-attachment</v-icon>
                            </v-btn>
                            <v-btn
                              small
                              text
                              @click.stop="removeFile(index, fIndex)"
                            >
                              <v-icon class="mr-2"
                                >mdi-trash-can-outline</v-icon
                              >
                            </v-btn>
                          </td>
                        </tr>
                      </tbody>
                    </template>
                  </v-simple-table>
                </v-card-text>
              </v-card>
            </vue-draggable>
            <h5 class="text-h5 mt-4" v-if="form.defaultLocationContents.length">
              {{ $t("pages.organizations.form.defaultLocationContents") }}
            </h5>
            <vue-draggable
              v-model="form.defaultLocationContents"
              group="defaultLocationContents"
              @choose="onDraggingStart"
              @unchoose="onDraggingEnd"
              @end="onDraggingEnd"
            >
              <v-card
                elevation="1"
                v-for="(content, index) in form.defaultLocationContents"
                :key="index"
                class="mt-4 cursor-grab"
              >
                <v-card-title class="pb-1">
                  {{
                    (content.informationType && content.informationType.name) ||
                    content.name
                  }}
                  <v-spacer />
                  <v-btn icon @click="removeLocationContent(index)"
                    ><v-icon>mdi-trash-can-outline</v-icon></v-btn
                  >
                </v-card-title>
                <v-card-text>
                  <v-row no-gutters class="align-center">
                    <v-col cols="4">
                      <v-subheader>{{
                        $t("components.contents.visibility")
                      }}</v-subheader>
                    </v-col>
                    <v-col cols="8">
                      <v-radio-group
                        v-model="content.visibility"
                        row
                        class="mt-1 visibility-buttons"
                        :error-messages="
                          form.errors[`contents${index}Visibility`]
                        "
                      >
                        <v-radio
                          v-for="visibility in visibilities"
                          :key="visibility"
                          :label="$t(`common.visibilities.${visibility}`)"
                          :value="visibility"
                        />
                      </v-radio-group>
                    </v-col>
                  </v-row>
                  <editor v-model="content.description" />
                </v-card-text>
                <v-card-text>
                  <v-file-input
                    v-model="content.newFiles"
                    :placeholder="
                      $t('components.contents.addFiles.placeholder')
                    "
                    :label="$t('components.contents.addFiles.label')"
                    multiple
                    small-chips
                    prepend-icon="mdi-paperclip"
                  />
                </v-card-text>
                <v-card-text v-if="content.files && content.files.length">
                  <v-simple-table fixed-header>
                    <template v-slot:default>
                      <thead>
                        <tr>
                          <th class="text-left">
                            {{ $t("common.fieldNames.name") }}
                          </th>
                          <th class="text-left">
                            {{ $t("common.fieldNames.createdAt") }}
                          </th>
                          <th class="text-right"></th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr
                          v-for="(item, fIndex) in content.files"
                          :key="item.id"
                        >
                          <td>{{ item.name }}{{ item.extension }}</td>
                          <td>
                            {{ new Date(item.createdAt).toLocaleString() }}
                          </td>
                          <td class="text-right">
                            <v-btn
                              small
                              text
                              @click.stop
                              :href="
                                route('api.files.content', {
                                  id: item.id,
                                  filename: item.originalName,
                                })
                              "
                              target="_blank"
                              :download="`${item.name}${item.extension}`"
                            >
                              <v-icon class="mr-2">mdi-attachment</v-icon>
                            </v-btn>
                            <v-btn
                              small
                              text
                              @click.stop="
                                removeLocationContentFile(index, fIndex)
                              "
                            >
                              <v-icon class="mr-2"
                                >mdi-trash-can-outline</v-icon
                              >
                            </v-btn>
                          </td>
                        </tr>
                      </tbody>
                    </template>
                  </v-simple-table>
                </v-card-text>
              </v-card>
            </vue-draggable>
          </form>
        </v-col>

        <v-col v-if="false" col="12" lg="4">
          <layout-preview v-if="form.color2" v-model="form" />
        </v-col>
      </v-row>
    </div>
    <content-dialog
      v-model="contentDialog"
      :contents="informationTypes"
      @submit="addContent"
    />
    <content-dialog
      v-model="locationContentDialog"
      :contents="informationTypes"
      @submit="addLocationContent"
    />
    <v-speed-dial v-model="fab" fixed fab bottom right>
      <template v-slot:activator>
        <v-btn
          fab
          color="primary"
          v-if="fab"
          :disabled="sending || form.processing"
          @click="submit"
        >
          <v-icon>mdi-content-save</v-icon>
        </v-btn>
        <v-btn fab color="secondary" v-else>
          <v-icon>mdi-pencil</v-icon>
        </v-btn>
      </template>
      <v-tooltip
        :nudge-left="20"
        left
        :value="true"
        class="v-tooltip--speed-dial"
      >
        <template #activator="{ on }">
          <v-btn
            fab
            dark
            small
            color="indigo"
            v-on="on"
            @click="contentDialog = true"
          >
            <v-icon>mdi-plus</v-icon>
          </v-btn>
        </template>
        <span>{{ $t("pages.organizations.form.menu.addContent") }}</span>
      </v-tooltip>
      <v-tooltip
        :nudge-left="20"
        left
        :value="true"
        class="v-tooltip--speed-dial"
      >
        <template #activator="{ on }">
          <v-btn
            fab
            dark
            small
            color="indigo"
            v-on="on"
            @click="locationContentDialog = true"
          >
            <v-icon>mdi-plus</v-icon>
          </v-btn>
        </template>
        <span>{{
          $t("pages.organizations.form.menu.addDefaultLocationContent")
        }}</span>
      </v-tooltip>
      <v-tooltip
        :nudge-left="20"
        left
        :value="true"
        class="v-tooltip--speed-dial"
      >
        <template #activator="{ on }">
          <v-btn
            fab
            dark
            small
            color="red"
            v-on="on"
            :disabled="sending || form.processing"
            @click="destroy"
          >
            <v-icon>mdi-trash-can</v-icon>
          </v-btn>
        </template>
        <span>{{
          $t("pages.organizations.form.menu.deleteOrganization")
        }}</span>
      </v-tooltip>
    </v-speed-dial>
  </div>
</template>

<script>
import { appLayout } from "@/util/layout";
import FlashMessages from "@/Shared/FlashMessages";
import Editor from "@/Shared/Editor";
import ContentDialog from "@/Shared/ContentDialog";
import RequestSizeError from "@/Shared/RequestSizeError";
import { rgbToHex, rgbToHsv, hexToRgb, hsvToRgb } from "@/util/colors";
import LayoutPreview from "./LayoutPreview.vue";
import { objectToFormData } from "@/util/formData";
import { pascalToSpaces } from "@/util/text";

export default {
  layout: appLayout({ title: "pages.organizations.form.pageTitle" }),
  components: {
    FlashMessages,
    Editor,
    LayoutPreview,
    ContentDialog,
    RequestSizeError,
  },
  props: {
    organization: Object,
    informationTypes: Array,
    visibilities: Array,
  },
  data() {
    return {
      logoRules: [
        (value) =>
          !value ||
          value.size < 1000000 ||
          this.$t("pages.organizations.form.fileSizeRule"),
      ],
      form: this.$inertia.form({
        id: this.organization.id,
        name: this.organization.name,
        subName: this.organization.subName,
        subdomain: this.organization.subdomain,
        contentManagementSystemUrl:
          this.organization.contentManagementSystemUrl,
        logo: null,
        description: this.organization.description,
        color1: this.organization.color1,
        color2: this.organization.color2,
        color3: this.organization.color3,
        color4: this.organization.color4,
        color5: this.organization.color5,
        color6: this.organization.color6,
        color7: this.organization.color7,
        color8: this.organization.color8,
        contents: this.organization.contents || [],
        defaultLocationContents:
          this.organization.defaultLocationContents || [],
      }),
      contentDialog: false,
      locationContentDialog: false,
      sending: false,
      fab: false,
      isContentDragging: false,
    };
  },
  watch: {
    "form.color8": function (newVal) {
      const hsv = rgbToHsv(hexToRgb(newVal));
      const defaultVvalues = [76, 67, 56, 47, 38, 29, 22].reverse();
      defaultVvalues.forEach((x, index) => {
        const rgb = hsvToRgb(hsv[0], hsv[1], x);
        this.form[`color${index + 1}`] = rgbToHex(...rgb);
      });
    },
  },
  methods: {
    submit() {
      const preparedForm = this.form.transform((data) => {
        const transformedData = {
          ...data,
          contents: data.contents.map((c, index) => ({
            ...c,
            sortOrder: index + 1,
          })),
          defaultLocationContents: data.defaultLocationContents.map(
            (c, index) => ({ ...c, sortOrder: index + 1 })
          ),
        };

        return objectToFormData(transformedData);
      });
      if (this.organization.id) {
        preparedForm.put(
          this.route("organizations.update", this.organization.id),
          {
            preserveScroll: true,
            onSucces: () => {
              this.form.logo = null;
            },
          }
        );
      } else {
        preparedForm.post(this.route("organizations.store"), {
          preserveScroll: true,
          onSucces: () => {
            this.form.logo = null;
          },
        });
      }
    },
    pascalToSpaces(text) {
      return pascalToSpaces(text);
    },
    removeFile(contentIndex, fileIndex) {
      this.form.contents[contentIndex].files.splice(fileIndex, 1);
    },
    removeContent(index) {
      this.form.contents.splice(index, 1);
    },
    addContent(contents) {
      this.form.contents = this.form.contents.concat(contents);
    },
    removeLocationContentFile(contentIndex, fileIndex) {
      this.form.defaultLocationContents[contentIndex].files.splice(
        fileIndex,
        1
      );
    },
    removeLocationContent(index) {
      this.form.defaultLocationContents.splice(index, 1);
    },
    addLocationContent(contents) {
      this.form.defaultLocationContents =
        this.form.defaultLocationContents.concat(contents);
    },
    onDraggingStart() {
      this.isContentDragging = true;
    },
    onDraggingEnd() {
      this.isContentDragging = false;
    },
    destroy() {
      if (window.confirm(this.$t("pages.organizations.form.confirmDelete"))) {
        this.$inertia.delete(
          this.route("organizations.destroy", this.organization.id),
          {
            onStart: () => (this.sending = true),
            onFinish: () => (this.sending = false),
          }
        );
      }
    },
  },
};
</script>
<style>
.editor-content .ProseMirror {
  min-height: 150px;
}

.color-label input {
  visibility: hidden;
}
.color-label {
  width: 50px;
  height: 50px;
  display: block;
  border-radius: 15px;
  margin: 0 2px;
}
</style>
