<template>
  <div class="px-5">
    <v-row align="center">
      <div
        class="col-12 border mb-3 justify-center d-flex align-center"
        style="height: 90px"
      >
        <EConomic v-if="selectedIntegration === 'e-conomic'" />
        <!-- <Uniconta v-if="selectedIntegration === 'uniconta'" /> -->
        <Dynamic365 v-if="selectedIntegration === 'dynamic365'" />
      </div>
    </v-row>
  </div>
</template>
<script>
import EConomic from "../../../assets/svg/visma-e-conomic.svg";
// import Uniconta from "../../../assets/svg/uniconta.svg";
import Dynamic365 from "../../../assets/svg/dynamic365.svg";
export default {
  components: {
    EConomic,
    // Uniconta,
    Dynamic365,
  },
  data() {
    return {};
  },
  props: {
    selectedIntegration: null,
  },
};
</script>
