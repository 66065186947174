<template>
  <v-tab-item :value="tabValue">
    <v-card flat>
      <v-card-text>
        <dialog-form-section-two-cols no-bottom-border>
          <template slot="left">
            <!--<attribute-link-input
              :attribute="accountNumberAttribute"
              :value="accountNumberData"
              @input="updateData(accountNumberAttribute.id, $event)"
              @decimal_changed="handleAccountNumberChanged"
            />-->
            <attribute-link-input
              class="my-2"
              :attribute="supplierAttribute"
              :value="supplierData"
              @input="updateData(supplierAttribute.id, $event)"
              @string_changed="handleSupplierChanged"
            />

            <!--<attribute-link-input
              :attribute="invoiceAttribute"
              :value="invoiceData"
              :second-value="invoiceSecondValueData"
              @input="updateData(invoiceAttribute.id, $event)"
              @input_secondValue="
                updateSecondValueData(invoiceAttribute.id, arguments[0])
              "
              @file_changed="handleInvoiceChanged"
              @file_removed="handleInvoiceRemoved"
            />-->

            <dialog-form-input-wrapper title="Attachment">
              <dialog-form-file-upload-input
                small
                :allowedImportMimeTypes="['application/pdf']"
                :allowedFileExpensions="'.pdf'"
                @onFileUploadChanged="
                  ($event) => {
                    updateData(invoiceAttribute.id, $event.target.files[0]);
                    handleInvoiceChanged($event.target.files[0]);
                  }
                "
                @fileChanged="
                  ($event) => {
                    updateData(invoiceAttribute.id, $event);
                    handleInvoiceChanged($event);
                  }
                "
                :selectedImportFile="invoiceData"
                :multiple="false"
                :overrideUploadFileText="$t('pages.assets.form.uploadFileText')"
              ></dialog-form-file-upload-input>
              <p class="mt-1" style="font-size: 10px; color: #b4b4b4">
                {{ $t("pages.assets.form.uploadFileInfo") }}
              </p>
            </dialog-form-input-wrapper>
            <div
              v-if="invoiceData"
              class="d-flex flex-row align-center pl-4 mb-2"
              style="background: #fef2ef; border: 1px solid #fef2ef"
            >
              <PdfIcon></PdfIcon>
              <p class="flex-grow-1 mb-0 ml-2">{{ invoiceData?.name }}</p>
              <v-btn
                class="mr-1"
                icon
                dark
                @click="updateData(invoiceAttribute.id, null)"
                ><v-icon size="20" color="#686868">mdi-close</v-icon></v-btn
              >
            </div>
            <div v-if="!invoiceData">
              <div
                v-for="invoice in invoiceSecondValueData"
                :key="invoice.id"
                class="d-flex flex-row align-center pl-4 mb-2 invoice-list-item"
              >
                <PdfIcon></PdfIcon>
                <v-btn
                  class="invoice-btn flex-grow-1 mb-0 ml-0 px-2 justify-start text-none font-weight-regular"
                  :href="
                    route('api.files.content', {
                      id: invoice.id,
                      filename: invoice.originalName,
                    })
                  "
                  target="_blank"
                  left
                  plain
                  >{{ invoice.originalName }}</v-btn
                >
                <v-btn
                  @click="handleInvoiceRemoved(invoice.id)"
                  class="mr-1"
                  icon
                  plain
                  ><v-icon size="20" color="#686868">mdi-close</v-icon></v-btn
                >
              </div>
            </div>
            <!--<attribute-link-input
              :attribute="entryTextAttribute"
              :value="entryTextData"
              @input="updateData(entryTextAttribute.id, $event)"
              @string_changed="handleEntryTextChanged"
            />
            <attribute-link-input
              :attribute="entryNumberAttribute"
              :value="entryNumberData"
              @input="updateData(entryNumberAttribute.id, $event)"
              @decimal_changed="handleEntryNumberChanged"
            />-->
          </template>
          <template slot="right">
            <div class="" v-if="integrationId">
              <v-row dense class="mt-3">
                <v-col cols="5">
                  {{ $t("pages.assets.form.fixedLabels.accountNumber") }}:
                </v-col>
                <v-col cols="7">
                  {{ accountNumberData }}
                </v-col>
              </v-row>
              <v-row dense class="mt-3">
                <v-col cols="5">
                  {{ $t("pages.assets.form.fixedLabels.entryId") }}:
                </v-col>
                <v-col cols="7">
                  {{ entryNumberData }}
                </v-col>
              </v-row>
              <v-row dense class="mt-3">
                <v-col cols="5">
                  {{ $t("pages.assets.form.fixedLabels.entryText") }}:
                </v-col>
                <v-col cols="7">
                  {{ entryTextData }}
                </v-col>
              </v-row>
              <v-row dense class="mt-3">
                <v-col cols="5">
                  {{ $t("pages.assets.form.fixedLabels.entryAmount") }}:
                </v-col>
                <v-col cols="7">
                  {{ entryAmountData }}
                </v-col>
              </v-row>
              <v-row dense class="mt-4" v-if="voucherNumber">
                <v-col cols="5">
                  {{ $t("pages.assets.form.fixedLabels.voucherNumber") }}
                </v-col>
                <v-col cols="7">
                  {{ voucherNumber }}
                </v-col>
              </v-row>
            </div>
            <confirm-dialog
              v-model="showConfirmDialog"
              v-show="showConfirmDialog"
              :confirm-message="confirmMessage"
              @confirmed="handleConfirmation"
            ></confirm-dialog>
          </template>
        </dialog-form-section-two-cols>
      </v-card-text>
    </v-card>
  </v-tab-item>
</template>
<script>
import AttributeLinkInput from "../../../Components/Attribute/AttributeLinkInput.vue";
import DialogFormFileUploadInput from "../../../Components/Dialog/inputs/DialogFormFileUploadInput.vue";
import DialogFormInputWrapper from "../../../Components/Dialog/inputs/DialogFormInputWrapper.vue";
import { fixedAttributes } from "../../../util/fixedAssetData";
import PdfIcon from "../../../assets/svg/pdf.svg";
import DialogFormSectionTwoCols from "../../../Components/Dialog/DialogFormSectionTwoCols.vue";
import ConfirmDialog from "../../../Components/ConfirmDialog.vue";

export default {
  components: {
    AttributeLinkInput,
    DialogFormFileUploadInput,
    DialogFormInputWrapper,
    PdfIcon,
    DialogFormSectionTwoCols,
    ConfirmDialog,
  },
  props: {
    show: Boolean,
    attributeLinkData: Object,
    attributes: Array,
    tabValue: [String, Number],
    integrationId: String,
  },
  data() {
    return {
      fixedAttributes,
      showConfirmDialog: false,
      confirmMessage: this.$t("pages.assets.form.confirmDeleteFile"),
      invoiceRemovedValue: String,
    };
  },
  methods: {
    updateData(attributeId, value) {
      this.$emit("asset_attribute_data_changed", attributeId, value);
    },
    updateSecondValueData(attributeId, value) {
      this.$emit(
        "asset_attribute_data_second_value_changed",
        attributeId,
        value
      );
    },
    handleAccountNumberChanged(value) {
      this.$emit("decimal_changed", value, this.accountNumberAttribute);
    },
    handleEntryTextChanged(value) {
      this.$emit("string_changed", value, this.entryTextAttribute);
    },
    handleEntryNumberChanged(value) {
      this.$emit("decimal_changed", value, this.entryNumberAttribute);
    },
    handleSupplierChanged(value) {
      this.$emit("string_changed", value, this.supplierAttribute);
    },
    handleInvoiceChanged(value) {
      this.$emit("file_changed", value, this.invoiceAttribute);
    },
    handleInvoiceRemoved(value) {
      this.invoiceRemovedValue = value;
      this.showConfirmDialog = true;
    },
    handleConfirmation(status) {
      this.isConfirmed = status;
      if (status) {
        this.confirmInvoiceRemoved();
      }
    },
    confirmInvoiceRemoved() {
      this.$emit(
        "file_removed",
        this.invoiceRemovedValue,
        this.invoiceAttribute
      );
      this.invoiceRemovedValue = "";
    },
    findAttribute(attributeId) {
      return this.attributes.find((x) => x.id === attributeId);
    },
  },
  computed: {
    accountNumberData() {
      return this.attributeLinkData[fixedAttributes.infoAccountNumberId];
    },
    accountNumberAttribute() {
      return this.findAttribute(fixedAttributes.infoAccountNumberId);
    },
    supplierData() {
      return this.attributeLinkData[fixedAttributes.infoSupplierId];
    },
    supplierAttribute() {
      return this.findAttribute(fixedAttributes.infoSupplierId);
    },
    invoiceData() {
      return this.attributeLinkData[fixedAttributes.infoInvoiceId];
    },
    invoiceSecondValueData() {
      return this.attributeLinkData[
        fixedAttributes.infoInvoiceId + "-secondValue"
      ];
    },
    invoiceAttribute() {
      return this.findAttribute(fixedAttributes.infoInvoiceId);
    },
    entryTextData() {
      return this.attributeLinkData[fixedAttributes.infoEntryTextId];
    },
    entryTextAttribute() {
      return this.findAttribute(fixedAttributes.infoEntryTextId);
    },
    entryNumberData() {
      return this.attributeLinkData[fixedAttributes.infoEntryNumberId];
    },
    entryNumberAttribute() {
      return this.findAttribute(fixedAttributes.infoEntryNumberId);
    },
    entryAmountData() {
      return this.attributeLinkData[fixedAttributes.infoEntryAmountId];
    },
    voucherNumber() {
      return this.attributeLinkData[fixedAttributes.co2VoucherNumberId];
    },
  },
};
</script>
<style lang="scss">
.invoice-list-item {
  background: #fef2ef;
  border: 1px solid #fef2ef;
  .invoice-btn {
    color: #686868;
    flex: 1;
    overflow: hidden;
    letter-spacing: 0;
  }
  &:hover {
    border-color: #f25d3b;
    .invoice-btn * {
      color: #f25d3b;
    }
  }
}
</style>
