<template>
  <div class="flex-grow-1 d-flex justify-center align-center">
    <div class="d-flex justify-center align-center">
      <div class="px-0" fluid style="width: 1313px">
        <v-card
          light
          color="#fff"
          elevation="0"
          rounded="0"
          max-width="100%"
          class="flex-grow-1"
        >
          <div class="px-10 py-10">
            <p class="page-title">Worth knowing</p>
            <v-row>
              <v-col cols="4" >
                <div class="d-flex align-center flex-grow-1 mb-5">
                  <v-list-item-avatar
                    color="#FEEFEC"
                    width="57px"
                    height="57px"
                    class="d-flex align-center justify-center items-center"
                  >
                    <WorthKnowingIcon
                      style="width: 28px; height: 27px"
                    ></WorthKnowingIcon>
                  </v-list-item-avatar>
                  <div class="heading">Kg CO2e</div>
                </div>
                <div class="text-content">
                  CO2e is a unit of measurement that makes it easier to compare
                  the impact of different greenhouse gases. It indicates the
                  amount of CO2 that would have the same global warming effect
                  as the particular greenhouse gas in question.
                </div>
              </v-col>
              <v-col cols="4">
                <div class="d-flex align-center flex-grow-1 mb-5">
                  <v-list-item-avatar
                    color="#FEEFEC"
                    width="57px"
                    height="57px"
                    class="d-flex align-center justify-center items-center"
                  >
                    <WorthKnowingIcon
                      style="width: 28px; height: 27px"
                    ></WorthKnowingIcon>
                  </v-list-item-avatar>
                  <div class="heading">Total emissions</div>
                </div>
                <div class="text-content">
                  The total amount of greenhouse gas emissions, usually measured
                  in units such as tons of CO2e or kg CO2e, represents the
                  overall impact of climate change for an organization or
                  business.
                </div>
              </v-col>
              <v-col cols="4">
                <div class="d-flex align-center flex-grow-1 mb-5">
                  <v-list-item-avatar
                    color="#FEEFEC"
                    width="57px"
                    height="57px"
                    class="d-flex align-center justify-center items-center"
                  >
                    <WorthKnowingIcon
                      style="width: 28px; height: 27px"
                    ></WorthKnowingIcon>
                  </v-list-item-avatar>
                  <div class="heading">Calculation method</div>
                </div>
                <div class="text-content">
                  There are several methods for calculating CO2, including
                  Supplier-specific, Average-data, and Spend-based methods.
                  Verarca's system is designed to select the most accurate
                  method based on the available data provided by the company.
                </div>
              </v-col>
              <v-col cols="4">
                <div class="d-flex align-center flex-grow-1 mb-5">
                  <v-list-item-avatar
                    color="#FEEFEC"
                    width="57px"
                    height="57px"
                    class="d-flex align-center justify-center items-center"
                  >
                    <WorthKnowingIcon
                      style="width: 28px; height: 27px"
                    ></WorthKnowingIcon>
                  </v-list-item-avatar>
                  <div class="heading">Supplier-specific</div>
                </div>
                <div class="text-content">
                  This method is based on supplier-provided data, allowing for
                  specific CO2 footprint to be directly derived from the
                  supplier's invoice or other documentation. In other words, the
                  supplier has calculated the CO2 footprint for a specific
                  product, service, or offering.
                </div>
              </v-col>
              <v-col cols="4">
                <div class="d-flex align-center flex-grow-1 mb-5">
                  <v-list-item-avatar
                    color="#FEEFEC"
                    width="57px"
                    height="57px"
                    class="d-flex align-center justify-center items-center"
                  >
                    <WorthKnowingIcon
                      style="width: 28px; height: 27px"
                    ></WorthKnowingIcon>
                  </v-list-item-avatar>
                  <div class="heading">Average-data</div>
                </div>
                <div class="text-content">
                  Is a calculation method that estimates emissions for goods and
                  services by collecting data on quantity (e.g., kg, hours,
                  liters, or kWh) or other relevant units for purchased goods
                  and services and multiplying it by relevant secondary emission
                  factors. This method can be used when dealing with raw
                  materials such as gasoline or electricity.
                </div>
              </v-col>
              <v-col cols="4">
                <div class="d-flex align-center flex-grow-1 mb-5">
                  <v-list-item-avatar
                    color="#FEEFEC"
                    width="57px"
                    height="57px"
                    class="d-flex align-center justify-center items-center"
                  >
                    <WorthKnowingIcon
                      style="width: 28px; height: 27px"
                    ></WorthKnowingIcon>
                  </v-list-item-avatar>
                  <div class="heading">Spend-based</div>
                </div>
                <div class="text-content">
                  This method estimates emissions for goods and services by
                  collecting data on the purchase price of acquired goods and
                  services and multiplying it by relevant secondary (e.g.,
                  industry averages) emission factors. This calculation method
                  is applicable in cases where neither Supplier-specific nor
                  Average-data methods can be utilized.
                </div>
              </v-col>
            </v-row>
            <!--<svg
              width="1244"
              height="738"
              viewBox="0 0 1244 738"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <text
                fill="#333333"
                xml:space="preserve"
                style="white-space: pre"
                font-family="Lexend"
                font-size="35"
                letter-spacing="-0.015em"
                class="graphic"
              >
                <tspan x="0" y="35.125">Worth knowing</tspan>
              </text>
              <rect y="94" width="57" height="56" rx="28" fill="#FEEFEC" />
              <path
                d="M40.089 131.408L29.8103 109.89C29.6547 109.565 29.3271 109.358 28.9671 109.358C28.6071 109.358 28.2795 109.565 28.1239 109.89L17.8452 131.408C17.707 131.698 17.7266 132.039 17.8977 132.311C18.0688 132.583 18.3677 132.748 18.6884 132.748H28.0327V135.554C28.0327 136.071 28.4511 136.49 28.9671 136.49C29.4831 136.49 29.9015 136.071 29.9015 135.554V132.748H39.2458C39.5666 132.748 39.8654 132.583 40.0365 132.311C40.2076 132.039 40.2272 131.698 40.089 131.408Z"
                fill="#F25C3B"
              />
              <path
                d="M24.2353 113.693L23.2624 111.745C22.9457 111.111 21.9073 111.111 21.5906 111.745L15.0497 124.843C14.905 125.133 14.9205 125.478 15.0907 125.754C15.2609 126.029 15.5616 126.197 15.8855 126.197H18.2624L24.2353 113.693Z"
                fill="#F25C3B"
              />
              <path
                d="M42.8849 124.843L36.3439 111.745C36.0273 111.111 34.9888 111.111 34.6722 111.745L33.6992 113.693L39.6719 126.197H42.0491C42.373 126.197 42.6737 126.029 42.8439 125.754C43.014 125.478 43.0296 125.133 42.8849 124.843Z"
                fill="#F25C3B"
              />
              <text
                fill="black"
                fill-opacity="0.6"
                xml:space="preserve"
                style="white-space: pre"
                font-family="Lexend"
                font-size="17"
                letter-spacing="0em"
              >
                <tspan x="0" y="200.9">
                  CO2e is a unit of measurement that
                </tspan>
                <tspan x="0" y="221.9">
                  makes it easier to compare the impact of
                </tspan>
                <tspan x="0" y="242.9">
                  different greenhouse gases. It indicates
                </tspan>
                <tspan x="0" y="263.9">
                  the amount of CO2 that would have the
                </tspan>
                <tspan x="0" y="284.9">same global warming effect as the</tspan>
                <tspan x="0" y="305.9">
                  particular greenhouse gas in question.
                </tspan>
              </text>
              <text
                fill="#131313"
                xml:space="preserve"
                style="white-space: pre"
                font-family="Lexend"
                font-size="30"
                letter-spacing="-0.015em"
              >
                <tspan x="73" y="133">Kg CO2e</tspan>
              </text>
              <text
                fill="black"
                fill-opacity="0.6"
                xml:space="preserve"
                style="white-space: pre"
                font-family="Lexend"
                font-size="17"
                letter-spacing="0em"
              >
                <tspan x="419" y="523.9">
                  Is a calculation method that estimates
                </tspan>
                <tspan x="419" y="544.9">
                  emissions for goods and services by
                </tspan>
                <tspan x="419" y="565.9">
                  collecting data on quantity (e.g., kg,
                </tspan>
                <tspan x="419" y="586.9">
                  hours, liters, or kWh) or other relevant
                </tspan>
                <tspan x="419" y="607.9">
                  units for purchased goods and services
                </tspan>
                <tspan x="419" y="628.9">
                  and multiplying it by relevant secondary
                </tspan>
                <tspan x="419" y="649.9">emission factors.</tspan>
                <tspan x="419" y="670.9"></tspan>
                <tspan x="419" y="691.9">
                  This method can be used when dealing
                </tspan>
                <tspan x="419" y="712.9">
                  with raw materials such as gasoline or
                </tspan>
                <tspan x="419" y="733.9">electricity.</tspan>
              </text>
              <text
                fill="#131313"
                xml:space="preserve"
                style="white-space: pre"
                font-family="Lexend"
                font-size="30"
                letter-spacing="-0.015em"
              >
                <tspan x="479.682" y="457">Average-data</tspan>
              </text>
              <rect
                x="419"
                y="418"
                width="44.9205"
                height="56"
                rx="22.4602"
                fill="#FEEFEC"
              />
              <path
                d="M450.594 455.409L442.494 433.891C442.371 433.566 442.113 433.359 441.829 433.359C441.545 433.359 441.287 433.566 441.165 433.891L433.064 455.409C432.955 455.699 432.971 456.04 433.106 456.312C433.24 456.584 433.476 456.749 433.729 456.749H441.093V459.555C441.093 460.072 441.422 460.491 441.829 460.491C442.236 460.491 442.565 460.072 442.565 459.555V456.749H449.929C450.182 456.749 450.418 456.584 450.553 456.312C450.687 456.04 450.703 455.699 450.594 455.409Z"
                fill="#F25C3B"
              />
              <path
                d="M438.1 437.694L437.333 435.746C437.084 435.112 436.265 435.112 436.016 435.746L430.861 448.844C430.747 449.134 430.759 449.479 430.893 449.755C431.027 450.03 431.264 450.198 431.52 450.198H433.393L438.1 437.694Z"
                fill="#F25C3B"
              />
              <path
                d="M452.798 448.844L447.643 435.746C447.393 435.112 446.575 435.112 446.325 435.746L445.559 437.694L450.266 450.198H452.139C452.394 450.198 452.631 450.03 452.765 449.755C452.899 449.479 452.912 449.134 452.798 448.844Z"
                fill="#F25C3B"
              />
              <text
                fill="black"
                fill-opacity="0.6"
                xml:space="preserve"
                style="white-space: pre"
                font-family="Lexend"
                font-size="17"
                letter-spacing="0em"
              >
                <tspan x="869" y="525.9">
                  This method estimates emissions for goods
                </tspan>
                <tspan x="869" y="546.9">
                  and services by collecting data on the
                </tspan>
                <tspan x="869" y="567.9">
                  purchase price of acquired goods and
                </tspan>
                <tspan x="869" y="588.9">
                  services and multiplying it by relevant
                </tspan>
                <tspan x="869" y="609.9">
                  secondary (e.g., industry averages) emission
                </tspan>
                <tspan x="869" y="630.9">factors.</tspan>
                <tspan x="869" y="651.9"></tspan>
                <tspan x="869" y="672.9">
                  This calculation method is applicable in
                </tspan>
                <tspan x="869" y="693.9">
                  cases where neither Supplier-specific nor
                </tspan>
                <tspan x="869" y="714.9">
                  Average-data methods can be utilized.
                </tspan>
              </text>
              <text
                fill="#131313"
                xml:space="preserve"
                style="white-space: pre"
                font-family="Lexend"
                font-size="30"
                letter-spacing="-0.015em"
              >
                <tspan x="928.715" y="459">Spend-based</tspan>
              </text>
              <rect
                x="869"
                y="420"
                width="44.2036"
                height="56"
                rx="22.1018"
                fill="#FEEFEC"
              />
              <path
                d="M900.088 457.409L892.117 435.891C891.997 435.566 891.743 435.359 891.463 435.359C891.184 435.359 890.93 435.566 890.81 435.891L882.838 457.409C882.731 457.699 882.746 458.04 882.879 458.312C883.012 458.584 883.244 458.749 883.492 458.749H890.739V461.555C890.739 462.072 891.063 462.491 891.463 462.491C891.864 462.491 892.188 462.072 892.188 461.555V458.749H899.435C899.683 458.749 899.915 458.584 900.048 458.312C900.18 458.04 900.196 457.699 900.088 457.409Z"
                fill="#F25C3B"
              />
              <path
                d="M887.794 439.694L887.039 437.746C886.794 437.112 885.988 437.112 885.743 437.746L880.67 450.844C880.558 451.134 880.57 451.479 880.702 451.755C880.834 452.03 881.067 452.198 881.318 452.198H883.162L887.794 439.694Z"
                fill="#F25C3B"
              />
              <path
                d="M902.256 450.844L897.184 437.746C896.938 437.112 896.133 437.112 895.887 437.746L895.133 439.694L899.765 452.198H901.608C901.859 452.198 902.093 452.03 902.225 451.755C902.356 451.479 902.369 451.134 902.256 450.844Z"
                fill="#F25C3B"
              />
              <text
                fill="#131313"
                xml:space="preserve"
                style="white-space: pre"
                font-family="Lexend"
                font-size="30"
                letter-spacing="-0.015em"
              >
                <tspan x="946" y="141">Calculation method</tspan>
              </text>
              <rect
                x="869"
                y="102"
                width="57"
                height="56"
                rx="28"
                fill="#FEEFEC"
              />
              <path
                d="M909.089 139.409L898.81 117.891C898.655 117.566 898.327 117.359 897.967 117.359C897.607 117.359 897.28 117.566 897.124 117.891L886.845 139.409C886.707 139.699 886.727 140.04 886.898 140.312C887.069 140.584 887.368 140.749 887.688 140.749H897.033V143.555C897.033 144.072 897.451 144.491 897.967 144.491C898.483 144.491 898.902 144.072 898.902 143.555V140.749H908.246C908.567 140.749 908.865 140.584 909.037 140.312C909.208 140.04 909.227 139.699 909.089 139.409Z"
                fill="#F25C3B"
              />
              <path
                d="M893.235 121.694L892.262 119.746C891.946 119.112 890.907 119.112 890.591 119.746L884.05 132.844C883.905 133.134 883.921 133.479 884.091 133.755C884.261 134.03 884.562 134.198 884.886 134.198H887.262L893.235 121.694Z"
                fill="#F25C3B"
              />
              <path
                d="M911.885 132.844L905.344 119.746C905.027 119.112 903.989 119.112 903.672 119.746L902.699 121.694L908.672 134.198H911.049C911.373 134.198 911.674 134.03 911.844 133.755C912.014 133.479 912.03 133.134 911.885 132.844Z"
                fill="#F25C3B"
              />
              <text
                fill="black"
                fill-opacity="0.6"
                xml:space="preserve"
                style="white-space: pre"
                font-family="Lexend"
                font-size="17"
                letter-spacing="0em"
              >
                <tspan x="869" y="200.9">
                  There are several methods for calculating
                </tspan>
                <tspan x="869" y="221.9">
                  CO2, including Supplier-specific, Average-
                </tspan>
                <tspan x="869" y="242.9">
                  data, and Spend-based methods. Verarca&#39;s
                </tspan>
                <tspan x="869" y="263.9">
                  system is designed to select the most
                </tspan>
                <tspan x="869" y="284.9">
                  accurate method based on the available
                </tspan>
                <tspan x="869" y="305.9">data provided by the company.</tspan>
              </text>
              <text
                fill="black"
                fill-opacity="0.6"
                xml:space="preserve"
                style="white-space: pre"
                font-family="Lexend"
                font-size="17"
                letter-spacing="0em"
              >
                <tspan x="0" y="523.9">This method is based on supplier-</tspan>
                <tspan x="0" y="544.9">
                  provided data, allowing for specific
                </tspan>
                <tspan x="0" y="565.9">
                  CO2 footprint to be directly derived
                </tspan>
                <tspan x="0" y="586.9">
                  from the supplier&#39;s invoice or other
                </tspan>
                <tspan x="0" y="607.9">documentation.</tspan>
                <tspan x="0" y="628.9"></tspan>
                <tspan x="0" y="649.9">In other words, the supplier has</tspan>
                <tspan x="0" y="670.9">
                  calculated the CO2 footprint for a
                </tspan>
                <tspan x="0" y="691.9">
                  specific product, service, or offering.
                </tspan>
              </text>
              <text
                fill="#131313"
                xml:space="preserve"
                style="white-space: pre"
                font-family="Lexend"
                font-size="30"
                letter-spacing="-0.015em"
              >
                <tspan x="69.2363" y="459">Supplier-specific</tspan>
              </text>
              <rect
                y="420"
                width="51.2523"
                height="56"
                rx="25.6262"
                fill="#FEEFEC"
              />
              <path
                d="M36.0458 457.409L26.8035 435.891C26.6637 435.566 26.3691 435.359 26.0454 435.359C25.7217 435.359 25.4271 435.566 25.2873 435.891L16.045 457.409C15.9207 457.699 15.9384 458.04 16.0922 458.312C16.246 458.584 16.5148 458.749 16.8032 458.749H25.2052V461.555C25.2052 462.072 25.5814 462.491 26.0454 462.491C26.5094 462.491 26.8856 462.072 26.8856 461.555V458.749H35.2876C35.576 458.749 35.8448 458.584 35.9986 458.312C36.1524 458.04 36.1701 457.699 36.0458 457.409Z"
                fill="#F25C3B"
              />
              <path
                d="M21.7913 439.694L20.9165 437.746C20.6318 437.112 19.698 437.112 19.4133 437.746L13.5319 450.844C13.4019 451.134 13.4158 451.479 13.5688 451.755C13.7219 452.03 13.9922 452.198 14.2835 452.198H16.4207L21.7913 439.694Z"
                fill="#F25C3B"
              />
              <path
                d="M38.5602 450.844L32.6788 437.746C32.3941 437.112 31.4604 437.112 31.1757 437.746L30.3008 439.694L35.6712 452.198H37.8087C38.0999 452.198 38.3703 452.03 38.5233 451.755C38.6763 451.479 38.6903 451.134 38.5602 450.844Z"
                fill="#F25C3B"
              />
              <text
                fill="black"
                fill-opacity="0.6"
                xml:space="preserve"
                style="white-space: pre"
                font-family="Lexend"
                font-size="17"
                letter-spacing="0em"
              >
                <tspan x="418" y="200.472">
                  The total amount of greenhouse gas
                </tspan>
                <tspan x="418" y="221.472">
                  emissions, usually measured in units
                </tspan>
                <tspan x="418" y="242.472">
                  such as tons of CO2e or kg CO2e,
                </tspan>
                <tspan x="418" y="263.472">
                  represents the overall impact of climate
                </tspan>
                <tspan x="418" y="284.472">
                  change for an organization or business.
                </tspan>
              </text>
              <text
                fill="#131313"
                xml:space="preserve"
                style="white-space: pre"
                font-family="Lexend"
                font-size="30"
                letter-spacing="-0.015em"
              >
                <tspan x="495" y="136">Total emissions</tspan>
              </text>
              <rect
                x="418"
                y="97.3809"
                width="57"
                height="56"
                rx="28"
                fill="#FEEFEC"
              />
              <path
                d="M458.089 134.79L447.81 113.272C447.655 112.947 447.327 112.74 446.967 112.74C446.607 112.74 446.28 112.947 446.124 113.272L435.845 134.79C435.707 135.08 435.727 135.421 435.898 135.693C436.069 135.965 436.368 136.13 436.688 136.13H446.033V138.936C446.033 139.453 446.451 139.872 446.967 139.872C447.483 139.872 447.902 139.453 447.902 138.936V136.13H457.246C457.567 136.13 457.865 135.965 458.037 135.693C458.208 135.421 458.227 135.08 458.089 134.79Z"
                fill="#F25C3B"
              />
              <path
                d="M442.235 117.075L441.262 115.127C440.946 114.493 439.907 114.493 439.591 115.127L433.05 128.225C432.905 128.515 432.921 128.859 433.091 129.135C433.261 129.411 433.562 129.579 433.886 129.579H436.262L442.235 117.075Z"
                fill="#F25C3B"
              />
              <path
                d="M460.885 128.225L454.344 115.127C454.027 114.493 452.989 114.493 452.672 115.127L451.699 117.075L457.672 129.579H460.049C460.373 129.579 460.674 129.411 460.844 129.135C461.014 128.859 461.03 128.515 460.885 128.225Z"
                fill="#F25C3B"
              />
            </svg>-->
          </div>
        </v-card>
      </div>
    </div>
  </div>
</template>

<script>
import WorthKnowingIcon from "../../assets/svg/worth-knowing.svg";
export default {
  components: { WorthKnowingIcon },
};
</script>

<style lang="scss" scoped>
.page-title {
  font-size: 35px;
}

.heading {
  font-size: 30px;
}

.text-content {
  font-size: 17px;
  color: #666666;
  margin-bottom: 70px;
}
</style>
