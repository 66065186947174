<template>
  <dialog-form :show-dialog="show" @showStateChanged="(val) => (show = val)">
    <dialog-form-header
      :title="
        attribute != null && attribute.id != null
          ? '#' + attribute.referenceKey + ' ' + attribute.name
          : $t('pages.attributes.form.title')
      "
    >
    </dialog-form-header>
    <dialog-form-section-two-cols no-bottom-border>
      <template slot="left">
        <dialog-form-text-input
          :value="form.name"
          name="name"
          @input="(val) => (form.name = val)"
          :title="$t('pages.attributes.form.fields.attributeName')"
          :errorMessages="[form.errors.name]"
        ></dialog-form-text-input>
        <dialog-form-input-wrapper
          :title="$t('pages.attributes.form.fields.attributeClass')"
        >
          <v-autocomplete
            class="rounded-0"
            v-model="form.attributeClassId"
            :items="attributeClasses"
            :search-input.sync="attributeClassSearch"
            clearable
            hide-selected
            item-text="name"
            item-value="id"
            solo
            flat
            single-line
            filled
            background-color="#F7F7F7"
          >
          </v-autocomplete>
        </dialog-form-input-wrapper>
        <dialog-form-select-input
          :title="$t('pages.attributes.form.fields.attributeFormat')"
          :items="attributeFormats"
          @input="(selectedFormat) => changeFormat(selectedFormat)"
        >
        </dialog-form-select-input>
      </template>
      <template slot="right">
        <!-- FILE -->
        <v-col v-if="form.format == 'File'" cols="12" sm="12">
          <v-row dense>
            <v-col>
              <span>{{
                $t("pages.attributes.form.fields.inputRestrictions")
              }}</span>
            </v-col>
          </v-row>
          <v-row dense>
            <v-col sm="6">
              <v-select
                v-model="form.multipleFiles"
                :items="fileRestrictionOptions"
                name="multipleFiles"
                item-text="text"
                item-value="value"
                dense
                solo
              ></v-select>
            </v-col>
          </v-row>
        </v-col>
        <!-- /FILE -->

        <!-- BOOLEAN -->
        <v-col v-if="form.format == 'Boolean'" cols="12" sm="12">
          <v-row dense>
            <v-col>
              <span>{{ $t("pages.attributes.form.fields.defaultValue") }}</span>
            </v-col>
          </v-row>
          <v-row dense>
            <v-col sm="6">
              <v-select
                v-model="form.boolValue"
                :items="boolValueOptions"
                name="boolValue"
                clearable
                item-text="text"
                item-value="value"
                dense
                solo
                :error-messages="form.errors.boolValue"
              ></v-select>
            </v-col>
          </v-row>
        </v-col>
        <!-- /BOOLEAN -->

        <!-- CURRENCY -->
        <v-col v-if="form.format == 'Currency'" cols="12" sm="12">
          <v-row dense align="center">
            <v-col cols="6">
              <span class="mb-1">{{
                $t("pages.attributes.form.fields.currencyCode")
              }}</span>
              <v-autocomplete
                dense
                v-model="currencyCode"
                :items="currencies"
                :search-input.sync="currencySearch"
                clearable
                hide-selected
                item-text="currencyCode"
                item-value="currencyCode"
                solo
              >
              </v-autocomplete>
            </v-col>
            <v-col cols="6">
              <span class="mb-1">{{
                $t("pages.attributes.form.fields.defaultValue")
              }}</span>
              <v-text-field
                type="number"
                :value="form.decimalValue"
                @input="
                  ($value) =>
                    (form.decimalValue = isNaN($value)
                      ? null
                      : parseFloat($value))
                "
                name="decimalValue"
                solo
                dense
                :error-messages="form.errors.decimalValue"
              />
            </v-col>
            <v-col cols="6">
              <span class="mb-1">{{
                $t("pages.attributes.form.fields.currencyDecimals")
              }}</span>
              <v-autocomplete
                dense
                v-model="form.visibleDecimals"
                :items="visibleDecimalOptions"
                solo
              ></v-autocomplete>
            </v-col>
          </v-row>
        </v-col>
        <!-- /CURRENCY -->

        <!-- DATE -->
        <v-col v-if="form.format == 'Date'" cols="12" sm="12">
          <v-row dense align="center">
            <v-col cols="6">
              <v-checkbox
                v-model="form.dateTimeValueCreated"
                @click="form.dateTimeValueUpdated = false"
                :label="$t('pages.attributes.form.fields.setCreationDate')"
              ></v-checkbox>
            </v-col>
            <v-col cols="6">
              <v-checkbox
                v-model="form.dateTimeValueUpdated"
                @click="form.dateTimeValueCreated = false"
                :label="$t('pages.attributes.form.fields.setUpdateDate')"
              ></v-checkbox>
            </v-col>
          </v-row>
          <v-row dense align="center">
            <v-col cols="12">
              <span>{{ $t("pages.attributes.form.fields.defaultValue") }}</span>
            </v-col>
            <v-col>
              <date-picker
                :lang="lang"
                first-day-of-week="1"
                :appendToBody="false"
                v-model="form.dateTimeValue"
                type="date"
                format="DD-MM-YYYY"
              ></date-picker>
            </v-col>
            <v-col>
              <span>{{ $t("pages.attributes.form.fields.dateFormat") }}</span>
            </v-col>
          </v-row>
        </v-col>
        <!-- /DATE -->

        <!-- DATETIME -->
        <v-col v-if="form.format == 'DateTime'" cols="12" sm="12">
          <v-row dense align="center">
            <v-col cols="6">
              <v-checkbox
                v-model="form.dateTimeValueCreated"
                @click="form.dateTimeValueUpdated = false"
                :label="$t('pages.attributes.form.fields.setCreationDate')"
              ></v-checkbox>
            </v-col>
            <v-col cols="6">
              <v-checkbox
                v-model="form.dateTimeValueUpdated"
                @click="form.dateTimeValueCreated = false"
                :label="$t('pages.attributes.form.fields.setUpdateDate')"
              ></v-checkbox>
            </v-col>
          </v-row>
          <v-row dense align="center">
            <v-col cols="12">
              <span>{{ $t("pages.attributes.form.fields.defaultValue") }}</span>
            </v-col>
            <v-col>
              <date-picker
                :lang="lang"
                first-day-of-week="1"
                style="width: full"
                :appendToBody="false"
                v-model="form.dateTimeValue"
                type="datetime"
                format="DD-MM-YYYY HH:mm:ss"
              ></date-picker>
            </v-col>
            <v-col>
              <span>{{
                $t("pages.attributes.form.fields.dateTimeFormat")
              }}</span>
            </v-col>
          </v-row>
        </v-col>
        <!-- /DATETIME -->

        <!-- NUMBER -->
        <v-col v-if="form.format == 'Number'" cols="12" sm="12">
          <v-row dense align="center">
            <v-col cols="12">
              <span>{{
                $t("pages.attributes.form.fields.defaultNumber")
              }}</span>
            </v-col>
            <v-col cols="12" sm="6">
              <v-text-field
                type="number"
                :value="form.decimalValue"
                @input="
                  ($value) =>
                    (form.decimalValue = isNaN($value)
                      ? null
                      : parseFloat($value))
                "
                name="decimalValue"
                solo
                dense
                :error-messages="form.errors.decimalValue"
              />
            </v-col>
          </v-row>
        </v-col>
        <!-- /NUMBER -->

        <!-- PERCENTAGE -->
        <v-col v-if="form.format == 'Percentage'" cols="12" sm="12">
          <v-row dense align="center">
            <v-col cols="12">
              <span>{{
                $t("pages.attributes.form.fields.defaultPercentage")
              }}</span>
            </v-col>
            <v-col cols="12" sm="6">
              <v-text-field
                type="number"
                :value="form.decimalValue"
                @input="
                  ($value) =>
                    (form.decimalValue = isNaN($value)
                      ? null
                      : parseFloat($value))
                "
                name="decimalValue"
                solo
                dense
                :error-messages="form.errors.decimalValue"
                append-icon="mdi-percent-outline"
              />
            </v-col>
          </v-row>
        </v-col>
        <!-- /PERCENTAGE -->

        <!-- SELECT -->
        <v-col v-if="form.format == 'Select'" cols="12" sm="12">
          <v-row dense align="center">
            <v-col cols="8">
              <span>{{
                $t("pages.attributes.form.fields.selectOptions")
              }}</span>
            </v-col>
            <v-col cols="3" class="d-flex justify-center">
              <span>{{ $t("pages.attributes.form.fields.preselected") }}</span>
            </v-col>
          </v-row>
          <v-radio-group
            class="ma-0 pa-0"
            v-model="form.attributeSelectOptionValue"
            column
          >
            <v-row
              v-for="(selectOption, index) in form.attributeSelectOptions"
              :key="index"
              dense
              justify="space-between"
            >
              <v-col cols="8" align-self="center">
                <v-text-field
                  v-model="selectOption.value"
                  name="name"
                  solo
                  dense
                  type="text"
                  hide-details
                />
              </v-col>
              <v-col cols="3" align-self="center" class="d-flex justify-center">
                <v-radio
                  class="mx-4 ma-0 pa-0"
                  hide-details
                  color="teal darken-4"
                  :value="selectOption.value"
                ></v-radio>
              </v-col>
              <v-col cols="1" align-self="center">
                <v-icon
                  @click="removeAttributeSelectOption(selectOption)"
                  class="black--text"
                  >mdi-trash-can-outline
                </v-icon>
              </v-col>
            </v-row>
          </v-radio-group>
          <v-col cols="9">
            <v-row dense justify="center" align="center">
              <v-btn
                color="blue darken-1"
                text
                @click="addAttributeSelectOption()"
              >
                <p class="teal--text text--darken-4 mb-0">
                  {{ $t("pages.attributes.form.fields.addOption") }}
                </p>
                <v-icon class="black--text">mdi-plus</v-icon>
              </v-btn>
            </v-row>
          </v-col>
        </v-col>
        <!-- /SELECT -->

        <!-- TEXT -->
        <v-col v-if="form.format == 'Text'">
          <v-row dense>
            <v-col>
              <span>{{ $t("pages.attributes.form.fields.defaultText") }}</span>
            </v-col>
          </v-row>
          <v-row dense>
            <v-col>
              <v-textarea
                solo
                name="input-7-4"
                v-model="form.stringValue"
              ></v-textarea>
            </v-col>
          </v-row>
        </v-col>
        <!-- /TEXT -->
      </template>
    </dialog-form-section-two-cols>
    <v-card-actions class="py-6 px-6 d-flex flex-column-reverse flex-md-row">
      <v-alert dense v-if="form.errors.alert" type="error" class="mb-0">
        {{ form.errors.alert }}
      </v-alert>
      <v-btn
        class="text-none px-4 mb-4 mb-md-0 align-self-stretch"
        color="#686868"
        large
        elevation="0"
        text
        tile
        x-large
        :loading="loading"
        @click="show = false"
        ><v-icon class="mr-3" dark>mdi-close</v-icon
        >{{ $t("common.actions.close") }}
      </v-btn>

      <v-spacer></v-spacer>

      <v-btn
        color="#266663"
        @click="saveAttribute()"
        :disabled="loading"
        class="text-none ml-0 mb-4 mb-md-0 ml-md-4 px-6 align-self-stretch"
        large
        dark
        elevation="0"
        tile
        :loading="loading"
      >
        <v-icon class="white--text">mdi-content-save-outline</v-icon>
        {{ $t("common.actions.save") }}
      </v-btn>
    </v-card-actions>
  </dialog-form>
</template>

<script>
import DatePicker from "vue2-datepicker";
import "vue2-datepicker/index.css";
import { removeTimezoneOffset, addTimezoneOffset } from "@/util/dateTime";
import DialogFormHeader from "../../Components/Dialog/DialogFormHeader.vue";
import DialogForm from "../../Components/Dialog/DialogForm.vue";
import DialogFormSectionTwoCols from "../../Components/Dialog/DialogFormSectionTwoCols.vue";
import DialogFormTextInput from "../../Components/Dialog/inputs/DialogFormTextInput.vue";
import DialogFormInputWrapper from "../../Components/Dialog/inputs/DialogFormInputWrapper.vue";
import DialogFormSelectInput from "../../Components/Dialog/inputs/DialogFormSelectInput.vue";

export default {
  components: {
    DatePicker,
    DialogFormHeader,
    DialogForm,
    DialogFormSectionTwoCols,
    DialogFormTextInput,
    DialogFormInputWrapper,
    DialogFormSelectInput,
  },
  props: {
    value: Boolean,
    attribute: Object,
    dialog: Boolean,
    attributeFormats: Array,
    attributeClasses: Array,
    currencies: Array,
    attributeRoute: String,
  },
  computed: {
    show: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit("input", value);
      },
    },
    defaultCurrencyId() {
      return this.$inertia.page.props.auth.settings.defaultCurrencyId;
    },
    defaultCurrencyCode() {
      const currency = this.currencies.find(
        (x) => x.id === this.defaultCurrencyId
      );

      return currency?.currencyCode;
    },
    currencyCode: {
      get() {
        if (
          this.defaultCurrencyCode &&
          (!this.form.stringValue || this.form.stringValue.trim() === "")
        )
          return this.defaultCurrencyCode;

        return this.form.stringValue;
      },
      set(value) {
        if (value === this.defaultCurrencyCode) {
          this.form.stringValue = null;
          return;
        }

        this.form.stringValue = value;
      },
    },
    boolValueOptions() {
      return [
        { value: true, text: this.$t("common.boolean.true") },
        { value: false, text: this.$t("common.boolean.false") },
      ];
    },
    fileRestrictionOptions() {
      return [
        {
          value: true,
          text: this.$t("pages.attributes.form.fileOptions.multiple"),
        },
        {
          value: false,
          text: this.$t("pages.attributes.form.fileOptions.single"),
        },
      ];
    },
  },
  data() {
    return {
      lang: {
        formatLocale: {
          firstDayOfWeek: 1,
        },
        monthBeforeYear: false,
      },
      loading: false,
      form: this.$inertia.form({
        id: "",
        name: "",
        format: "",
        stringValue: "",
        dateTimeValue: null,
        dateTimeValueCreated: false,
        dateTimeValueUpdated: false,
        decimalValue: null,
        boolValue: null,
        attributeSelectOptionValue: "",
        attributeSelectOptions: [],
        attributeClassId: null,
        multipleFiles: true,
        visibleDecimals: 2,
      }),
      visibleDecimalOptions: [0, 1, 2, 3, 4, 5, 6],
      currencySearch: null,
      attributeClassSearch: null,
    };
  },
  watch: {
    attribute: function (attribute) {
      this.setForm(attribute);
    },
  },
  methods: {
    setForm(attribute) {
      if (attribute != null) {
        this.form.id = attribute.id;
        this.form.name = attribute.name;
        this.form.format = attribute.format;
        this.form.stringValue = attribute.stringValue;
        this.form.dateTimeValue =
          attribute.dateTimeValue != null
            ? addTimezoneOffset(new Date(attribute.dateTimeValue + "Z"))
            : null;
        this.form.dateTimeValueCreated = attribute.dateTimeValueCreated;
        this.form.dateTimeValueUpdated = attribute.dateTimeValueUpdated;
        this.form.decimalValue = attribute.decimalValue;
        this.form.boolValue = attribute.boolValue;
        this.form.attributeSelectOptionValue =
          attribute.attributeSelectOption != null
            ? attribute.attributeSelectOption.value
            : null;
        this.form.attributeSelectOptions =
          attribute.attributeSelectOptions ?? [];
        this.form.attributeClassId = attribute.attributeClassId;
        this.form.multipleFiles = attribute.multipleFiles;
        this.form.visibleDecimals = attribute.visibleDecimals ?? 2;
      } else {
        this.form.id = "";
        this.form.name = "";
        this.form.format = "";
        this.form.stringValue = "";
        this.form.dateTimeValue = null;
        this.form.dateTimeValueCreated = false;
        this.form.dateTimeValueUpdated = false;
        this.form.decimalValue = null;
        this.form.boolValue = null;
        this.form.attributeSelectOptionValue = "";
        this.form.attributeSelectOptions = [];
        this.form.attributeClassId = null;
        this.form.multipleFiles = true;
        this.form.visibleDecimals = 2;
      }
    },
    changeFormat(attributeFormat) {
      console.log("format", attributeFormat);
      this.form.format = attributeFormat;
      this.form.stringValue = "";
      this.form.dateTimeValue = null;
      this.form.dateTimeValueCreated = false;
      this.form.dateTimeValueUpdated = false;
      this.form.decimalValue = null;
      this.form.boolValue = null;
      this.form.attributeSelectOptionValue = "";
      this.form.attributeSelectOptions = [];
      this.form.multipleFiles = true;
      this.form.visibleDecimals = 2;
    },
    removeAttributeSelectOption(option) {
      this.form.attributeSelectOptions =
        this.form.attributeSelectOptions.filter((x) => x !== option);
    },
    addAttributeSelectOption() {
      this.form.attributeSelectOptions.push({
        value: "option " + (this.form.attributeSelectOptions.length + 1),
      });
    },
    saveAttribute() {
      if (this.loading) return;
      this.loading = true;

      const preparedForm = this.form.transform((data) => {
        const transformedData = {
          ...data,
          dateTimeValue:
            this.form.dateTimeValue != null
              ? removeTimezoneOffset(this.form.dateTimeValue)
              : null,
          visibleDecimals:
            this.form.format === "Currency" ? this.form.visibleDecimals : null,
        };

        return transformedData;
      });
      if (this.form.id) {
        preparedForm.put(
          this.route(`${this.attributeRoute}.update`, this.attribute.id),
          {
            preserveScroll: true,
            onSuccess: () => {
              this.show = false;
              this.$emit("clear");
            },
            onFinish: () => {
              this.loading = false;
            },
          }
        );
      } else {
        preparedForm.post(this.route(`${this.attributeRoute}.store`), {
          preserveScroll: true,
          onSuccess: () => {
            this.show = false;
            this.setForm(null);
            this.$emit("clear");
          },
          onFinish: () => {
            this.loading = false;
          },
        });
      }
    },
  },
};
</script>
<style scoped>
.radio-group-full-width >>> .v-input__control {
  width: 100% !important;
}
.solo-item-list-area {
  border-radius: 4px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  color: rgba(0, 0, 0, 0.87);
  padding: 0 12px;
  background: #ffffff;
  background-origin: padding-box;
  background-clip: border-box;
  background-position-x: 0%;
  background-position-y: 0%;
  background-repeat: repeat;
  background-attachment: scroll;
  background-image: none;
  background-size: auto;
  box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2),
    0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
  transition: background 0.3s cubic-bezier(0.25, 0.8, 0.5, 1);
}
.selected {
  background: #004d40;
  color: white;
}
</style>
