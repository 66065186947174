<template>
  <div
    class="head-section pt-4 px-md-6"
    :class="tabs?.length > 0 ? null : 'pb-md-3'"
  >
    <v-btn
      v-if="onDismiss"
      @click="onDismiss()"
      color="#68686"
      icon
      absolute
      right
      top
    >
      <v-icon>mdi-close</v-icon>
    </v-btn>
    <v-card-title class="px-6 px-md-0 text--black">
      <span class="text-h5">{{ title }}</span>
    </v-card-title>
    <v-tabs
      v-if="tabs?.length > 0"
      v-model="tab"
      show-arrows=""
      color="#F25D3B"
    >
      <v-tab
        v-for="(tab, index) in tabs"
        :key="index"
        class="body-2 text-none"
        >{{ tab }}</v-tab
      >
    </v-tabs>
  </div>
</template>

<script>
export default {
  components: {},
  props: {
    title: String,
    onDismiss: Function,
    tabs: Array,
    selectedTab: [String, Number],
  },
  data() {
    return {
      tab: this.selectedTab,
    };
  },
  watch: {
    tab(newTab) {
      this.$emit("onChangedTab", newTab);
    },
    selectedTab(newValue) {
      this.tab = newValue;
    },
  },
  methods: {},
};
</script>

<style lang="scss" scoped>
.head-section {
  border-bottom: 1px solid #e8e8e8;
  position: sticky;
  top: 0;
  background: white;
  z-index: 1;
}
</style>
