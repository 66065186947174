<template>
  <div class="card">
    <h3>{{ $t("components.cards.upgradeToPro.title") }}</h3>
    <p>{{ $t("components.cards.upgradeToPro.description") }}</p>
    <v-btn
      @click="redirectToBilling()"
      elevation="0"
      block
      tile
      large
      x-large
      style="text-transform: none; letter-spacing: normal"
      >{{ $t("components.cards.upgradeToPro.upgrade") }}</v-btn
    >
  </div>
</template>

<script>
export default {
  methods: {
    redirectToBilling() {
      const url = this.route("settings.index") + "#billing";
      this.$inertia.get(url, { openPayment: true });
    },
  },
};
</script>

<style lang="scss" scoped>
.card {
  background-color: #1e1e1e;
  margin: 16px;
  padding: 16px;
  padding-top: 30px;
  width: 208px;

  h3 {
    color: #fff;
    margin-bottom: 13px;
    font-size: 18px;
    font-weight: normal;
    white-space: nowrap;
  }
  p {
    color: #686868;
    margin-bottom: 24px;
    font-size: 14px;
    font-weight: normal;
  }
  a {
    background-color: rgba(104, 104, 104, 0.16);
    color: #fff;
    padding: 18px 51px;
    display: block;
    white-space: nowrap;
  }
}
</style>
