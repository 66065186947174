<template>
  <div class="flex-grow-1 d-flex justify-center align-center">
    <div class="d-flex justify-center align-center">
      <div class="px-0" fluid style="width: 1313px">
        <v-card
          light
          color="#fff"
          elevation="0"
          rounded="0"
          max-width="100%"
          class="flex-grow-1"
        >
          <div class="px-10 py-10">
            <p class="page-title">Total GHG & Scope 1, 2, 3 emissions</p>
            <v-simple-table>
              <template v-slot:default>
                <thead>
                  <tr>
                    <th width="100%" class="text-left">GHG emissions</th>
                    <th class="text-right">Unit</th>
                    <th class="text-right">Previous period</th>
                    <th class="text-right">Current period</th>
                  </tr>
                </thead>
                <tbody>
                  <tr class="table-section-head">
                    <td>
                      <p>Scope 1 GHG emissions</p>
                    </td>
                    <td class="text-right">ton CO2e</td>
                    <td class="text-right">
                      {{
                        formatEmissions(
                          sumEmissionsForScope(
                            "cb483bd8-d20e-42a1-a252-c44ee12230ef"
                          )["prev"]
                        )
                      }}
                    </td>
                    <td class="text-right">
                      {{
                        formatEmissions(
                          sumEmissionsForScope(
                            "cb483bd8-d20e-42a1-a252-c44ee12230ef"
                          )["current"]
                        )
                      }}
                    </td>
                  </tr>
                  <tr
                    v-for="ghgCategory in getGhgCategorySummariesForScope(
                      'cb483bd8-d20e-42a1-a252-c44ee12230ef'
                    )"
                    :key="ghgCategory['current'].id"
                  >
                    <td>{{ ghgCategory["current"].name }}</td>
                    <td class="text-right">ton CO2e</td>
                    <td class="text-right">
                      {{ formatEmissions(ghgCategory["prev"]?.emissions) }}
                    </td>
                    <td class="text-right">
                      {{ formatEmissions(ghgCategory["current"]?.emissions) }}
                    </td>
                  </tr>

                  <!-- Scope 2 GHG emissions -->
                  <tr class="table-section-head">
                    <td>
                      <p>Scope 2 GHG emissions</p>
                    </td>
                    <td class="text-right">ton CO2e</td>
                    <td class="text-right">
                      {{
                        formatEmissions(
                          sumEmissionsForScope(
                            "6045ba53-b1c4-4ac0-a4be-7d4e4acc9f2b"
                          )["prev"]
                        )
                      }}
                    </td>
                    <td class="text-right">
                      {{
                        formatEmissions(
                          sumEmissionsForScope(
                            "6045ba53-b1c4-4ac0-a4be-7d4e4acc9f2b"
                          )["current"]
                        )
                      }}
                    </td>
                  </tr>

                  <tr
                    v-for="ghgCategory in getGhgCategorySummariesForScope(
                      '6045ba53-b1c4-4ac0-a4be-7d4e4acc9f2b'
                    )"
                    :key="ghgCategory['current'].id"
                  >
                    <td>{{ ghgCategory["current"].name }}</td>
                    <td class="text-right">ton CO2e</td>
                    <td class="text-right">
                      {{ formatEmissions(ghgCategory["prev"]?.emissions) }}
                    </td>
                    <td class="text-right">
                      {{ formatEmissions(ghgCategory["current"]?.emissions) }}
                    </td>
                  </tr>

                  <!-- Scope 3 GHG emissions -->
                  <tr class="table-section-head">
                    <td>
                      <p>Scope 3 GHG emissions</p>
                    </td>
                    <td class="text-right">ton CO2e</td>
                    <td class="text-right">
                      {{
                        formatEmissions(
                          sumEmissionsForScope(
                            "6d9ea628-4dcf-4d77-ad8b-7e8ea4d72dde"
                          )["prev"]
                        )
                      }}
                    </td>
                    <td class="text-right">
                      {{
                        formatEmissions(
                          sumEmissionsForScope(
                            "6d9ea628-4dcf-4d77-ad8b-7e8ea4d72dde"
                          )["current"]
                        )
                      }}
                    </td>
                  </tr>
                  <tr
                    v-for="ghgCategory in getGhgCategorySummariesForScope(
                      '6d9ea628-4dcf-4d77-ad8b-7e8ea4d72dde'
                    )"
                    :key="ghgCategory['current'].id"
                  >
                    <td>{{ ghgCategory["current"].name }}</td>
                    <td class="text-right">ton CO2e</td>
                    <td class="text-right">
                      {{ formatEmissions(ghgCategory["prev"]?.emissions) }}
                    </td>
                    <td class="text-right">
                      {{ formatEmissions(ghgCategory["current"]?.emissions) }}
                    </td>
                  </tr>

                  <!--<tr v-for="item in desserts" :key="item.name">
                    <td>{{ item.name }}</td>
                    <td>{{ item.calories }}</td>
                  </tr>-->
                </tbody>
              </template>
            </v-simple-table>
          </div>
        </v-card>
      </div>
    </div>
  </div>
</template>

<script>
import GlobalDateRange from "../../mixins/GlobalDateRange";
import { currencyFormatter } from "../../util/formatters";

const ghgOrder = [
  {
    name: "Company facilities",
    id: "3edee4c8-a1e4-4a77-a978-5da86d2ad7ba",
  },
  {
    name: "Company vehicles",
    id: "b0b345b4-87ad-4de5-b98f-4648d4c51edf",
  },
  //NOTE: Removed because of the split of el sources on the backend
  // {
  //   name: "Purchased electricity, steam, heating & cooling for own use",
  //   id: "e82fdc22-1ae0-4066-a662-ffa744de11a3",
  // },
  {
    name: "Purchased electricity, market based",
    id: "292656bd-cae4-4265-ba52-7e69c1d3e78f",
  },
  {
    name: "Purchased electricity, location based",
    id: "b0672a9c-3008-406a-b6f8-02ea99465b7e",
  },
  {
    name: "Steam, heating & cooling for own use",
    id: "b2a165b7-5e04-482f-92bd-9366707ddd0e",
  },
  {
    name: "Purchased goods and services",
    id: "a588c70c-a620-4d67-b65e-5ef7b11a8f87",
  },
  {
    name: "Capital goods",
    id: "24f8d5db-3871-464a-a52f-af31df23c978",
  },
  {
    name: "Fuel- and energy-related activities",
    id: "023053e9-84e3-47cb-9692-dc0243794af9",
  },
  {
    name: "Upstream transportation and distribution",
    id: "7595baac-87cd-40b5-a907-b1899b7fc62c",
  },
  {
    name: "Waste generated in operations",
    id: "e966141a-3d58-4096-967d-2f4e0776c75a",
  },
  {
    name: "Business travel",
    id: "97b1cb84-a7a0-4992-a0af-24d61f415510",
  },
  {
    name: "Employee commuting",
    id: "06b4a64c-d4be-43d9-bf73-6968fc778414",
  },
  {
    name: "Upstream leased assets",
    id: "3b8537c0-102c-40a1-be40-796ee826b9f8",
  },
  {
    name: "Downstream transportation and distribution",
    id: "783b4f4b-54c0-48c4-8949-b2cdf4a03911",
  },
  {
    name: "Processing of sold products",
    id: "4eddd004-d305-4f4c-8ed5-a7d131f31db2",
  },
  {
    name: "Use of sold products",
    id: "0fcb2df8-35bf-429e-bf6f-f73851616b58",
  },
  {
    name: "End-of-life treatment of sold products",
    id: "a147d878-dca6-436d-89ff-ab210f302d96",
  },
  {
    name: "Downstream leased assets",
    id: "3057fd53-9d3b-412a-9050-f8ad989e3727",
  },
  {
    name: "Franchises",
    id: "a431af20-eb08-420d-8a62-9e2d2f011f28",
  },
  {
    name: "Investments",
    id: "c3e8af39-30ee-44ab-84f7-58b28077a0de",
  },
];

export default {
  mixins: [GlobalDateRange],
  data() {
    return {
      ghgCategorySummariesCurrentPeriod: [],
      ghgCategorySummariesPreviousPeriod: [],
    };
  },
  mounted() {
    this.syncDateRange((dateRange) => {
      this.fetchData(dateRange);
    });
  },
  methods: {
    async fetchData(dateRange) {
      await fetch(
        this.route("api.dashboard.infographics.lists.ghgCategorySummaries", {
          fromDate: dateRange.from,
          toDate: dateRange.to,
        })
      )
        .then((response) => {
          if (!response.ok) {
            return Promise.reject();
          }
          return response.json();
        })
        .then((data) => {
          let _data = [];
          // order array
          ghgOrder.forEach((a) => {
            _data.push(data.find((b) => b.id === a.id));
          });
          this.ghgCategorySummariesCurrentPeriod = _data;
        })
        .catch((error) => {
          console.warn("Could not load data", error);
        });

      var dateFrom = new Date(dateRange.from);
      var pastYearFrom = dateFrom.getFullYear() - 1;
      let prevmm = dateFrom.getMonth() + 1; // Months start at 0!
      let prevdd = dateFrom.getDate();

      const prevFrom = pastYearFrom + "-" + prevmm + "-" + prevdd;

      var dateTo = new Date(dateRange.to);
      var pastYearTo = dateTo.getFullYear() - 1;
      let tomm = dateTo.getMonth() + 1; // Months start at 0!
      let todd = dateTo.getDate();

      const prevTo = pastYearTo + "-" + tomm + "-" + todd;

      await fetch(
        this.route("api.dashboard.infographics.lists.ghgCategorySummaries", {
          fromDate: prevFrom,
          toDate: prevTo,
        })
      )
        .then((response) => {
          if (!response.ok) {
            return Promise.reject();
          }
          return response.json();
        })
        .then((data) => {
          this.ghgCategorySummariesPreviousPeriod = data;
        })
        .catch((error) => {
          console.warn("Could not load data", error);
        });
    },
    getGhgCategorySummariesForScope(scopeId) {
      const filtered = this.ghgCategorySummariesCurrentPeriod.filter(
        (category) => category.scopeId === scopeId
      );

      const merged = filtered.map((cat) => ({
        current: cat,
        prev: this.ghgCategorySummariesPreviousPeriod.find(
          (c) => c.id === cat.id
        ),
      }));

      return merged;
    },
    sumEmissionsForScope(scopeId) {
      return {
        prev: this.ghgCategorySummariesPreviousPeriod
          .filter((category) => category.scopeId === scopeId)
          .reduce((acc, a) => acc + a?.emissions, 0),
        current: this.ghgCategorySummariesCurrentPeriod
          .filter((category) => category.scopeId === scopeId)
          .reduce((acc, a) => acc + a?.emissions, 0),
      };
    },
    formatEmissions(value) {
      if (isNaN(value)) {
        return "";
      }

      return currencyFormatter.format(value / 1000);
    },
  },
};
</script>

<style lang="scss" scoped>
.page-title {
  font-size: 35px;
}

.v-data-table > .v-data-table__wrapper {
  table {
    > thead {
      > tr:last-child > th {
        border-bottom: none;
      }
      > tr > th {
        font-size: 14px;
        font-weight: normal;
        border-bottom: none;
        height: 31px;
        &:first-child {
          font-size: 20px;
        }
      }
    }

    > tbody {
      > tr:not(:last-child) > td:not(.v-data-table__mobile-row) {
        border-bottom: none;
      }

      > tr {
        background-color: #fbfbfb;
        > td {
          height: 31px;
          font-size: 14px;
          font-weight: normal;

          &:first-child {
            font-size: 14px;
          }
        }
      }

      > .table-section-head {
        background-color: transparent;
        > td {
          border-bottom: 1px solid #00000014 !important;
          white-space: nowrap;
          > p {
            font-size: 18px;
            margin-bottom: 6px;
            margin-top: 11px;
          }
        }
      }
    }
  }
}
</style>
