<template>
  <v-dialog
    style="z-index: 20001"
    v-model="show"
    max-width="80vw"
    hide-overlay
    persistent
  >
    <template v-slot:activator="{ on, attrs }">
      <v-btn v-bind="attrs" v-on="on" text color="primary">
        {{ $t("pages.locations.import.import") }}
      </v-btn>
    </template>
    <v-card
      style="min-height: 470px"
      class="base-background-color d-flex flex-column import-card"
    >
      <v-card-title>
        <i18n path="pages.locations.import.importStep" tag="span">
          <span
            v-if="selectedImportStep === 3 && tableHasErrors"
            style="margin-left: 4px"
            class="red--text"
          >
            {{ $t("pages.locations.import.invalidData") }}
          </span>
          <span
            v-else
            style="margin-left: 4px"
            :class="
              currentStep && currentStep.color
                ? currentStep.color + '--text'
                : ''
            "
          >
            {{
              currentStep
                ? " " + currentStep.title
                : $t("pages.locations.import.stepTitles.unknown")
            }}
          </span>
        </i18n>
      </v-card-title>
      <p class="step-label">
        {{
          $t("pages.locations.import.stepCounter", { step: selectedImportStep })
        }}
      </p>
      <v-card-text class="flex-grow-1 pb-0">
        <location-import-step-one
          v-if="selectedImportStep === 1"
          :selectedImportFile="selectedImportFile"
          @errorChanged="handleStepOneError"
          @invalidFormatChanged="(value) => (invalidImportFileFormat = value)"
          @fileChanged="handleImportFileChanged"
        />
        <location-import-step-two
          v-if="selectedImportStep === 2"
          :selectedImportFile="selectedImportFile"
        />
        <location-import-step-three
          v-if="selectedImportStep === 3"
          :fields="fields"
          :locationTypes="locationTypes"
          :organizations="organizations"
          :countries="countries"
          :headerToFieldMappings="headerToFieldMappings"
          :locationRows="form.locationRows"
          :isEditing="tableIsEditing"
          :hasErrors="tableHasErrors"
          :updateOnId="form.updateOnId"
          @locationFieldUpdated="handleLocationFieldUpdated"
          @locationRemoved="handleLocationRemoved"
          @locationsRemoved="handleLocationsRemoved"
          @headerMappingChanged="handleHeaderMappingChanged"
          @isEditingUpdated="(isEditing) => (tableIsEditing = isEditing)"
          @updateOnIdChanged="(value) => (form.updateOnId = value)"
        />
        <location-import-step-four
          v-if="selectedImportStep === 4"
          :importCount="importSuccess.importCount"
          :updateCount="importSuccess.updateCount"
          :saveImportedFile="saveImportedFile"
          :importedFileName="importedFileName"
          @saveImportedFileChanged="(value) => (saveImportedFile = value)"
          @importedFileNameChanged="(value) => (importedFileName = value)"
        />
      </v-card-text>
      <v-card-actions class="px-6">
        <v-row justify="end">
          <v-alert
            dense
            v-if="errorMessage && errorMessageStep === selectedImportStep"
            type="error"
            class="mb-0"
          >
            {{ errorMessage }}
          </v-alert>
          <v-btn
            color="blue darken-1"
            text
            @click="hideModal"
            :loading="closeButtonLoading"
          >
            <v-icon class="black--text">mdi-close</v-icon>
            <p class="teal--text text--darken-4 mb-0">
              {{ $t("common.actions.close") }}
            </p>
          </v-btn>
          <v-btn
            v-if="selectedImportStep > 1 && selectedImportStep < 4"
            color="blue darken-1"
            text
            @click="moveToPreviousStep"
          >
            <v-icon class="black--text">mdi-chevron-left</v-icon>
            <p class="teal--text text--darken-4 mb-0">
              {{ $t("pages.locations.import.back") }}
            </p>
          </v-btn>
          <v-btn
            v-if="selectedImportStep < 4"
            color="blue darken-1"
            text
            @click="moveToNextStep()"
            :disabled="isNextButtonDisabled"
            :loading="nextButtonLoading"
          >
            <v-icon class="black--text">mdi-chevron-right</v-icon>
            <p
              :class="isNextButtonDisabled ? '' : 'teal--text text--darken-4'"
              class="mb-0"
            >
              {{ $t("pages.locations.import.next") }}
            </p>
          </v-btn>
        </v-row>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
import { serialize } from "object-to-formdata";
import { locationImportFields } from "@/util/importFields";

import LocationImportStepOne from "./ImportSteps/LocationImportStepOne.vue";
import LocationImportStepTwo from "./ImportSteps/LocationImportStepTwo.vue";
import LocationImportStepThree from "./ImportSteps/LocationImportStepThree.vue";
import LocationImportStepFour from "./ImportSteps/LocationImportStepFour.vue";

export default {
  components: {
    LocationImportStepOne,
    LocationImportStepTwo,
    LocationImportStepThree,
    LocationImportStepFour,
  },
  props: {
    value: Boolean,
  },
  computed: {
    show: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit("input", value);
      },
    },
    currentStep() {
      return this.importSteps.find((x) => x.id === this.selectedImportStep);
    },
    isNextButtonDisabled() {
      switch (this.selectedImportStep) {
        case 1:
          return this.invalidImportFileFormat || !this.selectedImportFile;
        case 2:
          return false;
        case 3:
          return (
            this.tableIsEditing ||
            !Object.values(this.headerToFieldMappings).some(
              (x) => x.type === "field" && x.fieldName !== null
            ) ||
            !this.form.locationRows.length
          );
        default:
          return true;
      }
    },
    importSteps() {
      return [
        { id: 1, title: this.$t("pages.locations.import.stepTitles.method") },
        { id: 2, title: this.$t("pages.locations.import.stepTitles.extras") },
        { id: 3, title: this.$t("pages.locations.import.stepTitles.validate") },
        {
          id: 4,
          title: this.$t("pages.locations.import.stepTitles.success"),
          color: "light-green",
        },
      ];
    },
  },
  data() {
    return {
      selectedImportStep: 1,
      selectedImportFile: null,
      errorMessage: null,
      errorMessageStep: null,
      invalidImportFileFormat: false,
      locationTypes: [],
      organizations: [],
      countries: [],
      fields: [],
      headerToFieldMappings: {},
      form: {
        headerToFieldNameMappings: {},
        locationRows: [],
        updateOnId: true,
      },
      tableIsEditing: false,
      tableHasErrors: false,
      nextButtonLoading: false,
      closeButtonLoading: false,
      importSuccess: {
        importCount: 0,
        updateCount: 0,
      },
      saveImportedFile: true,
      importedFileName: null,
    };
  },
  methods: {
    moveToPreviousStep() {
      const newStep = (this.currentStep?.id ?? 2) - 1;

      if (newStep < 1) {
        this.selectedImportStep = 1;
        return;
      }

      this.selectedImportStep = newStep;
    },
    moveToNextStep(step3Validated) {
      if (this.selectedImportStep === 3 && !step3Validated) {
        this.submitImportData();
        return;
      }

      this.selectedImportStep = (this.currentStep?.id ?? 0) + 1;

      this.runPageChangeSideEffects();
    },
    runPageChangeSideEffects() {
      if (this.selectedImportStep === 2) {
        this.fetchParsedLocationsFromImportFile();
      }
    },
    fetchParsedLocationsFromImportFile() {
      if (!this.selectedImportFile || this.form.locationRows?.length > 0)
        return;

      const formData = new FormData();
      formData.append("locationImportSpreadsheet", this.selectedImportFile);

      fetch(this.route("api.locations.parse"), {
        method: "POST",
        body: formData,
      })
        .then((res) => res.json())
        .then((data) => {
          this.headerToFieldMappings = data.headerToFieldMappings;
          this.fields = data.fields;
          this.locationTypes = data.locationTypes;
          this.organizations = data.organizations;
          this.countries = data.countries;

          const headers = Object.keys(this.headerToFieldMappings);

          const encounteredFields = [];

          headers.forEach((header) => {
            const mapping = this.headerToFieldMappings[header];

            if (mapping.type === "field") {
              let field = mapping.fieldName;

              if (encounteredFields.includes(field)) {
                this.headerToFieldMappings[header] = {
                  type: "field",
                  fieldName: null,
                };
                field = null;
              }

              this.form.headerToFieldNameMappings[header] = field ?? null;

              if (field) {
                encounteredFields.push(field);
              }
            }
          });

          this.form.locationRows = data.parsedLocations;
        });
    },
    submitImportData() {
      this.nextButtonLoading = true;

      const fieldMappings = { ...this.form.headerToFieldNameMappings };

      const locationRows = [
        ...this.form.locationRows.map((x) => ({
          ...x,
          row: { ...x.row },
        })),
      ];

      for (let mapping of Object.keys(fieldMappings)) {
        if (!fieldMappings[mapping]) {
          delete fieldMappings[mapping];
        }

        for (let { row } of locationRows) {
          const rowValue = row[mapping];

          if (!rowValue) continue;

          if (fieldMappings[mapping] === locationImportFields.locationType) {
            row[mapping] =
              this.locationTypes.find((x) => x.name === rowValue)?.id ??
              rowValue;
          }

          if (fieldMappings[mapping] === locationImportFields.organization) {
            row[mapping] =
              this.organizations.find((x) => x.name === rowValue)?.id ??
              rowValue;
          }
        }
      }

      const bodyContent = {
        ...this.form,
        locationRowsJson: JSON.stringify(locationRows.map((x) => x.row)),
        headerToFieldNameMappingsJson: JSON.stringify(fieldMappings),
      };

      delete bodyContent.locationRows;
      delete bodyContent.headerToFieldNameMappings;

      fetch(this.route("api.locations.import"), {
        method: "POST",
        body: serialize(bodyContent, {
          indices: true,
          dotsForObjectNotation: true,
        }),
      })
        .then((res) => {
          if (res.ok) {
            res.json().then((data) => {
              this.handleSuccessfulImport(data);
            });

            return;
          }

          return res.json();
        })
        .then((data) => {
          if (!data || data.status !== "error") return;

          const translationParameter = data.descriptionParameter
            ? { parameter: data.descriptionParameter }
            : undefined;

          const headerMappings = Object.keys(
            this.form.headerToFieldNameMappings
          );

          this.errorMessage = this.$t(
            `pages.locations.import.resultDescriptions.${data.description}`,
            translationParameter
          );
          this.errorMessageStep = 3;
          this.form.locationRows = data.locationRows.map((x) => {
            const row = x.row;

            const organizationMapping = headerMappings.find(
              (y) =>
                this.form.headerToFieldNameMappings[y] ===
                locationImportFields.organization
            );
            if (organizationMapping) {
              const organizationValue = row[organizationMapping];

              row[organizationMapping] =
                this.organizations.find((y) => y.id === organizationValue)
                  ?.name ?? organizationValue;
            }

            const locationTypeMapping = headerMappings.find(
              (y) =>
                this.form.headerToFieldNameMappings[y] ===
                locationImportFields.locationType
            );
            if (locationTypeMapping) {
              const locationTypeValue = row[locationTypeMapping];

              row[locationTypeMapping] =
                this.locationTypes.find((y) => y.id === locationTypeValue)
                  ?.name ?? locationTypeValue;
            }

            return {
              ...x,
              row: row,
            };
          });
          this.tableHasErrors = true;
        })
        .finally(() => {
          this.nextButtonLoading = false;
        });
    },
    async submitImportedFile() {
      this.closeButtonLoading = true;

      const blob = this.selectedImportFile.slice(
        0,
        this.selectedImportFile.size,
        this.selectedImportFile.type
      );
      const renamedFile = new File([blob], this.importedFileName, {
        type: this.selectedImportFile.type,
      });

      const formData = new FormData();
      formData.append("files", renamedFile);

      return fetch(this.route("api.files.store"), {
        method: "POST",
        body: formData,
      }).finally(() => {
        this.closeButtonLoading = false;
      });
    },
    resetModalState() {
      this.selectedImportStep = 1;
      this.selectedImportFile = null;
      this.errorMessage = null;
      this.headerToFieldMappings = {};
      this.form = {
        headerToFieldNameMappings: {},
        locationRows: [],
      };
      this.tableIsEditing = false;
      this.tableHasErrors = false;
      this.nextButtonLoading = false;
    },
    handleStepOneError(error) {
      this.errorMessage = error;
      this.errorMessageStep = 1;
    },
    handleImportFileChanged(value) {
      this.selectedImportFile = value;

      this.form.locationRows = [];
      this.form.headerToFieldNameMappings = {};
      this.headerToFieldMappings = {};

      if (value) {
        this.importedFileName = value.name;
      }
    },
    handleLocationRemoved(locationRow) {
      this.form.locationRows = this.form.locationRows.filter(
        (x) => x !== locationRow
      );
    },
    handleLocationsRemoved(locationRows) {
      this.form.locationRows = this.form.locationRows.filter(
        (x) => !locationRows.includes(x)
      );
    },
    handleHeaderMappingChanged(header, fieldName) {
      let mapping = this.headerToFieldMappings[header];

      if (!mapping) {
        mapping = {
          type: "field",
          fieldName: null,
        };
      }

      this.headerToFieldMappings[header] = {
        ...mapping,
        fieldName: fieldName,
      };

      this.form.headerToFieldNameMappings[header] = fieldName;
    },
    handleLocationFieldUpdated(locationRow, header, value) {
      const index = this.form.locationRows.indexOf(locationRow);

      if (index === -1) return;

      this.form.locationRows[index].row[header] = value;

      if (this.form.locationRows[index].columnErrorMessages) {
        delete this.form.locationRows[index].columnErrorMessages[header];
      }
    },
    handleSuccessfulImport(data) {
      this.tableHasErrors = false;
      this.errorMessage = false;

      this.importSuccess.importCount = data.importedLocationCount;
      this.importSuccess.updateCount = data.updatedLocationCount;

      this.moveToNextStep(true);
    },
    async hideModal() {
      if (this.selectedImportStep !== 4) {
        if (!window.confirm(this.$t("pages.locations.import.confirmClose")))
          return;
      }

      if (this.selectedImportStep === 4 && this.saveImportedFile) {
        await this.submitImportedFile();
      }

      this.show = false;
      this.resetModalState();
    },
  },
};
</script>
<style scoped>
.import-card {
  position: relative;
}

.import-card .step-label {
  position: absolute;
  top: 8px;
  left: 0;
  right: 0;
  text-align: center;
  font-weight: bolder;
}
</style>
