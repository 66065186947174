<template>
  <div>
    <v-row no-gutters class="d-flex align-center mb-4 space-between">
      <v-col>
        <h4 class="text-h4">{{ $t("pages.locations.index.title") }}</h4>
      </v-col>
      <v-col>
        <v-row class="d-flex align-end">
          <v-spacer></v-spacer>
          <import-locations-modal v-model="importLocationsDialog" />
          <v-btn
            text
            color="primary"
            @click="exportLocations"
            :disabled="exportLoading"
            :loading="exportLoading"
          >
            {{ $t("pages.locations.index.export") }}</v-btn
          >
          <v-btn
            text
            color="primary"
            v-inertia
            :href="route('locations.create')"
          >
            {{ $t("pages.locations.index.create") }}</v-btn
          >
        </v-row>
      </v-col>
    </v-row>
    <v-row class="d-flex align-center">
      <v-col>
        <v-text-field
          v-model="filters.search"
          append-icon="mdi-magnify"
          :label="$t('common.dropdowns.search.label')"
          single-line
          dense
          solo
          hide-details
        ></v-text-field>
      </v-col>
      <v-col>
        <v-select
          v-model="filters.country"
          :items="countries"
          :label="$t('pages.locations.index.country')"
          dense
          hide-details
          solo
        ></v-select>
      </v-col>
      <v-col>
        <v-select
          v-model="filters.city"
          :items="cities"
          :label="$t('pages.locations.index.city')"
          dense
          hide-details
          solo
        ></v-select>
      </v-col>
      <v-col>
        <v-select
          v-model="filters.locationTypeId"
          :items="locationTypes"
          :label="$t('pages.locations.index.locationType')"
          item-text="name"
          item-value="id"
          dense
          hide-details
          solo
        ></v-select>
      </v-col>
      <v-col>
        <v-menu
          v-model="installedFromMenu"
          :close-on-content-click="false"
          :nudge-right="40"
          transition="scale-transition"
          offset-y
          min-width="auto"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
              v-model="filters.installedFrom"
              append-icon="mdi-calendar"
              @click:append="installedFromMenu = true"
              readonly
              dense
              hide-details
              solo
              :label="$t('pages.locations.index.installedFrom')"
              v-bind="attrs"
              v-on="on"
            ></v-text-field>
          </template>
          <v-date-picker
            first-day-of-week="1"
            v-model="filters.installedFrom"
            @input="onInstalledFromUpdated"
          ></v-date-picker>
        </v-menu>
      </v-col>
      <v-col>
        <v-menu
          v-model="installedToMenu"
          :close-on-content-click="false"
          :nudge-right="40"
          transition="scale-transition"
          offset-y
          min-width="auto"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
              v-model="filters.installedTo"
              append-icon="mdi-calendar"
              @click:append="installedToMenu = true"
              readonly
              dense
              hide-details
              solo
              :label="$t('pages.locations.index.installedTo')"
              v-bind="attrs"
              v-on="on"
            ></v-text-field>
          </template>
          <v-date-picker
            first-day-of-week="1"
            v-model="filters.installedTo"
            :min="filters.installedFrom"
            @input="installedToMenu = false"
          ></v-date-picker>
        </v-menu>
      </v-col>
      <v-col cols="1" class="d-flex justify-center">
        <v-spacer />
        <v-btn
          icon
          @click="resetFilter"
          :title="$t('pages.locations.index.clearFilters')"
          ><v-icon>mdi-filter-off-outline</v-icon></v-btn
        >
      </v-col>
    </v-row>
    <v-data-table
      class="elevation-1 row-pointer"
      :headers="headers"
      :items="locations.data"
      :server-items-length="locations.totalCount"
      :options.sync="pagination"
      :must-sort="true"
      :footer-props="tableFooterProps"
      @click:row="rowClick"
      @update:page="changePage(pagination)"
      @update:items-per-page="changePage(pagination)"
      @update:sort-desc="changePage(pagination)"
      @update:sort-by="changePage(pagination)"
    >
      <template v-slot:item.locationType="{ item }">
        {{ locationTypesMap[item.locationTypeId] }}
      </template>
      <template v-slot:item.createdAt="{ item }">
        {{ new Date(item.createdAt).toLocaleString() }}
      </template>
      <template v-slot:item.updatedAt="{ item }">
        {{ new Date(item.updatedAt).toLocaleString() }}
      </template>
      <template #item.addressLine1="{ item }">
        <td class="text-start">
          <span class="location-address-text">{{ item.addressLine1 }}</span>
        </td>
      </template>
      <template v-slot:item.extraInfo="{ item }">
        <v-tooltip top v-if="item.coordinatesGeocoded">
          <template v-slot:activator="{ on, attrs }">
            <v-icon color="green" v-bind="attrs" v-on="on"> mdi-earth </v-icon>
          </template>
          <span>{{ $t("pages.locations.index.coordsGeocoding") }}</span>
        </v-tooltip>
        <v-tooltip top v-else-if="isItemCoordinatesInvalid(item)">
          <template v-slot:activator="{ on, attrs }">
            <v-icon color="orange" v-bind="attrs" v-on="on"> mdi-alert </v-icon>
          </template>
          <span>{{ $t("pages.locations.index.missingCoords") }}</span>
        </v-tooltip>
      </template>
      <template v-slot:item.actions>
        <v-icon class="mr-2">mdi-chevron-right</v-icon>
      </template>
      <template #footer.page-text="pageTextProps">
        {{ $t("common.dataTable.visibleRows", pageTextProps) }}
      </template>
    </v-data-table>
  </div>
</template>

<script>
import throttle from "lodash/throttle";
import pickBy from "lodash/pickBy";
import { appLayout } from "@/util/layout";
import { objectToFormData } from "@/util/formData";
import { footerProps } from "@/util/dataTable";

import ImportLocationsModal from "@/Components/Location/ImportLocationsModal.vue";

export default {
  layout: appLayout({ title: "pages.locations.index.title" }),
  components: { ImportLocationsModal },
  props: {
    locations: Object,
    locationTypes: Array,
    countries: Array,
    cities: Array,
  },
  data() {
    var searchParams = new URLSearchParams(window.location.search);
    const locationTypesMap = {};

    this.locationTypes.forEach((x) => {
      locationTypesMap[x.id] = x.name;
    });

    return {
      pagination: {
        page: this.locations.currentPage,
        itemsPerPage: 50,
        sortBy: [searchParams.get("sortBy") || "locationType"],
        sortDesc: [searchParams.get("sortDesc") === "true"],
      },
      tableFooterProps: footerProps,
      filters: {
        search: searchParams.get("filters[search]"),
        locationTypeId: searchParams.get("filters[locationTypeId]"),
        country: searchParams.get("filters[country]"),
        city: searchParams.get("filters[city]"),
        installedFrom: searchParams.get("filters[installedFrom]"),
        installedTo: searchParams.get("filters[installedTo]"),
      },
      locationTypesMap,
      installedFromMenu: false,
      installedToMenu: false,
      exportLoading: false,
      importLocationsDialog: false,
    };
  },
  computed: {
    user() {
      return this.$inertia.page.props.auth.user;
    },
    headers() {
      return [
        {
          text: this.$t("common.fieldNames.id"),
          value: "referenceKey",
        },
        {
          text: this.$t("pages.locations.index.columns.locationType"),
          value: "locationType",
        },
        {
          text: this.$t("pages.locations.index.columns.locationNumber"),
          value: "locationNumber",
        },
        {
          text: this.$t("pages.locations.index.columns.locationName"),
          value: "name",
        },
        {
          text: this.$t("pages.locations.index.columns.country"),
          value: "country",
        },
        { text: this.$t("pages.locations.index.columns.city"), value: "city" },
        {
          text: this.$t("pages.locations.index.columns.addressLineOne"),
          value: "addressLine1",
        },
        {
          text: this.$t("common.fieldNames.actions"),
          value: "actions",
          sortable: false,
          align: "right",
        },
      ];
    },
  },
  methods: {
    resetFilter() {
      this.filters = {};
    },
    rowClick(item) {
      this.$inertia.get(this.route("locations.edit", item.id));
    },
    changePage(options) {
      let query = {
        filters: pickBy(this.filters),
        sortBy: options.sortBy[0],
        sortDesc: options.sortDesc[0],
        page: options.page,
        pageSize: options.itemsPerPage,
      };

      this.$inertia.get(
        this.route("locations.index", query),
        {},
        { preserveState: true }
      );
    },
    isItemCoordinatesInvalid(item) {
      if (item.latitude === null || item.longitude === null) return true;

      return (
        item.latitude < -90 ||
        item.latitude > 90 ||
        item.longitude < -180 ||
        item.longitude > 180
      );
    },
    onInstalledFromUpdated() {
      let fromDate = new Date(this.filters.installedFrom);
      let toDate = this.filters.installedTo
        ? new Date(this.filters.installedTo)
        : null;

      if (toDate && toDate < fromDate) {
        this.filters.installedTo = null;
      }

      this.installedFromMenu = false;
    },
    exportLocations() {
      this.exportLoading = true;

      let body = {
        filters: pickBy(this.filters),
        sortBy: this.pagination.sortBy[0] || null,
        sortDesc: this.pagination.sortDesc[0] || null,
        page: this.pagination.page || null,
        pageSize: this.pagination.itemsPerPage || null,
      };

      fetch(this.route("locations.export"), {
        method: "POST",
        body: objectToFormData(body),
      })
        .then(async (res) => ({
          fileName: this.getFileNameFromHeader(
            res.headers.get("content-disposition")
          ),
          contentType: res.headers.get("content-type"),
          blob: await res.blob(),
        }))
        .then((fileData) => {
          const blob = new Blob([fileData.blob], {
            type: fileData.contentType,
          });

          if (window.navigator && window.navigator.msSaveOrOpenBlob) {
            window.navigator.msSaveOrOpenBlob(blob);
          } else {
            const blobUrl = URL.createObjectURL(blob);

            let link = document.createElement("a");
            link.href = blobUrl;
            link.download = fileData.fileName;
            link.click();

            setTimeout(() => {
              URL.revokeObjectURL(blobUrl);
            }, 250);
          }
        })
        .finally(() => (this.exportLoading = false));
    },
    // Based on: https://gist.github.com/devloco/5f779216c988438777b76e7db113d05c
    getFileNameFromHeader(header) {
      let contentDispostion = header.split(";");
      const fileNameToken = `filename*=UTF-8''`;

      let fileName = null;
      for (let thisValue of contentDispostion) {
        if (thisValue.trim().indexOf(fileNameToken) === 0) {
          fileName = decodeURIComponent(
            thisValue.trim().replace(fileNameToken, "")
          );
          break;
        }
      }

      return fileName;
    },
  },
  watch: {
    filters: {
      handler: throttle(function () {
        this.pagination = { page: 1, sortBy: [], sortDesc: [] };
        this.changePage(this.pagination);
      }, 150),
      deep: true,
    },
  },
};
</script>
<style lang="css" scoped>
.row-pointer >>> tbody tr :hover {
  cursor: pointer;
}
.location-address-text {
  display: inline-block;
  max-width: 800px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
</style>
