import menuItems from "./menuItems.json";
import layout from "./layout.json";
import notificationMenu from "./notificationMenu.json";
import requestSizeError from "./requestSizeError.json";
import fileMenu from "./fileMenu.json";
import contents from "./contents.json";
import assetInfo from "./assets/info.json";
import assetDialog from "./assets/dialog.json";
import assetPreview from "./assets/preview.json";
import createSolutions from "./createSolutions.json";
import mentionInput from "./mentionInput.json";
import amountChangeCard from "./cards/amountChange.json";

export default {
  menuItems,
  layout,
  notificationMenu,
  requestSizeError,
  fileMenu,
  contents,
  assets: {
    info: assetInfo,
    dialog: assetDialog,
    preview: assetPreview,
  },
  createSolutions,
  mentionInput,
  cards: {
    amountChange: amountChangeCard,
  },
};
