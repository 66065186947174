<template>
  <v-card
    light
    color="#fff"
    elevation="0"
    rounded="0"
    max-width="100%"
    class="flex-grow-1"
  >
    <div class="px-5 py-5 fill-height d-flex flex-column">
      <div class="d-flex flex-column flex-sm-row">
        <h3 class="pb-8 font-weight-regular text-h5 flex-grow-1">
          Emissions overview by calculation method
        </h3>
      </div>
      <div class="">
        <div class="stacked-bar d-flex flex-row">
          <div
            v-for="(item, index) in data"
            :key="index"
            class="stacked-bar-part"
            :style="'width:' + calculatePercentage(item.value, index) + '%'"
          ></div>
        </div>
        <div class="value-label pt-8 d-flex flex-row">
          <div
            v-for="(item, index) in data"
            :key="index"
            class="d-flex flex-row align-center mr-8 stacked-bar-label"
          >
            <div
              class="mr-4 rounded-4 color-swatch"
              :style="'display: inline-block; min-width: 16px; height: 16px; border-radius: 4px;'"
            ></div>
            <div class="mr-4 stacked-bar-title">
              {{ item.name }}
            </div>
            <div class="mr-4 stacked-bar-value">
              {{ formatCurrency(item.value) }} kg CO2e
            </div>
            <div class="stacked-bar-percentage">
              {{ calculatePercentage(item.value, index) }}%
            </div>
          </div>
        </div>
      </div>
    </div>
  </v-card>
</template>

<script>
import { currencyFormatter } from "../../util/formatters";

export default {
  components: {},
  props: {
    data: Array,
  },
  methods: {
    calculatePercentage(value, index) {
      const summedVals = this.data.reduce((acc, obj) => acc + obj.value, 0);
      const rawPercentage = (value / summedVals) * 100;
      let finalPercentage = Math.min(100, Math.round(rawPercentage));

      if (index === this.data.length - 1) {
        const totalPercentage = this.data.reduce(
          (acc, obj, i) =>
            acc +
            (i === index
              ? finalPercentage
              : Math.round((obj.value / summedVals) * 100)),
          0
        );
        finalPercentage += 100 - totalPercentage;
      }

      return finalPercentage;
    },
    formatCurrency(value) {
      if (isNaN(value)) {
        return "-";
      }
      return currencyFormatter.format(value);
    },
  },
};
</script>

<style lang="scss" scoped>
.stacked-bar {
  background: #eaeaea;
  height: 61px;
  border-radius: 10px;
  overflow: hidden;
  .stacked-bar-part {
    background: #a75481;
    &:nth-child(1) {
      background: #266663;
    }
    &:nth-child(2) {
      background: #f25d3b;
    }
    height: 61px;
  }
}

.value-label {
  font-size: 16px;

  .stacked-bar-label {
    .color-swatch {
      background: #a75481;
    }
    &:nth-child(1) .color-swatch {
      background: #266663;
    }
    &:nth-child(2) .color-swatch {
      background: #f25d3b;
    }
  }

  .stacked-bar-title {
    color: #686868;
  }
  .stacked-bar-value {
    color: #131313;
  }

  .stacked-bar-percentage {
    color: #131313;
  }
}

.date-arrow {
  path {
    fill: #131313;
  }
}
</style>
