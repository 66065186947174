<template>
  <v-select
    class="rounded-0 mb-1 custom-select"
    style="cursor: pointer; border: 1px solid #dbdbdb"
    clearable
    background-color="#fff"
    solo
    flat
    label="Assign GHG-categories"
    filled
    light
    :menu-props="{ top: false, offsetY: true }"
    color="red"
    item-color="red"
    :hide-details="true"
    :items="items"
    item-value="id"
    item-text="value"
    :height="38"
    v-model="value"
    @change="(val) => (value = val)"
    @input="(val) => (value = val)"
    @focus="focused = true"
    @blur="focused = false"
  >
    <template #item="{ item }">
      <div class="flex-grow-1">
        <span class="d-block select-label">{{ item?.value }} </span>
      </div>
      <span class="scope-label mr-3">{{ item?.dependentOption?.value }}</span>
      <check-circle
        v-show="value == item?.id"
        width="16"
        height="16"
        class="selected-check"
      ></check-circle>
    </template>
    <template #append>
      <span class="scope-label mr-3">
        {{ selectedItem?.dependentOption?.value ?? "Select" }}
      </span>
      <down-arrow
        class="selected-check align-self-center"
        :class="focused ? null : 'selected-icon'"
        width="8"
        height="8"
      ></down-arrow>
    </template>
  </v-select>
</template>

<script>
import CheckCircle from "../../assets/svg/check-circle.svg";
import DownArrow from "../../assets/svg/down-arrow.svg";

export default {
  components: { CheckCircle, DownArrow },
  props: {
    items: Array,
    initialValue: Object,
  },
  data() {
    return {
      value: this.initialValue?.id,
      focused: false,
    };
  },
  watch: {
    value(to) {
      this.$emit("change", to);
    },
    items() {
      this.items.forEach((item) => {
        if (item.selected === true) {
          this.value = item.id;
        }
      });
    },
  },
  computed: {
    selectedItem() {
      return this.items.find((item) => item.id === this.value);
    },
  },
};
</script>

<style lang="scss" scoped>
.custom-select {
  max-width: 380px;
  height: 41px;
  margin: 5px 0;
  flex: 1;
}

.custom-select:deep(.v-input__slot) {
  min-height: 38px;
}

.custom-select:deep(input) {
  box-shadow: none;
}

.scope-label {
  font-size: 12px;
  color: #b4b4b4;
}

.select-label {
  max-width: 258px;
  text-wrap: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

.selected-icon {
  transform: rotateZ(180deg);
}

.selected-check {
  path {
    fill: #f25d3b;
  }
}
</style>
