<template>
  <v-app>
    <v-container
      fluid
      ma-0
      pa-0
      class="fill-height"
      style="background: #131313"
    >
      <v-row class="fill-height">
        <v-col cols="6" class="d-none d-sm-flex white--text black" no-gutters>
          <v-row>
            <div>
              <LogoLarge class="ml-16 mb-12" />
            </div>
            <div class="ml-13 mb-5 container">
              <div class="grid-container">
                <NumberOfAssetsBox />
                <TotalEmissionsBox />
                <CO2EmissionsBox />
                <TreesOffsetBox />
              </div>
            </div>
            <div class="center-element">
              <VerarcaSkeleton class="skeleton-adjustment" />
            </div>
          </v-row>
          <v-row>
            <div class="carousel-container index-10 black">
              <v-carousel
                height="200"
                v-model="activeSlide"
                hide-delimiters
                :show-arrows="false"
              >
                <v-carousel-item
                  v-for="(item, index) in carouselItems"
                  :key="index"
                >
                  <v-sheet class="pa-4" :color="'black'">
                    <h1 class="text-h4 white--text mb-2">
                      {{ item.title }}
                    </h1>
                    <p class="text-subtitle-1 white--text font-weight-light">
                      {{ item.text }}
                    </p>
                  </v-sheet>
                </v-carousel-item>
              </v-carousel>
            </div>
          </v-row>
          <v-row>
            <div class="carousel-navigation black">
              <v-btn icon dark @click="prevSlide" class="margin-40">
                <v-icon>mdi-arrow-left-thin</v-icon>
              </v-btn>
              <v-btn icon dark @click="nextSlide">
                <v-icon>mdi-arrow-right-thin</v-icon>
              </v-btn>
            </div>
          </v-row>
        </v-col>
        <v-col class="white d-flex index-10" cols="12" sm="6">
          <page-transition>
            <slot />
          </page-transition>
        </v-col>
      </v-row>
    </v-container>
  </v-app>
</template>

<script>
import PageTransition from "@/Shared/PageTransition";
import LogoLarge from "../../src/assets/svg/verarca-logo-light.svg";
import VerarcaSkeleton from "../../src/assets/svg/verarca-skeleton.svg";
import CO2EmissionsBox from "../../src/assets/svg/login-co2-emissions-box.svg";
import NumberOfAssetsBox from "../../src/assets/svg/login-number-of-assets-box.svg";
import TotalEmissionsBox from "../../src/assets/svg/login-total-emissions-box.svg";
import TreesOffsetBox from "../../src/assets/svg/login-trees-offset-box.svg";
import { initializeRemoteLanguages } from "../i18n";

export default {
  components: {
    PageTransition,
    LogoLarge,
    VerarcaSkeleton,
    CO2EmissionsBox,
    NumberOfAssetsBox,
    TotalEmissionsBox,
    TreesOffsetBox,
  },
  data() {
    return {
      activeSlide: 0,
    };
  },
  computed: {
    auth() {
      return this.$page.props.auth;
    },
    language() {
      return this.$page.props.language;
    },
    carouselItems() {
      return [
        {
          title: this.$t("pages.login.carousel.title1"),
          text: this.$t("pages.login.carousel.text1"),
        },
        {
          title: this.$t("pages.login.carousel.title1"),
          text: this.$t("pages.login.carousel.text1"),
        },
        {
          title: this.$t("pages.login.carousel.title1"),
          text: this.$t("pages.login.carousel.text1"),
        },
      ];
    },
  },
  mounted() {
    this.$i18n.locale = this.auth.user?.preferredLocale ?? "en";

    initializeRemoteLanguages(this.language?.files);
  },
  methods: {
    prevSlide() {
      if (this.activeSlide > 0) {
        this.activeSlide--;
      }
    },
    nextSlide() {
      if (this.activeSlide < this.carouselItems.length - 1) {
        this.activeSlide++;
      }
    },
  },
};
</script>
<style scoped>
.v-container {
  width: 60%;
}

.text-h2 {
  z-index: 1;
}

#bg-circle {
  position: absolute;
  width: 70vh;
  height: 70vh;
  border-radius: 100%;
  left: -36vh;
  background-color: #ffffff3a;
}

#bg-inner-circle {
  position: absolute;
  width: 60%;
  height: 60%;
  border-radius: 100%;
}

.center-element {
  display: flex;
  justify-content: center;
  align-items: center;
  transform: translateX(50%) translateY(-40%);
  z-index: 1;
}

.skeleton-adjustment {
  margin-top: -30px;
}

.index-10 {
  z-index: 10;
}

.carousel-container {
  position: absolute;
  bottom: 10px;
  left: 45%;
  transform: translateX(-98%);
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 20px;
  width: 45%;
  height: 200px;
}

.carousel-navigation {
  position: absolute;
  left: 30px;
  bottom: 0;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin: 10px;
  z-index: 12;
}

.container {
  z-index: 10;
  display: flex;
  justify-content: flex-start;
}

.grid-container {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: 1fr 1fr;
  gap: 10px;
}

.margin-40 {
  margin-right: 40px;
}
</style>
