<template>
  <v-app>
    <v-navigation-drawer
      v-if="!hideMenu"
      v-model="drawer"
      app
      color="#131313"
      dark
      width="240px"
      mini-variant-width="80px"
      :mini-variant.sync="mini"
      :temporary="windowWidth < 960 ? true : false"
      :mobile-breakpoint="960"
      class="main-navigation"
    >
      <v-btn
        elevation="5"
        class="toggle-drawer-navigation-btn"
        :class="mini ? 'mini' : ''"
        icon
        @click.stop="mini = !mini"
      >
        <!--<v-icon color="#000">mdi-chevron-left</v-icon>-->
        <DrawerDismissBtnIcon></DrawerDismissBtnIcon>
        <!--<img :src="DrawerDismissBtnIcon" style="padding-right: 2.5px" />-->
      </v-btn>
      <template v-slot:prepend>
        <v-list-item class="d-flex">
          <div class="flex-grow-1">
            <h1 class="font-weight-light">
              <div class="d-flex align-center" style="height: 115px">
                <Transition name="fade">
                  <div
                    v-if="mini === true"
                    style="
                      width: 100%;
                      align-items: center;
                      justify-content: center;
                      display: flex;
                    "
                  >
                    <logo-small />
                  </div>
                  <logo-large
                    v-else-if="mini === false"
                    class="ml-4"
                    style="width: 134px; height: 20px; position: absolute"
                  />
                </Transition>
              </div>
            </h1>
          </div>
        </v-list-item>
        <v-divider></v-divider>
      </template>
      <template v-if="hasMultipleOrganizations">
        <div class="py-0 body-2">
          <div class="d-flex flex-column align-center">
            <organization-menu :mini="mini" />
          </div>
        </div>
      </template>
      <menu-items
        :is-organization-group="isOrganizationGroup"
        :mini="mini"
        :url="url()"
      />
      <template v-slot:append>
        <div style="overflow: hidden">
          <Transition name="fade">
            <div v-show="!mini">
              <upgrade-to-pro-card
                v-if="isFree && !isOrganizationGroup"
              ></upgrade-to-pro-card>
            </div>
          </Transition>
        </div>
        <!--<div class="px-2 py-4 black body-2" style="visibility: hidden">
          <div class="d-flex align-center">
            <notification-menu />
          </div>
        </div>-->
        <!--<v-divider class="navigation-divider" />
        <div class="px-4 black py-4 body-2">
          <div class="d-flex align-center">
            <img
              class="mr-4"
              style="height: auto; width: 48px"
              :src="`https://eu.ui-avatars.com/api/?name=${$page.props.auth.user.name}&rounded=true&size=48&background=cef2ef`"
            />
            <div class="d-flex flex-column">
              <div class="white--text">{{ $page.props.auth.user.name }}</div>
              <inertia-link
                :href="route('profile')"
                class="text-decoration-none white--text text--lighten-2"
                >{{ $t("components.layout.viewProfile") }}</inertia-link
              >
            </div>
          </div>
        </div>-->
      </template>
    </v-navigation-drawer>
    <v-main
      class="base-background-color main-content copilot-margin"
      :class="mini ? 'nav-mini' : 'nav-full'"
    >
      <v-banner
        v-if="!dismissedSyncAlert && syncingIntegrationProgress"
        single-line
        :sticky="true"
        color="#fff"
        style="z-index: 5"
      >
        <div class="d-flex flex-row align-center">
          <lottie-vue-player
            v-bind="loopingAnimation"
            :src="LottieSpinnerOrange"
            style="width: 25px; height: 25px; background-color: transparent"
          />
          <div class="mx-4">
            {{ $t("components.layout.wereCrunchingYourData") }}
            <!--<div v-if="syncingIntegrationProgress.totalCount > 0">
              {{ $t("components.layout.soFar") }}
              <strong>{{ syncingIntegrationProgress.processedCount }}</strong>
              {{ $t("components.layout.outOf") }}
              <strong>{{ syncingIntegrationProgress.totalCount }}</strong>
              {{ $t("components.layout.haveBeenProcessed") }}
            </div>-->
          </div>
        </div>
        <template v-slot:actions>
          <v-btn icon @click="dismissedSyncAlert = true">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </template>
      </v-banner>
      <!--<dialog-form :showDialog="showUpgradeModal" maxWidth="580px">
        <dialog-form-section-one-col>
          <div
            class="d-flex align-center flex-column"
            style="padding-top: 100px; padding-bottom: 20px"
          >
            <v-list-item-avatar
              color="#FDECE7"
              class="d-flex align-center justify-center items-center mb-4"
              width="62px"
              height="62px"
            >
              <WarningError
                style="width: 32px; height: 32px; border-radius: 0"
              ></WarningError>
            </v-list-item-avatar>
            <p class="text-h6 black--text mb-3 font-weight-regular">
              Upgrade to import more data
            </p>
            <p class="text-body-1 mb-5">
              You have reached the limit for import of data.
            </p>
            <p class="text-body-1 mb-3 text-center">
              Please press "Upgrade" to continue using easy import of data to
              Verarca, or stay on our free plan and enter your data manually.
            </p>
          </div>
        </dialog-form-section-one-col>
        <v-card-actions
          class="py-6 px-6 d-flex flex-column-reverse flex-md-row"
        >
          <v-spacer></v-spacer>
          <v-btn
            class="text-none ml-0 mb-4 mb-md-0 align-self-stretch"
            color="#686868"
            elevation="0"
            tile
            dark
            x-large
            text
            @click="cancelUpgradeModal"
          >
            <p class="mb-0">
              {{ $t("common.actions.cancel") }}
            </p>
          </v-btn>
          <v-btn
            class="text-none ml-0 mb-4 mb-md-0 ml-md-4 px-6 align-self-stretch"
            color="#F25D3B"
            dark
            elevation="0"
            tile
            x-large
            @click="upgrade()"
          >
            <p class="white--text mb-0">
              {{ $t("common.actions.upgrade") }}
            </p>
          </v-btn>
        </v-card-actions>
      </dialog-form>-->
      <v-container fluid class="py-0 content-header">
        <v-row class="px-2">
          <v-col class="px-2">
            <div
              class="page-top-bar pl-md-4 d-flex flex-column flex-lg-row align-lg-center"
              :style="
                pushContent ? 'height: 148px; padding-bottom: 60px;' : null
              "
            >
              <h4
                class="text-h4 text-md-h4 flex-grow-1 d-none d-lg-block font-weight-medium white--text"
              >
                {{ getPageTitleKey }}
              </h4>
              <div class="flex-grow-1 d-flex flex-row">
                <v-app-bar-nav-icon
                  class="toggle-mobile-nav pa-2"
                  @click.stop="drawer = !drawer"
                ></v-app-bar-nav-icon>
                <div
                  class="flex-grow-1 d-flex flex-column-reverse flex-sm-row align-end align-sm-center"
                >
                  <date-range-picker
                    class="d-flex flex-grow-1 justify-end justify-md-sm align-end"
                    :fromDate="globalDateRange.from"
                    :toDate="globalDateRange.to"
                    @change="(val) => dateRangeChanged(val)"
                  ></date-range-picker>
                  <span
                    class="d-none d-sm-block mx-4 mx-md-8"
                    style="
                      border-right: 1px solid rgba(217, 217, 217, 0.5);
                      height: 40px;
                    "
                  ></span>
                  <profile-management
                    class="justify-end"
                    :profilePictureUrl="profilePictureUrl"
                    :username="this.auth.user.name"
                    :companyName="this.getActiveOrganization.name"
                  ></profile-management>
                </div>
              </div>
            </div>
          </v-col>
        </v-row>
      </v-container>
      <!--:class="!mini ? 'avoid-navigation' : ''"-->
      <v-container fluid class="align-start px-0 pt-0">
        <!--<div
          v-if="!hideMenu"
          class="hidden-lg-and-up"
          style="height: 48px"
        ></div>-->
        <!--<v-app-bar
          v-if="!hideMenu"
          color="teal darken-4"
          dense
          light
          fixed
          class="hidden-lg-and-up"
        >
          <v-app-bar-nav-icon @click="navigationDrawer = !navigationDrawer">
          </v-app-bar-nav-icon>
        </v-app-bar>-->
        <slot />
        <v-snackbar
          :value="showError"
          :timeout="2000"
          v-if="errorMessage && !$page.props.flash.hidePopup"
          color="#fff"
          tile
          elevation="3"
        >
          {{ errorMessage }}
        </v-snackbar>
        <v-snackbar
          :value="showSuccess"
          :timeout="2000"
          v-if="successMessage && !$page.props.flash.hidePopup"
          color="#1F7439"
          tile
          elevation="3"
        >
          {{ successMessage }}
        </v-snackbar>
      </v-container>
    </v-main>
    <copilot></copilot>
  </v-app>
</template>
<script>
import MenuItems from "@/Shared/MenuItems";
import OrganizationMenu from "@/Shared/OrganizationMenu";
import DrawerDismissBtnIcon from "../../src/assets/svg/drawer-dismiss-btn-icon.svg";
import LogoSmall from "../../src/assets/svg/verarca-logo-icon-light.svg";
import LogoLarge from "../../src/assets/svg/verarca-logo-light.svg";
import UpgradeToProCard from "../Components/Cards/UpgradeToProCard.vue";
import { Inertia } from "@inertiajs/inertia";
import DateRangePicker from "../Components/Inputs/DateRangePicker.vue";
import ProfileManagement from "../Components/Navigation/ProfileManagement.vue";
import { initializeRemoteLanguages } from "../i18n";
import { EventBus } from "../eventBus.js";

import LottieSpinnerOrange from "@/assets/animations/lottie-spinner-orange.json";
import { loopingAnimation } from "../util/lottieConfigs";
import Copilot from "../Components/Copilot/Copilot.vue";
/*import DialogForm from "../Components/Dialog/DialogForm.vue";
import WarningError from "../assets/svg/warning-error.svg";
import DialogFormSectionOneCol from "../Components/Dialog/DialogFormSectionOneCol.vue";*/

export default {
  components: {
    MenuItems,
    OrganizationMenu,
    DrawerDismissBtnIcon,
    LogoSmall,
    LogoLarge,
    UpgradeToProCard,
    DateRangePicker,
    ProfileManagement,
    Copilot,
    /*DialogForm,
    WarningError,
    DialogFormSectionOneCol,*/
  },
  computed: {
    getPageTitleKey() {
      if (this.title === "pages.dashboard.title") {
        return this.$t("components.layout.welcome", {
          name: this.auth.user.name,
        });
      }

      if (this.title === "pages.integrationEntryEvents.index.title") {
        const integration = this.$inertia.page.props.integration;

        return this.$t(this.title, { integration: integration.name });
      }

      return this.$t(this.title, this.titleValues);
    },
    profilePictureUrl() {
      if (this.auth.user.profilePictureFile) {
        return this.route("api.files.content", {
          id: this.auth.user.profilePictureFile.id,
          filename: this.auth.user.profilePictureFile.originalName,
        });
      }

      return (
        "https://ui-avatars.com/api/?name=" +
        this.getInitials +
        "&color=fff&background=F25D3B"
      );
    },
    getInitials() {
      const nameParts = this.auth.user.name.split(" ");
      const initials = nameParts.map((name) => name.charAt(0)).join("");
      return initials.toUpperCase();
    },
    getActiveOrganization() {
      let activeId = this.auth.organizations.find(
        (item) => item.id === this.auth.organizationId
      );

      if (activeId === undefined) {
        activeId = this.auth.organizationGroups.find(
          (item) => item.id === this.auth.organizationId
        );
      }

      return activeId;
    },
    isOrganizationGroup() {
      return this.auth.organizationGroups.some(
        (item) => item.id === this.auth.organizationId
      );
    },
    snackbar() {
      return this.$page.props.flash.success != "";
    },
    successMessage() {
      const success = this.$page.props.flash.success;

      const languageKey = `common.flashMessages.success.${success}`;

      if (success && this.$te(languageKey)) return this.$t(languageKey);

      return success;
    },
    errorMessage() {
      const error = this.$page.props.flash.error;

      const languageKey = `common.flashMessages.error.${error}`;

      if (error && this.$te(languageKey)) return this.$t(languageKey);

      return error;
    },
    auth() {
      return this.$page.props.auth;
    },
    language() {
      return this.$page.props.language;
    },
    hasMultipleOrganizations() {
      if (!this.auth) return false;

      const organizationCount = this.auth.organizations?.length ?? 0;

      const organizationGroupCount = this.auth.organizationGroups?.length ?? 0;

      const combinedCount = organizationCount + organizationGroupCount;

      return combinedCount > 1;
    },
  },
  data: () => ({
    drawer: true,
    mini: false,
    items: [
      {
        title: "Dashboard",
        route: "dashboard.index",
        icon: "mdi-view-dashboard",
      },
      {
        title: "Organizations",
        route: "organizations.index",
        icon: "mdi-file-tree",
      },
    ],
    LottieSpinnerOrange: JSON.stringify(LottieSpinnerOrange),
    showSuccess: false,
    showError: false,
    windowWidth: window.innerWidth,
    globalDateRange: {
      from: null,
      to: null,
    },
    loopingAnimation,
    syncingIntegrationProgress: false,
    dismissedSyncAlert: false,
    syncInterval: null,
    isFree: false,
    //showUpgradeModal: false,
  }),
  methods: {
    hasSyncingIntegrations() {
      fetch(this.route("api.integrations.isSyncing"))
        .then((response) => {
          if (!response.ok) {
            return Promise.reject();
          }
          return response.json();
        })
        .then((data) => {
          this.syncingIntegrationProgress = data?.hasNonProcessedCount;

          // stop fast checking if syncing is no longer in progess
          if (!data?.hasNonProcessedCount) {
            localStorage.removeItem("last-sync-trigger-timestamp");
          }
        })
        .catch((error) => {
          console.warn("Could not load data", error);
        });
    },
    isFreeAccount() {
      fetch(this.route("api.payments.isFreeAccount"))
        .then((response) => {
          if (!response.ok) {
            return Promise.reject();
          }
          return response.json();
        })
        .then((data) => {
          this.isFree = data;
        })
        .catch((error) => {
          console.warn("Could not get company payment state", error);
        });

      return false;
    },
    isConfirmed() {
      fetch(this.route("api.me.get"))
        .then((response) => {
          if (!response.ok) {
            return Promise.reject();
          }
          return response.json();
        })
        .then((data) => {
          if (data.emailConfirmed === false) {
            Inertia.post("/logout");
          }
        });

      return false;
    },
    /*checkBillingLimit() {
      fetch(this.route("api.payments.getBillingLimit"))
        
          console.log("bill-lim", data);
          if (
            data?.percentageFilesUsed >= 100 ||
            data?.percentageLinesUsed >= 100
          ) {
            this.showUpgradeModal = true;
          }
          this.showUpgradeModal = false;
        });
    },*/
    dateRangeChanged(dateRange) {
      // Save new date range in localstorage
      localStorage.setItem("globalDateRange", JSON.stringify(dateRange));
      // Emit update event to trigger rerender
      EventBus.$emit("globalDateRangeChanged", dateRange);
    },
    url() {
      return location.pathname.substring(1);
    },
    enableSuccess() {
      this.showSuccess = true;

      setTimeout(() => (this.showSuccess = false), 2000);
    },
    enableError() {
      this.showError = true;

      setTimeout(() => (this.showError = false), 2000);
    },
    onResize() {
      this.windowWidth = window.innerWidth;
    },
    /*upgrade() {
      // show upgrade modal
      this.showUpgradeModal = false;
    },
    cancelUpgradeModal() {
      localStorage.setItem("dismissed-billing-limit", JSON.stringify(true));
      this.showUpgradeModal = false;
    },*/
  },
  props: {
    title: {
      type: String,
      default: "",
    },
    titleValues: {
      type: Object,
      default: () => ({}),
    },
    hideMenu: {
      type: Boolean,
      default: false,
    },
    pushContent: {
      type: Boolean,
      default: false,
    },
  },
  watch: {
    successMessage() {
      this.enableSuccess();
    },
    errorMessage() {
      this.enableError();
    },
  },
  mounted() {
    this.isFreeAccount();

    const preferredLocale = this.auth.user.preferredLocale ?? "en";

    this.$i18n.locale = preferredLocale;

    initializeRemoteLanguages(this.language?.files);

    Inertia.on("success", () => {
      this.drawer = false;
    });

    Inertia.on("finish", () => {
      this.enableSuccess();
      this.enableError();
    });

    this.$nextTick(() => {
      window.addEventListener("resize", this.onResize);
    });

    // Get date range from storage if set
    const dateRangeFromStorage = JSON.parse(
      localStorage.getItem("globalDateRange")
    );
    if (dateRangeFromStorage) {
      this.globalDateRange = dateRangeFromStorage;
      EventBus.$emit("globalDateRangeChanged", dateRangeFromStorage);
    } else {
      // Else create a date with current year selected
      const dateRange = {
        from: new Date(new Date().getFullYear(), 0, 1)
          .toISOString()
          .split("T")[0],
        to: new Date(new Date().getFullYear(), 11, 31)
          .toISOString()
          .split("T")[0],
      };
      // update global date range for rendering
      this.globalDateRange = dateRange;
      // update storage date range
      localStorage.setItem("globalDateRange", JSON.stringify(dateRange));
      // emit update event to trigger data fetching
      EventBus.$emit("globalDateRangeChanged", dateRange);
    }

    // Run once and ones every minute
    this.hasSyncingIntegrations();
    this.syncInterval = setInterval(() => {
      this.hasSyncingIntegrations();
      console.info("checkHasSyncingIntegrations", "slow layout");
    }, 60000 * 3);

    // Fast sync check
    this.secondarySyncInterval = setInterval(() => {
      const secondaryCheckLimit = Date.now() - 600000; // stop checking if fast check was started more than x seconds ago
      const lastSyncTriggerTimestamp = localStorage.getItem(
        "last-sync-trigger-timestamp"
      );

      if (
        lastSyncTriggerTimestamp &&
        Number(lastSyncTriggerTimestamp) > secondaryCheckLimit
      ) {
        this.hasSyncingIntegrations();
        console.info("checkHasSyncingIntegrations", "fast layout");
      }
    }, 30000);

    // Add hubspot chat bot
    /*let shScript = document.createElement("script");
    shScript.setAttribute("src", "//js-eu1.hs-scripts.com/143160538.js");
    document.head.appendChild(shScript);*/

    EventBus.$on("organizationChanged", this.isFreeAccount);
  },
  beforeDestroy() {
    window.removeEventListener("resize", this.onResize);
  },
  updated() {
    const title = this.$te(this.title) ? this.$t(this.title) : this.title;

    document.title = title ? `${title} - Verarca` : "Verarca";
  },
};
</script>
<style>
img,
svg,
video,
canvas,
audio,
iframe,
embed,
object {
  display: block;
  vertical-align: middle;
}
img,
video {
  max-width: 100%;
  height: auto;
}

.max-w-1\/2 {
  max-width: 50%;
}

.max-w-4\/5 {
  max-width: 80%;
}
.fill-width {
  width: 100%;
}
.navigation-divider {
  border-color: rgba(255, 255, 255, 0.24) !important;
}
.cursor-pointer {
  cursor: pointer;
}
.cursor-default {
  cursor: default !important;
}
.relative {
  position: relative;
}
.v-application {
  caret-color: red !important;
}
.base-background-color {
  background-color: #f7f7f7 !important;
}
.content-header {
  background-color: #2a2a2a;
}
.font-size-4 {
  font-size: 1rem !important;
}
.transition-none {
  transition-property: none !important;
}
.min-w-1\/2 {
  min-width: 50% !important;
}
.min-w-6\/10 {
  min-width: 60% !important;
}
.h-fit-content {
  height: fit-content !important;
}
@media screen and (min-width: 960px) {
  .nav-mini {
    padding-left: 80px !important;
  }
}
@media screen and (min-width: 1264px) {
  .nav-full {
    padding-left: 240px !important;
  }
}

.v-navigation-drawer {
  overflow: visible;
}

.toggle-drawer-navigation-btn {
  position: absolute;
  right: -16px;
  background: #fff;
  top: 40px;
  z-index: 9999;
  width: 32px !important;
  height: 32px !important;
}

.v-navigation-drawer__content {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

.v-navigation-drawer__content::-webkit-scrollbar {
  display: none;
}

.toggle-drawer-navigation-btn svg {
  transition: 0.3s cubic-bezier(0.25, 0.8, 0.5, 1), visibility 0s;
}

.toggle-drawer-navigation-btn.mini svg {
  transform: rotateZ(180deg);
}

.fade-enter-active {
  transition: all 0.2s ease;
}
.fade-leave-active {
  transition: all 0.2s ease;
}
.fade-enter,
.fade-leave-to {
  transform: translateX(-20px);
  opacity: 0;
}

@media screen and (min-width: 960px) {
  .toggle-mobile-nav {
    display: none;
  }
}

@media screen and (min-width: 1264px) {
  .page-top-bar {
    padding-bottom: 0px;
    height: 87px;
  }
}

/* vuetify seems to have a bug that allows the navigation to be swiped hidden on larger screens, this could potentially become an issue on larger tablets. */
/* So we force the drawer to stay put. */
@media screen and (min-width: 960px) {
  .v-navigation-drawer--close {
    transform: translateX(0%) !important;
    visibility: visible !important;
  }
}

.copilot-margin {
  padding-bottom: 70px !important;
}
</style>
