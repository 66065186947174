<template>
  <v-tab-item :value="tabValue">
    <attribute-link-input
      v-model="emissionSource"
      :attribute="emissionSourceAttribute"
      @input="handleEmissionSourceChanged"
    />
    <attribute-link-input
      v-if="emissionSource"
      v-model="ghgCategory"
      :attribute="ghgCategoryAttribute"
      :select-options="filteredGhgCategories"
    />
    <attribute-link-input
      v-model="calculationMethod"
      :attribute="calculationMethodAttribute"
      @input="handleCalculationMethodChanged"
    />
    <template v-if="!isSupplierSpecificMethod">
      <asset-rule-fixed-input
        v-model="sector"
        :items="sectors"
        :label="$t('pages.settings.assetRuleForm.stepTwo.fields.sector')"
        @input="handleSectorChanged"
      />
      <asset-rule-fixed-input
        v-model="category"
        :items="categories"
        :label="$t('pages.settings.assetRuleForm.stepTwo.fields.category')"
        @input="handleCategoryChanged"
      />
      <asset-rule-fixed-input
        v-model="region"
        :items="regions"
        :item-text="'countryName'"
        :label="$t('pages.settings.assetRuleForm.stepTwo.fields.region')"
        @input="handleRegionChanged"
      />
      <asset-rule-fixed-input
        v-model="emissionFactorName"
        :items="emissionFactors"
        :label="
          $t('pages.settings.assetRuleForm.stepTwo.fields.emissionFactorName')
        "
      >
        <template #selectItem="{ item }">
          {{ item.name }} {{ getFactorVersionLabel(item) }}
        </template>
      </asset-rule-fixed-input>
    </template>
    <template v-else>
      <attribute-link-input
        :value="emissions"
        :attribute="emissionsAttribute"
        @decimal_changed="
          $emit('setDynamicValue', emissionsAttribute.id, $event)
        "
      />
      <attribute-link-input
        v-model="documentation"
        :attribute="documentationAttribute"
      />
    </template>
  </v-tab-item>
</template>
<script>
import AssetRuleFixedInput from "../AssetRuleFixedInput.vue";
import AttributeLinkInput from "../../../Components/Attribute/AttributeLinkInput.vue";

import {
  assetRuleActionProperty,
  co2MethodOptions,
  fixedAttributes,
} from "../../../util/fixedAssetData";

export default {
  components: {
    AssetRuleFixedInput,
    AttributeLinkInput,
  },
  props: {
    tabValue: Number,
    initialSectors: Array,
    attributes: Array,

    rerenderCounter: Number,

    reloadEmissionDropdowns: Boolean,

    getFixedValue: Function,
    getDynamicValue: Function,
  },
  emits: ["setDynamicValue", "setFixedValue", "emissionDropdownsReloaded"],
  data() {
    return {
      sectors: this.initialSectors ?? [],
      categories: [],
      regions: [],
      emissionFactors: [],
    };
  },
  computed: {
    emissionSourceAttribute() {
      return this.getAttribute(fixedAttributes.co2EmissionSourceId);
    },
    calculationMethodAttribute() {
      return this.getAttribute(fixedAttributes.co2MethodId);
    },
    emissionsAttribute() {
      return this.getAttribute(fixedAttributes.co2EmissionsId);
    },
    documentationAttribute() {
      return this.getAttribute(fixedAttributes.co2DocumentationId);
    },
    ghgCategoryAttribute() {
      return this.getAttribute(fixedAttributes.co2GhgCategoryId);
    },
    emissionSource: {
      get() {
        return this.getDynamicValue(this.emissionSourceAttribute.id);
      },
      set(value) {
        this.$emit("setDynamicValue", this.emissionSourceAttribute.id, value);
      },
    },
    calculationMethod: {
      get() {
        return this.getDynamicValue(this.calculationMethodAttribute.id);
      },
      set(value) {
        this.$emit(
          "setDynamicValue",
          this.calculationMethodAttribute.id,
          value
        );
      },
    },
    emissions() {
      this.rerenderCounter;

      return this.getDynamicValue(this.emissionsAttribute.id);
    },
    documentation: {
      get() {
        return this.getDynamicValue(this.documentationAttribute.id);
      },
      set(value) {
        this.$emit("setDynamicValue", this.documentationAttribute.id, value);
      },
    },
    ghgCategory: {
      get() {
        return this.getDynamicValue(this.ghgCategoryAttribute.id);
      },
      set(value) {
        this.$emit("setDynamicValue", this.ghgCategoryAttribute.id, value);
      },
    },
    sector: {
      get() {
        return this.getFixedValue(assetRuleActionProperty.sector);
      },
      set(value) {
        this.$emit("setFixedValue", assetRuleActionProperty.sector, value);
      },
    },
    category: {
      get() {
        return this.getFixedValue(assetRuleActionProperty.category);
      },
      set(value) {
        this.$emit("setFixedValue", assetRuleActionProperty.category, value);
      },
    },
    region: {
      get() {
        return this.getFixedValue(assetRuleActionProperty.region);
      },
      set(value) {
        this.$emit("setFixedValue", assetRuleActionProperty.region, value);
      },
    },
    emissionFactorName: {
      get() {
        return this.getFixedValue(assetRuleActionProperty.emissionFactorName);
      },
      set(value) {
        this.$emit(
          "setFixedValue",
          assetRuleActionProperty.emissionFactorName,
          value
        );
      },
    },
    isSpendBasedMethod() {
      return this.calculationMethod === co2MethodOptions.spendBasedMethodId;
    },
    isSupplierSpecificMethod() {
      return (
        this.calculationMethod === co2MethodOptions.supplierSpecificMethodId
      );
    },
    filteredGhgCategories() {
      if (!this.emissionSource) return [];

      const categories = this.ghgCategoryAttribute.attributeSelectOptions
        .filter((x) => x.dependentOptionId === this.emissionSource)
        .sort((a, b) => ((a.sortOrder ?? 0) > (b.sortOrder ?? 0) ? 1 : -1));

      return categories;
    },
    emissionFactor() {
      if (!this.emissionFactorName) return null;

      return this.emissionFactors.find((x) => x.id === this.emissionFactorName);
    },
  },
  methods: {
    handleSectorChanged(sectorId, preserveValues = false) {
      if (!preserveValues) {
        this.category = null;
        this.region = null;
        this.emissionFactorName = null;
      }

      this.categories = [];
      this.regions = [];
      this.emissionFactors = [];

      if (!sectorId) return;

      this.loading = true;

      return fetch(
        this.route("api.emission-categories.index", {
          emissionSectorId: sectorId,
          spendBased: this.isSpendBasedMethod,
        })
      )
        .then((res) => res.json())
        .then((data) => (this.categories = data))
        .finally(() => (this.loading = false));
    },
    handleCategoryChanged(categoryId, preserveValues = false) {
      if (!preserveValues) {
        this.region = null;
        this.emissionFactorName = null;
      }

      this.regions = [];
      this.emissionFactors = [];

      if (!categoryId) return;

      this.loading = true;

      return fetch(
        this.route("api.emission-regions.index", {
          emissionCategoryId: categoryId,
          spendBased: this.isSpendBasedMethod,
        })
      )
        .then((res) => res.json())
        .then((data) => (this.regions = data))
        .finally(() => (this.loading = false));
    },
    handleRegionChanged(regionId, preserveValues = false) {
      if (!preserveValues) {
        this.emissionFactorName = null;
      }

      this.emissionFactors = [];

      if (!regionId) return;

      this.loading = true;

      return fetch(
        this.route("api.emission-factors.index", {
          emissionSectorId: this.sector,
          emissionCategoryId: this.category,
          emissionRegionId: regionId,
          emissionFactorId: this.emissionFactorName,
          spendBased: this.isSpendBasedMethod,
        })
      )
        .then((res) => res.json())
        .then((data) => (this.emissionFactors = data))
        .finally(() => (this.loading = false));
    },
    handleCalculationMethodChanged(calculationMethod) {
      this.sector = null;
      this.category = null;
      this.region = null;
      this.emissionFactorName = null;

      this.sectors = [];
      this.categories = [];
      this.regions = [];
      this.emissionFactors = [];

      const spendBased =
        calculationMethod === co2MethodOptions.spendBasedMethodId;

      this.loading = true;

      return fetch(
        this.route("api.emission-sectors.index", {
          spendBased,
        })
      )
        .then((res) => res.json())
        .then((data) => (this.sectors = data))
        .finally(() => (this.loading = false));
    },
    handleEmissionSourceChanged() {
      this.ghgCategory = null;
    },
    getAttribute(attributeId) {
      return this.attributes.find((x) => x.id === attributeId);
    },
    async reloadEmissionDropdownData() {
      console.log("HERE");

      try {
        if (!this.sector) return;

        await this.handleSectorChanged(this.sector, true);

        if (!this.category) return;

        await this.handleCategoryChanged(this.category, true);

        if (!this.region) return;

        await this.handleRegionChanged(this.region, true);
      } finally {
        this.$emit("emissionDropdownsReloaded");
      }
    },
    getFactorVersionLabel(factor) {
      const { activityId, yearReleased, yearsCalculatedFrom } =
        this.emissionFactor ?? {};

      if (factor.activityId !== activityId) return "";

      const year = yearsCalculatedFrom ?? yearReleased;
      const factorYear = factor.yearsCalculatedFrom ?? factor.yearReleased;

      const labelText =
        factorYear > year
          ? this.$t("common.statuses.updated")
          : this.$t("common.statuses.outdated");

      return `(${labelText})`;
    },
  },
  watch: {
    reloadEmissionDropdowns(value) {
      if (!value) return;

      this.reloadEmissionDropdownData();
    },
  },
  mounted() {
    if (this.reloadEmissionDropdowns) {
      this.reloadEmissionDropdownData();
    }
  },
};
</script>
