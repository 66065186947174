<template>
  <dialog-form
    :show-dialog="show"
    :loading="loading"
    @showStateChanged="show = $event"
    max-width="500px"
  >
    <integration-dynamics-365-environment-modal
      v-model="showDynamicsIntegration"
    ></integration-dynamics-365-environment-modal>
    <dialog-form-header-stepper
      :on-dismiss="() => (show = false)"
    ></dialog-form-header-stepper>
    <dialog-form-section-one-col class="py-6 px-2">
      <v-btn
        tile
        large
        elevation="0"
        icon
        class="btn-integration"
        @click="economicClicked"
      >
        <VismaEconomicLogoIcon />
      </v-btn>
    </dialog-form-section-one-col>
    <dialog-form-section-one-col class="py-6 px-2">
      <v-btn
        tile
        large
        elevation="0"
        icon
        class="btn-integration"
        @click="dynamics365Clicked"
      >
        <Dynamics365LogoIcon />
      </v-btn>
    </dialog-form-section-one-col>
  </dialog-form>
</template>
<script>
import DialogForm from "../../Components/Dialog/DialogForm.vue";
import DialogFormHeaderStepper from "../../Components/Dialog/DialogFormHeaderStepper.vue";
import DialogFormSectionOneCol from "../../Components/Dialog/DialogFormSectionOneCol.vue";
import VismaEconomicLogoIcon from "../../assets/svg/visma-economic-logo.svg";
import Dynamics365LogoIcon from "../../assets/svg/dynamic365.svg";
import IntegrationDynamics365EnvironmentModal from "./IntegrationDynamics365EnvironmentModal.vue";

export default {
  components: {
    DialogForm,
    DialogFormHeaderStepper,
    DialogFormSectionOneCol,
    VismaEconomicLogoIcon,
    Dynamics365LogoIcon,
    IntegrationDynamics365EnvironmentModal,
  },
  props: {
    value: Boolean,
  },
  data() {
    return {
      loading: false,
      showDynamicsIntegration: false,
    };
  },
  computed: {
    show: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit("input", value);
      },
    },
  },
  methods: {
    economicClicked() {
      window.location.href = this.route("api.settings.economic.install");
    },
    dynamics365Clicked() {
      // window.location.href = this.route("api.settings.dynamics365.install");
      this.showDynamicsIntegration = true;
    },
  },
};
</script>
<style scoped>
.btn-integration {
  width: 100%;
  position: relative;
  border: 2px solid #e8e8e8;
  padding-top: 2rem;
  padding-bottom: 2rem;
}

.btn-integration svg {
  position: absolute;
  left: 0;
  right: 0;
  margin: 0 auto;
}
</style>
