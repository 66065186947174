<template>
  <div class="page-container fill-height grow d-flex flex-column flex-nowrap">
    <v-card elevation="0" class="d-flex flex-column ma-4">
      <v-card-text class="pa-0 flex-grow-1">
        <v-row class="d-flex align-start">
          <v-spacer></v-spacer>
          <v-col col="1" class="d-flex justify-end">
            <v-btn
              large
              class="align-self-stretch align-self-sm-center mt-5 mt-sm-0"
              elevation="0"
              tile
              color="#F25D3B"
              dark
              v-inertia
              :href="route('roles.create')"
            >
              <v-icon class="pr-3 block" color="#fff" small>mdi-plus</v-icon>
              {{ $t("pages.roles.index.create") }}
            </v-btn>
          </v-col>
        </v-row>
        <v-data-table
          class="elevation-0"
          :headers="headers"
          :items="filteredRoles"
          :item-class="isRowDisabled"
          :server-items-length="roles.totalCount"
          :options.sync="pagination"
          :must-sort="true"
          :footer-props="tableFooterProps"
          @click:row="rowClick"
          @update:page="changePage(pagination)"
          @update:items-per-page="changePage(pagination)"
          @update:sort-desc="changePage(pagination)"
          @update:sort-by="changePage(pagination)"
        >
          <template v-slot:item.createdAt="{ item }">
            {{ new Date(item.createdAt).toLocaleString() }}
          </template>
          <template v-slot:item.color="{ item }">
            <v-chip :color="item.color" dark></v-chip>
          </template>
          <template v-slot:item.actions>
            <v-icon class="mr-2"> mdi-chevron-right </v-icon>
          </template>
          <template #footer.page-text="pageTextProps">
            {{ $t("common.dataTable.visibleRows", pageTextProps) }}
          </template>
        </v-data-table>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import debounce from "lodash/debounce";
import pickBy from "lodash/pickBy";
import { appLayout } from "@/util/layout";
import { footerProps } from "@/util/dataTable";

export default {
  layout: appLayout({ title: "pages.roles.index.title" }),
  props: {
    roles: Object,
  },
  data() {
    var searchParams = new URLSearchParams(window.location.search);
    return {
      pagination: {
        page: this.roles.currentPage,
        itemsPerPage: 50,
        sortBy: [searchParams.get("sortBy") || "name"],
        sortDesc: [searchParams.get("sortDesc") === "true"],
      },
      tableFooterProps: footerProps,
    };
  },
  watch: {
    filters: {
      handler: debounce(function () {
        this.pagination = { page: 1, sortBy: [], sortDesc: [] };
        this.changePage(this.pagination);
      }, 150),
      deep: true,
    },
  },
  computed: {
    headers() {
      return [
        { text: this.$t("common.fieldNames.name"), value: "name" },
        {
          text: this.$t("pages.roles.index.columns.description"),
          value: "description",
        },
        {
          text: this.$t("pages.roles.index.columns.organization"),
          value: "organization.name",
          sortable: false,
        },
        { text: this.$t("pages.roles.index.columns.color"), value: "color" },
        {
          text: this.$t("common.fieldNames.actions"),
          value: "actions",
          sortable: false,
          align: "right",
        },
      ];
    },
    filteredRoles() {
      let data = this.roles.data;

      // Remove employee and executive
      data = data.filter(
        (item) =>
          item.id != "5f7c2eae-52f0-4ee4-bc3d-c74d16e964bc" &&
          item.id != "8457ab36-c569-4d68-8b6b-de463c969c94"
      );

      return data;
    },
  },
  methods: {
    rowClick(item) {
      if (this.isRowDisabled(item)) return;

      this.$inertia.get(this.route("roles.edit", item.id));
    },
    changePage(options) {
      let query = {
        filters: pickBy(this.filters),
        sortBy: options.sortBy[0],
        sortDesc: options.sortDesc[0],
        page: options.page,
        pageSize: options.itemsPerPage,
      };
      this.$inertia.get(this.route("roles.index", query));
    },
    isRowDisabled(item) {
      return !item.organizationId ? "disabled" : "";
    },
  },
};
</script>
<style lang="css" scoped>
.row-pointer >>> tbody tr :hover {
  cursor: pointer;
}

.v-data-table >>> .disabled td {
  background: lightgray !important;
}
</style>
