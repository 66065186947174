<template>
  <div class="ai-status">
    <v-btn
      v-if="type === 'approved'"
      class="ai-btn d-flex flex-row rounded px-2 justify-start text-none"
      color="#266663"
      text
      x-small
      elevation="0"
      style="background-color: #e5edec"
    >
      <div class="icon">
        <VerifiedIcon />
      </div>
      <div
        class="status-label-wrapper"
        :class="collapsable ? 'collapsable' : null"
      >
        <span v-if="displayLabel" class="status-label pl-1">
          {{ $t("common.aiStatus.verified") }}
        </span>
      </div></v-btn
    >
    <v-btn
      v-else-if="type === 'undefined'"
      class="ai-btn d-flex flex-row rounded px-2 justify-start text-none"
      color="#F8A02B"
      text
      x-small
      elevation="0"
      style="background-color: #fef2e1"
    >
      <div class="icon">
        <QuestionMarkIcon />
      </div>
      <div
        class="status-label-wrapper"
        :class="collapsable ? 'collapsable' : null"
      >
        <span v-if="displayLabel" class="status-label pl-1">
          {{ $t("common.aiStatus.undefined") }}
        </span>
      </div></v-btn
    >
    <v-btn
      v-else-if="type === 'defined'"
      class="ai-btn d-flex flex-row rounded px-2 justify-start red--text"
      x-small
      color="#F25D3B"
      elevation="0"
      style="background-color: #fdece7"
    >
      <div class="icon">
        <AIRobotIcon />
      </div>
      <div
        class="status-label-wrapper"
        :class="collapsable ? 'collapsable' : null"
      >
        <span v-if="displayLabel" class="status-label pl-1">
          {{ $t("common.aiStatus.ai") }}
        </span>
      </div></v-btn
    >
    <activity-indicator
      class="ai-btn d-flex flex-row align-center relative"
      v-else-if="type === 'defining'"
    ></activity-indicator>
  </div>
</template>

<script>
/*
<v-btn
      v-else-if="type === 'defined'"
      class="ai-btn d-flex flex-row rounded px-2 justify-start text-none"
      color="#266663"
      text
      x-small
      elevation="0"
      style="background-color: #e5edec"
    >
      <div class="icon">
        <VerifiedIcon />
      </div>
      <div
        class="status-label-wrapper"
        :class="collapsable ? 'collapsable' : null"
      >
        <span v-if="displayLabel" class="status-label pl-1">
          {{ $t("common.aiStatus.defined") }}  
        </span>
      </div></v-btn
    >

*/

/**

Defining = The process is either in the queue or is processing.

Defined = The AI gave a result but the user hasn’t confirmed the result yet.

Undefined = The AI was unable to give a result

Approved = The user has either approved the AI result or the user has manually enter/modified the values. 

 */
import AIRobotIcon from "../../assets/svg/ai-robot.svg";
import VerifiedIcon from "../../assets/svg/verified.svg";
import QuestionMarkIcon from "../../assets/svg/question-mark-circle.svg";
import ActivityIndicator from "../Outputs/ActivityIndicator.vue";

export default {
  components: {
    AIRobotIcon,
    VerifiedIcon,
    QuestionMarkIcon,
    ActivityIndicator,
  },
  props: {
    type: String,
    displayLabel: { type: Boolean, default: true },
    collapsable: { type: Boolean, default: true },
  },
};
</script>

<style lang="scss" scoped>
.ai-status {
  position: relative;
  transition-duration: 250ms;
  transition-property: all;

  .ai-btn {
    min-width: 28px;
  }

  .icon {
    width: 12px;
    height: 12px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .status-label-wrapper {
    width: auto;
    overflow: hidden;
    transition-duration: 250ms;
    transition-property: all;
    display: block;
  }

  .collapsable {
    width: 0px;
  }

  &:hover .status-label-wrapper {
    width: calc(100% - 2px);
  }
}
</style>
