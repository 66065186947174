<template>
  <dialog-form :show-dialog="show" max-width="600px">
    <dialog-form-section-one-col class="py-6 px-2">
      <div class="py-8 d-flex justify-center">
        <LogoBlack />
      </div>
      <h1 class="text-subtitle-1 grey--text mb-1 font-weight-light text-center">
        {{
          $t(
            "pages.settings.integrationDynamics365.environmentSelection.inputEnvironment"
          )
        }}
      </h1>
      <div class="mb-6 text-center">
        <a
          href="https://learn.microsoft.com/en-us/dynamics365/business-central/dev-itpro/administration/tenant-admin-center"
          target="_blank"
          rel="noopener noreferrer"
          class="text-body-2 black--text"
          >{{
            $t(
              "pages.settings.integrationDynamics365.environmentSelection.seeEnvironmentGuide"
            )
          }}</a
        >
      </div>
      <input
        autofocus
        type="environment"
        autocomplete="environment"
        v-model="environment"
        :placeholder="
          $t(
            'pages.settings.integrationDynamics365.environmentSelection.production'
          )
        "
        name="environment"
        class="lightGrey black--text pl-4 pt-2 pb-2 mb-4 text-caption input"
        style="width: 100%"
      />
      <v-btn
        tile
        block
        elevation="0"
        color="orange"
        class="font-weight-regular text-capitalize mb-2 white--text"
        type="submit"
        @click="submitEnviroment"
      >
        {{ $t("common.actions.submit") }}
      </v-btn>
    </dialog-form-section-one-col>
  </dialog-form>
</template>

<script>
import DialogForm from "../../Components/Dialog/DialogForm.vue";
import DialogFormSectionOneCol from "../../Components/Dialog/DialogFormSectionOneCol.vue";
import LogoBlack from "../../assets/svg/verarca-logo-black.svg";
export default {
  components: {
    DialogForm,
    DialogFormSectionOneCol,
    LogoBlack,
  },
  props: {
    value: Boolean,
  },
  data() {
    return {
      environment: "",
    };
  },
  computed: {
    show: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit("input", value);
      },
    },
  },
  methods: {
    submitEnviroment() {
      const params = new URLSearchParams();
      params.append("environment", this.environment);
      const url =
        this.route("api.settings.dynamics365.install") +
        "?" +
        params.toString();
      window.location.href = url;
    },
  },
};
</script>

<style></style>
