<template>
  <dialog-form
    :show-dialog="show"
    :max-width="dialogMaxWidth"
    :persistent="true"
  >
    <div class="d-flex flex-column px-0 pt-16">
      <div class="pb-8 d-flex justify-center">
        <LogoBlack />
      </div>
      <step-visualizer
        class="pb-8"
        :steps="5"
        :selected-step="selectedOnboardingStep"
        ref="stepVisualizer"
        @input="/*(step) => (selectedOnboardingStep = step)*/"
        :hiddenStep="2"
      />
      <div style="max-width: 716px; align-self: center">
        <h1 class="text-h4 black--text mb-2 text-center">
          {{ currentStep.title }}
        </h1>
        <h2
          class="text-subtitle-1 grey--text mb-6 font-weight-light text-center"
        >
          {{ currentStep.subTitle }}<br />
          {{ currentStep.subTitleLine2 }}
          <a :href="currentStep.subTitleLink">{{
            currentStep.subTitleLinkText
          }}</a>
        </h2>
      </div>

      <v-alert
        type="error"
        v-if="
          integrationError &&
          $te(`common.integrations.errors.${integrationError}`)
        "
        class="mb-6 mx-8"
      >
        {{ $t(`common.integrations.errors.${integrationError}`) }}
      </v-alert>

      <div
        class="d-flex align-center relative"
        style="overflow-y: auto; min-height: 300px"
        :style="
          selectedOnboardingStep === 5
            ? 'height: calc(100vh - 527px);max-height: 527px;'
            : null
        "
      >
        <Transition name="fade">
          <onboarding-step-one
            v-if="selectedOnboardingStep === 1"
            class="transition-item"
            @updateForm="updateForm"
            :companyRegNo="companyRegNo"
            :companyName="companyName"
            :companyEmail="companyEmail"
          />
          <onboarding-step-two
            v-if="selectedOnboardingStep === 2"
            class="transition-item"
            :fiscalYear.sync="fiscalYear"
            :fiscalYears="fiscalYears"
            :depreciation-starts="depreciationStarts"
            :depreciation-methods="depreciationMethods"
            :currencies="currencies"
            :countries="countries"
            :default-currency="getDefaultCurrency()"
            :default-region="getDefaultRegion()"
            @updateForm="updateForm"
          />
          <onboarding-step-three
            v-if="selectedOnboardingStep === 3"
            class="transition-item"
            :selected-integration.sync="selectedIntegration"
            @otherIntegration="handleOtherIntegration"
            @updateForm="updateForm"
          />
          <!--<onboarding-step-four
            v-if="selectedOnboardingStep === 4"
            class="transition-item"
            :selected-integration="selectedIntegration"
          />-->
          <onboarding-step-five
            v-if="selectedOnboardingStep === 5"
            class="transition-item"
            :integrationId="integrationId"
            ref="account-mapper"
            style="overflow-y: scroll; overflow-x: hidden; height: 100%"
            :mappingIsReadOnly="mappingIsReadOnly"
          />
        </Transition>
        <integration-dynamics-365-environment-modal
          v-model="showDynamicsIntegration"
        ></integration-dynamics-365-environment-modal>
      </div>
      <v-card-actions
        v-if="selectedOnboardingStep == 5"
        class="d-flex flex-row footer-section pa-8"
        style="border-top: 1px solid #e8e8e8"
      >
        <v-spacer></v-spacer>
        <!--<v-btn
          tile
          outlined
          elevation="0"
          bg-color="white"
          class="border-sm text-capitalize black--text black--border white pa-0 ml-0"
          @click="selectedOnboardingStep += -1"
          style="flex: 1"
        >
          {{ $t("components.onboarding.goBack") }}
        </v-btn>-->
        <!--<v-btn
          v-if="selectedOnboardingStep !== 4"
          tile
          large
          outlined
          elevation="0"
          bg-color="white"
          class="border-sm text-none black--text black--border white pa-0 ml-0"
          style="flex: 1"
          @click="skipMapping()"
        >
          {{ $t("components.onboarding.skip") }}
        </v-btn>-->
        <v-btn
          v-if="mappingIsReadOnly"
          tile
          large
          outlined
          elevation="0"
          bg-color="white"
          class="border-sm text-none black--text black--border white pa-0 ml-0"
          style="flex: 1"
          @click="editMapping()"
        >
          {{ $t("components.onboarding.edit") }}
        </v-btn>
        <v-btn
          v-else
          tile
          large
          outlined
          elevation="0"
          bg-color="white"
          class="border-sm text-none black--text black--border white pa-0 ml-0"
          style="flex: 1"
          @click="cancelMapping()"
        >
          {{ $t("components.onboarding.cancel") }}
        </v-btn>
        <v-btn
          tile
          large
          elevation="0"
          color="orange"
          class="font-weight-regular text-none white--text"
          type="submit"
          :disabled="isContinueDisabled"
          @click="moveToNextStep()"
          style="flex: 1"
        >
          {{ $t("components.onboarding.continue") }}
        </v-btn>
      </v-card-actions>
      <v-card-actions v-else class="d-flex flex-column footer-section pa-8">
        <v-btn
          tile
          block
          elevation="0"
          color="orange"
          class="font-weight-regular text-none white--text pa-0 mb-4"
          type="submit"
          :disabled="isContinueDisabled"
          @click="moveToNextStep()"
        >
          {{
            selectedOnboardingStep === 3
              ? connectSystemButtonText
              : $t("components.onboarding.next")
          }}
        </v-btn>
        <v-btn
          v-if="selectedOnboardingStep !== 4"
          tile
          outlined
          block
          elevation="0"
          bg-color="white"
          class="border-sm text-none black--text black--border white pa-0 ml-0"
          @click="hideModal()"
        >
          {{
            selectedOnboardingStep === 4
              ? $t("components.onboarding.stepFour.apiFields")
              : $t("components.onboarding.skip")
          }}
        </v-btn>
        <!-- Hiding the api fields popup currently because they are not used -->
      </v-card-actions>
    </div>
  </dialog-form>
</template>
<script>
import OnboardingStepOne from "./OnboardingSteps/OnboardingStepOne.vue";
import OnboardingStepTwo from "./OnboardingSteps/OnboardingStepTwo.vue";
import OnboardingStepThree from "./OnboardingSteps/OnboardingStepThree.vue";
//import OnboardingStepFour from "./OnboardingSteps/OnboardingStepFour.vue";
import DialogForm from "../Dialog/DialogForm.vue";
import LogoBlack from "../../assets/svg/verarca-logo-black.svg";
import StepVisualizer from "../StepVisualizer.vue";
import { serialize } from "object-to-formdata";
import OnboardingStepFive from "./OnboardingSteps/OnboardingStepFive.vue";
import IntegrationDynamics365EnvironmentModal from "../../Pages/Setting/IntegrationDynamics365EnvironmentModal.vue";

export default {
  components: {
    OnboardingStepOne,
    OnboardingStepTwo,
    OnboardingStepThree,
    /*OnboardingStepFour,*/
    DialogForm,
    LogoBlack,
    StepVisualizer,
    OnboardingStepFive,
    IntegrationDynamics365EnvironmentModal,
  },
  props: {
    selectedStep: {
      type: Number,
      default: 1,
    },
    value: Boolean,
    settings: Object,
    fiscalYears: Array,
    depreciationMethods: Array,
    depreciationStarts: Array,
    currencies: Array,
    countries: Array,
    integrationId: String,
    integrationError: String,
    companyRegNo: String,
    companyName: String,
    companyEmail: String,
    integrationInstalled: Boolean
  },
  data() {
    return {
      form: this.$inertia.form(this.generateInitialForm()),
      financialSystemForm: this.$inertia.form(
        this.generateInitialFinancialSystemForm()
      ),

      fiscalYear: "",

      showDynamicsIntegration: false,

      selectedOnboardingStep: this.selectedStep,
      selectedIntegration: "",
      mappingIsReadOnly: true,
    };
  },
  computed: {
    show: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit("input", value);
      },
    },
    currentStep() {
      const onboardingSteps = [
        {
          id: 1,
          title: this.$t("components.onboarding.titles.title1"),
          subTitle: this.$t("components.onboarding.subtitles.subtitle1"),
        },
        {
          id: 2,
          title: this.$t("components.onboarding.titles.title2"),
          subTitle: this.$t("components.onboarding.subtitles.subtitle2"),
        },
        {
          id: 3,
          title: this.$t("components.onboarding.titles.title3"),
          subTitle: this.$t("components.onboarding.subtitles.subtitle3"),
        },
        {
          id: 4,
          title: this.$t("components.onboarding.titles.title4"),
          subTitle: this.$t("components.onboarding.subtitles.subtitle4"),
        },
        {
          id: 5,
          title: this.$t("components.onboarding.titles.title5"),
          subTitle: this.$t("components.onboarding.subtitles.subtitle5"),
          subTitleLine2: this.$t(
            "components.onboarding.subtitles.subtitle5Line2"
          ),
          subTitleLink: this.$t(
            "components.onboarding.subtitles.subtitle5Link"
          ),
          subTitleLinkText: this.$t(
            "components.onboarding.subtitles.subtitle5LinkText"
          ),
        },
      ];

      if (this.selectedOnboardingStep === 5 && this.mappingIsReadOnly) {
        return {
          title: this.$t("components.onboarding.titles.title5ReadOnly"),
          subTitle: this.$t(
            "components.onboarding.subtitles.subtitle5ReadOnly"
          ),
          subTitleLine2: this.$t(
            "components.onboarding.subtitles.subtitle5Line2ReadOnly"
          ),
        };
      }

      return onboardingSteps.find((x) => x.id === this.selectedOnboardingStep);
    },
    isContinueDisabled() {
      if (this.selectedOnboardingStep === 1) {
        return !(
          this.form.companyName &&
          this.form.companyRegNo &&
          this.form.companyEmail
        );
      }

      if (this.selectedOnboardingStep === 3) {
        return this.selectedIntegration === "";
      }

      return false;
    },
    dialogMaxWidth() {
      if (this.selectedOnboardingStep === 5) {
        return "960px";
      }
      return "538px";
    },
    connectSystemButtonText() {
      if (this.selectedIntegration == "other") {
        return this.$t(
          "components.onboarding.stepFour.continueWithoutIntegration"
        );
      }

      return this.selectedOnboardingStep === 3
        ? this.$t("components.onboarding.stepFour.clickHereToConnect") +
            " " +
            this.selectedIntegration
        : this.$t("components.onboarding.next");
    },
  },
  methods: {
    editMapping() {
      this.mappingIsReadOnly = false;
    },
    cancelMapping() {
      this.mappingIsReadOnly = true;
    },
    moveToNextStep() {
      if (
        this.selectedOnboardingStep === 3 &&
        this.selectedIntegration == "other"
      ) {
        this.finishOnboarding();
      }

      /*if (this.selectedOnboardingStep === 4) {
        if (this.selectedIntegration === "e-conomic") {
          this.redirectToEconomic();
        } else if (this.selectedIntegration === "dynamic365") {
          this.showDynamicsIntegration = true;
        }
        //this.selectedOnboardingStep = 5;
        return;
      }
      if (this.selectedOnboardingStep === 3) {
        if (this.financialSystemForm["financialSystem"] !== null) {
          this.submitFinancialSystemForm();
        }
        if (
          this.selectedIntegration === null ||
          this.selectedIntegration === "other"
        ) {
          this.close();
        }
      }*/
      if (this.selectedOnboardingStep === 3) {
        if (this.selectedIntegration === "e-conomic") {
          this.redirectToEconomic();
        } else if (this.selectedIntegration === "dynamic365") {
          this.showDynamicsIntegration = true;
        }
        //this.selectedOnboardingStep = 5;
        return;
      }
      if (this.selectedOnboardingStep === 2) {
        this.submitForm();
      }
      if (this.selectedOnboardingStep === 5) {
        this.$refs["account-mapper"].setLinkedAccounts(() =>
          this.$emit("onAccountMappingSuccess")
        );
        return;
      }
      this.selectedOnboardingStep = (this.currentStep?.id ?? 0) + 1;

      //this.$refs.stepVisualizer.goToStep(this.selectedOnboardingStep);
      this.$emit("update:selected-step", this.selectedOnboardingStep);
    },
    hideModal() {
      if (this.selectedOnboardingStep === 4) {
        this.$emit("show-api-fields");
      } else {
        this.$emit("show-warning");
      }
    },
    skipMapping() {
      this.$emit("skip-mapping");
    },
    async close() {
      this.$emit("close-all");
      this.show = false;
    },
    finishOnboarding() {
      this.submitForm();
      this.selectedOnboardingStep = 2;
      this.close();
    },
    redirectToEconomic() {
      const redirectToOnboarding = true;
      const queryParams = new URLSearchParams({ redirectToOnboarding });
      const url =
        this.route("api.settings.economic.install") +
        "?" +
        queryParams.toString();
      window.location.href = url;
    },
    updateForm(key, value) {
      this.$set(this.form, key, value);
    },
    handleOtherIntegration(value) {
      this.financialSystemForm["financialSystem"] = value;
    },
    submitForm() {
      this.form
        .transform((data) =>
          serialize(data, { noFilesWithArrayNotation: true })
        )
        .put(this.route("onboarding.settings"), {
          onSuccess: () => {
            this.form = this.$inertia.form(this.generateInitialForm());
          },
        });
    },
    submitFinancialSystemForm() {
      this.financialSystemForm
        .transform((data) =>
          serialize(data, { noFilesWithArrayNotation: true })
        )
        .post(this.route("onboarding.integration"), {
          onSuccess: () => {
            this.financialSystemForm = this.$inertia.form(
              this.generateInitialFinancialSystemForm()
            );
          },
        });
    },
    generateInitialForm() {
      const defaultCurrency = this.getDefaultCurrency();

      return {
        enableAssetRegister: false,
        enableEmissionReporting: true,
        financialSystem: null,
        companyRegNo: this.companyRegNo,
        companyName: this.companyName,
        companyEmail: this.companyEmail,
        defaultCurrencyId: defaultCurrency?.id ?? null,
        defaultRegion: this.getDefaultRegion() ?? null,
        //defaultDepreciationMethodId: this.depreciationMethods[0].id,
        //defaultStartOfDepreciationId: this.depreciationStarts[0].id,
      };
    },
    generateInitialFinancialSystemForm() {
      return {
        financialSystem: null,
      };
    },

    getDefaultCurrency() {
      return this.currencies[
        this.currencies.findIndex((currency) => currency.currencyCode === "DKK")
      ];
    },
    getDefaultRegion() {
      //return this.settings.defaultRegionId;
      return "denmark";
    },
  },
  mounted() {
    if (this.settings.skippedOnboarding && !this.integrationInstalled) {
      this.selectedOnboardingStep = 3;
    }
  },
};
</script>
<style>
.fade-enter-active {
  transition: all 0.4s ease;
}
.fade-leave-active {
  transition: all 0.2s ease;
}
.fade-enter {
  transform: translateX(20px);
  opacity: 1;
}

.fade-leave-to {
  transform: translateX(-20px);
  opacity: 0;
}
.transition-item {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
}
.footer-section {
  position: sticky;
  bottom: 0;
  background: #fff;
  /*border-top: 1px solid #e8e8e8;*/
}
</style>
